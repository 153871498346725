import React, { useState } from 'react';
import axios from 'axios';

const EditVendor = ({ vendor, locations }) => {  
    const { 
        id: vendorId,
        primary_location,
        business_name,
        secondary_location,
        third_location,
        in_quiz,
    } = vendor

    const [inQuiz, setInQuiz] = useState(in_quiz)

    const toggleQuiz = (e) => {
        setInQuiz(e)
        axios.post('/api/update-vendor-in-quiz', {in_quiz: e, id: vendorId})
    }
    const updatePrimaryLocation = (locationId) => {
        axios.post('/api/update-vendor-primary-location', {location: locationId, id: vendorId})
    }
    const updateSecondaryLocation = (locationId) => {
        axios.post('/api/update-vendor-secondary-location', {location: locationId, id: vendorId})
    }
    const updateThirdLocation = (locationId) => {
        axios.post('/api/update-vendor-third-location', {location: locationId, id: vendorId})
    }
    return(
        <div style={{padding: '24px'}}>
            <div style={{textAlign: 'center'}}>{business_name}</div>
            
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="primary">Primary Location: </label>         
            <select 
                style={{background: 'white'}} name='primary' defaultValue={primary_location}  onChange={(e) => updatePrimaryLocation(e.target.value)}>
                <option value="">------</option>            
                {locations.map(l =><option value={l.id}>{l.name}</option>)}
            </select>
        </div>
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="secondary">Secondary Location: </label>         
            <select 
                style={{background: 'white'}} name='secondary' defaultValue={secondary_location}  onChange={(e) => updateSecondaryLocation(e.target.value)}>
                <option value="">------</option>            
                {locations.map(l =><option value={l.id}>{l.name}</option>)}
            </select>
        </div>
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="third">Third Location: </label>         
            <select 
                style={{background: 'white'}} name='third' defaultValue={third_location}  onChange={(e) => updateThirdLocation(e.target.value)}>
                <option value="">------</option>            
                {locations.map(l =><option value={l.id}>{l.name}</option>)}
            </select>
        </div>
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="type">In Quiz: </label>  
            <input 
                name='type'
                type='checkbox'
                checked={inQuiz} 
                onChange={(e) => toggleQuiz(e.target.checked)} />
        </div>

        </div>
    )
}

export default EditVendor