import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../UserContext";
import DashboardNav from "./dashboard-nav.component";
import axios from "axios";
import styled from "styled-components";
import { PhotographerContext } from "../PhotographerContext";

const ActivityInput = (props) => {
	return(
		<div>
		<input
			className="dashboard-personality-input"
			type="number"
			onChange={(e) => props.setValue(e.target.value)}
			onBlur={() => props.checkActivityDuplicates(props.activityName.toLowerCase())}
			value={props.activity}
		/>		
		{props.activityName}{" "}
		</div>
	)
}

const PreferenceInput = (props) => {
	return(
		<div>
			<input
				className="dashboard-personality-input"
				type="number"
				onChange={(e) => props.setValue(e.target.value)}
				onBlur={() => props.checkPreferenceDuplicates(props.preferenceShortName)}
				value={props.preference}
			/>{" "}
			{props.preferenceName}
		</div>
	)
}

const Table1 = styled.div`
	width: 700px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
	padding: 20px 0;
	text-align: left;

	@media only screen and (max-device-width: 500px) {
		display: grid;
		width: 100%;
		grid-template-columns: 1fr 1fr;
	}
`;

const Row = styled.div`@media only screen and (max-device-width: 500px) {margin: 0 20px;}`;

const Row2 = styled.div`@media only screen and (max-device-width: 500px) {margin: 0 5px;}`;

const Form = styled.form`text-align: center;`;

const DashboardPersonality = () => {
	let { id } = useParams();

	const [ hiking, setHiking ] = useState(null);
	const [ cooking, setCooking ] = useState(null);
	const [ dancing, setDancing ] = useState(null);
	const [ beach, setBeach ] = useState(null);
	const [ concerts, setConcerts ] = useState(null);
	const [ art, setArt ] = useState(null);
	const [ pets, setPets ] = useState(null);
	const [ sports, setSports ] = useState(null);
	const [ travel, setTravel ] = useState(null);
	const [ reading, setReading ] = useState(null);
	const [ charge, setCharge ] = useState(null);
	const [ fly, setFly ] = useState(null);
	const [ relax, setRelax ] = useState(null);
	const [ ask, setAsk ] = useState(null);
	const [ toggleNav, setToggleNav ] = useState(false);
	const [ whyILove, setWhyILove ] = useState('')
	const { setPersonalityComplete, profileComplete, personalityTouched, setPersonalityTouched} = useContext(PhotographerContext);
	const { setPhotographer, setActivePage } = useContext(UserContext);

	useEffect(() => {
		setPersonalityTouched(false)
		setActivePage('personalityDashboard')
		axios.get("/api/photographerdata/" + id).then((result) => {
			setHiking(result.data.hiking);
			setCooking(result.data.cooking);
			setDancing(result.data.dancing);
			setBeach(result.data.beach);
			setConcerts(result.data.concerts);
			setArt(result.data.art);
			setPets(result.data.pets);
			setSports(result.data.sports);
			setTravel(result.data.travel);
			setReading(result.data.reading);
			setAsk(result.data.ask);
			setFly(result.data.fly);
			setRelax(result.data.relax);
			setCharge(result.data.charge);
			setPhotographer(id);
			setWhyILove(result.data.why_i_love)
		});
	}, []);

	const checkPersonalityComplete = () => {
		setPersonalityTouched(true)
		if(hiking && hiking != 99 && cooking && cooking != 99 && dancing && dancing != 99 && beach && beach != 99 && concerts && concerts != 99 && 
			art && art != 99 && pets && pets != 99 && sports && sports != 99 && travel && travel != 99 && reading && reading != 99 && ask && fly && relax && charge){
				setPersonalityComplete(true)
		} else{
			setPersonalityComplete(false)
		}
		
	}

	const checkActivityDuplicates = (activity) => {
		setPersonalityTouched(true)
		if (activity != "hiking" && hiking) {
			if (
				hiking == cooking ||
				hiking == dancing ||
				hiking == beach ||
				hiking == concerts ||
				hiking == art ||
				hiking == pets ||
				hiking == sports ||
				hiking == travel ||
				hiking == reading
			) {
				setHiking(99);
			}
		}
		if (activity != "cooking" && cooking) {
			if (
				cooking == hiking ||
				cooking == dancing ||
				cooking == beach ||
				cooking == concerts ||
				cooking == art ||
				cooking == pets ||
				cooking == sports ||
				cooking == travel ||
				cooking == reading
			) {
				setCooking(99);
			}
		}
		if (activity != "dancing" && dancing) {
			if (
				dancing == hiking ||
				dancing == cooking ||
				dancing == beach ||
				dancing == concerts ||
				dancing == art ||
				dancing == pets ||
				dancing == sports ||
				dancing == travel ||
				dancing == reading
			) {
				setDancing(99);
			}
		}
		if (activity != "beach" && beach) {
			if (
				beach == hiking ||
				beach == cooking ||
				beach == dancing ||
				beach == concerts ||
				beach == art ||
				beach == pets ||
				beach == sports ||
				beach == travel ||
				beach == reading
			) {
				setBeach(99);
			}
		}
		if (activity != "concerts" && concerts) {
			if (
				concerts == hiking ||
				concerts == cooking ||
				concerts == beach ||
				concerts == dancing ||
				concerts == art ||
				concerts == pets ||
				concerts == sports ||
				concerts == travel ||
				concerts == reading
			) {
				setConcerts(99);
			}
		}
		if (activity != "art" && art) {
			if (
				art == hiking ||
				art == cooking ||
				art == beach ||
				art == concerts ||
				art == dancing ||
				art == pets ||
				art == sports ||
				art == travel ||
				art == reading
			) {
				setArt(99);
			}
		}
		if (activity != "pets" && pets) {
			if (
				pets == hiking ||
				pets == cooking ||
				pets == beach ||
				pets == concerts ||
				pets == dancing ||
				pets == art ||
				pets == sports ||
				pets == travel ||
				pets == reading
			) {
				setPets(99);
			}
		}
		if (activity != "sports" && sports) {
			if (
				sports == hiking ||
				sports == cooking ||
				sports == beach ||
				sports == concerts ||
				sports == dancing ||
				sports == art ||
				sports == pets ||
				sports == travel ||
				sports == reading
			) {
				setSports(99);
			}
		}
		if (activity != "travel" && travel) {
			if (
				travel == hiking ||
				travel == cooking ||
				travel == beach ||
				travel == concerts ||
				travel == dancing ||
				travel == art ||
				travel == sports ||
				travel == pets ||
				travel == reading
			) {
				setTravel(99);
			}
		}
		if (activity != "reading" && reading) {
			if (
				reading == hiking ||
				reading == cooking ||
				reading == beach ||
				reading == concerts ||
				reading == dancing ||
				reading == art ||
				reading == sports ||
				reading == travel ||
				reading == pets
			) {
				setReading(99);
			}
		}
	};

	const checkPreferenceDuplicates = (preference) => {
		setPersonalityTouched(true)
		if (preference != "ask" && ask) {
			if (ask === fly || ask === relax || ask === charge) {
				setAsk(99);
			}
		}
		if (preference !== "fly" && fly) {
			if (fly === ask || fly === relax || fly === charge) {
				setFly(99);
			}
		}
		if (preference !== "relax" && relax) {
			if (relax === ask || relax === fly || relax === charge) {
				setRelax(99);
			}
		}
		if (preference !== "charge" && charge) {
			if (charge === ask || charge === fly || charge === relax) {
				setCharge(99);
			}
		}
	};

	useEffect(() => {
		if(personalityTouched){
			submitActivities()
		}
	}, [hiking, cooking, dancing, beach, concerts, art, pets, sports, travel, reading])
	
	useEffect(() => {
		if(personalityTouched){
			submitPreferences()
		}
	}, [charge, ask, fly, relax])

	const submitActivities = () => {
		checkPersonalityComplete();
		const data = {
			hiking,
			cooking,
			dancing,
			beach,
			concerts,
			art,
			pets,
			sports,
			travel,
			reading,
			id
		};
		axios.post("/api/personality", data);
	};

	const submitPreferences = () => {
		checkPersonalityComplete();
		const data = {
			charge,
			ask,
			fly,
			relax,
			id
		};
		axios.post("/api/preferences", data);
	};

	
	const submitWhyILove = () => {
		const data = {
			whyILove,
			id
		};
		axios.post("/api/why-i-love", data);
	};
	return (
		<div className="dashboard-container">
			<DashboardNav toggleNav={toggleNav} />

			<div
				className="dashboard-sub-container "
				onClick={() => {
					setToggleNav(!toggleNav);
				}}>
					
					{profileComplete && <span className="profile-active">Active</span>}
					{!profileComplete && <span className="profile-inactive">Inactive</span>}
				<div className="dashboard-text-2">
					Order the following 1 through 10, 1 being your top preference<br />
				</div>
				<Form>
					<Table1>
						<Row>
							<ActivityInput activity={hiking} setValue={setHiking} activityName="Hiking" checkActivityDuplicates={checkActivityDuplicates} />							
							<ActivityInput activity={cooking} setValue={setCooking} activityName="Cooking" checkActivityDuplicates={checkActivityDuplicates} />					
							<ActivityInput activity={dancing} setValue={setDancing} activityName="Dancing" checkActivityDuplicates={checkActivityDuplicates} />
						</Row>
						<Row>										
							<ActivityInput activity={sports} setValue={setSports} activityName="Sports" checkActivityDuplicates={checkActivityDuplicates} />							
							<ActivityInput activity={beach} setValue={setBeach} activityName="Beach" checkActivityDuplicates={checkActivityDuplicates} />							
							<ActivityInput activity={concerts} setValue={setConcerts} activityName="Concerts" checkActivityDuplicates={checkActivityDuplicates} />
						</Row>
						<Row>							
							<ActivityInput activity={pets} setValue={setPets} activityName="Pets" checkActivityDuplicates={checkActivityDuplicates} />							
							<ActivityInput activity={travel} setValue={setTravel} activityName="Travel" checkActivityDuplicates={checkActivityDuplicates} />							
							<ActivityInput activity={reading} setValue={setReading} activityName="Reading" checkActivityDuplicates={checkActivityDuplicates} />
						</Row>
						<Row>							
							<ActivityInput activity={art} setValue={setArt} activityName="Art" checkActivityDuplicates={checkActivityDuplicates} />
						</Row>
					</Table1>
				</Form>
				<br />
				<br />
				<div className="dashboard-text-2">
				Order the following 1 through 4, 1 being the closest match to your working style<br />
				</div>
				<Form>
					<Table1>
						<Row2>
							<PreferenceInput checkPreferenceDuplicates={checkPreferenceDuplicates} setValue={setCharge} preferenceName="Take Charge" preferenceShortName="charge" preference={charge} />							
							<PreferenceInput checkPreferenceDuplicates={checkPreferenceDuplicates} setValue={setFly} preferenceName="Be a fly on the wall" preferenceShortName="fly" preference={fly} />
						</Row2>
						<Row2>												
							<PreferenceInput checkPreferenceDuplicates={checkPreferenceDuplicates} setValue={setRelax} preferenceName="Help them relax" preferenceShortName="relax" preference={relax} />
							<PreferenceInput checkPreferenceDuplicates={checkPreferenceDuplicates} setValue={setAsk} preferenceName="Do whatever the couple asks" preferenceShortName="ask" preference={ask} />
						</Row2>
					</Table1>
				</Form>
				<br />
				
				<div className="dashboard-text-2">
				Why I love photographing weddings<br />
				<div style={{fontSize: '12px'}}>400 Characters Max</div>
				<textarea maxlength='400' className="dashboard-personality-input" style={{width: '100%', height: '100px'}} value={whyILove} onChange={(e) => setWhyILove(e.target.value)} />
				<button onClick={submitWhyILove} className='custom-file-upload'>Save</button>
				</div>
				<br />
			</div>
		</div>
	);
};

export default DashboardPersonality;
