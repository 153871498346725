import React, {useState, useEffect, useContext} from 'react';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { differenceInCalendarDays } from 'date-fns';
import { useParams } from "react-router-dom";
import { UserContext } from "../UserContext";
import DashboardNav from "./dashboard-nav.component";
import axios from "axios";
import { PhotographerContext } from "../PhotographerContext";
import DownArrow from '../styles/down-arrow.svg';

function isSameDay(a, b) {
  return differenceInCalendarDays(a, b) === 0;
}

const FAQ = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div style={{marginTop: '12px'}}>
            <div className='calendar-question' onClick={() => setIsOpen(prev => !prev)}>{question}
            <img alt="" style={{width: '16px'}} src={DownArrow} /></div>
            {isOpen && <div className='calendar-answer'>{answer}</div>}
        </div>
    )
}
const DashboardAvailability = () => {
	const {profileComplete, } = useContext(PhotographerContext);
	const { setPhotographer } = useContext(UserContext);
	const [ toggleNav, setToggleNav ] = useState(false);
    const [value, onChange] = useState(new Date());
    const [disabledDates, setDisabledDates ] = useState([])
    const [ loaded, setLoaded ] = useState(false)
    const [ calendarSynced, setCalendarSynced ] = useState(false)
    const [ calendarOptions, setCalendarOptions ] = useState([])
    const [ selectedCalendars, setSelectedCalendars ] = useState([])
    const [ calendarNotes, setCalendarNotes ] = useState('')
    const [ linkClicked, setLinkClicked ] = useState(false)
    const queryParams = new URLSearchParams(window.location.search)
    const code = queryParams.get("code")
    let { id } = useParams();

    useEffect(() => {
		setPhotographer(id);
        axios.get("/api/photographerdata/" + id).then((result) => {
            if (result.data.cronofy?.ids) {
                setCalendarSynced(true)
            }
        })
        axios.get('/api/photographer-unavailable/' + id)
        .then(r => {
            setDisabledDates(r.data.map((d) => {
                var date = new Date(d.date)
                date.setHours(date.getHours() + 5)
                return date
            }))
        })
        if (code) {
            axios.post('/auth/sync-calendar', { id, code, redirect_uri: `${window.origin}/dashboard/${id}/availability`})
            .then(r => {
                if (!r.data.msg) {
                    setCalendarOptions(r.data)
                }
            })
        }
    }, [])

    function tileClassName({ date, view }) {
        // Disable tiles in month view only
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is on the list of disabled dates
            if(disabledDates.find(dDate => isSameDay(dDate, date))){
                return 'myClassName'
            }
        }
    }

    const submitCalendar = () => {
        axios.post('/auth/submit-calendars', { selectedCalendars, id, calendarNotes })
        .then(r => {
            setSelectedCalendars([])
            setCalendarOptions([])
            setCalendarSynced(true)
        })
    }

    const unsync = () => {
        axios.post('/auth/un-sync', { id})
        .then(r => {
            window.location =  `${window.origin}/dashboard/${id}/availability`
            setCalendarSynced(false)
        })
    }
   useEffect(() => {
       if (loaded) {
        const offset = value.getTimezoneOffset()
        var adjusted = new Date(value.getTime() - offset * 60 * 1000)
        var disab = [...disabledDates]
        var found = false
        for(var i in disab){
            if(isSameDay(disab[i], value)){
                disab.splice(i, 1)
                found = true
            }
        }
        if(!found){
            disab.push(value)
            axios.post('/api/photographer-unavailable', { id, date: adjusted.toUTCString()})
        } else{
            axios.post('/api/photographer-available', { id, date: adjusted.toUTCString()})
        }
        setDisabledDates(disab)
    } else {
        setLoaded(true)
    }
   }, [value])

    return(
		<div className="dashboard-container">
			<DashboardNav toggleNav={toggleNav} />
            <div
				className="dashboard-sub-container "
				onClick={() => {
					setToggleNav(!toggleNav);
				}}>
					{profileComplete && <span className="profile-active">Active</span>}
					{!profileComplete && <span className="profile-inactive">Inactive</span>}
                    {!calendarSynced &&
                        <div className='calendar-sync-pane'>
                            <div style={{fontSize: '16px', marginTop: '16px', fontWeight: 'bold'}}>Sync your Calendar</div>
                            <div style={{color: '#0A8A95', fontWeight: '600', fontSize: '14px', marginBottom: '16px', marginTop: '12px'}}>Your calendar data will never be shared with anyone</div>
                            {!code && <a style={{marginBottom: '16px'}} href={`https://app.cronofy.com/oauth/authorize?response_type=code&client_id=0VyKXWTxm8NYsgfYFxTn2Q2RUi1BO4f2&redirect_uri=${window.origin}/dashboard/${id}/availability&scope=read_events&state=${id}`}>
                                <button disabled={linkClicked} style={linkClicked ? {cursor: 'default', opacity: '0.5'} : {}} onClick={() => setLinkClicked(true)} className={`calendar-sync-button`}>Sync Your Calendar</button>
                            </a>}
                            {code &&
                            <>
                                <div style={{textAlign: 'left'}}>Special Calendar Notes</div>
                                <textarea className='calendar-notes' value={calendarNotes} onChange={e => setCalendarNotes(e.target.value)} placeholder='I have associates and can take multiple weddings...' />
                                <div>Select the calendars you'd like to sync</div>
                                {calendarOptions.map(c =>
                                <div key={c.calendar_id}>
                                <input
                                    type='checkbox'
                                    style={{marginRight: '5px'}}
                                    checked={selectedCalendars.includes(c.calendar_id)}
                                    onChange={() => setSelectedCalendars(prev => prev.includes(c.calendar_id) ? prev.filter(p => p != c.calendar_id) : [c.calendar_id, ...prev])} />
                                    {c.calendar_name}
                                </div>
                            )}
                            {calendarOptions.length > 0 && selectedCalendars.length > 0 &&

                            <button
                            className={`question-button question-tag-button`}
                            style={{width: '250px'}}
                            onClick={submitCalendar}
                            >
                                Sync Selected Calendar(s)
                        </button>
                            }
                            </>}
                            <FAQ question='Can I sync a calendar that has business and personal events on it?' answer={`Yes, we are using code with rules to search for specific types of words and phrases to be able to tell if an event is a wedding. It may not be perfect, but we'll err on the side of NOT blocking a date unless we are really sure it's a wedding and not something like a birthday party that you could reschedule.`} />
                            <FAQ question='If I have associates and can take on multiple weddings, should I still sync my calendar?' answer={`Yes, you can use the text box above to make any special notes about how to handle your calendar. We will then create custom code to handle your calendar slightly differently.`} />
                            <FAQ question='Will my calendar update immediately?' answer={`No, we will only sync your calendar every few days, so if you go to check your calendar manually, just know that it may not be updated with any events you added in the last few days.`} />
                            <FAQ question='Will anyone see my calendar entries?' answer={`Only our team will have access to your calendar entries, nothing will be shared publicly.`} />
                      </div>
                    }
                    {calendarSynced && <div>
                        <div style={{fontWeight: 'bold'}}>Calendar Synced!</div>
                        {code && <div style={{fontWeight: 'bold'}}>Please allow 72 hours for changes to take effect.</div>}
                        <button onClick={unsync}
                            className={`question-button question-tag-button`}>Unsync</button>
                        </div>

                    }


           <Calendar
            value={value}
            onChange={onChange}
            tileClassName={tileClassName}
            calendarType="US"
            showNeighboringMonth={false}
           />
        </div>
        </div>
    )
}

export default DashboardAvailability;