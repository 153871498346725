import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../styles/admin.css";

const GetBookingStatus = () => {   
    const [results, setResults] = useState([]);
    useEffect(() => {        
        document.title="Bookings | WedMatch"    
        axios.get('/auth/getuser')
        .then(r => {
            if(!r.data.admin){
                window.location="/login"
            }
        })
        axios.get('/api/results-status')
        .then(result => {
            setResults(result.data)
        })
           
        }, [])
    
    const refetch = (status) => {
        axios.get('/api/results-status/' + status)
        .then(result => setResults(result.data))
    }

    const sortResults = (parameter) => {
        var arr = [...results]
        if(parameter == 'photographer'){
            arr.sort((a, b) => a.business_name.toUpperCase() > b.business_name.toUpperCase() ? 1 : -1)
            setResults(arr)
        } else if(parameter == 'photographer_email'){
            arr.sort((a, b) => a.photographer_email.toUpperCase() > b.photographer_email.toUpperCase() ? 1 : -1)
            setResults(arr)
        } else if(parameter == 'client_name'){
            arr.sort((a, b) => a.first_name.toUpperCase() > b.first_name.toUpperCase() ? 1 : -1)
            setResults(arr)
        } else if(parameter == 'created_at'){
            arr.sort((a, b) => a.created_at > b.created_at ? -1 : 1)
            setResults(arr)
        } else if(parameter == 'referral'){
            arr.sort((a, b) => a.referral_email ? -1 : 1)
            setResults(arr)
        }else if(parameter == 'date'){
            arr.sort((a, b) => `${a.year}${a.month}${a.day}` > `${b.year}${b.month}${b.day}` ? 1 : -1)
            setResults(arr)
        } else if(parameter == 'venue'){
            arr.sort((a, b) => a.venue_name.toUpperCase() > b.venue_name.toUpperCase() ? 1 : -1)
            setResults(arr)
        }
    }
    const setInvoiced = (id, checked, index) => {
        axios.post('/api/toggle-invoiced', {result: id, invoiced: checked})
        var arr = [...results]
        arr[index].invoiced = checked
        setResults(arr)
    }

    return(
        <div style={{maxWidth: '2000px'}}>
            <div className="filter-buttons-wrapper">
                <div onClick={() => refetch("Booked")} className="booked-button">Booked</div> 
                <div onClick={() => refetch("Not Booked")} className="booked-button">Not Booked</div> 
                <div onClick={() => refetch("Still Talking")} className="booked-button">Still Talking</div>    
                <div onClick={() => refetch("No Answer")} className="booked-button">No Answer</div>  
            </div> 
                <div>{results.length} total </div>       
            <br /><br />
            <table>
            <tr className="booking-row">
                <th onClick={() => sortResults('photographer')} style={{fontWeight: 'bold', cursor: 'pointer'}}>Photographer</th>
                <th onClick={() => sortResults('photographer_email')} style={{fontWeight: 'bold', cursor: 'pointer'}}>Photog Email</th>
                <th onClick={() => sortResults('client_name')} style={{fontWeight: 'bold', cursor: 'pointer'}}>Client Name</th>
                <th onClick={() => sortResults('created_at')} style={{fontWeight: 'bold', cursor: 'pointer'}}>Created at</th>              
                <th onClick={() => sortResults('status')} style={{fontWeight: 'bold', cursor: 'pointer'}}>Status</th>                
                <th onClick={() => sortResults('referral')} style={{fontWeight: 'bold', cursor: 'pointer'}}>Referral</th>                
                <th onClick={() => sortResults('date')} style={{fontWeight: 'bold', cursor: 'pointer'}}>Wedding Date</th>               
                <th onClick={() => sortResults('venue')} style={{fontWeight: 'bold', cursor: 'pointer'}}>Venue</th>          
                <th onClick={() => sortResults('invoiced')} style={{fontWeight: 'bold', cursor: 'pointer'}}>Invoiced</th>              
            </tr>
            {results.map((r, index) => <tr className="booking-row">
                <td>{r.business_name}</td>
                <td>{r.photographer_email}</td>
                <td>{r.first_name} {r.last_name}</td>
                <td>{r.created_at}</td>                
                <td>{r.status}</td>
                <td>{r.referral_email}</td>
                <td>{r.year}-{r.month}-{r.day}</td>
                <td>{r.venue_name}</td>
                <td><input onChange={(e) => setInvoiced(r.id, e.target.checked, index)} type="checkbox" checked={r.invoiced} /></td>
            </tr>)}
            </table>
        </div>
    )
}

export default GetBookingStatus