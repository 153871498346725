import React, {useEffect, useState, useReducer, Fragment, useMemo } from 'react';
import axios from 'axios';
import NavbarNew from "../Quiz/navbarnew.component";
import QuizReducer from '../../reducers/quizReducer'
import Calendar from 'react-calendar'
import { differenceInCalendarDays } from 'date-fns';
import Location from './location/location.component'
import _ from 'lodash'
import 'react-calendar/dist/Calendar.css';

function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }

const WeddingDate = ({ quizState, setValue, setShowModal, toggleBoolean }) => {
    function tileClassName({ date, view }) {
        // Disable tiles in month view only
        if (view === 'month') {
            // Check if a date React-Calendar wants to check is on the list of disabled dates
            if(isSameDay(date, quizState.weddingDate)){
                return 'calendar-selected'
            }
        }
    }
    return (
        <div className='quiz-modal'>
            <div className="header">Date</div>
            <div className="subheader">
                We'll check vendor's calendars to remove any that aren't available.
            </div>

           <Calendar
            value={quizState.weddingDate}
            onChange={e => { setValue('weddingDate', e); setShowModal(null); }}
            tileClassName={tileClassName}
            calendarType={"US"}
            showNeighboringMonth={false}
           />

            <label class="checkbox-container">
                <input
                    checked={quizState.dateNotDecided}
                    type="checkbox"
                    name='dateNotDecided'
                    onClick={(e) => { toggleBoolean(e); setShowModal(null);}}
                />
                <span class="checkmark email-checkmark" />
            </label>
			<span className="checkbox-label">We haven't decided yet</span>
        </div>
    )
}

const Options = ({ additionalData, quizState, setValue, setShowModal, toggleArraySelection, options }) => {
    const { header, subheader, question, property, selectOne } = additionalData
    return (
        <div className='quiz-modal'>
        <div className="header">{header}</div>
        <div className="subheader">{subheader}</div>
        <div className='question'>{question}</div>
        <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '25px', marginLeft: '-6px', alignItems: 'center'}}>
            {options.map(t =>
            {
                const selected = ((selectOne && _.get(quizState, property)=== t) || (selectOne && typeof t === 'object' && _.get(quizState, property) === t.name)) || (!selectOne && _.get(quizState, property)?.length && _.get(quizState, property).some(i => i == t))
                return (
                <button
                    key={typeof t === 'object' ? t.name : t}
                    className={`question-button question-tag-button ${selected ? 'selected' : ''}`}
                    onClick={() => {
                        if (!selectOne) {
                            toggleArraySelection(property, t)
                         } else {
                             setValue(property, t)
                             setShowModal(null);
                        }
                    }
                }>
                    {typeof t === 'object' ? t.name : t}
                </button>
                )
            }
            )}
            </div>
            {!selectOne &&
            <form method="dialog">
                <div style={{textAlign: 'center'}}><button className='new-quiz-done-button' onClick={() => setShowModal(null)}>Done</button></div>
            </form>}
        </div>
    )
}

const modals = {
    'WeddingDate': WeddingDate,
    'WeddingLocation': Location,
    'Options': Options
}

const initialQuizState = {
    day: '',
    email: '',
    firstName: '',
    month: '',
    preference: '',
    venueName: '',
    weddingLocation: '',
    year: '',
    weddingDate: '',

    hoursSelected: null,
    optionsTotal: 0,
    timesOfDay: 0,

    venueVendorId: null,

    canContact: true,
    ceremonyChecked: true,
    dateNotDecided: false,
    gettingReadyChecked: true,
    noBudget: false, // Need to double check this. Used to be just 'budget' and was null if they didn't check it
    portraitsChecked: true,
    receptionChecked: true,
    privateVenue: false,
    styleNotImportant: false,
    skipVendors: false,

    attributes: [],
    ceremony: [],
    chosenVendorTypes: [],
    couplesPortrait: [],
    formalDance: [],
    generalReception: [],
    gettingReady: [],
    importantTags: [],
    locationTags: [],
    matchups: [],
    options1: [],
    options2: [],
    photoStyles: [],
    selectedBudgets: [],
    selections: [],
    styleTags: [],
    usedPhotographers: [],
    userOnlyTags: [],
    weddingParty: [],
  }

const Quiz = () => {

    const [ vendorTypes, setVendorTypes ] = useState([])
    const [ quizState, dispatch ] = useReducer(QuizReducer, initialQuizState)
    const [ showModal, setShowModal ] = useState(null)
    const [ locations, setLocations ] = useState([])
    const [ tags, setTags ] = useState([])

    useEffect(() => {
        console.log(quizState)
    }, [quizState])


    const handleChangeMoneyInput = e => {
        let value = e.target.value.replace('$', '').replace(',', '')
        const withCommas = parseInt(value).toLocaleString()
        if (value.substring(0, 1) !== '$') {
            value = `$${withCommas}`
        }
        dispatch({
            type: 'SET VALUE',
            field: e.target.name,
            value: value
        })
    }


    const handleChangeText = e => {
        dispatch({
          type: 'SET VALUE',
          field: e.target.name,
          value: e.target.value,
        })
      }


      const toggleBoolean = e => {
        dispatch({
          type: 'TOGGLE BOOLEAN',
          field: e.target.name,
        })
      }


      const toggleArraySelection = (field, id) => {
        dispatch({
          type: 'TOGGLE ARRAY SELECTION',
          field,
          id,
        })
      }


      const setValue = (field, value) => {
        dispatch({
          type: 'SET VALUE',
          field,
          value
        })
      }


      const pushToArray = (field, value) => {
        dispatch({
          type: 'PUSH TO ARRAY',
          field,
          value
        })
      }


   useEffect(() => {
    axios.get('/api/all-vendor-types')
    .then(r => setVendorTypes(r.data.filter(d => d.is_in_quiz)))

    axios.get('/api/locations')
    .then(r => {
        setLocations(r.data)
    })
     axios.get('/api/all-tags')
     .then(r => setTags(r.data))
   }, [])

   const NeededModalComponent = modals[showModal?.displayModal]
   let firstUnfinishedType = 99

   vendorTypeMap.details.components.forEach((c, index) => {
    if ((!_.get(quizState, c.property) && c.property) && (!c.alternateProperty || !_.get(quizState, c.alternateProperty))) {
        firstUnfinishedType = -1
    }
   })

   if (firstUnfinishedType !== -1 && quizState.chosenVendorTypes) {
        quizState.chosenVendorTypes.forEach((id, index) => {
            if (vendorTypeMap[id]) {
                vendorTypeMap[id].components.forEach((component, index2) => {
                    if (component.property && !_.get(quizState, component.property) && index < firstUnfinishedType) {
                        firstUnfinishedType = index
                    }
                })
                console.log(vendorTypeMap[id])
            }
        })
    }

   let firstUnfinishedComponent = 99
   if (firstUnfinishedType === -1) {
    vendorTypeMap.details.components.forEach((c, index) => {
        if (c.property && !_.get(quizState, c.property) && (!c.alternateProperty || !_.get(quizState, c.alternateProperty)) && index < firstUnfinishedComponent) {
            firstUnfinishedComponent = index
        }
    })
   }

   if (firstUnfinishedType !== -1 && firstUnfinishedType !== 99) {
    vendorTypeMap[quizState.chosenVendorTypes[firstUnfinishedType]].components.forEach((c, index) => {
        if (c.property && !_.get(quizState, c.property) && index < firstUnfinishedComponent) {
            firstUnfinishedComponent = index
        }
    })
   }

   useEffect(() => {
        if (showModal) {
            document.getElementById('dialog').showModal()
        }
   }, [showModal])

   const options = {
    tags
   }

   const scores = useMemo(() => {
        const test = quizState.chosenVendorTypes.map(v => {
            const properties = quizState[vendorTypeMap[v].rootProperty]
            let score = 0
            console.log(properties)
        })
        console.log(test)
   }, [quizState])

    return(
		<div className="question-page">
			<NavbarNew />
            <div className="question-container">
                <div>
                    <div className="header">Which vendors are you ready to be matched with?</div>
                    <div className="subheader">
                    Don’t worry, we’ll only ask you questions that give you better matches.
                    </div>
                </div>

                <div>
                    <div>
                        <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '25px', marginLeft: '-6px', alignItems: 'center'}}>
                        {vendorTypes.map(t =>
                            <button
                                className={`question-button question-tag-button ${quizState.chosenVendorTypes.length && quizState.chosenVendorTypes.some(i => i == t.id) ? 'selected' : ''}`}
                                selected={quizState.chosenVendorTypes.length && quizState.chosenVendorTypes.some(i => i == t.id)}
                                onClick={() => toggleArraySelection('chosenVendorTypes', t.id)}
                                >
                                    <img src={t.icon_url} style={{width: '35px', marginRight: '5px'}} />
                                {t.name}
                            </button>
                        )}
                        </div>
                    </div>
                </div>

                <div style={{lineHeight: 2}}>
                    <div className='header'>Wedding Details</div>
                    {vendorTypeMap.details.components.map((c, index2) =>
                        <span className={`${firstUnfinishedType === -1 && index2 > firstUnfinishedComponent  ? 'new-quiz-unavailable' : ''}`} key={c.property}>
                        {c.jsx} {c.displayModal && <button
                        disabled={/*firstUnfinishedType === -1 && index2 > firstUnfinishedComponent*/ false}
                                    className={`new-quiz-question ${_.get(quizState, c.property) || _.get(quizState, c.alternateProperty) ? 'answered' : ''}`}
                                    onClick={() => setShowModal(c)}>
                                        {c.value && c.value(quizState, locations) ? c.value(quizState, locations) : _.get(quizState, c.property) ? _.get(quizState, c.property) : c.label}</button>}
                                {!c.displayModal && c.property &&
                                <input
                                // disabled={firstUnfinishedType === -1 && index2 > firstUnfinishedComponent}
                                placeholder={c.label} step="1" className={`new-quiz-question ${_.get(quizState, c.property) ? 'answered' : ''} ${c.small ? 'small' : ''}`} type={c.type === 'number' ? 'number' : 'text'} value={_.get(quizState, c.property)} name={c.property} onChange={(e) => c.type === 'money' ? handleChangeMoneyInput(e) : handleChangeText(e)} />}
                        </span>
                    )}
                </div>
                {quizState.chosenVendorTypes.map((v, index) =>
                {
                    const typeDisabled = index > firstUnfinishedType
                    return (
                    <div style={{marginTop: '16px', lineHeight: 2}} className={typeDisabled ? 'new-quiz-unavailable' : ''} key={v}>
                        <div className='header'>{vendorTypes.find(t => t.id === v)?.name}</div>
                    {vendorTypeMap[v].components.map((c, index2) =>
                        <span className={`${(index > firstUnfinishedType || (index === firstUnfinishedType && index2 > firstUnfinishedComponent)) ? 'new-quiz-unavailable' : ''}`} key={c.property}>
                        {c.jsx} {c.displayModal && <button
                                    // disabled={(index > firstUnfinishedType || (index === firstUnfinishedType && index2 > firstUnfinishedComponent))}
                                    className={`new-quiz-question ${_.get(quizState, c.property) ? 'answered' : ''} ${c.small ? 'small' : ''}`}
                                    onClick={() => setShowModal(c)}>
                                        {(c.value && _.get(quizState, c.property)) ? c.value(quizState, locations) : _.get(quizState, c.property) ? _.get(quizState, c.property) : c.label}</button>}
                                {!c.displayModal && c.property &&
                                <input
                                // disabled ={(index > firstUnfinishedType || (index === firstUnfinishedType && index2 > firstUnfinishedComponent))}
                                    className={`new-quiz-question ${_.get(quizState, c.property) ? 'answered' : ''} ${c.small ? 'small' : ''}`}
                                    placeholder={c.label} type={c.type === 'number' ? 'number' : 'text'}
                                    value={_.get(quizState, c.property)}
                                    name={c.property}
                                    onChange={(e) => c.type === 'money' ? handleChangeMoneyInput(e) : handleChangeText(e)} />}
                        </span>
                    )}
                    </div>
                    )
                }
                )}
                {showModal && <dialog id='dialog'>
                    <NeededModalComponent
                        additionalData={showModal}
                        options={options[showModal.optionsProperty] ?? showModal.options}
                        locations={locations}
                        quizState={quizState}
                        setValue={setValue}
                        setShowModal={setShowModal}
                        handleChangeText={handleChangeText}
                        toggleBoolean={toggleBoolean}
                        toggleArraySelection={toggleArraySelection}
                    />
                </dialog>}
            </div>
        </div>
    )
}

export default Quiz;

const vendorTypeMap = {
    'details': {
        rootProperty: 'details',
        components: [
            {
                property: 'weddingDate',
                alternateProperty: 'dateNotDecided',
                jsx: <span>I'm getting married on </span>,
                displayModal: 'WeddingDate',
                label: 'Wedding Date',
                value: quizState => {
                    if (quizState.dateNotDecided) return 'no date yet'
                    if (typeof quizState.weddingDate !== 'object') return quizState.weddingDate
                    return `${quizState.weddingDate.getMonth() + 1}/${quizState.weddingDate.getDate() + 1}/${quizState.weddingDate.getFullYear()}`
                }
            },
            {
                property: 'weddingLocation',
                jsx: <span> in </span>,
                displayModal: 'WeddingLocation',
                label: 'Wedding Location',
                value: (quizState, locations) => {
                    if (quizState.weddingLocation) {
                        return `${locations.find(l => l.id == quizState.weddingLocation)?.name} ${quizState.venueName ? `at ${quizState.venueName}` : ''}`
                    }
                }
            },
            {
                property: 'overallBudgetMin',
                jsx: <span>&nbsp;with a total wedding budget between </span>,
                label: 'Min',
                small: true,
                type: 'money',
            },
            {
                property: 'overallBudgetMax',
                jsx: <span> and </span>,
                label: 'Max',
                small: true,
                type: 'money',
            },
            {
                property: 'minGuests',
                jsx: <span>. I plan to have between </span>,
                label: 'Min',
                type: 'number',
                small: true,
            },
            {
                property: 'maxGuests',
                jsx: <span> and </span>,
                label: 'Max',
                type: 'number',
                small: true,
            },
            {
                property: 'chosenTags',
                jsx: ' guests. Some words that describe my wedding are ',
                label: 'Tags',
                optionsProperty: 'tags',
                header: 'Tags',
                subheader: '',
                question: 'Select any of these words that describe this wedding.',
                displayModal: 'Options',
                value: (quizState) => quizState.chosenTags?.map(t => t.name).join(', ')
            },
            {
                jsx: '.'
            }

        ],
    },
    13: { // Planner
        rootProperty: 'planner',
        components: [
            {
                jsx: <span>I'm looking for</span>,
                label: 'full service planning',
                property: 'planner.services',
                selectOne: true,
                options: ['Full service', 'Day-of Coordination Only', 'Partial planning'],
                header: 'Wedding Planning Services',
                subheader: 'We collect detailed pricing from planners to make sure they match your budget.',
                question: 'Which planning services are you interested in?',
                displayModal: 'Options',
                value: (quizState) => quizState.planner.services
            },
            {
                jsx: <span>. My budget for a wedding planner is between </span>,
                property: 'planner.budgetMin',
                label: 'Min',
                small: true,
                type: 'money',
            },
            {
                jsx: <span> and </span>,
                property: 'planner.budgetMax',
                label: 'Max',
                small: true,
                type: 'money',
            }
        ],
    },
    3: { // Florist
        rootProperty: 'florist',
        components: [
            {
                jsx: <span>I'm looking for </span>,
                label: '',
                property: 'florist.services',
                options: ['Bridal Bouquet', 'Bridesmaids Bouquets', 'Boutonnieres', 'Ceremony Decor', 'Centerpieces'],
                header: 'Florist Services',
                subheader: 'We collect detailed pricing from florists to make sure they match your budget.',
                question: 'Which florist services are you interested in?',
                displayModal: 'Options',
                value: (quizState) => quizState.florist?.services?.join(', ')
            },
            {
                jsx: <span>. My budget for flowers is between </span>,
                property: 'florist.budgetMin',
                label: 'Min',
                small: true,
                type: 'money',
            },
            {
                jsx: <span> and </span>,
                property: 'florist.budgetMax',
                label: 'Max',
                small: true,
                type: 'money',
            }
        ],
    },
    2: { // Cake
        rootProperty: 'baker',
        components: [
            {
                jsx: <span>I'm looking for</span>,
                label: '',
                property: 'baker.services',
                options: ['Cake', 'Cookies and Macarons', 'Cupcakes', 'Mini Desserts', 'Pies', 'Donuts', 'Pastries', 'Candies'],
                header: 'Baker Services',
                subheader: 'We collect detailed pricing from bakers to make sure they match your budget.',
                question: 'Which baker services are you interested in?',
                displayModal: 'Options',
                value: (quizState) => quizState.baker?.services?.join(', ')
            },
            {
                jsx: <span>. My budget for desserts is between </span>,
                property: 'baker.budgetMin',
                label: 'Min',
                small: true,
                type: 'money',
            },
            {
                jsx: <span> and </span>,
                property: 'baker.budgetMax',
                label: 'Max',
                small: true,
                type: 'money',
            }
        ],

    },
    5: { // DJ
        rootProperty: 'dj',
        components: [
            {
                jsx: <span>I'm interested in</span>,
                label: '',
                property: 'dj.services',
                options: ['Reception Coverage', 'Ceremony Coverage', 'Up-lighting', 'Photo-booth'],
                header: 'DJ Services',
                subheader: 'We collect detailed pricing from DJs to make sure they match your budget.',
                question: 'Which DJ services are you interested in?',
                displayModal: 'Options',
                value: (quizState) => quizState.dj?.services?.join(', ')
            },
            {
                jsx: <span>. My budget for these services is between </span>,
                property: 'dj.budgetMin',
                label: 'Min',
                small: true,
                type: 'money',
            },
            {
                jsx: <span> and </span>,
                property: 'dj.budgetMax',
                label: 'Max',
                small: true,
                type: 'money',
            }
        ],
    },
    15: { // Photographer
        rootProperty: 'photographer',
        components: [
            {
                jsx: <span>I'm interested in</span>,
                label: '',
                property: 'photographer.services',
                options: ['Reception', 'Ceremony', 'Formal Dance', 'Getting Ready', 'Wedding Party', 'Couples Portraits'],
                header: 'Photography Coverage',
                subheader: 'We collect detailed pricing from photographers to make sure they match your budget.',
                question: 'Which photographer services are you interested in?',
                displayModal: 'Options',
                value: (quizState) => quizState.photographer?.services?.join(', ')
            },
            {
                jsx: <span>. My budget for these services is between </span>,
                property: 'photographer.budgetMin',
                label: 'Min',
                small: true,
                type: 'money',
            },
            {
                jsx: <span> and </span>,
                property: 'photographer.budgetMax',
                label: 'Max',
                small: true,
                type: 'money',
            },
            {
                property: 'photographer.minHours',
                jsx: <span>. I'd like between </span>,
                label: 'Min',
                type: 'number',
                small: true,
            },
            {
                property: 'photographer.maxHours',
                jsx: <span> and </span>,
                label: 'Max',
                type: 'number',
                small: true,
            },
            {
                jsx: <span> hours of coverage and I most care about my photographer being </span>,
                selectOne: true,
                label: '',
                property: 'photographer.personality',
                options: ['1', '2', '3'],
                header: 'Photographer Personality',
                subheader: 'What is most important to you in a photographer?',
                question: 'What is most important to you in a photographer?',
                displayModal: 'Options',
                value: (quizState) => quizState.photographer.personality
            },
        ],
    },
    6: { // Videographer
        rootProperty: 'videographer',
        components: [
            {
                jsx: <span>I'm interested in</span>,
                label: '',
                property: 'videographer.services',
                options: ['Reception Coverage', 'Ceremony Coverage', 'Drone Coverage', 'Highlight Film', 'Full-length Film'],
                header: 'Videography Coverage',
                subheader: 'We collect detailed pricing from videographers to make sure they match your budget.',
                question: 'Which videographer services are you interested in?',
                displayModal: 'Options',
                value: (quizState) => quizState.videographer?.services?.join(', ')
            },
            {
                jsx: <span>. My budget for video is between </span>,
                property: 'videographer.budgetMin',
                label: 'Min',
                small: true,
                type: 'money',
            },
            {
                jsx: <span> and </span>,
                property: 'videographer.budgetMax',
                label: 'Max',
                small: true,
                type: 'money',
            },
        ],
    },
    7: { // Hair/makeup
        rootProperty: 'hair',
        components: [
            {
                jsx: <span>I'm interested in</span>,
                label: '',
                property: 'hair.services',
                options: ['Bridal Hair', 'Bridal Makeup', 'Bridesmaid Hair', 'Bridesmaid Makeup', 'More'],
                header: 'Hair and Makeup',
                subheader: 'We collect detailed pricing from hair and makeup vendors to make sure they match your budget.',
                question: 'Which hair and makeup services are you interested in?',
                displayModal: 'Options',
                value: (quizState) => quizState.hair?.services?.join(', ')
            },
            {
                jsx: <span>. My budget for these services is between </span>,
                property: 'hair.budgetMin',
                label: 'Min',
                small: true,
                type: 'money',
            },
            {
                jsx: <span> and </span>,
                property: 'hair.budgetMax',
                label: 'Max',
                small: true,
                type: 'money',
            },
        ],
    },
}