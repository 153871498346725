import React, { useState, useMemo } from "react";
import axios from 'axios'

export default function Pricing({ vendor }) {
    const [ pricingProperties, setPricingProperties ] = useState(vendor.pricing_properties ?? {})
    const handleChange = e => {
        setPricingProperties({...pricingProperties, [e.target.name]: e.target.value })
    }
    const changed = useMemo(() => {
        let ch = false
        Object.keys(pricingProperties).forEach(f => {
            if (pricingProperties?.[f] !== vendor.pricing_properties?.[f]) {
                ch = true
            }
        })
        return ch
    }, [pricingProperties])

    
    const handleSubmit = () => {
        axios.post('/api/update-vendor-pricing', {...pricingProperties, vendorId: vendor.id})
        .then(r => window.location = window.location)
    }
 
    return (
        <div className='vendor-dashboard-card' style={{maxWidth: '725px'}}>
        <div className='vendor-dashboard-card-header'>Pricing</div>
            {vendorTypeMap[vendor.vendor_type].components.map(c =>
                <div  style={{marginBottom: '24px'}}>
                {c.disableText && <DisableableFeature jsx={c.jsx} disableText={c.disableText} minimum={c.minimum} maximum={c.maximum} disableProperty={c.disableProperty} handleChange={handleChange} setPricingProperties={setPricingProperties} pricingProperties={pricingProperties} Child={c.component} />}
                {!c.disableText &&<c.component betweenWord={c.betweenWord} jsx={c.jsx} minimum={c.minimum} maximum={c.maximum} handleChange={handleChange} setPricingProperties={setPricingProperties} pricingProperties={pricingProperties} Child={c.component} />}

                </div>
            )}
         <button disabled={!changed} onClick={handleSubmit} className='vendor-dashboard-button vendor-dashboard-button-primary vendor-dashboard-button-save'>Save Changes</button>
     
        </div>
    )
}


function ToFrom({ pricingProperties, handleChange, jsx, minimum, maximum, betweenWord='to' }) { 
    return (
    <div>{jsx} <input className='vendor-dashboard-pricing-input'
        name={minimum}
        onChange={handleChange}
        type='number'
        value={pricingProperties[minimum]} /> {betweenWord} <input
        className='vendor-dashboard-pricing-input'
        type='number'
        name={maximum} onChange={handleChange}
        value={pricingProperties[maximum]} />
    </div> 
    )
}


function SingleValue({ pricingProperties, handleChange, jsx, property }) { 
    return (
    <div>{jsx} <input className='vendor-dashboard-pricing-input'
        name={property}
        onChange={handleChange}
        type='number'
        value={pricingProperties[property]} />
    </div> 
    )
}

function DisableableFeature({ Child, handleChange, pricingProperties, disableProperty, setPricingProperties, disableText, jsx, minimum, maximum }) {
    return (
        <div className={`${pricingProperties[disableProperty] ? 'pricing-section pricing-section-disabled' : 'pricing-section'}`}>
            <Child minimum={minimum} maximum={maximum} pricingProperties={pricingProperties} jsx={jsx} handleChange={handleChange} />
            <div style={{display: 'flex', alignItems: 'center'}}>
            <label style={{marginTop: 'inherit', transform: 'inherit'}} className="how-important-checkbox-container">
                <input
                    id={disableProperty}
                     type='checkbox'
                     checked={pricingProperties[disableProperty]}
                     className='vendor-dashboard-pricing-checkbox'
                     onChange={() => setPricingProperties({...pricingProperties, [disableProperty]: !pricingProperties[disableProperty]})}
                />
                <span class="how-important-checkmark" />
            </label>
            <label for={disableProperty} className='checkbox-label' style={{marginLeft: '2px', marginBottom: 'inherit'}}>{disableText}</label>
            </div>
        </div>
    )
}

const vendorTypeMap = {
    13: { // Planner
        components: [
            {
                component: ToFrom,
                disableText: `I don't offer this`,
                disableProperty: 'dayOfCoordinationDisabled',
                jsx: <span><b>Day of Coordination</b> packages range from</span>,
                minimum: 'dayOfMinimum',
                maximum: 'dayOfMaximum'
            },
            {
                component: ToFrom,
                disableText: `I don't offer this`,
                disableProperty: 'partialPlanningDisabled',
                jsx: <span><b>Partial planning</b> packages range from</span>,
                minimum: 'partialPlanningMinimum',
                maximum: 'partialPlanningMaximum'
            },
            {
                component: ToFrom,
                disableText: `I don't offer this`,
                disableProperty: 'fullServiceDisabled',
                jsx: <span><b>Full service</b> packages range from</span>,
                minimum: 'fullServiceMinimum',
                maximum: 'fullServiceMaximum'
            },
            {
                component: ToFrom,
                jsx: <span>Most of my couples' <b>overall wedding budget</b> ranges from</span>,
                minimum: 'overallBudgetMinimum',
                maximum: 'overallBudgetMaximum'
            },
            {
                component: ToFrom,
                disableText: `I don't offer this`,
                disableProperty: 'offSeasonDisabled',
                jsx: <span>I give off-season or weekday <b>discounts</b> ranging from</span>,
                minimum: 'offSeasonMinimum',
                maximum: 'offSeasonMaximum'
            },
        ],
    },
    3: { // should be 3 | Florist
        components: [
            {
                component: ToFrom,
                jsx: <span>Most of my couples spend on <b>average</b> between</span>,
                betweenWord: 'and',
                minimum: 'averageSpendMinimum',
                maximum: 'averageSpendMaximum'
            },
            {
                component: ToFrom,
                jsx: <span><b>Bridal Bouquets</b> range from </span>,
                minimum: 'bridalBouquetsMinimum',
                maximum: 'bridalBouquetsMaximum'
            },
            {
                component: ToFrom,
                jsx: <span><b>Centerpieces</b> range from </span>,
                minimum: 'centerpiecesMinimum',
                maximum: 'centerpiecesMaximum'
            },
            {
                component: SingleValue,
                jsx: <span>Our <b>Minimum</b> total spend is </span>,
                property: 'minimumTotal',
                disableText: `I don't have a minimum`,
                disableProperty: 'minimumDisabled',
            },
            {
                component: ToFrom,
                jsx: <span>I give off-season or weekday <b>discounts</b> ranging from </span>,
                minimum: 'offSeasonMinimum',
                maximum: 'offSeasonMaximum',
                disableText: `I don't offer this`,
                disableProperty: 'offSeasonDisabled',
            },
        ]
    },
    2: { // Cake
        components: [
            {
                component: ToFrom,
                jsx: <span>In <u>total</u> most of my couples spend on <b>average</b> between</span>,
                betweenWord: 'and',
                minimum: 'totalMinimum',
                maximum: 'totalMaximum',
            },
            {
                component: ToFrom,
                jsx: <span><u>Per person,</u> most of my couples spend on <b>average</b> between</span>,
                betweenWord: 'and',
                minimum: 'perPersonMinimum',
                maximum: 'perPersonMaximum',
            },
            {
                component: SingleValue,
                jsx: <span>Our <b>Minimum</b> total spend is </span>,
                property: 'minimumTotal',
                disableText: `I don't have a minimum`,
                disableProperty: 'minimumDisabled',
            },
        ]
    },
    5: { // DJ
        components: [
            {
                component: SingleValue,
                jsx: <span><b>4 hour reception coverage</b> couples on average spend </span>,
                property: 'average',
            },
            {
                component: SingleValue,
                jsx: <span>Price per <b>extra hour</b> of coverage averages</span>,
                property: 'extraHourAverage',
            },
            {
                component: SingleValue,
                jsx: <span>Price for <b>ceremony only</b> coverage averages</span>,
                property: 'ceremonyAverage',
                disableText: `I don't offer ceremony only`,
                disableProperty: 'ceremonyOnlyDisabled',
            },
            {
                component: SingleValue,
                jsx: <span>Price for <b>4 hour reception + ceremony</b> coverage averages</span>,
                property: 'receptionCeremonyAverage',
            },
            {
                component: SingleValue,
                jsx: <span>Average price to <b>ADD uplighting</b> to a package is </span>,
                property: 'uplighting',
                disableText: `I don't offer uplighting`,
                disableProperty: 'uplightingDisabled',
            },
            {
                component: SingleValue,
                jsx: <span>Average price to <b>ADD photo booth</b> to a package is </span>,
                property: 'photobooth',
                disableText: `I don't offer photo booth`,
                disableProperty: 'photoboothDisabled',
            },
        ]
    },
    6: { // 6 Videographer
        components: [
            {
                component: ToFrom,
                jsx: <span>Most of my couples spend on <b>average</b> between</span>,
                betweenWord: 'and',
                minimum: 'averageSpendMinimum',
                maximum: 'averageSpendMaximum'
            },
            {
                component: ToFrom,
                jsx: <span>My minimum for an <b>off-season</b> or weekday averages between</span>,
                betweenWord: 'and',
                minimum: 'offSeasonMinimum',
                maximum: 'offSeasonMaximum',
            },
            {
                component: ToFrom,
                jsx: <span>For <b>6-8 hours of coverage + highlight film</b>, couples spend on averages between</span>,
                betweenWord: 'and',
                minimum: 'coverageAverageMinimum',
                maximum: 'coverageAverageMaximum',
            },
        ]
    },
    7: { // hair
        components: [
            {
                component: SingleValue,
                jsx: <span>Our <b>Minimum</b> total spend is </span>,
                property: 'minimumTotal',
                disableText: `I don't have a minimum`,
                disableProperty: 'minimumDisabled',
            },
            {
                component: ToFrom,
                jsx: <span><b>Bridal hair</b> costs on average between</span>,
                betweenWord: 'and',
                minimum: 'bridalHairMinimum',
                maximum: 'bridalHairMaximum',
                disableText: `I don't offer bridal hair`,
                disableProperty: 'bridalHairDisabled',
            },
            {
                component: ToFrom,
                jsx: <span><b>Bridal makeup</b> costs on average between</span>,
                betweenWord: 'and',
                minimum: 'bridalMakeupMinimum',
                maximum: 'bridalMakeupMaximum',
                disableText: `I don't offer bridal makeup`,
                disableProperty: 'bridalMakeupDisabled',
            },
            {
                component: ToFrom,
                jsx: <span><b>Bridesmade hair</b> per person costs on average between</span>,
                betweenWord: 'and',
                minimum: 'bridesmaidHairMinimum',
                maximum: 'bridesmaidHairMaximum',
                disableText: `I don't offer bridesmaid hair`,
                disableProperty: 'bridesmadeHairDisabled',
            },
            {
                component: ToFrom,
                jsx: <span><b>Bridesmade makeup</b> per person costs on average between</span>,
                betweenWord: 'and',
                minimum: 'bridesmaidMakeupMinimum',
                maximum: 'bridesmaidMakeupMaximum',
                disableText: `I don't offer bridesmaid makeup`,
                disableProperty: 'bridesmadeMakeupDisabled',
            },
        ]
    }

}