import React from 'react'
import { TellUsAboutYourWedding } from './TellAboutYourWedding'
import { TellUsAboutCouple } from './TellUsAboutCouple'
import { WeddingKeywords } from './WeddingKeywords'

export const StepOneWeddingDetails = ({ step1, setForm, stepOneErrors, setStepOneErrors }) => {

    const validateStepOne = (event) => {
        const required = [ "partner1", "partner2", "descriptiveTitle" ]
        const { name, value } = event.target
            if(required.includes(name)) {
                    setStepOneErrors(prev=>{
                        return{
                            ...prev,
                            [name]:{
                                ...prev[name],
                                error:value===""
                            }
                        }
                    })
            }
    }

    const handleInput = (e) => {
        if(!e.noPersist){
            e.persist();
        }
        setForm(prev=>{
            return{
                ...prev,
                steps:{
                    ...prev.steps,
                    step1:{
                        ...prev.steps.step1,
                        [e.target.name]:e.target.value
                    }
                }
            }
        })
    }

    return(
        <div className=''>
            <WeddingKeywords 
                step1={step1} 
                setForm={setForm} 
                handleInput={handleInput}
                stepOneErrors={stepOneErrors}
            />
            <TellUsAboutYourWedding 
                step1={step1} 
                setForm={setForm} 
                handleInput={handleInput}
                stepOneErrors={stepOneErrors}
                validateStepOne={validateStepOne}
            />
            <TellUsAboutCouple 
                step1={step1} 
                setForm={setForm} 
                handleInput={handleInput}
                stepOneErrors={stepOneErrors}
                validateStepOne={validateStepOne}
            />
        </div>
    )
}