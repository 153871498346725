
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import SharePopup from './share-popup.component';
import NavbarNew from ".././navbarnew.component";
import _ from 'lodash';
import InstructionsPopup from '.././instructions-popup.component';
import { Mixpanel } from '../../../Mixpanel';
import '../../../styles/results.css'
import verifiedbadge from '../../../styles/verified-badge.svg';
import ShareLogo from '../../../styles/share.svg';
import Instagram from '../../../styles/instagram.svg';
import Website from '../../../styles/website.svg';
import Email from '../../../styles/email.svg';
import ArrowRight from '../../../styles/arrow-right.svg';
import TickCircle from '../../../styles/tick-circle.svg';
import Calendar from '../../../styles/calendar.svg';
import Location from '../../../styles/location.svg';
import ResultCard from './results-card.component.js'
import tempResults from './temp'




function vendorClicked(name, type) {
	Mixpanel.track(`Vendor ${type} Clicked`, { "Name": name})
}

const Tokens = ({ photographer }) => {
	return (
	<div className='result-token-wrapper'>
		{photographer.budgetScore == 100 && <div className='budget-token token'><img src={TickCircle} alt='' />In Your Budget</div>}
		{photographer.calendar_score == 100 && <div className='calendar-confirmed-token token'><img src={Calendar} alt='' />Confirmed Availability</div>}
		{photographer.calendar_score < 100 && photographer.calendar_score > 98 && <div className='calendar-updated-token token'><img src={Calendar} alt='' />Recently Updated Calendar</div>}
	</div>
	)
}

const Weddings = ({ photographer, mobile }) => {
	return (
		<div className={`bottom-left ${mobile ? 'mobile' : ''}`}>
		{photographer.number_of_weddings > 0 &&
		<a onClick = {(e) => vendorClicked(photographer.business_name, 'real weddings')} className='wedding-link' target='_blank' href={`https://wedmatch.com/wedding-photographers/${photographer.business_name?.replace(/,/g, "").replace(/&/g, "").replace(new RegExp('/', 'g'), '').replace(/  /g, " ").replace(/ /g, "-").replace(/'/g, "").replace(/\+/g, "").replaceAll(/\./g, '')}`}>
			View {photographer.number_of_weddings} Real Wedding{photographer.number_of_weddings > 1 ? 's' : ''}
		</a>}
		{photographer.weddingExperience > 1 &&
			<div className='wedding-experience'><img src={Location} alt='' />{photographer.weddingExperience} weddings at your venue</div>
		}
		{photographer.weddingExperience == 1 &&
			<div className='wedding-experience'><img src={Location} alt='' />{photographer.weddingExperience} wedding at your venue</div>
		}
	</div>
	)
}

const Stat = ({name, score}) => {
	return (
		<div className='stat-wrapper'>
			<div className='stat-text-wrapper'>
				<div className='stat-name'>{name}</div>
				<div className='stat-score-wrapper'><span className='stat-score'>{score}</span><span className='stat-out-of'>% Match</span></div>
			</div>
			<div className='stat-bar'>
				<div className='stat-bar-background'></div>
				<div className='stat-bar-bar' style={{width: `${score}%`}}></div>
				<div className='stat-bar-circle' style={{left: `calc(${score}% - 5px)`}}></div>
			</div>
		</div>
	)
}

const Quotes = ({ quotes }) => {
	const [ currentQuote, setCurrentQuote ] = useState(0)
	return (
		<div className='quote-box'>
			<div className='flex-between' style={{alignItems: 'center'}}><div className='header-small'>Verified Review Quotes</div><div className='quote-badge'>{quotes[currentQuote].attribute}</div></div>
			<div className='quote-body'>"{quotes[currentQuote].quote}"</div>
			<div className='flex-between'>
				<div className='quote-credit'><span className='quote-author'>{quotes[currentQuote].name}</span><span className='quote-date'> ● {quotes[currentQuote].date.split('-')[1]}/{quotes[currentQuote].date.split('-')[2].split('T')[0]}/{quotes[currentQuote].date.split('-')[0]}</span></div>
				<div className='quote-arrows'>
					<button onClick={() => setCurrentQuote(prev => prev - 1)} disabled={currentQuote === 0} className='quote-arrow'><img alt='next' className='mirror' src={ArrowRight} /></button>
					<button onClick={() => setCurrentQuote(prev => prev + 1)} disabled={currentQuote === quotes.length - 1} className='quote-arrow'><img alt='next' src={ArrowRight} /></button>
				</div>
			</div>
		</div>
	)
}


const toCamelCase = (str) => {
  return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
};

// Function to convert all keys in an object from snake_case to camelCase
const convertKeysToCamelCase = (obj) => {
  let newObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelCaseKey = toCamelCase(key); // Convert key to camelCase
      newObj[camelCaseKey] = obj[key]; // Assign value
    }
  }
  return newObj;
};



const Results = () => {
	const [ dataLoaded, setDataLoaded ] = useState(false)
	const [scoredSelections, setScoredSelections ] = useState([])
	const [posted, setPosted ] = useState(false)
	const [ restart, setRestart ] = useState(false)
	const [ locations, setLocations ] = useState([])
	const [ share, setShare ] = useState(false)
	const [ resultId, setResultId ] = useState(null)
	const [ reviewQuotes, setReviewQuotes ] = useState([])
	const [ extraPhotos, setExtraPhotos ] = useState([])
	const [quizState, setQuizState] = useState({
    attributes: [],
    firstName: '',
    lastName: '',
    budget: null,
    month: '',
    day: '',
    year: '',
    interests: [],
    preference: '',
    email: '',
    optionsTotal: 0,
    moreLocations: [],
    gettingReadyChecked: false,
    gettingReadyImportance: 0,
    ceremonyChecked: false,
    ceremonyImportance: 0,
    receptionChecked: false,
    receptionImportance: 0,
    portraitsChecked: false,
    portraitsImportance: 0,
    canContact: false,
    photoStyles: [],
    weddingLocation: '',
    styleTags: [],
    locationTags: [],
    importantTags: [],
    userOnlyTags: [],
    selectedBudgets: [],
    hoursSelected: null,
    chosenVendorTypes: [],
    noVenue: false,
    privateVenue: false,
    selections: [],
    venueVendorId: null,
    venueName: '',
  });

	let { result } = useParams();


	useEffect(() => {
		fetchData()
	}, [])


	const fetchData = async (e) => {
		let userId = localStorage.getItem('userId')
    if (userId) {
      console.log('get')
      try {
        const response = await axios.get('/api/get-quiz-attributes/', {
          params: { userId: userId }
        })

				const fetchedData = response.data[0]

				const keysToParse = ['photo_styles', 'selections'];

				// Iterate over the keys in fetchedData and parse if necessary
				keysToParse.forEach((key) => {
					if (fetchedData[key] && Array.isArray(fetchedData[key])) {
						fetchedData[key] = fetchedData[key].map((item) => {
							try {
								return JSON.parse(item); // Attempt to parse each item in the array
							} catch (error) {
								console.error(`Failed to parse ${key} for item:`, item);
								return item; // If parsing fails, return the item as is
							}
						});
					}
				});


				const manuallyMappedData = {
					attributes: fetchedData.attributes,
					firstName: fetchedData.first_name,
					lastName: fetchedData.last_name,
					budget: fetchedData.budget,
					month: fetchedData.month,
					day: fetchedData.day,
					year: fetchedData.year,
					interests: fetchedData.interests,
					preference: fetchedData.preference,
					email: fetchedData.email,
					optionsTotal: fetchedData.options_total,
					moreLocations: fetchedData.more_locations,
					gettingReadyChecked: fetchedData.getting_ready_checked,
					gettingReadyImportance: fetchedData.getting_ready_importance,
					ceremonyChecked: fetchedData.ceremony_checked,
					ceremonyImportance: fetchedData.ceremony_importance,
					receptionChecked: fetchedData.reception_checked,
					receptionImportance: fetchedData.reception_importance,
					portraitsChecked: fetchedData.portraits_checked,
					portraitsImportance: fetchedData.portraits_importance,
					canContact: fetchedData.can_contact,
					photoStyles: fetchedData.photo_styles,
					weddingLocation: fetchedData.wedding_location,
					styleTags: fetchedData.style_tags,
					locationTags: fetchedData.location_tags,
					importantTags: fetchedData.important_tags,
					userOnlyTags: fetchedData.user_only_tags,
					selectedBudgets: fetchedData.selected_budgets,
					hoursSelected: fetchedData.hours_selected,
					chosenVendorTypes: fetchedData.chosen_vendor_types,
					noVenue: fetchedData.no_venue,
					privateVenue: fetchedData.private_venue,
					selections: fetchedData.selections,
					venueVendorId: fetchedData.venue_vendor_id,
					venueName: fetchedData.venue_name
				};

				setQuizState(manuallyMappedData);
      } catch (error) {
        console.log(error)
      }
    }
	}


	const {
		// quizState,
		matchups,
		setValue,
		scoredPhotographers,
	} = useContext(AnswersContext);


	useEffect(() => {
		Mixpanel.track('View Results Page');
		window.setTimeout(function(){
			setShare(true)
		}, 60000);
		document.title="Results: My top wedding photographers in Richmond, VA based on my personal style, budget, and wedding date."
		console.log('prereview')
		if(result && !dataLoaded){
			console.log('postreview')
			axios.get('/api/result/' + result)
			.then(r => {
				setDataLoaded(true)
				setScoredSelections(r.data.top_ten.slice(0,5))
				axios.post('/api/review-quotes', { attributes: r.data.attributes, photographerIds: r.data.top_ten.slice(0, 5).map(p => p.photographer.id)})
				.then(r2 => {
					setReviewQuotes(r2.data.quotes)
					setExtraPhotos(r2.data.photos)
				})
			})
		}
		// localStorage.clear()
		window.pintrk('track', 'signup')
		axios.get('/api/locations')
		.then(r => setLocations(r.data))
	}, [])


	const {
		attributes,
		firstName,
		lastName,
		budget,
		month,
		day,
		year,
		interests,
		preference,
		email,
		optionsTotal,
		moreLocations,
		gettingReadyChecked,
		gettingReadyImportance,
		ceremonyChecked,
		ceremonyImportance,
		receptionChecked,
		receptionImportance,
		portraitsChecked,
		portraitsImportance,
		canContact,
		photoStyles,
		weddingLocation,
		styleTags,
		locationTags,
		importantTags,
		userOnlyTags,
		selectedBudgets,
		hoursSelected,
		chosenVendorTypes,
		noVenue,
		privateVenue,
		selections,
		venueVendorId,
		venueName
	} = quizState


	function shareSet(){
		setShare(true);
		Mixpanel.track('Share Result Clicked');
	}

	function restartLocation(location){
		Mixpanel.track("Another City Clicked",{
			"Location":location.name
		});
		setValue('weddingLocation', location.id)
		setScoredSelections([])
		setValue('selections', [])
		setTimeout(() => {
			setRestart(true)
		}, 1000)

	}


	function restartBudget(amount){
		Mixpanel.track("Another budget Clicked",{
			"Amount":amount
		});
		setTimeout(() => {
			setRestart(true)
		}, 1000)
	}

	//calculates score and creates the array to be rendered in the results page
	useEffect(() => {
		if(!result && selections.length){
			console.log('result!')
			//removes the keys and just makes it an array of arrays, grouped by photographer



			const selectionsByPhotographer = Object.values(_.groupBy(selections, "photographer_fkey"))
			var scoredSelectionsTemp = [] // holds the array until we set state to scoredSelections
			selectionsByPhotographer.map((photographerArray, index) => {
				const photoScore = photographerArray.length / optionsTotal * .7 * 100
				const totalScore = photoScore
				const photographer = scoredPhotographers.find(p => p.id == photographerArray[0].photographer_fkey)
				scoredSelectionsTemp.push({ photographer, photoScore, totalScore, images: photographerArray})
			})
			scoredSelectionsTemp.sort((a, b) => (a.totalScore > b.totalScore) ? -1 : 1)

			console.log(scoredSelectionsTemp)


			for(var i = 0; i < scoredSelectionsTemp.length; i ++){
				scoredSelectionsTemp[i].rank = i+1
			}
			setValue('usedPhotographers', scoredSelectionsTemp.slice(0, 10).map(p => p.photographer.id))



			axios.post('/api/review-quotes', { attributes: quizState.attributes, photographerIds: scoredSelectionsTemp.slice(0, 5).map(p => p.photographer.id)})
			.then(r2 => {
				setReviewQuotes(r2.data.quotes)
				setExtraPhotos(r2.data.photos)
			})

			setScoredSelections(scoredSelectionsTemp.slice(0,5))
			var ranked = []

			// if(photoStyles.filter(f => !f.rank).length == 0 && photoStyles.length){
			// 	ranked.push(photoStyles.find(s => s.rank == 1).id)
			// 	ranked.push(photoStyles.find(s => s.rank == 2).id)
			// 	ranked.push(photoStyles.find(s => s.rank == 3).id)
			// 	ranked.push(photoStyles.find(s => s.rank == 4).id)
			// }

			const data = {
				attributes,
				firstName,
				lastName,
				budget,
				month,
				day,
				year,
				interests,
				photographer_preference: preference,
				email,
				selections,
				howImportantGettingReady: (gettingReadyChecked === "true" || gettingReadyChecked === true) ? gettingReadyImportance: 0,
				howImportantCeremony:  (ceremonyChecked === "true" || ceremonyChecked === true) ? ceremonyImportance: 0,
				howImportantReception: (receptionChecked === "true" || receptionChecked === true) ? receptionImportance: 0,
				howImportantPortraits: (portraitsChecked === "true" || portraitsChecked === true) ? portraitsImportance: 0,
				topTen: scoredSelectionsTemp.slice(0,10),
				canContact,
				weddingLocation,
				matchups,
				styleTags,
				locationTags,
				importantTags,
				userOnlyTags,
				photoStyles: ranked,
				selectedBudgets,
				hoursSelected,
				chosenVendorTypes,
				noVenue,
				privateVenue,
				venueVendorId,
				venueName
			};

			if(!posted){
				axios.post("/api/results", {
					data
				})
				.then((r) => {
					setPosted(true)
					setResultId(r.data.id)
				});
			}
	}


	}, [selections, preference])

	const mostPhotosChosen = scoredSelections?.sort((a, b) => a.images.length > b.images.length ? -1 : 1)[0]?.images.length

	return (
		<div className="w-full min-h-screen bg-[#F5F7FA]">
		<NavbarNew />

		<div className="flex justify-center space-x-20 mt-10 mb-6">
			<h1 className="text-2xl font-medium">Daniel's Wedding Vendor Manager</h1>
			<button className="px-4 py-2 bg-blue-500 rounded text-white">
				+ Add Vendor Category
			</button>
		</div>




		<div className="w-full flex justify-center">

			<div className="py-8">
				<div className="flex">
					{/* Sidebar */}
					<div className="w-[300px] mr-24">
						<div className="border bg-white rounded-xl p-4">
							<h2 className="text-xl font-semibold mb-4">Wedding Details</h2>
							{/* Wedding details placeholder */}
							<div className="space-y-4">
								<div>
									<p className="text-sm">Wedding Location</p>
									<p className="mt-1 font-medium">Richmond, VA</p>
								</div>

								<div>
									<p  className="text-sm">Wedding Venue</p>
									<p className="mt-1 font-medium">TBD</p>
								</div>

								<div>
									<p className="text-sm">Wedding Date</p>
									<p className="mt-1 font-medium">Sep 7, 2025 (in 394 Days)</p>
								</div>

								<div>
									<p className="text-sm">Total Wedding Budget</p>
									<p className="mt-1 font-medium">$30,000 to $40,000</p>
								</div>
							</div>
						</div>

						<div className="mt-5 border bg-white rounded-xl p-4">
							<h2 className="text-xl font-semibold mb-4">Photographer Preferences</h2>
							{/* Wedding details placeholder */}
							<div className="space-y-4">
								<div>
									<p className="text-sm">Photographer Budget</p>
									<p className="mt-1 font-medium">$2,500 to $4,000</p>
								</div>

								<div>
									<p  className="text-sm">Parts of the Day</p>
									<p className="mt-1 font-medium">All Parts of the Day</p>
								</div>

								<div>
									<p className="text-sm">Number of Hours</p>
									<p className="mt-1 font-medium">6 Hours</p>
								</div>

								<button className="px-3 py-1.5 border rounded-xl text-greenblue text-center">Retake Matching Quiz</button>

							</div>
						</div>

					</div>
				</div>
			</div>


				<div className='panes'>
					{scoredSelections.sort((a, b) => (a.photographer.budgetScore + a.photographer.locationScore + a.photographer.styleScore + (Math.round(a.images.length / mostPhotosChosen * 50 + 50)) + 400)/8 > (b.photographer.budgetScore + b.photographer.locationScore + b.photographer.styleScore + (b.photoScore * 2) + 400)/8 ? -1 : 1).map((s, index) =>
					<>
					<div className='result-card result-card-desktop'>
						<div className='result-card-left'>
							<div className='image-scroller'>
								{s.images.concat(extraPhotos.filter(p => p.photographer_fkey == s.photographer.id).slice(0, 10)).map((i, index) =>
									<div key={index}>
										<img alt='selected' key={index} src={i.image_url} />
									</div>
								)}
							</div>
						</div>
						<div className='result-right'>
								<div className='result-top' style={{display: 'flex'}}>
									<div className="avatar">
										<img src={s.photographer.profile_photo_url} alt=""/>
									</div>
									<div style={{width: '100%', paddingTop: '6px'}}>
										<div className='result-top-right-top'>
											<div className='result-top-right' style={{display: 'flex'}}>
												<div className='result-left'>
													<div className='header' style={{marginBottom: 0, color: 'black'}}>
														{s.photographer.business_name}
														<img alt='' className="verified-badge" src={verifiedbadge} />
													</div>
													<div className='subheader'>{locations.length > 0 && s.photographer.primary_location && locations.find(l => l.id == s.photographer.primary_location).name}</div>
												</div>
												<div style={{display: 'flex'}} className='result-top-top-right-right'>
													<div className='result-left percent-match-wrapper' style={{position: 'relative'}}>
														<div className='percentage'>{Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (Math.round(s.images.length / mostPhotosChosen * 50 + 50)) + 400)/8)}%</div>
														<div className='percentage-bar-background'></div>
														<div className='percentage-bar' style={{width: `${Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (s.photoScore * 2) + 400)/8)}%`}}></div>
														<div style={{marginTop: '6px', fontSize: '10px'}} className='subheader'>Match</div>
													</div>
													<div className='result-right rank'>
														<span>#</span><span style={{fontSize: '24px'}}>{index + 1}</span>
													</div>
												</div>
											</div>
										</div>
										<Tokens photographer={s.photographer} />
									</div>
							</div>
							<div className='result-middle'>
									<div className='stats'>
										<div className='header-small'>Match Stats</div>
											{false && Object.entries(s.photographer.attributes).sort((a, b) => a[1] > b[1] ? -1 : 1).slice(0, 3).map((o, index) => <Stat key={index} name={o[0]} score={Math.round(o[1]*20 + 80)} />)}
											<Stat name='Photos' score={Math.round(s.images.length / mostPhotosChosen * 50 + 50)} />
											<Stat name='Budget' score={Math.round(s.photographer.budgetScore)} />
											<Stat name='Editing Style' score={Math.round(s.photographer.styleScore/2) + 50} />
									</div>
									<div className='quotes'>
										{reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id).length > 0 && <Quotes quotes={reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id)} />}

										{!reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id).length > 0 && <div className='quote-box'><div className='no-reviews'>This photographer doesn't have reviews yet.</div></div>}
									</div>
							</div>
						</div>
							<div className='bottom'>
								<Weddings photographer={s.photographer} />
								<div className='social'>
									{s.photographer.instagram_url && <a onClick = {(e) => vendorClicked(s.photographer.business_name, 'instagram')} target='_blank' rel='noopener noreferrer' href={s.photographer.instagram_url.substr(0,4) != "http" ? `https://${s.photographer.instagram_url}` : s.photographer.instagram_url}>
										<button className='round-button'><img alt='' src={Instagram} /></button>
									</a>}
									<a onClick = {(e) => vendorClicked(s.photographer.business_name, 'email')}  target='_blank' rel='noopener noreferrer' href={`mailto: ${s.photographer.email}`}>
										<button className='social-button'><img alt='' src={Email} />Email</button>
									</a>
									<a onClick = {(e) => vendorClicked(s.photographer.business_name, 'website')} target='_blank' rel='noopener noreferrer' href={s.photographer.website_url.substr(0,4) != "http" ? `https://${s.photographer.website_url}` : s.photographer.website_url}>
										<button className='social-button inverted'><img alt='' src={Website} />Website</button>
									</a>
								</div>
							</div>
						</div>


						<div className='result-card result-card-mobile'>
								<div className='result-top' style={{display: 'flex'}}>
									<div className="avatar">
										<img src={s.photographer.profile_photo_url} alt=""/>
									</div>
									<div style={{width: '100%', paddingTop: '6px'}}>
										<div className='result-top-right-top'>
											<div className='result-top-right' style={{display: 'flex'}}>
												<div className='result-left'>
													<div className='header' style={{marginBottom: 0, color: 'black'}}>
														{s.photographer.business_name.includes(' ') ? <>{s.photographer.business_name.split(' ').slice(0, Math.round(s.photographer.business_name.split(' ').length / 2)).join(' ')}<br />{s.photographer.business_name.split(' ').slice(Math.round(s.photographer.business_name.split(' ').length / 2)).join(' ')}</> : <>{s.photographer.business_name}</> }<img alt='' className="verified-badge" src={verifiedbadge} /></div>
													<div className='subheader'>{locations.length > 0 && s.photographer.primary_location && locations.find(l => l.id == s.photographer.primary_location).name}</div>
												</div>
												<div className='result-right rank'>
													<span>#</span><span style={{fontSize: '24px'}}>{index + 1}</span>
												</div>

											</div>
										</div>
									</div>
							</div>
							<div className='flex-between' style={{alignItems: 'center', marginBottom: '3px'}}>
							<Tokens photographer={s.photographer} />

							<div style={{position: 'relative'}} className='percent-match-wrapper'>
										<div className='percentage'>{Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (Math.round(s.images.length / mostPhotosChosen * 50 + 50)) + 400)/8)}%</div>
										<div className='percentage-bar-background'></div>
										<div className='percentage-bar' style={{width: `${Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (s.photoScore * 2) + 400)/8)}%`}}></div>
										<div className='subheader' style={{marginTop: '6px', marginBottom: '16px', fontSize: '10px'}}>Match</div>
								</div>
							</div>
							<div className='image-scroller'>
								{s.images.concat(extraPhotos.filter(p => p.photographer_fkey == s.photographer.id).slice(0, 10)).map((i, index) =>
									<div key={index}>
										<img alt='selected' key={index} src={i.image_url} />
									</div>
								)}
							</div>
								<div className='quotes'>
									{reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id).length > 0 && <Quotes quotes={reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id)} />}
								</div>
								<div className='stats'>
									<div className='header-small'>Match Stats</div>
										{false && Object.entries(s.photographer.attributes).sort((a, b) => a[1] > b[1] ? -1 : 1).slice(0, 3).map((o, index) => <Stat key={index} name={o[0]} score={Math.round(o[1]*20 + 80)} />)}
										<Stat name='Photos' score={Math.round(s.images.length / mostPhotosChosen * 50 + 50)} />
										<Stat name='Budget' score={Math.round(s.photographer.budgetScore)} />
										<Stat name='Editing Style' score={Math.round(s.photographer.styleScore/2) + 50} />
								</div>


							<div className='social'>
								{s.photographer.instagram_url && <a onClick = {(e) => vendorClicked(s.photographer.business_name, 'instagram')} target='_blank' rel='noopener noreferrer' href={s.photographer.instagram_url.substr(0,4) != "http" ? `https://${s.photographer.instagram_url}` : s.photographer.instagram_url}>
									<button className='round-button'><img alt='' src={Instagram} /></button>
								</a>}
								<a onClick = {(e) => vendorClicked(s.photographer.business_name, 'email')} target='_blank' rel='noopener noreferrer' href={`mailto: ${s.photographer.email}`}>
									<button className='social-button'><img alt='' src={Email} />Email</button>
								</a>
								<a onClick = {(e) => vendorClicked(s.photographer.business_name, 'website')} target='_blank' rel='noopener noreferrer' href={s.photographer.website_url.substr(0,4) != "http" ? `https://${s.photographer.website_url}` : s.photographer.website_url}>
									<button className='social-button inverted'><img alt='' src={Website} />Website</button>
								</a>
							</div>
							<Weddings mobile photographer={s.photographer} />
							</div>
						</>
					)}
				</div>

			</div>
		</div>
	);
};

export default Results;