import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../UserContext";
import DashboardNav from "./dashboard-nav.component";
import axios from "axios";
import styled from "styled-components";
import { PhotographerContext } from "../PhotographerContext";

const ProfilePicture = styled.img`
	width: 200px;
	height: 200px;
	border-radius: 50%;
	object-fit: cover;
	display: inline;
	margin-right: 25px;
	@media (max-width: 700px) {
		margin: 0;
		margin-bottom: 20px;
	}
`;

const ProfilPicSection = styled.div`
	width: 570px;
	max-width: 95%;
	margin-bottom: 50px;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	@media (max-width: 700px) {
		flex-direction: column;
		justify-content: center;
		justify-content: center;
	}
`;
const InputSection = styled.div`
	width: 570px;
	max-width: 95%;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	@media (max-width: 700px) {
		flex-direction: column;
		margin-bottom: 0px;
		align-items: center;
		justify-content: center;
	}
`;

const ChangePicSection = styled.div`
	display: flex;
	flex-direction: column;
	@media (max-width: 700px) {
		align-items: center;
	}
`;

const Label = styled.div`
	text-align: left;
	font-size: 18px;
	margin: 0 0 5px 10px;
	color: #7e7e7e;
`;

const DashboardInfo = () => {
	let { id } = useParams();

	const [ businessName, setBusinessName ] = useState("");
	const [ contactName, setContactName ] = useState("");
	const [ phoneNumber, setPhoneNumber ] = useState("");
	const [ websiteUrl, setWebsiteUrl ] = useState("");
	const [ instagramUrl, setInstagramUrl ] = useState("");
	const [ email, setEmail ] = useState("");
	const [ profilePhotoSrc, setProfilePhotoSrc ] = useState("");
	const [ zipCode, setZipCode ] = useState(null);
	const { setInfoComplete, profileComplete, setInfoTouched, setPersonalityTouched} = useContext(PhotographerContext);
	const { setPhotographer, setActivePage } = useContext(UserContext);

	const checkInfoComplete = () => {
		if(phoneNumber && phoneNumber.length > 7 && contactName && contactName.length > 1 && businessName && businessName.length > 1 && websiteUrl && websiteUrl.length > 3 && instagramUrl && instagramUrl.length > 3 && email && email.length > 3 && profilePhotoSrc && zipCode && zipCode > 1000){
			setInfoComplete(true)
		} else{
			setInfoComplete(false)
		}
	}
	useEffect(() => {
		setActivePage('infoDashboard')
		setPhotographer(id);
		setInfoTouched(false)
		setPersonalityTouched(false)
		axios.get("/api/photographerdata/" + id).then((result) => {
			setBusinessName(result.data.business_name);
			setContactName(result.data.contact_name);
			setPhoneNumber(result.data.phone_number);
			setWebsiteUrl(result.data.website_url);
			setInstagramUrl(result.data.instagram_url);
			setEmail(result.data.email);
			setProfilePhotoSrc(result.data.profile_photo_url);
			setZipCode(result.data.zip_code);
		})
	}, []);

	useEffect(() => {
		checkInfoComplete()
		
	}, [phoneNumber, businessName, contactName, websiteUrl, instagramUrl, email, profilePhotoSrc, zipCode])

	const submitBusinessName = () => {
		setInfoTouched(true)
		const data = {
			business_name: businessName,
			id: id
		};
		axios.post("/api/businessname", data);
	};
	const submitContactName = () => {
		setInfoTouched(true)
		const data = {
			contact_name: contactName,
			id: id
		};
		axios.post("/api/contactname", data);
	};
	const submitPhoneNumber = () => {
		setInfoTouched(true)
		const data = {
			phone_number: phoneNumber,
			id: id
		};
		axios.post("/api/phone", data);
	};
	const updatePhoneNumber = (e) => {
		setInfoTouched(true)
		setPhoneNumber(e.target.value);
	};
	const submitWebsiteUrl = () => {
		setInfoTouched(true)
		const data = {
			website_url: websiteUrl,
			id: id
		};
		axios.post("/api/websiteurl", data);
	};
	const submitInstagramUrl = (e) => {
		setInfoTouched(true)
		const data = {
			instagram_url: instagramUrl,
			id: id
		};
		axios.post("/api/instagramurl", data);
	};
	const submitEmail = () => {
		setInfoTouched(true)
		const data = {
			email: email,
			id: id
		};
		axios.post("/api/photographeremail", data);
	};

	const submitZipCode = () => {
		setInfoTouched(true)
		const data = {
			zip_code: zipCode,
			id: id
		};
		axios.post("/api/zipcode", data);
	};

	useEffect(
		() => {
			if (phoneNumber && phoneNumber.length > 9) {
				if (phoneNumber.match(/\d/g).length === 10 && phoneNumber.length == 10) {
					var justNumber = phoneNumber.match(/\d/g).join("");
					setPhoneNumber(
						"(" +
							justNumber.substring(0, 3) +
							")" +
							justNumber.substring(3, 6) +
							"-" +
							justNumber.substring(6, 10)
					);
				}
			}
		},
		[ phoneNumber ]
	);
	//CLOSING NACBAR CODE
	const [ toggleNav, setToggleNav ] = useState(false);

	const uploadPhoto = (e) => {

		const formData = new FormData();			
		formData.append("file", e.target.files[0]);
		formData.append("upload_preset", "avatar");
		axios.post("https://api.cloudinary.com/v1_1/dfjwlskop/image/upload", formData).then((p) => {
			const data = {
				id,
				image_url: p.data.secure_url,
			};
			setProfilePhotoSrc(p.data.secure_url)
			axios.post("/api/cloudinary-profile-photo-upload", data).then((r) => {
				
			});
		});
	}

	return (
		<div className="dashboard-container">
			<DashboardNav toggleNav={toggleNav} />
			<div
				className="dashboard-sub-container "
				onClick={() => {
					setToggleNav(!toggleNav);
				}}>
					{profileComplete && <span className="profile-active">Active</span>}
					{!profileComplete && <span className="profile-inactive">Inactive</span>}
				<ProfilPicSection>
					<ProfilePicture src={profilePhotoSrc}/>
					<ChangePicSection>
						<label className="custom-file-upload">
						<input
								className="upload-button"
								type="file"
								name="image"
								onChange={uploadPhoto}
							/>
							Change Image
						</label>
						<Label style={{ marginTop: "10px", marginLeft: " 0" }}>
							Profile photo of you, the photographer
						</Label>
					</ChangePicSection>
				</ProfilPicSection>

				<InputSection>
					<div>
						<Label>Business Name</Label>
						<input
							className="dashboard-info-input"
							style={{ width: "280px" }}
							onChange={(e) => {setBusinessName(e.target.value); setInfoTouched(true);}}
							onBlur={submitBusinessName}
							value={businessName}
						/>
					</div>
					<div>
						<Label>Contact Name</Label>
						<input
							className="dashboard-info-input"
							style={{ width: "280px" }}
							onChange={(e) => {setContactName(e.target.value); setInfoTouched(true);}}
							onBlur={submitContactName}
							value={contactName}
						/>
					</div>
				</InputSection>

				<InputSection>
					<div>
						<Label>Phone Number</Label>
						<input
							className="dashboard-info-input"
							style={{ width: "280px" }}
							onChange={updatePhoneNumber}
							onBlur={submitPhoneNumber}
							value={phoneNumber}
							type="tel"
							maxLength="13"
						/>
					</div>

					<div>
						<Label>Business Location Zip Code</Label>

						<input
							className="dashboard-info-input"
							style={{ width: "280px" }}
							onChange={(e) => {setZipCode(e.target.value); setInfoTouched(true);}}
							onBlur={submitZipCode}
							value={zipCode}
							type="number"

							maxLength="13"
						/>
					</div>
				</InputSection>

				<InputSection>
					<div>
						<Label>Website URL</Label>
						<input
							className="dashboard-info-input"
							style={{ width: "280px" }}
							onChange={(e) => {setInfoTouched(true); setWebsiteUrl(e.target.value);}}
							onBlur={submitWebsiteUrl}
							value={websiteUrl}
							type="url"
						/>
					</div>

					<div>
						<Label>Instagram URL (full link)</Label>
						<input
							className="dashboard-info-input"
							style={{ width: "280px" }}
							onChange={(e) => {setInstagramUrl(e.target.value); setInfoTouched(true);}}
							onBlur={submitInstagramUrl}
							value={instagramUrl}
							type="url"
						/>
					</div>
				</InputSection>
				<InputSection>
					<div>
						<Label>Email</Label>
						<input
							className="dashboard-info-input"
							style={{ width: "280px" }}
							onChange={(e) => {setEmail(e.target.value); setInfoTouched(true);}}
							onBlur={submitEmail}
							value={email}
							type="email"
							name="email"
						/>
					</div>
				</InputSection>

				{/* </InfoContainer> */}
			</div>
		</div>
	);
};

export default DashboardInfo;
