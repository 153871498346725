import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StyledInput } from '../styles/StyledComponents/StyledInput.js';
import axios from 'axios';

const PhotographerDashboard = () => {
  useEffect(() => {
    axios.get('/api/photographerdata/' + id).then(result => {
      if (result.data.account_type == 'photographer') {
        setBusinessName(result.data.business_name);
        setContactName(result.data.contact_name);
        setPhoneNumber(result.data.phone_number);
        setWebsiteUrl(result.data.website_url);
        setInstagramUrl(result.data.instagram_url);
        setEmail(result.data.email);
        setProfilePhotoSrc(result.data.profile_photo_url);
      } else {
        window.location = '/login';
      }
    });
  }, []);
  let { id } = useParams();
  const [businessName, setBusinessName] = useState('');
  const [contactName, setContactName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [email, setEmail] = useState('');
  const [profilePhoto, setProfilePhoto] = useState('');
  const [profilePhotoSrc, setProfilePhotoSrc] = useState('');

  const submitBusinessName = () => {
    const data = {
      business_name: businessName,
      id: id,
    };
    axios.post('/api/businessname', data);
  };
  const submitContactName = () => {
    const data = {
      contact_name: contactName,
      id: id,
    };
    axios.post('/api/contactname', data);
  };
  const submitPhoneNumber = () => {
    const data = {
      phone_number: phoneNumber,
      id: id,
    };
    axios.post('/api/phone', data);
  };
  const updatePhoneNumber = e => {
    setPhoneNumber(e.target.value);
  };
  const submitWebsiteUrl = e => {
    const data = {
      website_url: websiteUrl,
      id: id,
    };
    axios.post('/api/websiteurl', data);
  };
  const submitInstagramUrl = () => {
    const data = {
      instagram_url: instagramUrl,
      id: id,
    };
    axios.post('/api/instagramurl', data);
  };
  const submitEmail = () => {
    const data = {
      email: email,
      id: id,
    };
    axios.post('/api/photographeremail', data);
  };
  const uploadProfileImage = e => {
    setProfilePhoto(e.target.files[0]);
  };

  useEffect(() => {
    if (profilePhoto) {
      const formData = new FormData();
      formData.append('image', profilePhoto);
      formData.append('id', id);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios.post('/api/profile-photo', formData, config).then(() => {
        axios.get('/api/photographerdata/' + id).then(result => {
          setProfilePhotoSrc(result.data.profile_photo_url);
        });
      });
    }
  }, [profilePhoto]);

  useEffect(() => {
    if (phoneNumber.length > 9) {
      if (phoneNumber.match(/\d/g).length === 10 && phoneNumber.length == 10) {
        var justNumber = phoneNumber.match(/\d/g).join('');
        setPhoneNumber(
          '(' + justNumber.substring(0, 3) + ')' + justNumber.substring(3, 6) + '-' + justNumber.substring(6, 10)
        );
      }
    }
  }, [phoneNumber]);

  return (
    <div style={{ margin: '50px' }}>
      <br />
      <br />
      <br />
      <img style={{ width: '200px', height: '200px', objectFit: 'cover' }} src={profilePhotoSrc} />
      <br />
      <input type="file" name="image" onChange={uploadProfileImage} />
      <br />
      <br />
      <br />
      <StyledInput
        style={{ maxWidth: '100%' }}
        onChange={e => setBusinessName(e.target.value)}
        onBlur={submitBusinessName}
        value={businessName}
        placeholder="Business Name"
      />
      <br />
      <StyledInput
        style={{ maxWidth: '100%' }}
        onChange={e => setContactName(e.target.value)}
        onBlur={submitContactName}
        value={contactName}
        placeholder="Contact Name"
      />
      <br />
      <StyledInput
        style={{ maxWidth: '100%' }}
        onChange={updatePhoneNumber}
        onBlur={submitPhoneNumber}
        value={phoneNumber}
        placeholder="(888)888-8888"
        type="tel"
        maxLength="13"
      />
      <br />
      <StyledInput
        style={{ maxWidth: '100%' }}
        onChange={e => setWebsiteUrl(e.target.value)}
        onBlur={submitWebsiteUrl}
        value={websiteUrl}
        type="url"
        placeholder="Website URL"
      />
      <br />
      <StyledInput
        style={{ maxWidth: '100%' }}
        onChange={e => setInstagramUrl(e.target.value)}
        onBlur={submitInstagramUrl}
        value={instagramUrl}
        type="url"
        placeholder="Instagram URL"
      />
      <br />
      <StyledInput
        style={{ maxWidth: '100%' }}
        onChange={e => setEmail(e.target.value)}
        onBlur={submitEmail}
        value={email}
        type="email"
        name="email"
        placeholder="Email"
      />
    </div>
  );
};

export default PhotographerDashboard;
