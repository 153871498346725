import React from 'react'
import './Header.css'

export const Header = () => {
    return(
        <header>
            <div className='bg-light-blue'>
                <div className='wedmatch_header album_container'>
                    <img 
                        src='/wedmatch-logo.png'
                        alt='WedMatch Logo'
                    />
                    <div className='d-flex align-items-center'>
                        <div className='d-none d-md-block'>
                            <div className='font14 bold400'>Questions?</div>
                            <a target="_blank" rel="noopener noreferrer" href="https://wedmatch.com/contact/"><div className='font16 bold700 text-blue'>Contact Us</div></a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}