import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';

const FullSizeWeddingPhotos = () => {
	let { id } = useParams();
    const [photos, setPhotos ] = useState([])
    useEffect(() => {
        axios.get('/api/wedding-photos/' + id).then(r => {
            setPhotos(r.data)
        })
    }, [])
    return (
        <div>
        {photos.map(p => <img key={p.id} src={p.image_url} />)}
        </div>
    )
}

export default FullSizeWeddingPhotos