import { useNavigate } from "react-router-dom";
import axios from 'axios';


const submitVendor = async (vendor, quizState) => {
  const {
    attributes,
    firstName,
    lastName,
    budget,
    month,
    day,
    year,
    interests,
    preference,
    email,
    optionsTotal,
    moreLocations,
    gettingReadyChecked,
    gettingReadyImportance,
    ceremonyChecked,
    ceremonyImportance,
    receptionChecked,
    receptionImportance,
    portraitsChecked,
    portraitsImportance,
    canContact,
    photoStyles,
    weddingLocation,
    styleTags,
    locationTags,
    importantTags,
    userOnlyTags,
    selectedBudgets,
    hoursSelected,
    chosenVendorTypes,
    noVenue,
    privateVenue,
    selections,
    venueVendorId,
    venueName,
    wedBudget,
    photographerPartsDay,
    photographerNumHours,
    photographerMinMax,
    photographerEstimatedBudget,
    videographerHours,
    videographerMinMax,
    videographerEstimatedBudget,
    floristEstimatedBudget,
    floristMinMax,
    hairMakeupChosenServices,
    hairMakeupEstimatedBudget,
    hairMakeupMinMax,
    hairMakeupNumBridesmaid,
    plannerEstimatedBudget,
    plannerMinMax,
    chosenPlannerTypes,
    djChosenServices,
    djHours,
    djMinMax,
    djEstimatedBudget,
    bakerMinMax,
    bakerEstimatedBudget
  } = quizState
  const accessToken = localStorage.getItem('auth_token');
  const refreshToken = localStorage.getItem('refresh_token');
  let lowerCaseVendor = vendor.toLowerCase()


  try {
    const response = await axios.post('/api/validate-supa-login', {
      accessToken: accessToken,
      refreshToken: refreshToken,
    })
    if (response.status === 200) {
      const userId = response.data.session.user.id


      if (userId) {

        const weddingResponse = await axios.post('/api/update-saved-quiz-attributes/', {
          newVendorCategory: vendor,
          userId: userId,
        });

        if (weddingResponse) {
          try {
            if (lowerCaseVendor === 'photographer') {
              const photographerResponse = await axios.post('/api/save-photographer-preferences/', {
                photoPreferences: {
                  attributes,
                  optionsTotal, // photog
                  gettingReadyChecked, // photog
                  gettingReadyImportance, // photog
                  ceremonyChecked, // photog
                  ceremonyImportance, // photog
                  receptionChecked, // photog
                  receptionImportance, // photog
                  portraitsChecked, // photog
                  portraitsImportance, // photog
                  photoStyles, // photog
                  styleTags, // photog
                  selectedBudgets, // photog
                  selections, // photog
                  photographerPartsDay,
                  photographerNumHours,
                  photographerMinMax,
                  photographerEstimatedBudget,
                  lowerCaseVendor,
                },
                userId: userId,
              })
            } else if (lowerCaseVendor === 'videographer') {
              const vendorResponse = await axios.post('/api/save-videographer-preferences/', {
                vendorPreferences: {
                  attributes,
                  optionsTotal, // Vendor specific
                  gettingReadyChecked, // Vendor specific
                  gettingReadyImportance, // Vendor specific
                  ceremonyChecked, // Vendor specific
                  ceremonyImportance, // Vendor specific
                  receptionChecked, // Vendor specific
                  receptionImportance, // Vendor specific
                  portraitsChecked, // Vendor specific
                  portraitsImportance, // Vendor specific
                  photoStyles, // Vendor specific
                  styleTags, // Vendor specific
                  selectedBudgets, // Vendor specific
                  selections, // Vendor specific
                  videographerHours,
                  videographerMinMax,
                  videographerEstimatedBudget,
                  lowerCaseVendor,
                },
                userId: userId,
              });
            } else if (lowerCaseVendor === 'planner/coordinator') {
              const vendorResponse = await axios.post('/api/save-planner-preferences/', {
                  vendorPreferences: {
                    attributes,
                    optionsTotal, // Vendor specific
                    gettingReadyChecked, // Vendor specific
                    gettingReadyImportance, // Vendor specific
                    ceremonyChecked, // Vendor specific
                    ceremonyImportance, // Vendor specific
                    receptionChecked, // Vendor specific
                    receptionImportance, // Vendor specific
                    portraitsChecked, // Vendor specific
                    portraitsImportance, // Vendor specific
                    photoStyles, // Vendor specific
                    styleTags, // Vendor specific
                    selectedBudgets, // Vendor specific
                    selections, // Vendor specific
                    chosenPlannerTypes,
                    plannerMinMax,
                    plannerEstimatedBudget,
                    lowerCaseVendor,
                  },
                  userId: userId,
                });
            } else if (lowerCaseVendor === 'florist') {
              const vendorResponse = await axios.post('/api/save-florist-preferences/', {
                  vendorPreferences: {
                    attributes,
                    optionsTotal, // Vendor specific
                    gettingReadyChecked, // Vendor specific
                    gettingReadyImportance, // Vendor specific
                    ceremonyChecked, // Vendor specific
                    ceremonyImportance, // Vendor specific
                    receptionChecked, // Vendor specific
                    receptionImportance, // Vendor specific
                    portraitsChecked, // Vendor specific
                    portraitsImportance, // Vendor specific
                    photoStyles, // Vendor specific
                    styleTags, // Vendor specific
                    selectedBudgets, // Vendor specific
                    selections, // Vendor specific
                    floristMinMax,
                    floristEstimatedBudget,
                    lowerCaseVendor,
                  },
                  userId: userId,
                });
            } else if (lowerCaseVendor === 'hair/makeup') {
              const vendorResponse = await axios.post('/api/save-hairmakeup-preferences/', {
                  vendorPreferences: {
                    attributes,
                    optionsTotal, // Vendor specific
                    gettingReadyChecked, // Vendor specific
                    gettingReadyImportance, // Vendor specific
                    ceremonyChecked, // Vendor specific
                    ceremonyImportance, // Vendor specific
                    receptionChecked, // Vendor specific
                    receptionImportance, // Vendor specific
                    portraitsChecked, // Vendor specific
                    portraitsImportance, // Vendor specific
                    photoStyles, // Vendor specific
                    styleTags, // Vendor specific
                    selectedBudgets, // Vendor specific
                    selections, // Vendor specific,
                    hairMakeupNumBridesmaid,
                    hairMakeupChosenServices,
                    hairMakeupMinMax,
                    hairMakeupEstimatedBudget,
                    lowerCaseVendor,
                  },
                  userId: userId,
                });
            } else if (lowerCaseVendor === 'dj') {
              const vendorResponse = await axios.post('/api/save-dj-preferences/', {
                  vendorPreferences: {
                    attributes,
                    optionsTotal, // Vendor specific
                    gettingReadyChecked, // Vendor specific
                    gettingReadyImportance, // Vendor specific
                    ceremonyChecked, // Vendor specific
                    ceremonyImportance, // Vendor specific
                    receptionChecked, // Vendor specific
                    receptionImportance, // Vendor specific
                    portraitsChecked, // Vendor specific
                    portraitsImportance, // Vendor specific
                    photoStyles, // Vendor specific
                    styleTags, // Vendor specific
                    selectedBudgets, // Vendor specific
                    selections, // Vendor specific
                    djChosenServices,
                    djHours,
                    djMinMax,
                    djEstimatedBudget,
                    lowerCaseVendor,
                  },
                  userId: userId,
                });
            } else if (lowerCaseVendor === 'bakers') {
              const vendorResponse = await axios.post('/api/save-baker-preferences/', {
                  vendorPreferences: {
                    attributes,
                    optionsTotal, // Vendor specific
                    gettingReadyChecked, // Vendor specific
                    gettingReadyImportance, // Vendor specific
                    ceremonyChecked, // Vendor specific
                    ceremonyImportance, // Vendor specific
                    receptionChecked, // Vendor specific
                    receptionImportance, // Vendor specific
                    portraitsChecked, // Vendor specific
                    portraitsImportance, // Vendor specific
                    photoStyles, // Vendor specific
                    styleTags, // Vendor specific
                    selectedBudgets, // Vendor specific
                    selections, // Vendor specific
                    bakerMinMax,
                    bakerEstimatedBudget,
                    lowerCaseVendor,
                  },
                  userId: userId,
                });
            } else {
              return "fail"
            }

            if (response) {
              return "success";
            }

          } catch (error) {
            console.log(error)
          }
        }


      }
    }
  } catch (error) {
    console.error('Error validating session:', error);
  }
};


export default submitVendor
