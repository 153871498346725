import React, { useState, useEffect } from 'react';
import axios from 'axios';


const TempPhotoUpload = () => {    
    const [images, setImages] = useState([])
    const imageUpload = (i) => {
        setImages(i)
    }

    const onChangeHandler = (e) => {
        imageUpload(e.target.files)        
    }

    useEffect(() => {
        if(images.length > 0){
            let data = new FormData()
            for(var x = 0; x<images.length; x++) {
                data.append('image', images[x])
            }
            axios.post( '/api/temp-upload', data, {
				headers: {
					'accept': 'application/json',
					'Accept-Language': 'en-US,en;q=0.8',
					'Content-Type': 'multipart/form-data',
				}
			})
        }
    })
    return(
    <div>
        <form action="/api/temp-upload" encType="multipart/form-data" method="POST">
            Hello?
            <input type="file" name="image" multiple onChange={onChangeHandler}/>
            <input type="submit" />
        </form>
    </div>
  )
}

export default TempPhotoUpload