
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { AnswersContext } from "../../AnswersContext";
import SharePopup from './share-popup.component';
import NavbarNew from "./navbarnew.component";
import _ from 'lodash';
import InstructionsPopup from './instructions-popup.component';
import { Mixpanel } from '../../Mixpanel';
import '../../styles/results.css'
import verifiedbadge from '../../styles/verified-badge.svg';
import ShareLogo from '../../styles/share.svg';
import Instagram from '../../styles/instagram.svg';
import Website from '../../styles/website.svg';
import Email from '../../styles/email.svg';
import ArrowRight from '../../styles/arrow-right.svg';
import TickCircle from '../../styles/tick-circle.svg';
import Calendar from '../../styles/calendar.svg';
import Location from '../../styles/location.svg';


function vendorClicked(name, type) {
	Mixpanel.track(`Vendor ${type} Clicked`, { "Name": name})
}

const Tokens = ({ photographer }) => {
	return (
	<div className='result-token-wrapper'>
		{photographer.budgetScore == 100 && <div className='budget-token token'><img src={TickCircle} alt='' />In Your Budget</div>}
		{photographer.calendar_score == 100 && <div className='calendar-confirmed-token token'><img src={Calendar} alt='' />Confirmed Availability</div>}
		{photographer.calendar_score < 100 && photographer.calendar_score > 98 && <div className='calendar-updated-token token'><img src={Calendar} alt='' />Recently Updated Calendar</div>}
	</div>
	)
}

const Weddings = ({ photographer, mobile }) => {
	return (
		<div className={`bottom-left ${mobile ? 'mobile' : ''}`}>
		{photographer.number_of_weddings > 0 &&
		<a onClick = {(e) => vendorClicked(photographer.business_name, 'real weddings')} className='wedding-link' target='_blank' href={`https://wedmatch.com/wedding-photographers/${photographer.business_name?.replace(/,/g, "").replace(/&/g, "").replace(new RegExp('/', 'g'), '').replace(/  /g, " ").replace(/ /g, "-").replace(/'/g, "").replace(/\+/g, "").replaceAll(/\./g, '')}`}>
			View {photographer.number_of_weddings} Real Wedding{photographer.number_of_weddings > 1 ? 's' : ''}
		</a>}
		{photographer.weddingExperience > 1 &&
			<div className='wedding-experience'><img src={Location} alt='' />{photographer.weddingExperience} weddings at your venue</div>
		}
		{photographer.weddingExperience == 1 &&
			<div className='wedding-experience'><img src={Location} alt='' />{photographer.weddingExperience} wedding at your venue</div>
		}
	</div>
	)
}

const Stat = ({name, score}) => {
	return (
		<div className='stat-wrapper'>
			<div className='stat-text-wrapper'>
				<div className='stat-name'>{name}</div>
				<div className='stat-score-wrapper'><span className='stat-score'>{score}</span><span className='stat-out-of'>% Match</span></div>
			</div>
			<div className='stat-bar'>
				<div className='stat-bar-background'></div>
				<div className='stat-bar-bar' style={{width: `${score}%`}}></div>
				<div className='stat-bar-circle' style={{left: `calc(${score}% - 5px)`}}></div>
			</div>
		</div>
	)
}

const Quotes = ({ quotes }) => {
	const [ currentQuote, setCurrentQuote ] = useState(0)
	return (
		<div className='quote-box'>
			<div className='flex-between' style={{alignItems: 'center'}}><div className='header-small'>Verified Review Quotes</div><div className='quote-badge'>{quotes[currentQuote].attribute}</div></div>
			<div className='quote-body'>"{quotes[currentQuote].quote}"</div>
			<div className='flex-between'>
				<div className='quote-credit'><span className='quote-author'>{quotes[currentQuote].name}</span><span className='quote-date'> ● {quotes[currentQuote].date.split('-')[1]}/{quotes[currentQuote].date.split('-')[2].split('T')[0]}/{quotes[currentQuote].date.split('-')[0]}</span></div>
				<div className='quote-arrows'>
					<button onClick={() => setCurrentQuote(prev => prev - 1)} disabled={currentQuote === 0} className='quote-arrow'><img alt='next' className='mirror' src={ArrowRight} /></button>
					<button onClick={() => setCurrentQuote(prev => prev + 1)} disabled={currentQuote === quotes.length - 1} className='quote-arrow'><img alt='next' src={ArrowRight} /></button>
				</div>
			</div>
		</div>
	)
}
const Results = () => {
	const [ dataLoaded, setDataLoaded ] = useState(false)
	const [scoredSelections, setScoredSelections ] = useState([])
	const [posted, setPosted ] = useState(false)
	const [ restart, setRestart ] = useState(false)
	const [ locations, setLocations ] = useState([])
	const [ share, setShare ] = useState(false)
	const [ resultId, setResultId ] = useState(null)
	const [ reviewQuotes, setReviewQuotes ] = useState([])
	const [ extraPhotos, setExtraPhotos ] = useState([])

	let { result } = useParams();


	const {
		quizState,
		matchups,
		setValue,
		scoredPhotographers,
	} = useContext(AnswersContext);

	useEffect(() => {
		Mixpanel.track('View Results Page');
		window.setTimeout(function(){
			setShare(true)
		}, 60000);
		document.title="Results: My top wedding photographers in Richmond, VA based on my personal style, budget, and wedding date."
		if(result && !dataLoaded){
			axios.get('/api/result/' + result)
			.then(r => {
				setDataLoaded(true)
				setScoredSelections(r.data.top_ten.slice(0,5))
				axios.post('/api/review-quotes', { attributes: r.data.attributes, photographerIds: r.data.top_ten.slice(0, 5).map(p => p.photographer.id)})
				.then(r2 => {
					setReviewQuotes(r2.data.quotes)
					setExtraPhotos(r2.data.photos)
				})
			})
		}
		localStorage.clear()
		window.pintrk('track', 'signup')
		axios.get('/api/locations')
		.then(r => setLocations(r.data))
	}, [])

	const {
		attributes,
		firstName,
		lastName,
		budget,
		month,
		day,
		year,
		interests,
		preference,
		email,
		optionsTotal,
		moreLocations,
		gettingReadyChecked,
		gettingReadyImportance,
		ceremonyChecked,
		ceremonyImportance,
		receptionChecked,
		receptionImportance,
		portraitsChecked,
		portraitsImportance,
		canContact,
		photoStyles,
		weddingLocation,
		styleTags,
		locationTags,
		importantTags,
		userOnlyTags,
		selectedBudgets,
		hoursSelected,
		chosenVendorTypes,
		noVenue,
		privateVenue,
		selections,
		venueVendorId,
		venueName
	} = quizState

	function shareSet(){
		setShare(true);
		Mixpanel.track('Share Result Clicked');
	}

	function restartLocation(location){
		Mixpanel.track("Another City Clicked",{
			"Location":location.name
		});
		setValue('weddingLocation', location.id)
		setScoredSelections([])
		setValue('selections', [])
		setTimeout(() => {
			setRestart(true)
		}, 1000)

	}
	function restartBudget(amount){
		Mixpanel.track("Another budget Clicked",{
			"Amount":amount
		});
		setTimeout(() => {
			setRestart(true)
		}, 1000)
	}

	//calculates score and creates the array to be rendered in the results page
	useEffect(() => {
		if(!result && selections.length){
			//removes the keys and just makes it an array of arrays, grouped by photographer
			const selectionsByPhotographer = Object.values(_.groupBy(selections, "photographer_fkey"))
			var scoredSelectionsTemp = [] // holds the array until we set state to scoredSelections
			selectionsByPhotographer.map((photographerArray, index) => {
				const photoScore = photographerArray.length / optionsTotal * .7 * 100
				const totalScore = photoScore
				const photographer = scoredPhotographers.find(p => p.id == photographerArray[0].photographer_fkey)
				scoredSelectionsTemp.push({ photographer, photoScore, totalScore, images: photographerArray})
			})
			scoredSelectionsTemp.sort((a, b) => (a.totalScore > b.totalScore) ? -1 : 1)
			for(var i = 0; i < scoredSelectionsTemp.length; i ++){
				scoredSelectionsTemp[i].rank = i+1
			}
			setValue('usedPhotographers', scoredSelectionsTemp.slice(0, 10).map(p => p.photographer.id))

			axios.post('/api/review-quotes', { attributes: quizState.attributes, photographerIds: scoredSelectionsTemp.slice(0, 5).map(p => p.photographer.id)})
			.then(r2 => {
				setReviewQuotes(r2.data.quotes)
				setExtraPhotos(r2.data.photos)
			})
			setScoredSelections(scoredSelectionsTemp.slice(0,5))
			var ranked = []
			if(photoStyles.filter(f => !f.rank).length == 0 && photoStyles.length){
				ranked.push(photoStyles.find(s => s.rank == 1).id)
				ranked.push(photoStyles.find(s => s.rank == 2).id)
				ranked.push(photoStyles.find(s => s.rank == 3).id)
				ranked.push(photoStyles.find(s => s.rank == 4).id)
			}
			const data = {
				attributes,
				firstName,
				lastName,
				budget,
				month,
				day,
				year,
				interests,
				photographer_preference: preference,
				email,
				selections,
				howImportantGettingReady: (gettingReadyChecked === "true" || gettingReadyChecked === true) ? gettingReadyImportance: 0,
				howImportantCeremony:  (ceremonyChecked === "true" || ceremonyChecked === true) ? ceremonyImportance: 0,
				howImportantReception: (receptionChecked === "true" || receptionChecked === true) ? receptionImportance: 0,
				howImportantPortraits: (portraitsChecked === "true" || portraitsChecked === true) ? portraitsImportance: 0,
				topTen: scoredSelectionsTemp.slice(0,10),
				canContact,
				weddingLocation,
				matchups,
				styleTags,
				locationTags,
				importantTags,
				userOnlyTags,
				photoStyles: ranked,
				selectedBudgets,
				hoursSelected,
				chosenVendorTypes,
				noVenue,
				privateVenue,
				venueVendorId,
				venueName
			};
			if(!posted){
				axios.post("/api/results", {
					data
				})
				.then((r) => {
					setPosted(true)
					setResultId(r.data.id)
				});
			}
	}


	}, [selections, preference])

	const mostPhotosChosen = scoredSelections?.sort((a, b) => a.images.length > b.images.length ? -1 : 1)[0]?.images.length

	return (
		<div className="question-page">
			{restart && <InstructionsPopup differentBudgetOrLocation={true} />}
			{(share && (resultId || result)) &&<SharePopup setShare={setShare} id={result ? result : resultId}/>}
            <NavbarNew/>
			<div className='results-page'>
				<div className='result-top-wrapper'>
					<div>
				<div className='results-header-new'>You've got the best photographers!</div>
				<div className='results-subheader-new'>Don't wait! Contact these photographers to get the conversation started!</div>
				</div>
				<div style={{textAlign: 'right'}}><button className="share-button-new" onClick={() => { shareSet()}}><img src={ShareLogo} style={{marginRight: '4px'}}/> Share Result</button></div>
				</div>
				<div className='panes'>
					{scoredSelections.sort((a, b) => (a.photographer.budgetScore + a.photographer.locationScore + a.photographer.styleScore + (Math.round(a.images.length / mostPhotosChosen * 50 + 50)) + 400)/8 > (b.photographer.budgetScore + b.photographer.locationScore + b.photographer.styleScore + (b.photoScore * 2) + 400)/8 ? -1 : 1).map((s, index) =>
					<>
					<div className='result-card result-card-desktop'>
						<div className='result-card-left'>
							<div className='image-scroller'>
								{s.images.concat(extraPhotos.filter(p => p.photographer_fkey == s.photographer.id).slice(0, 10)).map((i, index) =>
									<div key={index}>
										<img alt='selected' key={index} src={i.image_url} />
									</div>
								)}
							</div>
						</div>
						<div className='result-right'>
								<div className='result-top' style={{display: 'flex'}}>
									<div className="avatar">
										<img src={s.photographer.profile_photo_url} alt=""/>
									</div>
									<div style={{width: '100%', paddingTop: '6px'}}>
										<div className='result-top-right-top'>
											<div className='result-top-right' style={{display: 'flex'}}>
												<div className='result-left'>
													<div className='header' style={{marginBottom: 0, color: 'black'}}>
														{s.photographer.business_name}
														<img alt='' className="verified-badge" src={verifiedbadge} />
													</div>
													<div className='subheader'>{locations.length > 0 && s.photographer.primary_location && locations.find(l => l.id == s.photographer.primary_location).name}</div>
												</div>
												<div style={{display: 'flex'}} className='result-top-top-right-right'>
													<div className='result-left percent-match-wrapper' style={{position: 'relative'}}>
														<div className='percentage'>{Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (Math.round(s.images.length / mostPhotosChosen * 50 + 50)) + 400)/8)}%</div>
														<div className='percentage-bar-background'></div>
														<div className='percentage-bar' style={{width: `${Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (s.photoScore * 2) + 400)/8)}%`}}></div>
														<div style={{marginTop: '6px', fontSize: '10px'}} className='subheader'>Match</div>
													</div>
													<div className='result-right rank'>
														<span>#</span><span style={{fontSize: '24px'}}>{index + 1}</span>
													</div>
												</div>
											</div>
										</div>
										<Tokens photographer={s.photographer} />
									</div>
							</div>
							<div className='result-middle'>
									<div className='stats'>
										<div className='header-small'>Match Stats</div>
											{false && Object.entries(s.photographer.attributes).sort((a, b) => a[1] > b[1] ? -1 : 1).slice(0, 3).map((o, index) => <Stat key={index} name={o[0]} score={Math.round(o[1]*20 + 80)} />)}
											<Stat name='Photos' score={Math.round(s.images.length / mostPhotosChosen * 50 + 50)} />
											<Stat name='Budget' score={Math.round(s.photographer.budgetScore)} />
											<Stat name='Editing Style' score={Math.round(s.photographer.styleScore/2) + 50} />
									</div>
									<div className='quotes'>
										{reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id).length > 0 && <Quotes quotes={reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id)} />}

										{!reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id).length > 0 && <div className='quote-box'><div className='no-reviews'>This photographer doesn't have reviews yet.</div></div>}
									</div>
							</div>
						</div>
							<div className='bottom'>
								<Weddings photographer={s.photographer} />
								<div className='social'>
									{s.photographer.instagram_url && <a onClick = {(e) => vendorClicked(s.photographer.business_name, 'instagram')} target='_blank' rel='noopener noreferrer' href={s.photographer.instagram_url.substr(0,4) != "http" ? `https://${s.photographer.instagram_url}` : s.photographer.instagram_url}>
										<button className='round-button'><img alt='' src={Instagram} /></button>
									</a>}
									<a onClick = {(e) => vendorClicked(s.photographer.business_name, 'email')}  target='_blank' rel='noopener noreferrer' href={`mailto: ${s.photographer.email}`}>
										<button className='social-button'><img alt='' src={Email} />Email</button>
									</a>
									<a onClick = {(e) => vendorClicked(s.photographer.business_name, 'website')} target='_blank' rel='noopener noreferrer' href={s.photographer.website_url.substr(0,4) != "http" ? `https://${s.photographer.website_url}` : s.photographer.website_url}>
										<button className='social-button inverted'><img alt='' src={Website} />Website</button>
									</a>
								</div>
							</div>
						</div>


						<div className='result-card result-card-mobile'>
								<div className='result-top' style={{display: 'flex'}}>
									<div className="avatar">
										<img src={s.photographer.profile_photo_url} alt=""/>
									</div>
									<div style={{width: '100%', paddingTop: '6px'}}>
										<div className='result-top-right-top'>
											<div className='result-top-right' style={{display: 'flex'}}>
												<div className='result-left'>
													<div className='header' style={{marginBottom: 0, color: 'black'}}>
														{s.photographer.business_name.includes(' ') ? <>{s.photographer.business_name.split(' ').slice(0, Math.round(s.photographer.business_name.split(' ').length / 2)).join(' ')}<br />{s.photographer.business_name.split(' ').slice(Math.round(s.photographer.business_name.split(' ').length / 2)).join(' ')}</> : <>{s.photographer.business_name}</> }<img alt='' className="verified-badge" src={verifiedbadge} /></div>
													<div className='subheader'>{locations.length > 0 && s.photographer.primary_location && locations.find(l => l.id == s.photographer.primary_location).name}</div>
												</div>
												<div className='result-right rank'>
													<span>#</span><span style={{fontSize: '24px'}}>{index + 1}</span>
												</div>

											</div>
										</div>
									</div>
							</div>
							<div className='flex-between' style={{alignItems: 'center', marginBottom: '3px'}}>
							<Tokens photographer={s.photographer} />

							<div style={{position: 'relative'}} className='percent-match-wrapper'>
										<div className='percentage'>{Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (Math.round(s.images.length / mostPhotosChosen * 50 + 50)) + 400)/8)}%</div>
										<div className='percentage-bar-background'></div>
										<div className='percentage-bar' style={{width: `${Math.round((s.photographer.budgetScore + s.photographer.locationScore + s.photographer.styleScore + (s.photoScore * 2) + 400)/8)}%`}}></div>
										<div className='subheader' style={{marginTop: '6px', marginBottom: '16px', fontSize: '10px'}}>Match</div>
								</div>
							</div>
							<div className='image-scroller'>
								{s.images.concat(extraPhotos.filter(p => p.photographer_fkey == s.photographer.id).slice(0, 10)).map((i, index) =>
									<div key={index}>
										<img alt='selected' key={index} src={i.image_url} />
									</div>
								)}
							</div>
								<div className='quotes'>
									{reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id).length > 0 && <Quotes quotes={reviewQuotes.filter(q => q.photographer_fkey == s.photographer.id)} />}
								</div>
								<div className='stats'>
									<div className='header-small'>Match Stats</div>
										{false && Object.entries(s.photographer.attributes).sort((a, b) => a[1] > b[1] ? -1 : 1).slice(0, 3).map((o, index) => <Stat key={index} name={o[0]} score={Math.round(o[1]*20 + 80)} />)}
										<Stat name='Photos' score={Math.round(s.images.length / mostPhotosChosen * 50 + 50)} />
										<Stat name='Budget' score={Math.round(s.photographer.budgetScore)} />
										<Stat name='Editing Style' score={Math.round(s.photographer.styleScore/2) + 50} />
								</div>


							<div className='social'>
								{s.photographer.instagram_url && <a onClick = {(e) => vendorClicked(s.photographer.business_name, 'instagram')} target='_blank' rel='noopener noreferrer' href={s.photographer.instagram_url.substr(0,4) != "http" ? `https://${s.photographer.instagram_url}` : s.photographer.instagram_url}>
									<button className='round-button'><img alt='' src={Instagram} /></button>
								</a>}
								<a onClick = {(e) => vendorClicked(s.photographer.business_name, 'email')} target='_blank' rel='noopener noreferrer' href={`mailto: ${s.photographer.email}`}>
									<button className='social-button'><img alt='' src={Email} />Email</button>
								</a>
								<a onClick = {(e) => vendorClicked(s.photographer.business_name, 'website')} target='_blank' rel='noopener noreferrer' href={s.photographer.website_url.substr(0,4) != "http" ? `https://${s.photographer.website_url}` : s.photographer.website_url}>
									<button className='social-button inverted'><img alt='' src={Website} />Website</button>
								</a>
							</div>
							<Weddings mobile photographer={s.photographer} />
							</div>
						</>
					)}
				</div>
			<br /><br />
				{!moreLocations && !result &&
				<div style={{padding: '12px'}}>
					<div className="header" >Want to try another city?</div>
					<div className="subheader" style={{marginBottom: '10px'}}>Just pick new photos, we'll save your other preferences</div>
				<div style={{display: 'flex', flexWrap: 'wrap', marginLeft: '-10px'}}>
				{locations.filter(l => l.id != weddingLocation).map(l => <button onClick={() => restartLocation(l)} style={{width: '160px', margin: '10px'}} className="question-button results-button location-button">{l.name}</button>)}
				</div>
				</div>
				}
				<br />
				{!result &&
				<div style={{padding: '12px'}}>
				<div className="header">Want to change your budget?</div>
					<div className="subheader" style={{marginBottom: '10px'}}>Just pick new photos, we'll save your other preferences</div>
				<div style={{display: 'flex', flexWrap: 'wrap', marginLeft: '-10px'}}>
				{selectedBudgets.filter(b => b == 1).length == 0 && <button onClick={() => {setValue('selectedBudgets', [1]); setScoredSelections([]); setValue('selections', []);  restartBudget('0-$2,000');}} style={{width: '160px', margin: '10px'}} className="question-button results-button">0-$2,000</button>}
				{selectedBudgets.filter(b => b == 2).length == 0 && <button  onClick={() => {setValue('selectedBudgets', [2]); setScoredSelections([]); setValue('selections', []); restartBudget('2,001-$3,000');}} style={{width: '160px', margin: '10px'}} className="question-button results-button">2,001-$3,000</button>}
				{selectedBudgets.filter(b => b == 3).length == 0 && <button  onClick={() => {setValue('selectedBudgets', [3]); setScoredSelections([]); setValue('selections', []); restartBudget('3,001-$4,000');}} style={{width: '160px', margin: '10px'}} className="question-button results-button">3,001-$4,000</button>}
				{selectedBudgets.filter(b => b == 4).length == 0 && <button  onClick={() => {setValue('selectedBudgets', [4]); setScoredSelections([]); setValue('selections', []); restartBudget('4,001-$6,000');}} style={{width: '160px', margin: '10px'}} className="question-button results-button">4,001-$6,000</button>}
				{selectedBudgets.filter(b => b == 5).length == 0 && <button onClick={() => {setValue('selectedBudgets', [5]); setScoredSelections([]); setValue('selections', []); restartBudget('$6,000+');}} style={{width: '160px', margin: '10px'}} className="question-button results-button">$6,000+</button>}
				</div>
				</div>
				}
			</div>
		</div>
	);
};

export default Results;