import React from "react";
import { Link } from "react-router-dom";
import backArrow from '../../styles/back-arrow.svg'

const FooterNew = (props) => {


	return (
		<div className="footer-new">
			<div className="footer-back">
				<Link to={props.from} className="flex">
					<img src={backArrow} style={{marginRight: '14px'}}/>
					<p className="text-[#17A2B8] hover:text-[#007bff]">Back</p>
				</Link>
			</div>
            <div className="footer-right">
                <div className="footer-current">{props.otherText}</div>
				{props.noLink &&
				 <div><button onClick={props.clickEvent} className={`footer-next-button ${props.inactive ? 'inactive' : ''}`}>{props.buttonText}</button></div>

				}
				{!props.noLink &&
                	<div><Link to={props.to}><button className={`footer-next-button ${props.inactive ? 'inactive' : ''}`}>{props.buttonText}</button></Link></div>
				}
				</div>
		</div>
	);
};

export default FooterNew;
