import React, { useEffect, useState } from 'react';
import axios from 'axios'

const Vendor = ({ vendor, updateTagline }) => {
    const [ roundupTagline, setRoundupTagline ] = useState(vendor.roundup_tagline)
    
    return (
        <div>
            {vendor.business_name}
            <input style={{border: '1px solid black', marginLeft: '16px'}} value={roundupTagline} onBlur={() => updateTagline(vendor, roundupTagline)} onChange={(e) => setRoundupTagline(e.target.value)} />
        </div>
    )
}
const UpdateVendors = () => {   
    const [ venues, setVenues ] = useState([])
   useEffect(() => {
    axios.get('/api/venues')
    .then(r => {
        console.log(r)
        setVenues(r.data)
    })
   }, [])
   const updateTagline = (v, tagline) => {
    axios.post('/api/update-vendor-roundup', {id: v.id, roundup_tagline: tagline, roundup_url: ''})
   }

    return(
        <div>
           {venues.map(v => 
            <Vendor updateTagline={updateTagline} key={v.id} vendor={v} />
            )}
        </div>
    )
}

export default UpdateVendors;