const UpArrow = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#F5F5F5"/>
    <g clip-path="url(#clip0_2734_9782)">
    <path d="M14.4147 10.8331L7.73963 17.5083C7.58512 17.6627 7.5 17.8688 7.5 18.0886C7.5 18.3083 7.58512 18.5144 7.73963 18.6688L8.23109 19.1604C8.55133 19.4803 9.07182 19.4803 9.39157 19.1604L14.9969 13.5551L20.6084 19.1666C20.7629 19.321 20.9689 19.4062 21.1885 19.4062C21.4084 19.4062 21.6144 19.321 21.769 19.1666L22.2604 18.675C22.4149 18.5205 22.5 18.3146 22.5 18.0948C22.5 17.875 22.4149 17.6689 22.2604 17.5146L15.5792 10.8331C15.4242 10.6784 15.2173 10.5934 14.9973 10.5939C14.7764 10.5934 14.5696 10.6784 14.4147 10.8331Z" fill="#0A8A95"/>
    </g>
    <defs>
    <clipPath id="clip0_2734_9782">
    <rect width="15" height="15" fill="white" transform="matrix(0 -1 1 0 7.5 22.5)"/>
    </clipPath>
    </defs>
  </svg>
)

export default UpArrow