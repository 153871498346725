import React from 'react'
export const VendorDetailsBox = ({item, handleInput, step2, resultsActiveOn, setResultsActiveOn}) => {
    return(
        <div>
            
            {resultsActiveOn &&
                <div className='overlay transparent' onClick={() => setResultsActiveOn('')}></div>
            }
            <div style={{marginTop:15}} className='font18 bold600'>
                {item.header}
            </div>
            <div style={{marginTop:15}} className='d-flex '>
                <div className='vendor_img_container'>
                    <img 
                        src={item.icon}
                        alt={`${item.header} logo`}
                    />
                </div>
                <div style={{ gap:15, flex:1}} className='d-md-flex flex-wrap'>
                    <div style={{flex:1}} className='floating_inputs_wrapper mb-20 mb-md-0 d-flex'>
                        <label htmlFor={`vendorName`} className='signup-input-label'>
                            Vendor Name
                        </label>
                        <input 
                            style={{
                                width:"100%", 
                                // margin:'0 10px'
                            }}
                            onChange={(e)=>handleInput(e, item.name)}
                            value={step2[item.name].vendorName}
                            autocomplete="off"
                            name={`vendorName`}
                            type='text'
                            placeholder='Vendor name...'
                        />
                        {resultsActiveOn === item.name &&
                        <div className='search-results'>
                            {item.searchResults.map(v => <div onClick={() => {setResultsActiveOn(''); handleInput({target: {value: v.business_name, name: 'vendorName'}, noPersist: true}, item.name);}} key={v.id}>{v.business_name}</div>)}
                        </div>
                        }
                    </div>
                    <div style={{flex:1}} className='floating_inputs_wrapper d-flex'>
                        <label htmlFor={`vendorEmail`} className='signup-input-label'>
                            Vendor Email (Optional)
                        </label>
                        <input 
                            style={{width:"100%"}}
                            onChange={(e)=>handleInput(e, item.name)}
                            value={step2[item.name].vendorEmail}
                            autocomplete="off"
                            name={`vendorEmail`}
                            type='text'
                            placeholder='Vendor email...'
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}