import React, {useEffect, useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import axios from 'axios';
import FooterNew from ".././footernew.component";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import { Mixpanel } from '../../../Mixpanel';
import ScrollJump from '../../scroll-jump.component';

const TagsNewQuiz = () => {
    document.title="Pick your tags | WedMatch"


	const { toggleArraySelection, quizState	} = useContext(AnswersContext);
    const [ tags, setTags ] = useState([])
    const [ nextStep, setNextStep] = useState(false)
    const history = useNavigate();


    // const FooterRender = (props) => {
    //     if (props) {
    //         return ( <FooterNew button={true} back={true} to="/videographernewquiz" from="/datenewquiz" buttonText="Next: Budget" stopAnimating="true"/> );
    //     } else {
    //         return ( <FooterNew button={true} back={true} to="#" inactive="true" from="/datenewquiz" buttonText="Next: Budget" stopAnimating="true"/> );
    //     }
    // };



    const setDelay = (e) => {
		e.preventDefault(); //prevent transition
		window.setTimeout(() => {
				history("/photographernewquiz");
		}, 500);
	};

	const goBack = (e) => {
			e.preventDefault(); //prevent transition
			window.setTimeout(() => {
					history("/importantnewquiz");
			}, 500);
	};


    useEffect(() => {
        let tagsCount = quizState.styleTags.concat(quizState.importantTags.concat(quizState.locationTags.concat(quizState.userOnlyTags)))
        if (tagsCount.length > 0) {
            setNextStep(true)
        } else {
            setNextStep(false)
        }
    }, [quizState.styleTags, quizState.importantTags, quizState.locationTags, quizState.userOnlyTags])


   useEffect(() => {
        Mixpanel.track('View Style Page');
        axios.get('/api/all-tags')
        .then(r => setTags(r.data))
   }, [])



    return (
		<div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
			<div className="w-full">
				<NavbarNew />
			</div>

			<div className="w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-3 pb-0 mt-5 rounded-3xl bg-white mobile:w-[90%]">
				{/* Status Bar */}
				<Timeline phase="tags" />

				<div className="w-full flex flex-col items-center pt-10 pb-6 mx-12 border-t z-10">
                    <div className="flex flex-col items-center text-center">
                        <p className="text-3xl font-medium mobile:text-lg">Describe your dream wedding.</p>
                        <p className="mt-2.5 text-[#444444] mobile:text-sm">Select at least one tag. We'll match you with vendors that best align with your wedding style and interests</p>
                    </div>

                    <div className="w-[75%] mt-3 mobile:w-[95%]">
                        <div className="flex flex-wrap justify-center">

                        {tags.filter(t => t.is_style).map(t =>
                            <button
                                className={`question-button question-tag-button ${quizState.styleTags.length && quizState.styleTags.some(i => i == t.id) ? 'selected' : ''}`}
                                selected={quizState.styleTags.length && quizState.styleTags.some(i => i == t.id)}
                                onClick={() => toggleArraySelection('styleTags', t.id)}
                                >
                                {t.name == "Church/House of Worship" ? "House of Worship" : t.name}
                            </button>
                        )}
                        {tags.filter(t => t.is_important).map(t =>
                            <button
                            className={`question-button question-tag-button ${quizState.importantTags.length && quizState.importantTags.some(i => i == t.id) ? 'selected' : ''}`}
                                selected={quizState.importantTags.length && quizState.importantTags.some(i => i == t.id)}
                                onClick={() => toggleArraySelection('importantTags', t.id)}
                                >
                                {t.name == "Indian/Cultural" ? "Cultural Wedding" : t.name}
                            </button>
                        )}
                        {tags.filter(t => t.is_location).map(t =>
                            <button
                                className={`question-button question-tag-button ${quizState.locationTags.length && quizState.locationTags.some(i => i == t.id) ? 'selected' : ''}`}
                                selected={quizState.locationTags.length && quizState.locationTags.some(i => i == t.id)}
                                onClick={() => toggleArraySelection('locationTags', t.id)}
                                >
                                {t.name}
                            </button>
                        )}
                        {tags.filter(t => t.user_only).map(t =>
                            <button
                            className={`question-button question-tag-button ${quizState.userOnlyTags.length && quizState.userOnlyTags.some(i => i == t.id) ? 'selected' : ''}`}
                                selected={quizState.userOnlyTags.length && quizState.userOnlyTags.some(i => i == t.id)}
                                onClick={() => toggleArraySelection('userOnlyTags', t.id)}
                                >
                                {t.name}
                            </button>
                        )}
                        </div>
                    </div>


                    <div>
                        <FooterNew phase={"detailsTags"} complete={nextStep}/>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default TagsNewQuiz;