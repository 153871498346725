import React, { useContext, useEffect, useState, useRef } from "react";
import { AnswersContext } from "../../../AnswersContext";
import { useNavigate } from "react-router-dom";
import FooterNew from ".././footernew.component";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import closecircleX from "../../../styles/closecircleX"
import ScrollJump from "../../scroll-jump.component";

const DoTogetherNewQuiz = () => {

	const {
		quizState,
		setValue,
		toggleArraySelection,
	} = useContext(AnswersContext);

	const [ nextStep, setNextStep ] = useState(false)

	const history = useNavigate();

	const setDelay = (e) => {
		e.preventDefault(); //prevent transition
		window.setTimeout(() => {
				history("/photostylenewquiz");
		}, 500);
	};

	const goBack = (e) => {
			e.preventDefault(); //prevent transition
			window.setTimeout(() => {
					history("/importantnewquiz");
			}, 500);
	};

	useEffect(() => {
		if (quizState.attributes.length > 0 && quizState.preference) {
			setNextStep(true)
		}
	}, [quizState.attributes, quizState.preference])

	return (
		<div className="w-full flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
			<div className="w-full">
					<NavbarNew />
			</div>

			<div className="w-[75%] max-w-[1000px] flex flex-col items-center mt-5 pt-3 pb-10 rounded-3xl bg-white mobile:w-[95%]">
				<Timeline phase="dotogether"/>

					<div className="w-full flex flex-col items-center justify-center mt-10">
						<div className="text-center">
							<div className="text-3xl font-medium mobile:text-lg">Photographer Attribute Preferences</div>
							<div className="mt-3 text-[#444444] mobile:text-sm">Select at least 1 attribute you would want your photographer to be strong in</div>
						</div>

						<div className="w-[75%] mt-3 mobile:w-[95%]">
							<div className="flex flex-wrap justify-center mt-3 text-sm">
								{attributeOptions.map(a =>
									<button style={{width: 'inherit' }} className={`question-button question-tag-button ${quizState.attributes.includes(a) ? 'selected' : ''}`}  onClick={() => toggleArraySelection('attributes', a)}>{a.charAt(0).toUpperCase()
										+ a.slice(1)}</button>
								)}
							</div>
						</div>

						<div className="text-3xl font-medium mt-3 mobile:text-lg">Working Style</div>
						<div className="w-[60%] mt-3 text-[#444444] text-center mobile:text-sm mobile:w-[90%]">Your photographer's approach to the wedding day should match your needs. I'd most like my photographer to:</div>


						<div className="w-[75%] mt-3 mobile:w-[95%]">
							<div className="flex flex-wrap justify-center" style={{marginLeft: '-5px'}}>
								<button
									className={`question-button question-tag-button ${quizState.preference == 'charge' ? 'selected' : ''}`}
									selected={quizState.preference == "charge"} onClick={() => setValue('preference', "charge")}>
									Take charge
								</button>
								<button
									className={`question-button question-tag-button ${quizState.preference == 'fly' ? 'selected' : ''}`}
									selected={quizState.preference == "fly"}
									onClick={() => setValue('preference', "fly")}>
									Be a fly on the wall
								</button>
								<button
									className={`question-button question-tag-button ${quizState.preference == 'relax' ? 'selected' : ''}`}
									selected={quizState.preference == "relax"} onClick={() => setValue('preference', "relax")}>
									Help me relax
								</button>
								<button
									className={`question-button question-tag-button ${quizState.preference == 'whatever' ? 'selected' : ''}`}
									selected={quizState.preference == "whatever"} onClick={() => setValue('preference', "whatever")}>
									<p className="mobile:w-28">Do whatever I ask</p>
								</button>
							</div>
						</div>

					</div>

					{/* <div className="flex space-x-4">
						<button className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-white border border-[10px] text-[#0A8A95] z-1 ' onClick={(e) => goBack(e)}>
								Back
						</button>

						{nextStep ?
								<button className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-[#0A8A95] opacity-100 text-white z-1' onClick={(e) => setDelay(e)}>
										Next: Photographer Style
								</button>
								:
								<div className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-[#0A8A95] opacity-40 text-white z-1'>
										Next: Photographer Style
								</div>
						}
					</div> */}
					 <div>
            <FooterNew phase={"dotogether"} complete={nextStep}/>
          </div>

			</div>
		</div>
	);
};

export default DoTogetherNewQuiz;

const attributeOptions = [
    'flexible',
    'adaptable',
    'patient',
    'creative',
    'artistic',
    'unique',
    'communicative',
    'responsive',
    'organized',
    'experienced',
    'professional',
    'knowledgeable',
    'assertive',
    'talented',
    'skilled',
    'documentary',
    'capture moment',
    'candid',
    'photojournalistic',
    'friendly',
    'helpful',
    'supportive'
]