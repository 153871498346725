const photographerIcon = (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.3594 14.8931C21.1772 14.8931 20.0289 15.1225 18.9466 15.5748C18.5883 15.7246 18.4192 16.1367 18.5688 16.4952C18.7185 16.8538 19.1304 17.0228 19.4887 16.8732C20.3984 16.493 21.3642 16.3002 22.3594 16.3002C26.4691 16.3002 29.8125 19.6458 29.8125 23.758C29.8125 27.8702 26.4691 31.2157 22.3594 31.2157C18.2497 31.2157 14.9063 27.8702 14.9063 23.758C14.9063 22.7637 15.0983 21.7985 15.4773 20.8895C15.6267 20.5309 15.4574 20.1189 15.099 19.9693C14.7408 19.82 14.3288 19.9893 14.1794 20.3479C13.7286 21.4295 13.5 22.5768 13.5 23.758C13.5 28.6461 17.4743 32.6229 22.3594 32.6229C27.2445 32.6229 31.2188 28.6461 31.2188 23.758C31.2188 18.8698 27.2445 14.8931 22.3594 14.8931Z" fill="#666666"/>
    <path d="M22.3594 29.5277C25.5386 29.5277 28.125 26.9396 28.125 23.7585C28.125 20.5773 25.5386 17.9893 22.3594 17.9893C19.1802 17.9893 16.5938 20.5773 16.5938 23.7585C16.5938 26.9396 19.1803 29.5277 22.3594 29.5277ZM22.3594 19.3964C24.7632 19.3964 26.7188 21.3533 26.7188 23.7585C26.7188 26.1637 24.7632 28.1206 22.3594 28.1206C19.9556 28.1206 18 26.1637 18 23.7585C18 21.3532 19.9556 19.3964 22.3594 19.3964Z" fill="#666666"/>
    <path d="M32.4341 11.5162H30.2952L27.8402 7.35909C27.7137 7.14485 27.4835 7.01343 27.2349 7.01343H17.4849C17.2362 7.01343 17.006 7.14485 16.8795 7.35909L14.4246 11.5162H10.4063V10.672C10.4063 9.27531 9.27079 8.13913 7.87502 8.13913C6.47924 8.13913 5.34376 9.27531 5.34376 10.672V11.5162H3.56598C1.59968 11.5162 0 13.1169 0 15.0845V32.432C0 34.3996 1.59968 36.0002 3.56598 36.0002H32.434C34.4003 36.0002 36 34.3996 36 32.432V15.0845C36.0001 13.1169 34.4003 11.5162 32.4341 11.5162ZM17.8861 8.42055H26.8336L28.6618 11.5162H16.0579L17.8861 8.42055ZM6.75001 10.672C6.75001 10.0513 7.25472 9.54626 7.87502 9.54626C8.49531 9.54626 9.00002 10.0513 9.00002 10.672V11.5162H6.75001V10.672ZM34.5938 32.432C34.5938 33.6237 33.625 34.5931 32.4341 34.5931H3.56598C2.37509 34.5931 1.40625 33.6236 1.40625 32.432V15.0845C1.40625 13.8928 2.37509 12.9234 3.56598 12.9234H32.434C33.6249 12.9234 34.5938 13.8928 34.5938 15.0845V32.432H34.5938Z" fill="#666666"/>
    <path d="M22.3594 22.3864C23.1154 22.3864 23.7305 23.0019 23.7305 23.7583C23.7305 24.1469 24.0453 24.4619 24.4336 24.4619C24.8219 24.4619 25.1367 24.1469 25.1367 23.7583C25.1367 22.226 23.8908 20.9792 22.3594 20.9792C21.971 20.9792 21.6562 21.2943 21.6562 21.6828C21.6562 22.0713 21.971 22.3864 22.3594 22.3864Z" fill="#666666"/>
    <path d="M22.3594 5.16226C22.7477 5.16226 23.0625 4.8472 23.0625 4.45869V0.703563C23.0625 0.315056 22.7477 0 22.3594 0C21.971 0 21.6562 0.314985 21.6562 0.703563V4.45869C21.6562 4.84727 21.971 5.16226 22.3594 5.16226Z" fill="#666666"/>
    <path d="M25.7031 5.1624C25.883 5.1624 26.063 5.09366 26.2003 4.95633L28.7801 2.37488C29.0547 2.10014 29.0547 1.65464 28.7801 1.37983C28.5055 1.10516 28.0603 1.10516 27.7857 1.37983L25.2059 3.96135C24.9314 4.23609 24.9314 4.68158 25.2059 4.9564C25.3432 5.09373 25.5232 5.1624 25.7031 5.1624Z" fill="#666666"/>
    <path d="M18.5201 4.9563C18.6574 5.09363 18.8374 5.16237 19.0173 5.16237C19.1972 5.16237 19.3772 5.09363 19.5145 4.9563C19.789 4.68156 19.7891 4.23606 19.5145 3.96125L16.9347 1.37981C16.6601 1.1052 16.2149 1.10513 15.9403 1.37981C15.6658 1.65455 15.6657 2.10004 15.9403 2.37485L18.5201 4.9563Z" fill="#666666"/>
    <path d="M9.70313 17.2852H6.04688C5.65854 17.2852 5.34375 17.6 5.34375 17.9883C5.34375 18.3766 5.65854 18.6914 6.04688 18.6914H9.70313C10.0915 18.6914 10.4063 18.3766 10.4063 17.9883C10.4063 17.5999 10.0915 17.2852 9.70313 17.2852Z" fill="#666666"/>
    <path d="M16.5938 18.6914C16.7787 18.6914 16.9601 18.6162 17.0909 18.4854C17.2216 18.3546 17.2969 18.1738 17.2969 17.9883C17.2969 17.8034 17.2216 17.622 17.0909 17.4912C16.9601 17.3605 16.7787 17.2852 16.5938 17.2852C16.4088 17.2852 16.2274 17.3604 16.0966 17.4912C15.9652 17.622 15.8906 17.8034 15.8906 17.9883C15.8906 18.1732 15.9652 18.3546 16.0966 18.4854C16.2274 18.6162 16.4081 18.6914 16.5938 18.6914Z" fill="#666666"/>
  </svg>
)

export default photographerIcon