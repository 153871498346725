import React, { useState, useEffect, useContext } from "react";
import CloseButton from '../../styles/close-x.svg';
import FacebookLogo from '../../styles/facebook-logo.svg';
import MessengerLogo from '../../styles/messenger-logo.svg';
import TwitterLogo from '../../styles/twitter-logo.svg';
import CopyLinkLogo from '../../styles/copy-link-logo.svg';
import { Mixpanel } from '../../Mixpanel';
import axios from "axios";

const SharePopup = (props) => {
    const [linkCopied, setLinkCopied ] = useState(false)
    const [resultsSent, setResultsSent ] = useState(false)
    const [email, setEmail ] = useState('')
	const fbShare = () => {
        Mixpanel.track('Facebook Share');
        window.FB.ui({
            method: 'feed',
            name: 'Check out my favorite wedding photographers on WedMatch!',
            link: 'https://app.wedmatch.com/results/' + props.id,
            caption: 'Check out my favorite wedding photographers on WedMatch!',
            description: 'My top wedding photographers in Richmond, VA based on my personal style, budget, and wedding date.',
            message: 'Check out my favorite wedding photographers on WedMatch!',
            quote: 'Check out my favorite wedding photographers on WedMatch!'
        
        }, function(response){});
    }
    const messengerShare = () => {
        Mixpanel.track('Messenger Share');
        window.FB.ui({
            method: 'send',
            link: 'https://app.wedmatch.com/results/' + props.id,
            caption: 'Check out my favorite wedding photographers on WedMatch!',
            description: 'My top wedding photographers in Richmond, VA based on my personal style, budget, and wedding date.',
            message: 'Check out my favorite wedding photographers on WedMatch!',
            quote: 'Check out my favorite wedding photographers on WedMatch!'
          });
    }

    const messengerShareMobile = () => {
        window.open('fb-messenger://share?link=' + encodeURIComponent('https://app.wedmatch.com/results/' + props.id) + '&app_id=' + encodeURIComponent(503884004138888));
    }

    const twitterShare = () => {
        Mixpanel.track('Twitter Share');
    }

    const shareEmail = () => {
        if(email.includes('.') && email.includes('@'))
        {
            setResultsSent(true)
            axios.post('/api/share-email', {id: props.id, email: email})
            setEmail('')
        }
    }

    const copyShare = () => {
        setLinkCopied(true)
        Mixpanel.track('Copy Link Share');
        const el = document.createElement("textarea");
        el.value = 'https://app.wedmatch.com/results/' + props.id;
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
    }

    useEffect(() => {
        setTimeout(function(){ setLinkCopied(false) }, 3000);
    }, [linkCopied])
    
    useEffect(() => {
        setTimeout(function(){ setResultsSent(false) }, 3000);
    }, [resultsSent])

	return (
		<div className="great-overlay">
            <div className="great-popup share-popup">
                <div className="share-close-button" onClick={()=> props.setShare(false)}><img src={CloseButton} /></div>
                <div className="share-main-text">Share your top 5 with your partner, friends and family</div>
                <div className="share-links">
                    <div className="share-links-container">
                        <img style={{cursor: 'pointer'}} onClick={fbShare} src={FacebookLogo} />
                        <div className="share-sub-text">Facebook</div>
                    </div>
                    <div className="share-links-container share-links-desktop">
                        <img style={{cursor: 'pointer'}} onClick={messengerShare} src={MessengerLogo} />
                        <div className="share-sub-text">Messenger</div>
                    </div>
                    <div className="share-links-container share-links-mobile">                            
                        <img style={{cursor: 'pointer'}} onClick={messengerShareMobile} src={MessengerLogo} />
                        <div className="share-sub-text">Messenger</div>
                    </div>
                    <a class="twitter-share-button"
                        href={"https://twitter.com/intent/tweet?text=Check%20out%20my%20favorite%20wedding%20photographers%20on%20WedMatch!%20https://app.wedmatch.com/results/" + props.id}
                        target="_blank"
                        data-size="large" data-url={'https://app.wedmatch.com/results/' + props.id}>
                    <div className="share-links-container">
                        <img style={{cursor: 'pointer'}} onClick={twitterShare} src={TwitterLogo}/>
                        <div className="share-sub-text">Twitter</div>
                    </div>
                    </a>
                    <div className="share-links-container" style={{position: 'relative'}}>
                        <img style={{cursor: 'pointer'}} onClick={copyShare} src={CopyLinkLogo} />
                        <div className="share-sub-text">Copy</div>
                        {linkCopied &&
                        <div className="copy-confirm">Link Copied!</div>
                        }
                    </div>
                </div>
                <div style={{textAlign: 'left', position: 'relative'}}>
                    <div className="share-input-label">Share results with</div>
                    <input style={{fontSize: '14px', width: '100%'}} className="question-input" type="text" placeholder="Recipient's Email Address" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    
                    {resultsSent &&
                        <div className="copy-confirm results-confirm">Email sent!</div>
                    }
                </div>
                <div className="share-submit-wrapper">
                    <button onClick={shareEmail} className={`share-submit-button ${!email.includes('.') || !email.includes('@') ? 'share-submit-inactive' : ''}`}>Share</button>
                    <button onClick={() => props.setShare(false)} className="share-cancel-button">Cancel</button>
                </div>
            </div>
			
		</div>
	);
};

export default SharePopup;
