
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import SharePopup from '.././share-popup.component';
import _ from 'lodash';
import { Mixpanel } from '../../../Mixpanel';
import '../../../styles/results.css'
import verifiedbadge from '../../../styles/verified-badge.svg';
import ShareLogo from '../../../styles/share.svg';
import Instagram from '../../../styles/instagram.svg';
import Website from '../../../styles/website.svg';
import Email from '../../../styles/email.svg';
import ArrowRight from '../../../styles/arrow-right.svg';
import TickCircle from '../../../styles/tick-circle.svg';
import Calendar from '../../../styles/calendar.svg';
import Location from '../../../styles/location.svg';
import closecircleX from "../../../styles/closecircleX"
import Slider from 'react-slick';
import UpArrow from  "../../../styles/dashboard-icons/upArrow"
import DownArrow from  "../../../styles/dashboard-icons/downArrow"



const floristPreferences = ({ vendorPreferences, vendorMode, vendorPreferencesOpen, setVendorPreferencesOpen }) => {

  const vendorData = vendorPreferences.find(item => item.vendor_category === 'florist');

  return (
    <div className="w-full">
      <div className="w-full mobile:hidden">
        <div className="mt-[24px] border bg-white rounded-xl p-4">
          <h2 className="text-xl font-semibold mb-4 mobile:text-lg">Florist Preferences</h2>
          <div className="space-y-4">
            <div>
              <p className="text-sm">Florist Budget</p>
                {vendorData.photographer_min_max &&
                  <p className="mt-1 font-medium">{vendorData.photographer_min_max[0]} to {vendorData.photographer_min_max[1]}</p>
                }
            </div>


          </div>

        </div>
      </div>

      <div className="hidden w-[360px] mobile:flex mobile:flex-col">
        <div className="mt-[12px] border bg-white rounded-xl px-4 py-3">
          <div
            className="flex justify-center items-center cursor-pointer"
            onClick={() => setVendorPreferencesOpen(!vendorPreferencesOpen)}
          >
            <div className="w-full flex justify-between items-center text-xl font-semibold mobile:text-base">
              <span>Florist Preferences</span>
              <div>{vendorPreferencesOpen ?  <div>{UpArrow}</div>: <div>{DownArrow}</div>}</div>
            </div>

          </div>

          {vendorPreferencesOpen && (
            <div className="space-y-6">
              <div className="flex justify-between items-center mt-4">
                <div className="">
                  <p className="text-sm">Florist Budget</p>
                    {vendorData.photographer_min_max &&
                      <p className="mt-1 font-medium">{vendorData.photographer_min_max[0]} to {vendorData.photographer_min_max[1]}</p>
                    }
                </div>

              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default floristPreferences