import React, { useContext, useEffect, useState, useRef } from "react";
import { AnswersContext } from "../../../AnswersContext";
import { useNavigate } from "react-router-dom";
import FooterNew from ".././footernew.component";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import closecircleX from "../../../styles/closecircleX"
import ScrollJump from "../../scroll-jump.component";

const getMinimumBudget = hoursSelected => {
	switch(hoursSelected) {
		case 3:
		case 4:
			return '$700'
		case 5:
			return '$900'
		case 6:
		case 7:
		case 8:
			return '$1,000'
		case 9:
		case 10:
			return '$1,500'
		default:
			return '$700'
	}
}

const HairMakeupNewQuiz = () => {

	const { toggleBoolean, toggleArraySelection, quizState, hoursSelected, setValue, setBooleanFalse } = useContext(AnswersContext);

	const [ nextStep, setNextStep ] = useState(true)
	const [ openHairMakeup, setOpenHairMakeup ] = useState(false) //bool
	const [ hairMakeupServices, setHairMakeupServices ] = useState([]) //array
	const [ displayHairMakeup, setDisplayHairMakeup ] = useState(quizState.hairMakeupChosenServices || []) //array
	const [ openMinBudget, setOpenMinBudget ] = useState(false) //bool
	const [ minBudget, setMinBudget ] = useState() //num
	const [ openMaxBudget, setOpenMaxBudget ] = useState(false) //bool
	const [ maxBudget, setMaxBudget ] = useState() //num
	const [ openBridesmaids, setOpenBridesmaids ] = useState(false) //bool
	const [ bridesmaids, setBridesmaids ] = useState() //num
	const [ estimatedBudget, setEstimatedBudget] = useState(false)
	const minDropdownRef = useRef(null);
	const maxDropdownRef = useRef(null);
	const bridesmaidDropdownRef = useRef(null);
	const history = useNavigate();


	const handleClickOutside = (event) => {
		if (minDropdownRef.current && !minDropdownRef.current.contains(event.target)) {
      setOpenMinBudget(false);
    }
		if (maxDropdownRef.current && !maxDropdownRef.current.contains(event.target)) {
      setOpenMaxBudget(false);
    }
		if (bridesmaidDropdownRef.current && !bridesmaidDropdownRef.current.contains(event.target)) {
      setOpenBridesmaids(false);
    }
  };


	const confirmHairMakeup = () => {
		setOpenHairMakeup(false)
		setDisplayHairMakeup(quizState.hairMakeupChosenServices)
	}


	const toggleHairMakeupService = (service) => {
		toggleArraySelection('hairMakeupChosenServices', service)
  };


	const renderMinBudgetDropdown = (budgetOptions) => {

		return (
			<span className="absolute w-full max-h-48 flex flex-col px-1 py-2 bg-white rounded-xl text-center shadow overflow-auto">
				{budgetOptions.map((minOption) => {
					const minOptionValue = parseFloat(minOption.replace('$', ''));

					if (quizState.hairMakeupMinMax[1]) {
						const maxBudgetValue = parseFloat(quizState.hairMakeupMinMax[1].replace('$', ''));
						if (maxBudgetValue > minOptionValue) {
							return (
								<span
									key={minOption}
									className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
									onClick={() => {
										quizState.hairMakeupMinMax[0] = minOption;
										setOpenMinBudget(false);
									}}
								>
									{minOption}
								</span>
							);
						}
					} else {
						return (
							<span
								 key={minOption}
								 className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
								 onClick={() => {
									 quizState.hairMakeupMinMax[0] = minOption;
									 setOpenMinBudget(false);
								 }}
							 >
								 {minOption}
						 </span>
						 );
					}

				})}
			</span>
		)
	}


	const renderMaxBudgetDropdown = (budgetOptions) => {
		return (
			<span className="absolute w-full max-h-48 flex flex-col px-1 py-2 bg-white rounded-xl text-center shadow overflow-auto">
				{budgetOptions.map((maxOption) => {
					let maxOptionValue = (maxOption.replace('$', ''));
					if (maxOptionValue.includes('+')) {
						maxOptionValue = parseFloat(maxOptionValue.replace('+', '')) + 1
					} else {
						maxOptionValue = parseFloat(maxOptionValue)
					}

					if (quizState.hairMakeupMinMax[0]) {
						const minBudgetValue = parseFloat(quizState.hairMakeupMinMax[0].replace('$', ''));

						if (maxOptionValue > minBudgetValue) {
							return (
								<span
									key={maxOption}
									className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
									onClick={() => {
										quizState.hairMakeupMinMax[1] = maxOption
										setOpenMaxBudget(false);
									}}
								>
									{maxOption}
								</span>
							);
						}
						return null
					}

					return (
						<span
							key={maxOption}
							className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
							onClick={() => {
								quizState.hairMakeupMinMax[1] = maxOption
								setOpenMaxBudget(false);
							}}
						>
							{maxOption}
						</span>
					);
				})}
			</span>
		);
	}


	useEffect(() => {
		if (quizState.hairMakeupMinMax[0] && quizState.hairMakeupMinMax[1]) {
			const e = {
				target: {
					name: 'hairMakeupEstimatedBudget',
					checked: false
				}
			};

			setBooleanFalse(e);
		}
	}, [quizState.hairMakeupMinMax[0], quizState.hairMakeupMinMax[1]])



	useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


	useEffect(() => {

		if (displayHairMakeup.includes('Bridesmaid Hair') || displayHairMakeup.includes('Bridesmaid Makeup')) {
			console.log(displayHairMakeup)
			if (((quizState.hairMakeupMinMax[0] && quizState.hairMakeupMinMax[1]) || quizState.hairMakeupEstimatedBudget) && displayHairMakeup.length > 0 && quizState.hairMakeupNumBridesmaid) {
				setNextStep(true)
			} else {
				setNextStep(false)
			}
		} else {
			if (((quizState.hairMakeupMinMax[0] && quizState.hairMakeupMinMax[1]) || quizState.hairMakeupEstimatedBudget) && displayHairMakeup.length > 0) {
				console.log(displayHairMakeup, quizState.hairMakeupMinMax[0])
				setNextStep(true)
			} else {
				setNextStep(false)
			}
		}

	}, [quizState.hairMakeupMinMax[0], quizState.hairMakeupMinMax[1], displayHairMakeup, quizState.hairMakeupEstimatedBudget, quizState.hairMakeupNumBridesmaid])


	const budgets =  [
			{
				id: 1,
				displayMinimum: getMinimumBudget(hoursSelected),
				displayMaximum: '$2,000'
			},
			{
				id: 2,
				displayMinimum: '$2,001',
				displayMaximum: '$3,000'
			},
			{
				id: 3,
				displayMinimum: '$3,001',
				displayMaximum: '$4,000'
			},
			{
				id: 4,
				displayMinimum: '$4,001',
				displayMaximum: '$6,000'
			},
			{
				id: 5,
				displayMinimum: '$6,000',
			},
	]


	const hairAndMakeupServiceList = [
		"Bridal Hair",
		"Bridal Makeup",
		"Bridesmaid Hair",
		"Bridesmaid Makeup"
	]


	const bridesmaidsOptions = [
		'1 Bridesmaid',
		'2 Bridesmaids',
		'3 Bridesmaids',
		'4 Bridesmaids',
		'5 Bridesmaids',
		'6 Bridesmaids',
		'7 Bridesmaids',
		'8 Bridesmaids',
		'9 Bridesmaids',
		'10 Bridesmaids',
		'11 Bridesmaids',
		'12 Bridesmaids',
		'13 Bridesmaids',
		'14 Bridesmaids',
		'15 Bridesmaids',
		'15+ Bridesmaids'
	]


	const budgetOptions = [
		'$250',
		'$500',
		'$750',
		'$1000',
		'$1250',
		'$1500',
		'$2000',
		'$2500',
		'$2500+'
	]


	return (
		<div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
			<div className="w-full">
					<NavbarNew />
			</div>

			<div className={`relative w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-3 pb-[24px] mt-5 rounded-3xl bg-white bg-white/50 mobile:w-[90%]`}>
				{/* Status Bar */}

				<Timeline phase="preferences"/>

				<div className="w-full flex flex-col items-center pt-10 pb-6 mx-12 border-t z-10">

					{openHairMakeup &&
					<>
						<div className="fixed top-32 inset-0 z-20 backdrop-blur-sm"></div>

						<div className="absolute w-[550px] h-[295px] flex flex-col pl-4 pt-3 pb-2 border rounded-2xl bg-white z-20 mobile:w-[95%] mobile:h-max mobile:text-sm">

							<button className="w-full flex justify-end px-3 py-2" onClick={() => (setHairMakeupServices(displayHairMakeup), setOpenHairMakeup(false))}>
								{closecircleX}
							</button>

							<div className="mb-4 text-xl font-medium mobile:text-lg">Hair & Makeup Services</div>

							<div className="w-full flex flex-wrap mb-2">
								{hairAndMakeupServiceList.map((service, id) => (
									<button
										className={`${quizState.hairMakeupChosenServices.length && quizState.hairMakeupChosenServices.some(i => i == service) ? 'bg-greenblue text-white' : ''} mr-2 mb-2.5 px-3 py-2 border rounded-xl`}
										onClick={() => {toggleHairMakeupService(service)}}>
											{service}
									</button>
								))}
							</div>

							<div className="w-full flex justify-end pr-4">
								<button className="px-5 py-[12px] rounded-full bg-greenblue text-sm text-white" onClick={() => (confirmHairMakeup())}>
									Confirm
								</button>
							</div>
						</div>
					</>
					}


					<div className="mb-7 text-center mobile:mb-10">
						<p className="mb-7 text-3xl font-medium mobile:mb-5 mobile:text-lg">Hair & Makeup Preferences</p>
						<p className="mobile:text-[13px]">Click or tap to fill in the blanks.</p>
					</div>

					<div className={`${displayHairMakeup.length > 0 ? 'w-[70%]' : 'w-[65%]'} flex flex-wrap justify-center items-center gap-y-5 text-lg mobile:w-full mobile:mb-6 mobile:text-sm`}>
						<span>I'm interested in</span>

						<span className="relative">
							<span
								className={`${displayHairMakeup.length > 0 ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-3 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${displayHairMakeup.length > 0 ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => setOpenHairMakeup(!openHairMakeup)}
							>
								{displayHairMakeup.length > 0 ? displayHairMakeup.join(", ") : 'Hair/Makeup Types(s)'}
							</span>
						</span>

						{displayHairMakeup.some(str => str.toLowerCase().includes('bridesmaid'))&& <span>for</span>}



						{displayHairMakeup.length > 0 && displayHairMakeup.some(str => str.toLowerCase().includes('bridesmaid')) &&
						<span className="relative" ref={bridesmaidDropdownRef}>
							<span
								className={`${quizState.hairMakeupNumBridesmaid ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-2 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${quizState.hairMakeupNumBridesmaid ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => setOpenBridesmaids(!openBridesmaids)}
							>
								{quizState.hairMakeupNumBridesmaid || 'Number of Bridesmaids'}
							</span>
							{openBridesmaids &&
								<span className="absolute w-full h-48 flex flex-col px-1 py-2 bg-white rounded-xl text-center shadow overflow-auto z-20">
									{bridesmaidsOptions.map((bridesmaidOption) => (
										<button className="hover:bg-gray-100 px-2 py-1.5 rounded-lg" onClick={() => (quizState.hairMakeupNumBridesmaid = bridesmaidOption, setOpenBridesmaids(false))}>{bridesmaidOption}</button>
									))}
								</span>
							}
						</span>
						}


						<span>and my budget is between</span>


						<span className="relative" ref={minDropdownRef}>
							<span
								className={`${quizState.hairMakeupMinMax[0] ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-2 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${quizState.hairMakeupMinMax[0] ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => setOpenMinBudget(!openMinBudget)}
							>
								{quizState.hairMakeupMinMax[0] || 'Min'}
							</span>
							{openMinBudget && renderMinBudgetDropdown(budgetOptions)}
						</span>

						<span>and</span>

						<span className="relative" ref={maxDropdownRef}>
							<span
								className={`${quizState.hairMakeupMinMax[1]  ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-2 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${quizState.hairMakeupMinMax[1]  ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => setOpenMaxBudget(!openMaxBudget)}
							>
								{quizState.hairMakeupMinMax[1]  || 'Max'}
							</span>
							{openMaxBudget && renderMaxBudgetDropdown(budgetOptions)}
						</span>
					</div>


				</div>

				{/* <div className="w-full flex justify-center items-center text-center mobile:text-sm">
					<label class="checkbox-container" style={{marginRight: '8px', marginTop: '0px'}}>
						<input
						  name="hairMakeupEstimatedBudget"
							checked={quizState.hairMakeupEstimatedBudget}
							type="checkbox"
							onClick={(e) => {toggleBoolean(e)}}
						/>
						<span class="checkmark email-checkmark" />
					</label>
					<span className="">Use typical price range based on my wedding details</span>
				</div> */}


				<div>
					<FooterNew phase={"Hair/Makeup"} complete={nextStep} />
				</div>

			</div>

		</div>
	);
};

export default HairMakeupNewQuiz;
