import React, {useEffect, useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import { AnswersContext } from "../../AnswersContext";
import axios from 'axios';
import FooterNew from "./footernew.component";
import NavbarNew from "./navbarnew.component";
import Timeline from "./timeline.component";
import { Mixpanel } from '../../Mixpanel';
import InstructionsPopup from "./instructions-popup.component";
import ScrollJump from '../scroll-jump.component';

const FooterRender = (props) => {
    if(props.chosenVendorTypes.length || props.skipVendors)
    {
        return (
        <FooterNew
            noLink={true}
            to=""
            from="/dotogether"
            button={true}
            buttonText="Next: Photos"
            otherText="Style & Attributes 5/5"
            clickEvent={() => props.setFinished(true)}
        />
    );
        } else{
            return (
                <FooterNew
                    button={true}
                    to="#"
                    inactive="true"
                    from="/dotogether"
                    buttonText="Next: Photos"
                    otherText="Style & Attributes 5/5"
                    stopAnimating="true"
                />
            );
        }

};

const VendorTypes = () => {

	const {
        quizState,
        toggleArraySelection,
        toggleBoolean,
        getPhotographers,
	} = useContext(AnswersContext);

    const [ finished, setFinished ] = useState(false)
    const [ vendorTypes, setVendorTypes ] = useState([])

    const history = useNavigate();

    const setDelay = (e) => {
        e.preventDefault(); //prevent transition
        window.setTimeout(() => {
            history("/howimportant");
        }, 500);
    };

   useEffect(() => {
    Mixpanel.track('View Style Page');
    getPhotographers()
    axios.get('/api/vendor-types')
    .then(r => setVendorTypes(r.data))
   }, [])

    return(
		<div className="question-page">
		    {finished && <InstructionsPopup />}
			<NavbarNew />
			<Timeline phase="dotogether" />
            <div className="question-container">
            <div>
                <div className="header">Other Vendors</div>
                <div className="subheader">
                We'll take the information you've already provided and email you a list of other top rated vendors.
                </div>
			</div>
            <div>
                <div className="question">
                    Want personalized recommendations for other vendors sent to your email?
                </div>
                <div>
                <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '25px', marginLeft: '-6px'}}>

                {vendorTypes.map(t =>
                    (t.name && <button
                        className={`question-button question-tag-button ${quizState.chosenVendorTypes.length && quizState.chosenVendorTypes.some(i => i == t.name) ? 'selected' : ''}`}
                        selected={quizState.chosenVendorTypes.length && quizState.chosenVendorTypes.some(i => i == t.name)}
                        onClick={() => toggleArraySelection('chosenVendorTypes', t.name)}
                        >
                            <img src={t.icon_url} style={{width: '35px', marginRight: '5px'}} />
                            {t.name}
                    </button>)
                )}
                </div>
                <label class="checkbox-container">
					<input
						checked={quizState.skipVendors}
						type="checkbox"
                        name='skipVendors'
						onClick={toggleBoolean}
					/>
					<span class="checkmark email-checkmark" />
				</label>
				<span className="checkbox-label">Skip this step</span>
                <ScrollJump />
            </div>
            </div>
            </div>

			<FooterRender setFinished={setFinished} setDelay={setDelay} chosenVendorTypes={quizState.chosenVendorTypes} skipVendors={quizState.skipVendors} />
        </div>
    )
}

export default VendorTypes;