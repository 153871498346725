import React, { useEffect, useState } from "react";
import axios from "axios";

const PickRates = () => {
    const [photos, setPhotos ] = useState([])
	useEffect(() => {           
        document.title="Pick Rate | WedMatch" 
        axios.get('/auth/getuser')
        .then(r => {
            if(!r.data.admin){
                window.location="/login"
            }
        })
        axios.get('/api/pickrates/wins')
        .then(r => {
            setPhotos(r.data)
        })
    }, [])
    const sortBy = (sortProperty) => {
        if(sortProperty == 'wins'){            
            axios.get('/api/pickrates/wins')
            .then(r => {
                setPhotos(r.data)
            })
        }
        if(sortProperty == 'losses'){            
            axios.get('/api/pickrates/losses')
            .then(r => {
                setPhotos(r.data)
            })
        }
        if(sortProperty == 'rate'){            
            axios.get('/api/pickrates/rate')
            .then(r => {
                setPhotos(r.data)
            })
        }
    }
	return (
		<table style={{textAlign: 'center'}}>
            <tr>
                <th style={{cursor: 'pointer'}} onClick={() => sortBy("wins")}>Wins</th>
                <th style={{cursor: 'pointer'}} onClick={() => sortBy("losses")}>Losses</th>
                <th style={{cursor: 'pointer'}} onClick={() => sortBy("rate")}>Rate</th>
                <th>Photo</th>
                <th>Time of day</th>
                <th>Photographer</th>
            </tr>
            {photos.map(p => <tr>
                <td>{p.wins}</td>
                <td>{p.losses}</td>
                <td>{Math.round(p.rate)}%</td>
                <td><a target="_blank" href={p.image_url}><img style={{width: '50px'}} src={p.image_url} /></a></td>
                <td>{p.time_of_day}</td>
                <td>{p.business_name}</td>
                </tr>)}
               
        </table>
	);
};

export default PickRates;
