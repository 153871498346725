import React, { useState, Fragment, useReducer, useEffect, useMemo } from 'react';
import './index.css';

import { PhotographerContext } from './PhotographerContext';
import { UserContext } from './UserContext';
import { AnswersContext } from './AnswersContext';
import { AuthProvider } from './AuthContext';
import TempPhotoUpload from './components/temp-photo.component';
import SelectionPage from './components/Quiz/selection-page.component';
import GetResults from './components/get-results.component';
import GetVendorResults from './components/get-vendor-results.component';
import GetVendorsChosen from './components/get-vendors-chosen.component';
import GetBookingStatus from './components/get-booking-status.component';
import Success from './components/success.component';
import Results from './components/Quiz/results-new.component';
import PhotographerDashboard from './components/photographer-dashboard.component';
import ImportantNew from './components/Quiz/howimportantnew.component';
import DashboardPhotos from './components/dashboard-photos.component';
import WeddingLocationNew from './components/Quiz/wedding-location-new.component';
import DoTogetherNew from './components/Quiz/dotogethernew.component';
import EmailNew from './components/Quiz/emailnew.component';
import BudgetNew from './components/Quiz/budgetnew.component';
import DateNew from './components/Quiz/datenew.component';
import TagsNew from './components/Quiz/tagsnew.component';
import VendorTypes from './components/Quiz/vendor-types.component';
import BasicPhotographer from './components/basic-photographer.component';
import PhotoStyle from './components/Quiz/photo-style.component';
import PickRates from './components/pickrates.component';
import CreatePhotographer from './components/create-photographer.component';
import CreateReferral from './components/create-referral.component';
import PhotographerList from './components/photographer-list.component';
import WeddingList from './components/wedding-list.component';
import DashboardInfo from './components/dashboard-info.component';
import DashboardAvailability from './components/dashboard-availability.component';
import DashboardPersonality from './components/dashboard-personality.component';
import DashboardPricing from './components/dashboard-pricing.component';
import DashboardMatches from './components/dashboard-matches.component';
import Signup from './components/signup.component';
import AccountTypeSelect from './components/account-type-select.component';
import Login from './components/login.component';
import VendorSignup from './components/vendor-signup.component';
import SignupList from './components/signup-list.component';
import ForgotPassword from './components/forgot-password.component';
import CalendarList from './components/calendar-list.component';
import { AlbumsPage } from './components/AlbumsPage/AlbumsIndexPage';
import WeddingUpload from './components/WeddingUpload/index.component';
import UpdateVenues from './components/update-venues.component';
import FullSizeWeddingPhotos from './components/full-size-wedding-photos.component';
import OauthCalendar from './components/oauth-calendar.component';
import AlgorithmTest from './components/algorithm-test.component';
import VendorDashboard from './components/NewVendorDashboard/index.component'
import DashboardPreferredVendors from './components/dashboard-preferred-vendors.component';
import NewQuiz from './components/NewQuiz/new-quiz.component'
import AuthorizeAccount from './components/authorize-account.component';
import VendorList from './components/vendor-list.component';
import ResetPassword from './components/reset-password.component';
import UpdatePassword from './components/update-password.component';
import AccountManagement from './components/account-settings.component';

import WeddingLocationNewQuiz from './components/NewQuiz/location/location.component'
import VendorTypesNewQuiz from './components/NewQuiz/vendorTypes/vendor-types.component'
import DateNewQuiz from './components/NewQuiz/date/datenew.component'
import TagsNewQuiz from './components/NewQuiz/tags/tagsnew.component'
import BudgetNewQuiz from './components/NewQuiz/budget/budgetnew.component'
import ImportantNewQuiz from './components/NewQuiz/coveragePriority/howimportantnew.component'
import PhotoStyleNewQuiz from './components/NewQuiz/photoStyle/photo-style.component'
import DoTogetherNewQuiz from './components/NewQuiz/photographerPreferences/dotogethernew.component'
import VideographerNewQuiz from './components/NewQuiz/videographer/videographer.component'
import FloristNewQuiz from './components/NewQuiz/florist/florist.component'
import DjNewQuiz from './components/NewQuiz/dj/dj.component'
import CakeDessertNewQuiz from './components/NewQuiz/baker/cakedessert.component'
import HairMakeupNewQuiz from './components/NewQuiz/hairMakeup/hairmakeup.component'
import PlannerNewQuiz from './components/NewQuiz/planner/planner.component'
import TempLogin from './components/NewQuiz/email/tempLogin'
import SignUp from './components/NewQuiz/email/signup.component'
import TempResults from './components/NewQuiz/results/results-new.component'
import SelectionPageNewQuiz from './components/NewQuiz/selection/selection-page.component'
import Dashboard from './components/Dashboard/home/home'



import CustomVendorSignup from './components/custom-vendor-signup.component';

// Vendor Dashboard
import VendorForm from './components/VendorDashboard/index.component';

import axios from 'axios';
import _ from 'lodash';
import { CreatePage } from './components/CreatePage/CreateIndexPage';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './styles/global.css';
import './styles/album-upload.css';
import './styles/animationStyles.css';
import ScrollToTop from './components/ScrollToTop';
import './styles/dashboardStyles.css';
import './styles/fonts/AirbnbCerealMedium.woff';
import './styles/fonts/AirbnbCerealBlack.woff';
import './styles/fonts/AirbnbCerealBook.woff';
import './styles/fonts/AirbnbCerealExtraBold.woff';
import './styles/fonts/AirbnbCerealLight.woff';
import './styles/fonts/AirbnbCerealMedium.woff';
import './styles/fonts/style.css';
import QuizReducer from './reducers/quizReducer'
import { calculateAttributeScores, calculateBudgetScore, calculateLocationScore, calculatePreferenceScore, calculateStyleScore, calculateTagScore, calculateVenueScore, calculateRankScore } from './utils/scoring';
import { createClient } from '@supabase/supabase-js';


const supabase = createClient('https://zzhiugmthrvzmeyznffd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp6aGl1Z210aHJ2em1leXpuZmZkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjExNDQ4OTIsImV4cCI6MjAzNjcyMDg5Mn0.zuIK4-Uwx9moh225qtJzAzDgEYQfE6AmVuwuM3Sanow', {
  auth: {
    persistSession: true,
    detectSessionInUrl: true,
    storage: localStorage,
  },
});


const budgetWeight = 40
const attributeWeight = 20
const locationWeight = 10
const styleWeight = 10
const venueWeight = 3
const tagWeight = 10
const bookingWeight = 10
const albumWeight = 1
const taggingWeight = 1
const calendarWeight = 10
const matchingWeight = 3
const preferenceWeight = 3
const rankWeight = 3

const attributeMap = {
  'candid': 'candid',
  'creative': 'creative',
  'friendly': 'friendly',
  'flexible': 'flexible',
  'adaptable': 'flexible',
  'patient': 'flexible',
  'artistic': 'creative',
  'unique': 'creative',
  'communicative': 'communicative',
  'responsive': 'communicative',
  'organized': 'communicative',
  'experienced': 'experienced',
  'professional': 'experienced',
  'knowledgeable': 'experienced',
  'assertive': 'experienced',
  'talented': 'talented',
  'skilled': 'talented',
  'documentary': 'candid',
  'capture moment': 'candid',
  'photojournalistic': 'candid',
  'helpful': 'friendly',
  'supportive': 'friendly'
}

const baseAttributes = ['flexible', 'creative', 'communicative', 'experienced', 'talented', 'candid', 'friendly']

const splitPhotos = (arr) => {
	var arr1 = []
	var arr2 = []
	var shuffled = _.shuffle(arr)
	arr1 = shuffled.slice(0, Math.floor(shuffled.length/2))
	arr2 = shuffled.slice(Math.floor(shuffled.length/2), shuffled.length)
	arr1.sort(function(a, b){return a.aspect_ratio - b.aspect_ratio});
	arr2.sort(function(a, b){return a.aspect_ratio - b.aspect_ratio});
	if(arr1.length > arr2.length){arr1.splice(arr1.length-1)}
	if(arr2.length > arr1.length){arr2.splice(arr2.length-1)}
	avoidOverlaps(arr1, arr2)
	return ({arr1, arr2})
}

// axios.get('/api/supa-auth-test', { headers: {
//   Authorization: localStorage['sb-zzhiugmthrvzmeyznffd-auth-token']
// }})
// .then(r => console.log(r))

const determinePhotoQuantities = (quizState) => {
	const {
		month,
		formalDance,
		ceremony,
		generalReception,
		couplesPortrait,
		weddingParty,
		gettingReady,
	} = quizState

	let [gettingReadyPhotosNeeded, ceremonyPhotosNeeded, formalDancePhotosNeeded, generalReceptionPhotosNeeded, couplesPortraitPhotosNeeded, weddingPartyPhotosNeeded] = [0, 0, 0, 0, 0, 0]
	if(quizState.gettingReadyChecked != "false" && quizState.gettingReadyChecked != false){
		const gettingReadyPhotoAmounts = [0, 0, 0, 1, 2, 2]
		gettingReadyPhotosNeeded = gettingReadyPhotoAmounts[quizState.gettingReadyImportance]
	}
	if(quizState.ceremonyChecked != "false" && quizState.ceremonyChecked != false){
		const ceremonyPhotoAmounts = [0, 0, 1, 2, 2, 3]
		ceremonyPhotosNeeded = ceremonyPhotoAmounts[quizState.ceremonyImportance]
	}
	if(quizState.portraitsChecked != "false" && quizState.portraitsChecked != false){
		switch(parseInt(quizState.portraitsImportance)){
			case 1:
				couplesPortraitPhotosNeeded = 0
				weddingPartyPhotosNeeded = 0
				break
			case 2:
				couplesPortraitPhotosNeeded = 2
				weddingPartyPhotosNeeded = 0
				break
			case 3:
				couplesPortraitPhotosNeeded = 2
				weddingPartyPhotosNeeded = 1
				break
			case 4:
				couplesPortraitPhotosNeeded = 3
				weddingPartyPhotosNeeded = 1
				break
			case 5:
				couplesPortraitPhotosNeeded = 4
				weddingPartyPhotosNeeded = 1
				break
		}
	}
	if(quizState.receptionChecked != "false" && quizState.receptionChecked != false){
		const formalDancePhotoAmounts = [0, 0, 1, 1, 2, 2]
		formalDancePhotosNeeded = formalDancePhotoAmounts[quizState.receptionImportance]
		const generalReceptionPhotoAmounts = [0, 0, 0, 1, 1, 2]
		generalReceptionPhotosNeeded = generalReceptionPhotoAmounts[quizState.receptionImportance]
	}
	if((couplesPortraitPhotosNeeded + weddingPartyPhotosNeeded + formalDancePhotosNeeded + generalReceptionPhotosNeeded + ceremonyPhotosNeeded + gettingReadyPhotosNeeded) < 2){
		ceremonyPhotosNeeded = 2
	}
	const styleWithSeason = [...quizState.styleTags]
	if(month == 12 || month == 1 || month == 2){
		styleWithSeason.push("18")
	} else if(month == 3 || month == 4 || month == 5){
		styleWithSeason.push("19")
	} else if(month == 6 || month == 7 || month == 8){
		styleWithSeason.push("20")
	}else if(month == 9 || month == 10 || month == 11){
		styleWithSeason.push("21")
	}

const sortPhotos = (array, amountNeeded) => {
		var arr = []
		array.map(p => {
			var ar = [...p]
			ar = _.shuffle(ar)
			ar.sort((a, b) => {
				if(!a.tags){
					return 1
				} else if(!b.tags){
					return -1
				}
				else if(a.tags.filter(at => quizState.importantTags.some(it => it == at.id)).length > b.tags.some(at => quizState.importantTags.some(it => it == at.id))){
					return -1
				}
				else if(a.tags.filter(at => quizState.importantTags.some(it => it == at.id)).length < b.tags.some(at => quizState.importantTags.some(it => it == at.id))){
					return 1
				} else if(a.tags.filter(at => styleWithSeason.some(it => it == at.id) || quizState.locationTags.some(it => it == at.id)).length > b.tags.some(at => styleWithSeason.some(it => it == at.id) || quizState.locationTags.some(it => it == at.id))){
					return -1
				}else if(a.tags.filter(at => styleWithSeason.some(it => it == at.id) || quizState.locationTags.some(it => it == at.id)).length < b.tags.some(at => styleWithSeason.some(it => it == at.id) || quizState.locationTags.some(it => it == at.id))){
					return 1
				}
			})
			arr.push(ar)
			if(quizState.locationTags.length == 0 && styleWithSeason.length == 0 && quizState.importantTags.length == 0){
				arr = _.shuffle(arr)
			}
		})
		arr = arr.map(photographerArray => {
			var arr2 = photographerArray
			return arr2.slice(0, amountNeeded)
		}).flat()
		return arr
	}
	const gettingReadyByPhotographer = sortPhotos(gettingReady, gettingReadyPhotosNeeded)
	const ceremonyByPhotographer = sortPhotos(ceremony, ceremonyPhotosNeeded)
	const couplesPortraitByPhotographer = sortPhotos(couplesPortrait, couplesPortraitPhotosNeeded)
	const weddingPartyByPhotographer = sortPhotos(weddingParty, weddingPartyPhotosNeeded)
	const formalDanceByPhotographer = sortPhotos(formalDance, formalDancePhotosNeeded)
	const generalReceptionByPhotographer = sortPhotos(generalReception, generalReceptionPhotosNeeded)

	const ceremonyPhotos = splitPhotos(ceremonyByPhotographer)
	const ceremonyPhotos1 = ceremonyPhotos.arr1
	const ceremonyPhotos2 = ceremonyPhotos.arr2
	const gettingReadyPhotos = splitPhotos(gettingReadyByPhotographer)
	const gettingReadyPhotos1 = gettingReadyPhotos.arr1
	const gettingReadyPhotos2 = gettingReadyPhotos.arr2
	const couplesPortraitPhotos = splitPhotos(couplesPortraitByPhotographer)
	const couplesPortraitPhotos1 = couplesPortraitPhotos.arr1
	const couplesPortraitPhotos2 = couplesPortraitPhotos.arr2
	const weddingPartyPhotos = splitPhotos(weddingPartyByPhotographer)
	const weddingPartyPhotos1 = weddingPartyPhotos.arr1
	const weddingPartyPhotos2 = weddingPartyPhotos.arr2
	const formalDancePhotos = splitPhotos(formalDanceByPhotographer)
	const formalDancePhotos1 = formalDancePhotos.arr1
	const formalDancePhotos2 = formalDancePhotos.arr2
	const generalReceptionPhotos = splitPhotos(generalReceptionByPhotographer)
	const generalReceptionPhotos1 = generalReceptionPhotos.arr1
	const generalReceptionPhotos2 = generalReceptionPhotos.arr2

	var arr1 = gettingReadyPhotos1.concat(ceremonyPhotos1, couplesPortraitPhotos1, weddingPartyPhotos1, formalDancePhotos1, generalReceptionPhotos1)
	var arr2 = gettingReadyPhotos2.concat(ceremonyPhotos2, couplesPortraitPhotos2, weddingPartyPhotos2, formalDancePhotos2, generalReceptionPhotos2)
	return { arr1, arr2 }
}

const avoidOverlaps = (arr1, arr2) => {
	var newArr1 = arr1
	var newArr2 = arr2
	var matches = []
	for(var i = 0; i < newArr1.length; i++){
		if(newArr1[i].photographer_fkey == newArr2[i].photographer_fkey){
			matches.push(i)
		}
	}
	for(var i in matches){
		if(newArr1[matches[i]+ 1] && newArr2[matches[i]+ 1] && newArr1[matches[i]+ 1].photographer_fkey != newArr2[matches[i]].photographer_fkey && newArr1[matches[i]].photographer_fkey != newArr2[matches[i]+ 1].photographer_fkey){
			var temp = newArr1[matches[i]]
			newArr1[matches[i]] = newArr1[matches[i] + 1]
			newArr1[matches[i]+1] = temp
		} else if(newArr1[matches[i]+ 2] && newArr2[matches[i]+ 2] && newArr1[matches[i]+ 2].photographer_fkey != newArr2[matches[i]].photographer_fkey && newArr1[matches[i]].photographer_fkey != newArr2[matches[i]+ 2].photographer_fkey){
			var temp = newArr1[matches[i]]
			newArr1[matches[i]] = newArr1[matches[i] + 2]
			newArr1[matches[i]+2] = temp
		} else if(newArr1[matches[i]- 2] && newArr2[matches[i] - 2] && newArr1[matches[i] - 2].photographer_fkey != newArr2[matches[i]].photographer_fkey && newArr1[matches[i]].photographer_fkey != newArr2[matches[i] - 2].photographer_fkey){
			var temp = newArr1[matches[i]]
			newArr1[matches[i]] = newArr1[matches[i] - 2]
			newArr1[matches[i]-2] = temp
		} else if(newArr1[matches[i]- 1] && newArr2[matches[i] - 1] && newArr1[matches[i] - 1].photographer_fkey != newArr2[matches[i]].photographer_fkey && newArr1[matches[i]].photographer_fkey != newArr2[matches[i] - 1].photographer_fkey){
			var temp = newArr1[matches[i]]
			newArr1[matches[i]] = newArr1[matches[i] - 1]
			newArr1[matches[i]-1] = temp
		} else if(newArr1[matches[i]- 3] && newArr2[matches[i] - 3] && newArr1[matches[i] - 3].photographer_fkey != newArr2[matches[i]].photographer_fkey && newArr1[matches[i]].photographer_fkey != newArr2[matches[i] - 3].photographer_fkey){
			var temp = newArr1[matches[i]]
			newArr1[matches[i]] = newArr1[matches[i] - 3]
			newArr1[matches[i]-3] = temp
		} else{
		}
	}

}
const getAttributeRanges = photographers => {
  return {
          flexible: photographers.reduce(function(prev, current) { return (prev.flexible > current.flexible) ? prev : current}).flexible,
          creative: photographers.reduce(function(prev, current) { return (prev.creative > current.creative) ? prev : current}).creative,
          communicative: photographers.reduce(function(prev, current) { return (prev.communicative > current.communicative) ? prev : current}).communicative,
          experienced: photographers.reduce(function(prev, current) { return (prev.experienced > current.experienced) ? prev : current}).experienced,
          talented: photographers.reduce(function(prev, current) { return (prev.talented > current.talented) ? prev : current}).talented,
          candid: photographers.reduce(function(prev, current) { return (prev.candid > current.candid) ? prev : current}).candid,
          friendly: photographers.reduce(function(prev, current) { return (prev.friendly > current.friendly) ? prev : current}).friendly,
      }
}

const initialQuizState = {
  day: '',
  email: '',
  firstName: '',
  month: '',
  preference: '',
  venueName: '',
  weddingLocation: '',
  year: '',

  ceremonyImportance: 3,
  gettingReadyImportance: 3,
  hoursSelected: null,
  optionsTotal: 0,
  portraitsImportance: 3,
  receptionImportance: 3,
  timesOfDay: 0,

  venueVendorId: null,

  canContact: true,
  ceremonyChecked: true,
  dateNotDecided: false,
  gettingReadyChecked: true,
  noBudget: false, // Need to double check this. Used to be just 'budget' and was null if they didn't check it
  portraitsChecked: true,
  receptionChecked: true,
  privateVenue: false,
  styleNotImportant: false,
  skipVendors: false,

  attributes: [],
  ceremony: [],
  chosenVendorTypes: [],
  couplesPortrait: [],
  formalDance: [],
  generalReception: [],
  gettingReady: [],
  importantTags: [],
  locationTags: [],
  matchups: [],
  options1: [],
  options2: [],
  photoStyles: [],
  selectedBudgets: [],
  selections: [],
  styleTags: [],
  usedPhotographers: [],
  userOnlyTags: [],
  weddingParty: [],

  wedBudget: [],
  guestCount: null,

  photographerPartsDay: [],
  photographerNumHours: null,
  photographerHours: null,
  photographerMinMax: [null, null],
  photographerEstimatedBudget: false,

  videographerHours: null,
  videographerMinMax: [null, null],
  videographerEstimatedBudget: false,

  floristMinMax: [null, null],
  floristEstimatedBudget: false,

  djChosenServices: [],
  djHours: null,
  djMinMax: [null, null],
  djEstimatedBudget: false,

  bakerMinMax: [null, null],
  bakerEstimatedBudget: false,

  hairMakeupChosenServices: [],
  hairMakeupNumBridesmaid: null,
  hairMakeupMinMax: [null, null],
  hairMakeupEstimatedBudget: false,

  chosenPlannerTypes: [],
  plannerMinMax: [null, null],
  plannerEstimatedBudget:false,


}

function App() {
  const [photographer, setPhotographer] = useState(-1);
  const [photosComplete, setPhotosComplete] = useState(null);
  const [imagePrefix, setImagePrefix] = useState('')
  const [personalityComplete, setPersonalityComplete] = useState(null);
  const [personalityTouched, setPersonalityTouched] = useState(false);
  const [pricingComplete, setPricingComplete] = useState(false);
  const [pricingTouched, setPricingTouched] = useState(false);
  const [infoComplete, setInfoComplete] = useState(null);
  const [profileComplete, setProfileComplete] = useState(null);
  const [infoTouched, setInfoTouched] = useState(false);
  const [photosTouched, setPhotosTouched] = useState(false);
  const [activePage, setActivePage] = useState('');
  const [ photoStyles, setPhotoStyles ] = useState([])
  const [matchups, setMatchups] = useState([]);
  const [ photoStylesFromUrl, setPhotoStylesFromUrl ] = useState('')
  const [ email, setEmail ] = useState('') //For the photographer info page, I guess? Or login?
  const [ scoredPhotographers, setScoredPhotographers ] = useState([])
  const [ photographers, setPhotographers ] = useState([])
  const [ weddings, setWeddings ] = useState([])
  const [ bookedTags, setBookedTags ] = useState([])

  useEffect(() => {
    axios.get(`/api/photographer-by-date/`)
    .then(r => {
        setPhotographers(r.data)
        axios.post('/api/get-booked-tags', { photographerIds: r.data.map(p => p.id)})
            .then(r => {
                setBookedTags(r.data)
            })
    })
  }, [])

  const [quizState, dispatch] = useReducer(QuizReducer, initialQuizState)

  const handleChangeText = e => {
    dispatch({
      type: 'SET VALUE',
      field: e.target.name,
      value: e.target.value,
    })
  }

  const toggleBoolean = e => {
    dispatch({
      type: 'TOGGLE BOOLEAN',
      field: e.target.name,
    })
  }

  const setBooleanFalse = e => {
    dispatch({
      type: 'SET BOOLEAN FALSE',
      field: e.target.name,
    })
  }

  const toggleArraySelection = (field, id) => {
    dispatch({
      type: 'TOGGLE ARRAY SELECTION',
      field,
      id,
    })
  }

  const setValue = (field, value) => {
    dispatch({
      type: 'SET VALUE',
      field,
      value
    })
  }

  const pushToArray = (field, value) => {
    dispatch({
      type: 'PUSH TO ARRAY',
      field,
      value
    })
  }

  const estimateHours = () => {
    const {
			gettingReadyChecked,
			ceremonyChecked,
			portraitsChecked,
			portraitsImportance,
			receptionChecked,
      receptionImportance,
      hoursSelected,
		} = quizState

		if(hoursSelected){
			return hoursSelected
		}
    let hours = 0
    if(gettingReadyChecked != "false" && gettingReadyChecked != false){
			hours += 2
		}
		if(ceremonyChecked != "false" && ceremonyChecked != false){
			hours += 2
		}
		if(portraitsChecked != "false" && portraitsChecked != false){
			switch(parseInt(portraitsImportance)){
				case 1:
				case 2:
				case 3:
					hours += 1
					break
				case 4:
				case 5:
					hours += 2
					break
			}
		}
  	if(receptionImportance == 5 && receptionChecked != "false"){
			hours += 4
		} else if(receptionImportance > 1 && receptionChecked != "false"){
			hours += 2
		}
		if(hours < 3){
			hours = 3
		}
    return hours
  }

  const getPhotographers = () => {
		const {
			gettingReadyChecked,
			ceremonyChecked,
			gettingReadyImportance,
			ceremonyImportance,
			portraitsChecked,
			portraitsImportance,
			receptionChecked,
      receptionImportance,
		} = quizState
    let [gettingReadyPhotosNeeded, ceremonyPhotosNeeded, formalDancePhotosNeeded, generalReceptionPhotosNeeded, couplesPortraitPhotosNeeded, weddingPartyPhotosNeeded] = [0, 0, 0, 0, 0, 0]
		if(gettingReadyChecked != "false" && gettingReadyChecked != false){
			gettingReadyPhotosNeeded = Math.max(0, gettingReadyImportance - 2)
		}
		if(ceremonyChecked != "false" && ceremonyChecked != false){
			ceremonyPhotosNeeded = ceremonyImportance - 1
		}
		if(portraitsChecked != "false" && portraitsChecked != false){
			switch(parseInt(portraitsImportance)){
				case 1:
					couplesPortraitPhotosNeeded = 0
					weddingPartyPhotosNeeded = 0
					break
				case 2:
					couplesPortraitPhotosNeeded = 3
					weddingPartyPhotosNeeded = 0
					break
				case 3:
					couplesPortraitPhotosNeeded = 3
					weddingPartyPhotosNeeded = 1
					break
				case 4:
				case 5:
					couplesPortraitPhotosNeeded = 4
					weddingPartyPhotosNeeded = 1
					break
			}
		}
		if(receptionChecked != "false" && receptionChecked != false){
			formalDancePhotosNeeded = Math.ceil((receptionImportance - 1) / 2)
			generalReceptionPhotosNeeded = Math.max(0,Math.ceil((receptionImportance - 1) / 2))
		}
		if((couplesPortraitPhotosNeeded + weddingPartyPhotosNeeded + formalDancePhotosNeeded + generalReceptionPhotosNeeded + ceremonyPhotosNeeded + gettingReadyPhotosNeeded) < 2){
			ceremonyPhotosNeeded = 2
		}
		setValue('optionsTotal', couplesPortraitPhotosNeeded + weddingPartyPhotosNeeded + formalDancePhotosNeeded + generalReceptionPhotosNeeded + ceremonyPhotosNeeded + gettingReadyPhotosNeeded)
		var tod = 0
		if(couplesPortraitPhotosNeeded > 0){
			tod += 1
		}
		if(weddingPartyPhotosNeeded > 0){
			tod += 1
		}
		if(formalDancePhotosNeeded > 0){
			tod += 1
		}
		if(generalReceptionPhotosNeeded > 0){
			tod += 1
		}
		if(ceremonyPhotosNeeded > 0){
			tod += 1
		}
		if(gettingReadyPhotosNeeded > 0){
			tod += 1
		}
		setValue('timesOfDay', tod)
    const { usedPhotographers } = quizState

    const photographerIds = scoredPhotographers.filter(p => !usedPhotographers.includes(p.id) && p.budgetScore > 49).sort((a, b) => a.totalScore > b.totalScore || !b.totalScore ? -1 : 1).slice(0, 10).map(s => s.id)
    if (photographerIds.length) {
      axios.post('/api/get-needed-photos-new', { photographerIds })
      .then(r => {
        const {
          gettingReadyPhotos,
          ceremonyPhotos,
          couplesPortraitPhotos,
          formalDancePhotos,
          weddingPartyPhotos,
          generalReceptionPhotos,
        } = r.data
        const gettingReadyByPhotographer = Object.values(_.groupBy(gettingReadyPhotos, "photographer_fkey"))
        const ceremonyByPhotographer = Object.values(_.groupBy(ceremonyPhotos, "photographer_fkey"))
        const couplesPortraitByPhotographer = Object.values(_.groupBy(couplesPortraitPhotos, "photographer_fkey"))
        const weddingPartyByPhotographer = Object.values(_.groupBy(weddingPartyPhotos, "photographer_fkey"))
        const generalReceptionByPhotographer = Object.values(_.groupBy(generalReceptionPhotos, "photographer_fkey"))
        const formalDanceByPhotographer = Object.values(_.groupBy(formalDancePhotos, "photographer_fkey"))
        setValue('generalReception', generalReceptionByPhotographer)
        setValue('formalDance', formalDanceByPhotographer)
        setValue('weddingParty', weddingPartyByPhotographer)
        setValue('couplesPortrait', couplesPortraitByPhotographer)
        setValue('ceremony', ceremonyByPhotographer)
        setValue('gettingReady', gettingReadyByPhotographer)
      })
    }
  }


  useEffect(() => {
      if (quizState.month && quizState.year && quizState.day) {
          axios.get(`/api/photographer-by-date/${quizState.year}-${quizState.month}-${quizState.day}`)
          .then(r => {
              axios.post('/api/get-booked-tags', { photographerIds: r.data.map(p => p.id)})
              .then(r => {
                  setBookedTags(r.data)
              })
              if (quizState.venueVendorId) {
                  axios.post('/api/get-wedding-experience', { photographerIds: photographers.map(p => p.id), vendorId: quizState.venueVendorId})
                  .then(r2 => {
                      setPhotographers(r.data.map(p => {
                        return {
                          ...p,
                          weddingExperience: r2.data.filter(w => w == p.id).length
                        }
                      }))
                      setWeddings(r2.data)
                  })
              } else {
                  setPhotographers(r.data)
                  setWeddings([])
              }
          })
      } else {
          axios.post('/api/get-booked-tags', { photographerIds: photographers.map(p => p.id)})
          .then(r => {
              setBookedTags(r.data)
          })
          if (quizState.venueVendorId) {
              axios.post('/api/get-wedding-experience', { photographerIds: photographers.map(p => p.id), vendorId: quizState.venueVendorId})
              .then(r2 => {
                  const arr = [...photographers]
                  setPhotographers(arr.map(p => {
                    return {
                      ...p,
                      weddingExperience: r2.data.filter(w => w == p.id).length
                    }
                  }))
                  setWeddings(r2.data)
              })
          } else {
              setWeddings([])
          }
      }

  }, [quizState.year, quizState.month, quizState.day, quizState.venueVendorId])

  useEffect(() => {
    const {
      arr1,
      arr2
    } = determinePhotoQuantities(quizState)
    setValue('options1', arr1)
    setValue('options2', arr2)
  }, [
		quizState.month,
		quizState.formalDance,
		quizState.ceremony,
		quizState.generalReception,
		quizState.couplesPortrait,
		quizState.weddingParty,
		quizState.gettingReady])

  useEffect(() => {
    getPhotographers()
  }, [quizState.weddingLocation, scoredPhotographers])

  const baseAttributeWeights = useMemo(() => {
      const numberOfAttributes = quizState.attributes.length
      return baseAttributes.map(a => {
          return {
              name: a,
              weight: numberOfAttributes > 0 ? quizState.attributes.filter(at => attributeMap[at] === a).length / numberOfAttributes : 0
          }
      })
  }, [quizState.attributes])

  useEffect(() => {
        const newArr = [...photographers]
        const attributeRanges = newArr.length ? getAttributeRanges(newArr.filter(p => p.attributes).map(p => p.attributes)) : null
        const scoredArray = newArr.map(p => {
            const attributeScores = calculateAttributeScores(attributeRanges, p.attributes)
            let attributeScore = 0
            baseAttributeWeights.forEach(a => {
                attributeScore += ((attributeScores[a.name] ?? 0) * a.weight)
            })
            const newObject = {
                ...p,
                budgetScore: calculateBudgetScore(quizState.selectedBudgets, p, quizState.hoursSelected ?? estimateHours()),
                attributeScores,
                attributeScore: attributeScore,
                locationScore: calculateLocationScore(quizState.weddingLocation, p, quizState.hoursSelected),
                styleScore: calculateStyleScore(quizState.photoStyles, p.photo_style_fkey),
                venueScore: calculateVenueScore(p.id, weddings),
                tagScore: calculateTagScore(p.id, bookedTags, quizState.importantTags),
                rankScore: calculateRankScore(p),
                preferenceScore: quizState.preference ? calculatePreferenceScore(p, quizState.preference) : 0
            }
            newObject.totalScore = parseFloat(parseFloat(budgetWeight)*parseFloat(newObject.budgetScore)) +
                parseFloat(attributeWeight*parseFloat(newObject.attributeScore)) +
                parseFloat(locationWeight*parseFloat(newObject.locationScore)) +
                parseFloat(styleWeight*parseFloat(newObject.styleScore)) +
                parseFloat(venueWeight*parseFloat(newObject.venueScore)) +
                parseFloat(tagWeight*parseFloat(newObject.tagScore)) +
                parseFloat(bookingWeight*parseFloat(newObject.booking_score)) +
                parseFloat(albumWeight * parseFloat(newObject.album_score)) +
                parseFloat(taggingWeight*parseFloat(newObject.tagging_score)) +
                parseFloat(calendarWeight*parseFloat(newObject.calendar_score)) +
                parseFloat(matchingWeight*parseFloat(newObject.matching_score)) +
                parseFloat(preferenceWeight*parseFloat(newObject.preferenceScore)) +
                parseFloat(rankWeight*parseFloat(newObject.rankScore))

            return newObject
        })
        setScoredPhotographers(scoredArray)
    }, [
			quizState.gettingReadyChecked,
			quizState.ceremonyChecked,
			quizState.portraitsChecked,
			quizState.portraitsImportance,
			quizState.receptionChecked,
      quizState.receptionImportance,
      quizState.hoursSelected,quizState.importantTags, quizState.preference, preferenceWeight, quizState.selectedBudgets, quizState.hoursSelected, photographers, quizState.attributes, quizState.weddingLocation, quizState.photoStyles, weddings, bookedTags, budgetWeight, attributeWeight, locationWeight, styleWeight, venueWeight, tagWeight, bookingWeight, albumWeight, taggingWeight, calendarWeight, matchingWeight])






  const retrieveLocalStorage = () => {
    const values = localStorage
    for (const [key, value] of Object.entries(values)) {
      if (typeof(quizState[key]) != 'undefined') {
        switch(typeof(quizState[key])) {
          case 'boolean':
            setValue(key, value !== "false")
            break;
          case 'number':
            setValue(key, parseInt(JSON.parse(value)))
            break;
          case 'string':
            setValue(key, JSON.parse(value))
            break;
          default:
            setValue(key, JSON.parse(value))

        }
      }
    }
  }

  return (
    <Fragment>
      <UserContext.Provider
        value={{
          email,
          setEmail,
          photographer,
          setPhotographer,
          activePage,
          setActivePage,
        }}
      >
        <PhotographerContext.Provider
          value={{
            photosComplete,
            setPhotosComplete,
            pricingComplete,
            setPricingComplete,
            infoComplete,
            setInfoComplete,
            profileComplete,
            setProfileComplete,
            pricingTouched,
            setPricingTouched,
            personalityComplete,
            personalityTouched,
            setPersonalityComplete,
            setPersonalityTouched,
            infoTouched,
            setInfoTouched,
            photosTouched,
            setPhotosTouched,
            imagePrefix,
            setImagePrefix
          }}
        >
          <Routes>
          <Route path="/dashboard/:id/photos" element={<DashboardPhotos />} />
          <Route path="/dashboard/:id/info" element={<DashboardInfo />} />
          <Route path="/dashboard/:id/personality" element={<DashboardPersonality />} />
          <Route path="/dashboard/:id/pricing" element={<DashboardPricing />} />
          <Route path="/dashboard/:id/availability" element={<DashboardAvailability />} />
          <Route path="/dashboard/:id/matches" element={<DashboardMatches />} />
          <Route path="/dashboard/:id/albums" element={<CreatePage />} />
          <Route path="/dashboard/:id/listalbums" element={<AlbumsPage />} />
          <Route path="/dashboard/:id/preferredvendors" element={<DashboardPreferredVendors />} />
          <Route path="/fullsizephotos/:id" element={<FullSizeWeddingPhotos />} />
          <Route exact path="/dashboard/:id" element={<PhotographerDashboard />} />
          </Routes>
        </PhotographerContext.Provider>
          <Routes>
        <Route path="/createphotographer" element={<CreatePhotographer />} />
        <Route path="/createreferral" element={<CreateReferral />} />
        <Route path="/calendarlist" element={<CalendarList />} />
        <Route path="/photographerlist" element={<PhotographerList />} />
        <Route path="/vendorlist" element={<VendorList />} />
        <Route path="/updatevenues" element={<UpdateVenues />} />
        <Route path="/signuplist" element={<SignupList />} />
        <Route path="/weddinglist" element={<WeddingList />} />
        <Route path="/getvendorschosen" element={<GetVendorsChosen />} />
        <Route path="/setpassword/:id/:code" element={<Signup />} />
        <Route path="/weddingupload/:code?" element={<WeddingUpload />} />
        <Route path="/editwedding/:weddingId?" element={<WeddingUpload />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword /> } />
        <Route path="/updatepassword" element={<UpdatePassword /> } />
        <Route path="/account" element={<AccountManagement /> } />
        <Route path="/vendorlogin" element={<Login />} />
        <Route path="/authorize" element={<AuthorizeAccount />} />
        <Route path="/createaccount" element={<VendorSignup />} />
        <Route path="/albumdashboard" element={<BasicPhotographer />} />
        <Route path="/accounttype" element={<AccountTypeSelect />} />
        <Route path="/albums" element={<AlbumsPage />} />
        <Route path="/vendorsignup" element={<VendorSignup />} />
        <Route path="/algorithmtest" element={<AlgorithmTest />} />
        <Route path="/vendordashboard/:vendorId?" element={<VendorDashboard />} />
        <Route path="/newquiz" element={<NewQuiz />} />
        <Route path="/createaccount=SM16GP3" element={<CustomVendorSignup />} />


        {/* Vendor Dashboard */}
        <Route path="/vendor/dashboard/:id" element={<VendorForm />} />

        </Routes>
        <AnswersContext.Provider
          value={{
            setValue,
            toggleArraySelection,
            handleChangeText,
            pushToArray,
            quizState,
            toggleBoolean,
            setBooleanFalse,
            matchups,
            setMatchups,
            photoStyles,
            setPhotoStyles,
            photoStylesFromUrl,
            setPhotoStylesFromUrl,
            retrieveLocalStorage,
            getPhotographers,
            scoredPhotographers,
          }}
        >
            <ScrollToTop />
            <AuthProvider>

              <Routes>
                <Route path="/tempupload" element={<TempPhotoUpload />} />
                <Route path="/dateprev" element={<DateNew /> } />
                <Route path="/email" element={<EmailNew />} />
                <Route path="/success" element={<Success />} />
                <Route path="/budget" element={<BudgetNew />} />
                <Route path="/dotogetherprev" element={<DoTogetherNew />} />
                <Route path="/results/:result?" element={<Results />} />
                <Route path="/main/:budget" element={<SelectionPage />} />
                <Route exact path="/budget" element={<BudgetNew />} />
                <Route path="/getresults" element={<GetResults />} />
                <Route path="/getvendorresults" element={<GetVendorResults />} />
                <Route path="/calendar/:id" element={<OauthCalendar />} />
                <Route path="/pickrates" element={<PickRates />} />
                <Route path="/getbookingstatus" element={<GetBookingStatus />} />
                <Route path="/howimportantprev" element={<ImportantNew />} />
                <Route path="/howimportantnew" element={<ImportantNew />} />
                <Route path="/tagsprev" element={<TagsNew />} />
                <Route path="/photostyleprev" element={<PhotoStyle />} />
                <Route path="/location" element={<WeddingLocationNew />} />
                <Route path="/locationnew" element={<WeddingLocationNew />} />
                <Route path="/dotogethernew" element={<DoTogetherNew />} />
                <Route path="/emailnew" element={<EmailNew />} />
                <Route path="/datenew" element={<DateNew />} />
                <Route path="/budgetnew" element={<BudgetNew />} />
                <Route path="/tagsnew" element={<TagsNew />} />
                <Route path="/vendortypesprev" element={<VendorTypes />} />
                <Route path="/ranks/:ranks" element={<WeddingLocationNew />} />

                {/* <Route exact path="/" element={<WeddingLocationNew />} /> */}

                {/* <Route path="/locationnewquiz" element={<WeddingLocationNewQuiz />} /> */}
                <Route exact path="/" element={<WeddingLocationNewQuiz />} />
                <Route path="/vendortypes" element={<VendorTypesNewQuiz />} />
                <Route path="/date" element={<DateNewQuiz />} />
                <Route path="/tags" element={<TagsNewQuiz />} />
                <Route path="/photographer" element={<BudgetNewQuiz />} />
                <Route path="/howimportant" element={<ImportantNewQuiz />} />
                <Route path="/photostyle" element={<PhotoStyleNewQuiz />} />
                <Route path="/dotogether" element={<DoTogetherNewQuiz />} />
                <Route path="/videographernewquiz" element={<VideographerNewQuiz />} />
                <Route path="/floristnewquiz" element={<FloristNewQuiz />} />
                <Route path="/djnewquiz" element={<DjNewQuiz />} />
                <Route path="cakedessertnewquiz" element={<CakeDessertNewQuiz />} />
                <Route path="/hairmakeupnewquiz" element={<HairMakeupNewQuiz />} />
                <Route path="/plannernewquiz" element={<PlannerNewQuiz />} />
                <Route path="/login" element={<TempLogin />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/selection" element={<SelectionPageNewQuiz />} />
                <Route path="/tempresults" element={<TempResults />} />
                <Route path="/home" element={<Dashboard />} />
              </Routes>
            </AuthProvider>
        </AnswersContext.Provider>
      </UserContext.Provider>
    </Fragment>
  );
}

export default App;
