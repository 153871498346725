import React, { useState } from 'react'
import axios from 'axios'
import { VendorDetailsBox } from "./VendorDetailBox"

var vendorsFormKeys = [
    {
        id:1,
        dbId: 1,
        header:'Bridal Shop',
        name:'bridalShop',
        icon:'/images/vendor-img/bridal-shop.svg',
        searchResults: [],
        active: false,
    },
    {
        id:2,
        dbId: 2,
        header:'Wedding Cake',
        name:'weddingCake',
        icon:'/images/vendor-img/wedding-cake.svg',
        searchResults: [],
        active: false,
    },
    {
        id:3,
        dbId: 8,
        header:'Catering',
        name:'catering',
        icon:'/images/vendor-img/catering.svg',
        searchResults: [],
        active: false,
    },
    {
        id:4,
        dbId: 5,
        header:'DJ',
        name:'dj',
        icon:'/images/vendor-img/dj.svg',
        searchResults: [],
        active: false,
    },
    {
        id:5,
        dbId: 3,
        header:'Florist',
        name:'florist',
        icon:'/images/vendor-img/florist.svg',
        searchResults: [],
        active: false,
    },
    {
        id:6,
        dbId: 9,
        header:'Lighting',
        name:'lighting',
        icon:'/images/vendor-img/lighting.svg',
        searchResults: [],
        active: false,
    },
    {
        id:7,
        dbId: 7,
        header:'Makeup',
        name:'makeup',
        icon:'/images/vendor-img/makeup.svg',
        searchResults: [],
        active: false,
    },
    {
        id:8,
        dbId: 4,
        header:'Officiant',
        name:'officiant',
        icon:'/images/vendor-img/officiant.svg',
        searchResults: [],
        active: false,
    },
    {
        id:9,
        dbId: 10,
        header:'Photobooth',
        name:'photobooth',
        icon:'/images/vendor-img/photobooth.svg',
        searchResults: [],
        active: false,
    },
    {
        id:10,
        dbId: 13,
        header:'Planner',
        name:'planner',
        icon:'/images/vendor-img/planner.svg',
        searchResults: [],
        active: false,
    },
    {
        id:11,
        dbId: 11,
        header:'Rentals',
        name:'rentals',
        icon:'/images/vendor-img/rentals.svg',
        searchResults: [],
        active: false,
    },
    {
        id:12,
        dbId: 12,
        header:'Transportation',
        name:'transportation',
        icon:'/images/vendor-img/transportation.svg',
        searchResults: [],
        active: false,
    },
]

export const StepTwoVendors = ({ step2, setForm, currentIndex }) => {
    const [ vendorsFormKeys2, setVendorsFormKeys2 ] = useState(vendorsFormKeys)
    const [ resultsActiveOn, setResultsActiveOn ] = useState('')

    const searchVendors = (value, section) => {
        if(value.length > 1){
            axios.get('/api/get-vendors/' + vendorsFormKeys.find(v => v.name === section).dbId + '/' + value)
            .then(r => {
                var vends = [ ... vendorsFormKeys2]
                vends.find(v => v.name === section).searchResults = r.data.vendors
                setVendorsFormKeys2(vends)
                if(vends.find(v => v.name === section).searchResults.filter(s => s.business_name == value).length === 0){
                    setResultsActiveOn(section)
                }
            })
        } else{
            var vends = [ ... vendorsFormKeys2]
            vends.find(v => v.name === section).searchResults = []
            setVendorsFormKeys2(vends)
        }
    }

    const handleInput = (e, section) => {
        if(!e.noPersist){
            e.persist();
        }
        console.log(e)
        if(e.target.name !== "vendorEmail"){
            searchVendors(e.target.value, section)
        }
        setForm(prev=>{
            return {
                ...prev,
                steps:{
                    ...prev.steps,
                    step2:{
                        ...prev.steps.step2,
                        [section]:{
                            ...prev.steps.step2[section],
                            [e.target.name]:e.target.value
                        }
                    }
                }
            }
        })
    }

    return(
        <div className='my_custom_container'>
            <div className='font22 bold600'>
            Optional: Tag this wedding's vendors so they can share your work.
            </div>
            We’ll email any vendors and encourage them to share your wedding. 
            <div style={{marginTop:30, marginBottom:30}}>
                {
                    vendorsFormKeys2.map((item)=>(
                        <VendorDetailsBox 
                            key={item.id}
                            item={item}
                            step2={step2}
                            resultsActiveOn={resultsActiveOn}
                            setResultsActiveOn={setResultsActiveOn}
                            handleInput={handleInput}
                        />
                    ))
                }
            </div>
        </div>
    )
}