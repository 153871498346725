import { ErrorBanner } from '../CreatePage/StepThreeUploads/ErrorBanner';
import { UploadError } from '../CreatePage/StepThreeUploads/UploadError';
import { Progressbar } from '../CreatePage/StepThreeUploads/Progressbar';
import { ListImageContainer } from '../CreatePage/StepThreeUploads/ListImageContainer';
import axios, {CancelToken} from 'axios';
import { Link } from 'react-router-dom';
import { uploadConfig } from '../../utils/upload_config';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { MyCustomDropzone } from '../CreatePage/StepThreeUploads/MyCustomDropzone';
import { MyCustomDropdown } from '../CreatePage/Components/MyCustomDropdown';
import DatePicker from 'react-datepicker';
import '../CreatePage/StepThreeUploads/StepThree.css';
import DownArrow from '../../styles/down-arrow.svg';
import RadioCircle from '../../styles/radio-circle.svg';
import RadioCircleFilled from '../../styles/radio-circle-filled.svg';
import { useParams } from "react-router-dom";
import { uniqueId } from '../../utils/functions';
import logo from '../../styles/wedmatchlogo.png';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  // DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  // rectSwappingStrategy,
  rectSortingStrategy,
  // verticalListSortingStrategy
} from '@dnd-kit/sortable';
import Bugsnag from '@bugsnag/js';
const cloudName = 'dfjwlskop';
const cloudinary_preset = 'comparison';
const allKeywords = [
  "Backyard",
  "Ballroom",
  "Beach",
  "Black Tie",
  "City",
  "Cultural Wedding",
  "Elopement",
  "House of Worship",
  "LGBTQ+",
  "Micro Wedding",
  "Monuments",
  "Mountains",
  "Multi-day Wedding",
  "Nature",
  "Offbeat",
  "Outdoor Ceremony",
  "Party",
  "People of Color",
  "Rustic",
  "Vineyard",
]
const vendorsFormKeys =
{
  bridalShop: {
      id:1,
      dbId: 1,
      header:'Bridal Shop',
      name:'bridalShop',
      icon:'/images/vendor-img/bridal-shop.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  weddingCake: {
      id:2,
      dbId: 2,
      header:'Wedding Cake',
      name:'weddingCake',
      icon:'/images/vendor-img/wedding-cake.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  catering: {
      id:3,
      dbId: 8,
      header:'Catering',
      name:'catering',
      icon:'/images/vendor-img/catering.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  dj: {
      id:4,
      dbId: 5,
      header:'DJ',
      name:'dj',
      icon:'/images/vendor-img/dj.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  florist: {
      id:5,
      dbId: 3,
      header:'Florist',
      name:'florist',
      icon:'/images/vendor-img/florist.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  lighting: {
      id:6,
      dbId: 9,
      header:'Lighting',
      name:'lighting',
      icon:'/images/vendor-img/lighting.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  makeup: {
      id:7,
      dbId: 7,
      header:'Makeup',
      name:'makeup',
      icon:'/images/vendor-img/makeup.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  officiant: {
      id:8,
      dbId: 4,
      header:'Officiant',
      name:'officiant',
      icon:'/images/vendor-img/officiant.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  photobooth: {
      id:9,
      dbId: 10,
      header:'Photobooth',
      name:'photobooth',
      icon:'/images/vendor-img/photobooth.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  planner: {
      id:10,
      dbId: 13,
      header:'Planner',
      name:'planner',
      icon:'/images/vendor-img/planner.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  rentals: {
      id:11,
      dbId: 11,
      header:'Rentals',
      name:'rentals',
      icon:'/images/vendor-img/rentals.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
  transportation: {
      id:12,
      dbId: 12,
      header:'Transportation',
      name:'transportation',
      icon:'/images/vendor-img/transportation.svg',
      searchResults: [],
      active: false,
      vendorName: '',
      vendorEmail: ''
  },
}
const UploadPage = () => {
  const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;
  let { code, weddingId } = useParams();
  const [photos, setPhotos] = useState([])
  const [ thumbnails, setThumbnails] = useState([])
  const [totalProgress, setTotalProgress] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [ keywords, setKeywords] = useState([])
  const [ weddingDate, setWeddingDate ] = useState(null)
  const [ ceremonyVenue, setCeremonyVenue ] = useState(null)
  const [ receptionVenue, setReceptionVenue] = useState(null)
  const [ exactCeremonyLocation, setExactCeremonyLocation ] = useState(null)
  const [ exactReceptionLocation, setExactReceptionLocation ] = useState(null)
  const [ partner1, setPartner1 ] = useState('')
  const [ partner2, setPartner2 ] = useState('')
  const [ showTags, setShowTags ] = useState(Boolean(weddingId))
  const [ showVendors, setShowVendors ] = useState(true)
  const [activeId, setActiveId] = useState(null);
  const [sortableArray, setSortableArray] = useState(Object.keys(photos));
  const [venueResults, setVenueResults] = useState([])
  const [receptionResults, setReceptionResults] = useState([])
  const [showCeremonyResults, setShowCeremonyResults ] = useState(false)
  const [showReceptionResults, setShowReceptionResults ] = useState(false)
  const [ photographerResults, setPhotographerResults ] = useState([])
  const [ showPhotographerResults, setShowPhotographerResults ] = useState(false)
  const [ userId, setUserId ] = useState(null)
  const [ceremonyExactOptions, setCeremonyExactOptions] = useState([])
  const [receptionExactOptions, setReceptionExactOptions] = useState([])
  const [maxSizeError, setMaxSizeError] = useState([]);
  const [networkOnline, setNetworkOnline] = useState(true);
  const [uploadSection, setUploadSection] = useState(false);
  const [uploadSuccessChecker, setUploadSuccessChecker] = useState({
    success: 0,
    error: 0,
  });
  const [ photographer, setPhotographer ] = useState({business_name: '', email: '', website_url: '', instagram_url: ''})
  const [ vendorsFormKeys2, setVendorsFormKeys2 ] = useState(vendorsFormKeys)
  const [ resultsActiveOn, setResultsActiveOn ] = useState('')
  const [ isPhotographer, setIsPhotographer ] = useState(null)
  const [ password, setPassword ] = useState('')
  const [ showPopup, setShowPopup ] = useState(false)
  
  useEffect(() => {
    if (code) {
      axios.get(`/api/wedding-request-by-code/${code}`)
      .then(r => {
        setPartner1(r.data.weddingRequest.partner1)
        setPartner2(r.data.weddingRequest.partner2)
        handleDateInput(r.data.weddingRequest.wedding_date)
        setPhotographer(r.data.photographer)
      })
    }
  }, [code])
  useEffect(() => {
    if (weddingId) {
      axios.get(`/api/wedding/${weddingId}`)
      .then(r => {
        const wedding = r.data
        // setWeddingDate(wedding.wedding_date)
        setKeywords(wedding.tags)
        setPartner1(wedding.partner1)
        setPartner2(wedding.partner2)
        setCeremonyVenue(wedding.ceremony_venue_name)
        setReceptionVenue(wedding.reception_venue_name)
        setWeddingDate(new Date(wedding.wedding_date))        
        setExactCeremonyLocation(wedding.ceremony_exact_location)
        setExactReceptionLocation(wedding.reception_exact_location)
        const vends = {...vendorsFormKeys2}
        console.log(wedding.vendors)
        Object.keys(vends).map(k => {
          const thisVendorType = vendorsFormKeys2[k]
          const enteredVendor = wedding.vendors.find(v => v.vendor_type_fkey == thisVendorType.dbId)
          vends[k] = {...vends[k], vendorName: enteredVendor?.business_name ?? enteredVendor?.vendor_name ?? '', email: enteredVendor?.email ?? ''}
        })
        setVendorsFormKeys2(vends)       
      })
    }
  }, [weddingId])

  const submitForm = (e) => {
    e.preventDefault()
    sessionStorage.clear()    
    const sortedPhotos = Object.values(photos).map(p => { 
      return {...p, sortPriority: sortableArray.indexOf(p.id) + 1 }
    })
    if (!weddingId) {
      axios.post('/api/create-wedding-new', { keywords, weddingDate, partner1, partner2, receptionVenue, ceremonyVenue, exactCeremonyLocation, exactReceptionLocation, photos: sortedPhotos, photographer, vendors: vendorsFormKeys2, thumbnails })
      .then(r => {
          if(isPhotographer && !r.data.photographerId){ 
              setShowPopup(true)
          } else {
            window.location = "/weddingupload?success"
          }
      })
    } else {
      axios.post('/api/update-wedding', { weddingId, keywords, weddingDate, partner1, partner2, receptionVenue, ceremonyVenue, photographer, vendors: vendorsFormKeys2 })
      .then(r => {
          window.location = `/editwedding/${weddingId}?success`
      })
    }
  }
  
  const signup = (e) => {    
    e.preventDefault()    
    const data = {
      email: photographer.email,
      password,
      instagram: photographer.instagram_url,
      website: photographer.website_url,
      businessName: photographer.business_name,
    };
      axios.post("/auth/createaccountandphotographer", data).then((r) => {
        window.location = "/weddingupload";
          
      });
  }
  const onDrop = useCallback(acceptedFiles => {
    let allImages = {};

    acceptedFiles.forEach((file, index) => {
      let id = uniqueId();
      if (file.size > uploadConfig.MAX_SIZE_OF_PHOTO) {
        setMaxSizeError(prev => [...prev, { id, err: true }]);
      } else {
        setMaxSizeError(prev => [...prev, { id, err: false }]);
      }
      let imgObj = {
        id,
        imageFile: file,
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        allowed: !(file.size > uploadConfig.MAX_SIZE_OF_PHOTO),
        error: false,
      };
      allImages = {
        ...allImages,
        [id]: imgObj,
      };
    });

    setPhotos(prev => {
      return { ...prev, ...allImages };
    });

    setUploadSection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    axios.get('/auth/getuser')
        .then(r => {
            setUserId(r.data.id)
        })
    window.addEventListener('offline', function (e) {
      setNetworkOnline(false);
    });
    window.addEventListener('online', function (e) {
      setNetworkOnline(true);
    });
    return window.removeEventListener('offline', function (e) {
      setNetworkOnline(false);
    });
  }, []);


  useEffect(() => {
    setSortableArray(Object.keys(photos))
    if (Object.keys(photos).length !== 0) setUploadSection(true);
    if (Object.keys(photos).length === 0) setUploadSection(false);
  }, [photos]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      transition: {
        duration: 150, // milliseconds
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      },
    })
  );
  const totalImages = Object.keys(photos).length;

  const handleDateInput = (date) => {
    setWeddingDate(new Date(date).getTime())
}

const handleDropdownChange = (type, value) => {
    if (type == 'exactCeremonyLocation') {
      setExactCeremonyLocation(value)
    } else if (type == 'exactReceptionLocation') {
      setExactReceptionLocation(value)
    }
}

const searchVenues = (e, fieldToUpdate) => {
    if(fieldToUpdate === 'ceremony'){
        if(e.target.value?.length > 1){
            axios.get('/api/get-vendors/14/' + e.target.value)
            .then(r => {
                setVenueResults(r.data.vendors)
            })
        } else{
            setVenueResults([])
        }
    } else{
        if(e.target.value?.length > 1){
            axios.get('/api/get-vendors/14/' + e.target.value)
            .then(r => setReceptionResults(r.data.vendors))
        } else{
            setReceptionResults([])
        }
    }
}
const searchPhotographers = (e) => {
    if(e.target.value?.length > 1){
        axios.get('/api/get-photographers/' + e.target.value)
        .then(r => {
            setPhotographerResults(r.data.photographers)
        })
    } else{
      setPhotographerResults([])
    }
}

  const uploadEachImage = item => {
    if (item.allowed === true) {
      if (item.started !== true) {
        // SET STARTED UPLOADING
        setPhotos(prev => {
          return {
            ...prev,
            [item.id]: {
              ...prev[item.id],
              started: true,
            },
          };
        });
        const config = {
          onUploadProgress: function (progressEvent) {
            setProgressOfEachImage(item.id, Math.round((progressEvent.loaded * 100) / progressEvent.total));
          },
          cancelToken: new CancelToken(cancel => {
            setPhotos(prev => {
              return {
                ...prev,
                [item.id]: {
                  ...prev[item.id],
                  cancel: cancel,
                },
              };
            });
          }),
        };
        const data = new FormData();
        data.append('file', item.imageFile);
        //data.append("api_key", cloudinary_api_key);
        //data.append("api_secret", cloudinary_api_secret);
        data.append('upload_preset', cloudinary_preset);

        axios
          .post(uploadUrl, data, config)
          .then(response => {
            if (response.status === 200) {
              controlSuccessAndErrorUpload('success');
              setUploadedImages(item.id, response.data);
            }
          })
          .catch(err => {
            if (axios.isCancel(err)) {
              console.log('Request canceled', err.message);

              Bugsnag.notify(err);
            } else {
              let errorMessage = err?.response?.data?.error?.message;

              Bugsnag.notify(err, event => {
                event.addMetadata('details', err?.response?.data);
              });

              controlSuccessAndErrorUpload('error');
              setUploadError(item.id, errorMessage);
            }
          });
      }
    }
  };

  const uploadNow = () => {
    let moreThan75 = Object.keys(photos).length > 75;
    if (!moreThan75) {
      Object.entries(photos).forEach(([key, item]) => {
        uploadEachImage(item);
      });
      controlTotalProgress();
    }
  };

  const controlTotalProgress = () => {
    let total = 0;
    Object.entries(photos).forEach(([key, item]) => {
      total += item.progress;
    });
    setTotalProgress(Math.round(total / totalImages));
  };

  useEffect(() => {
    uploadNow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photos]);

  const setProgressOfEachImage = (id, progress) => {
    setPhotos(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          progress,
        },
      };
    });
  };

  const setUploadedImages = (id, data) => {
    setPhotos(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          imgUrl: data.url,
          uploaded: true,
          cloudinaryResponse: data,
        },
      };
    });
  };

  const setUploadError = (id, msg) => {
    setPhotos(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          error: true,
          uploaded: false,
          msg,
        },
      };
    });
  };

  const cancelAll = () => {
    Object.entries(photos).forEach(([key, item]) => {
      if (!item.uploaded) {
        if (item.cancel) {
          item.cancel('cancelled by user');
        }
        removeItem(key);
      }
    });
  };
  const removeItem = (id, deleteFromCloudinary) => {
    if (deleteFromCloudinary) {
      setSortableArray(prev => prev.filter(i => i !== id));
      setPhotos(prev => {
        const { [id]: removedProperty, ...rest } = prev;
        return {
          ...rest,
        };
      });
    } else {
      setMaxSizeError(prev => [...prev.filter(i => i.id !== id)]);
      if (photos[id].cancel) photos[id].cancel('Cancelled by user!');
      setSortableArray(prev => prev.filter(i => i !== id));
      setPhotos(prev => {
        const { [id]: removedProperty, ...rest } = prev;
        return {
          ...rest,
        };
      });
    }
  };

  const calculateMaxSizeError = () => {
    let error = maxSizeError.map(item => item.err);
    return error.includes(true);
  };

  const controlSuccessAndErrorUpload = (type, deleted) => {
    setUploadSuccessChecker(prev => {
      return {
        ...prev,
        [type]: deleted ? prev[type] - 1 : prev[type] + 1,
      };
    });
  };

  const handleDragStart = event => {
    const { active } = event;

    setActiveId(active.id);
  };
  const onDragEnd = result => {
    const { active, over } = result;
    if (active.id !== over.id) {
      setSortableArray(items => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  };
/*
  const updateVendorName = (type, name) => {
    setVendorsFormKeys2(prevState => ({
          ...prevState,
          [type]: {...prevState[type], vendorName: name }
    })
  )
  if (name.length > 1) {
    searchVendors(name, type)
  }
}
*/
const updateVendorName = (type, value, clicked=false) => {  
  setVendorsFormKeys2(prevState => ({
    ...prevState,
    [type]: {...prevState[type], vendorName: value, searchResults: [] },
  }))
  if(value.length > 1 && !clicked){
      axios.get('/api/get-vendors/' + vendorsFormKeys2[type].dbId + '/' + value)
      .then(r => {
          var vends = {...vendorsFormKeys2}
          vends[type].searchResults = r.data.vendors
          setVendorsFormKeys2(prevState => ({
              ...prevState,
              [type]: {...prevState[type], searchResults: r.data.vendors },
          }))
          if(vends[type].searchResults.filter(s => s.business_name == value).length === 0){
              setResultsActiveOn(type.name)
          }
      })
  } else{
    setVendorsFormKeys2(prevState => ({
      ...prevState,
      [type]: {...prevState[type], searchResults: [] },
  }))
  }
}

const updateVendorEmail = (type, vendorEmail) => {
  setVendorsFormKeys2(prevState => ({
        ...prevState,
        [type]: {...prevState[type], vendorEmail }
  })
)
}

  return (
    <>
    <div className='light-blue-background'>
      <div className='wedding-upload-navbar'>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '60px'}}>
          <div style={{marginLeft: '72px'}}><img src={logo} /></div>      
          <div>
            <Link style={{margin: '0px 24px'}} to="/login"><button className='secondary'>Vendor Login</button></Link>
          </div>
        </div>
      </div>
    </div>
    <div className='light-blue-background'>
    {window.location.search === '?success' && <div style={{textAlign: 'center', background: 'green', color: 'white', marginTop: '24px', fontSize: '18px'}}>Success!</div>}
      <div className="my_custom_container" style={{ marginTop: 30 }}>
        {showPopup &&
              <div className='signup-overlay'>
                  <div className='signup-modal' style={{padding: '0px'}}>
                      <div style={{position: 'relative'}}>
                        <button className='share-close-button' style={{top: '10px'}} onClick={(e) => submitForm(e)}>x</button>
                        <div style={{padding: '24px 32px'}}>
                        <div className='password-modal-header'>Password</div>
                        <div className='password-modal-subheader'>Create a password to update your credit details or edit this wedding in the future.</div>
                        <input
                          style={{width: '100%'}}
                          placeholder="Password..."
                          className='password-modal-input'
                          name="Password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className='password-modal-button' onClick={(e) => { setShowPopup(false); signup(e) }}>Create Password</button>
                        </div>
                      </div>
                  </div>
              </div>
          }
      {!uploadSection ? (
        <>{!weddingId &&<MyCustomDropzone photos={photos} onDrop={onDrop} />}</>
      ) : (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={onDragEnd}>
        <div>
          {totalProgress === 100 ? (
            <div className="progress_box">
              <img style={{ marginBottom: 31 }} src="/images/vendor-img/upload-success.svg" alt="Upload Success" />
              <div className="font24 bold600 text-center">
                Successfully Uploaded {uploadSuccessChecker.success} Photos
              </div>
              {uploadSuccessChecker.error > 0 && (
                <div className="font22 bold500 text-center text-warning">
                  Error in uploading {uploadSuccessChecker.error} Photos
                </div>
              )}
              <button
                onClick={e => {
                  e.preventDefault();
                  setUploadSection(false);
                }}
                className="add_more_btn mt-30"
              >
                Add More Photos
              </button>
            </div>
          ) : (
            <div className="progress_box">
              <div className="font24 bold600 text-center">
                Uploading {totalImages} Photo(s)
              </div>
              <div className="progress_bar_section">
                <div className="font12 bold400 d-flex justify-content-between">
                  <div className="d-flex align-items-center text-light-grey">
                    <span>{totalProgress}%</span>
                    {/* <img
                                          style={{margin:"0 10px"}}
                                          className='d-block'
                                          src='/images/icons/upload-dot.svg'
                                          alt='Pause Icon'
                                      /> */}
                    {/* <span>{timeRemaining} remaining</span> */}
                  </div>
                  <div className="d-flex align-items-center">
                    {/* <div className='circle-29 pause_bg'>
                                          <img
                                              src='/images/icons/pause-icon.svg'
                                              alt='Pause Icon'
                                          />
                                      </div> */}
                    <div onClick={() => cancelAll()} className="circle-29 cancel_bg">
                      <img src="/images/icons/cancel-icon.svg" alt="Cancel Cross" />
                    </div>
                  </div>
                </div>
                <Progressbar progress={totalProgress} />
              </div>
              {!networkOnline && <UploadError uploadNow={uploadNow} />}
            </div>
          )}
          {totalImages > uploadConfig.MAX_NUMBER_OF_PHOTOS && (
            <ErrorBanner
              total={totalImages}
              type="max_photos" // max_photos || max_size
            />
          )}
          {calculateMaxSizeError() && (
            <ErrorBanner
              total={totalImages}
              type="max_size" // max_photos || max_size
            />
          )}
          {thumbnails.length !== 3 && (
            <div>
              <div className="bold500 font19" style={{ marginTop: 50 }}>
                <b>IMPORTANT:</b> Select the 3 photos to show in the thumbnail for this wedding
              </div>
              <div>Photo #1 will be cropped vertical, Photo #2, and #3 horizontal</div>
            </div>
          )}
          <SortableContext items={sortableArray} strategy={rectSortingStrategy}>
            <div className="images_list_container">
              {sortableArray.map((key, index) => (
                <Fragment key={index}>
                  <ListImageContainer
                    selectMode={true}
                    thumbnails={thumbnails}
                    setThumbnails={setThumbnails}
                    item={photos[key]}
                    id={key}
                    removeItem={removeItem}
                    uploadEachImage={uploadEachImage}
                    index={index}
                    extra={index > 74 || photos[key]?.imageFile?.size > uploadConfig.MAX_SIZE_OF_PHOTO}
                    // deleteItem={deleteItem}
                  />
                </Fragment>
              ))}
            </div>
          </SortableContext>
          {/* </DragDropContext> */}
        </div>
      </DndContext>
      )}
      
          <div className='' style={{marginTop:30}}>
              {((showCeremonyResults && venueResults?.length) > 0 || (showReceptionResults && receptionResults?.length > 0)) &&
                  <div className='overlay transparent' onClick={() => setShowCeremonyResults(false)}></div>
              }
              <div className='font22 bold600 my_custom_container'>
                  Date and Venues
              </div>
              <div style={{marginTop:30, gap:15}} className='d-md-flex flex-wrap my_custom_container_stepone'>
                  <div style={{flex:1}} className={`floating_inputs_wrapper transparent_background_padding mb-20`}>
                      <label style={{zIndex:9}} htmlFor='weddingDate' className='signup-input-label-special'>
                          Wedding Date
                      </label>
                      <DatePicker
                          selected={weddingDate}
                          onChange={(date) => handleDateInput(date)}
                          className='date_input'
                          placeholderText="MM/DD/YYYY"
                      />
                      <img 
                          className='calendar_icon_positioning'
                          src='/images/icons/calendar-icon.png'
                          alt='Calendar Icon'
                      />
                  </div>
                  <div style={{flex:1}} className='floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding'>
                      <label htmlFor='ceremonyVenue' className='signup-input-label-special'>
                          Ceremony Venue
                      </label>
                      <input 
                          onChange={(e)=> {setCeremonyVenue(e.target.value); searchVenues(e, 'ceremony'); setShowCeremonyResults(true) }}
                          value={ceremonyVenue}
                          autocomplete="off"
                          placeholder='Ceremony Venue...'
                          style={{
                              width:"100%", 
                              // margin:'0 10px'
                          }}
                          name='ceremonyVenue'
                          type='text'
                      />
                      {showCeremonyResults && venueResults?.length > 0 &&
                          <div className='search-results'>
                              {venueResults.map(v => <div onClick={() => {setShowCeremonyResults(false); setCeremonyExactOptions(v.ceremony_exact_locations); setCeremonyVenue(v.business_name);}} key={v.id}>{v.business_name}</div>)}
                          </div>
                      }
                  </div>
                  {/* FOR MOBILE DESIGN ONLY */}
                  {ceremonyExactOptions?.length > 0 && !weddingId &&
                  <div style={{flex:1}} className='floating_inputs_wrapper mb-20 d-block d-md-none white_background_select'>
                      <label htmlFor='exactCeremonyLocation' style={{zIndex:9}} className='signup-input-label-special white'>
                          Exact Location for Ceremony
                      </label>
                      <MyCustomDropdown 
                          id='ceremonyexact'
                          items={ceremonyExactOptions}
                          type='exactCeremonyLocation'
                          onChange={handleDropdownChange}
                          value={exactCeremonyLocation}
                          placeholder='Exact location for ceremony...' 
                      />
                  </div>
                  }



                  <div style={{flex:1}} className='floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding'>
                      <label htmlFor='receptionVenue' className='signup-input-label-special'>
                          Reception Venue
                      </label>
                      <input 
                          onChange={(e)=>{setReceptionVenue(e.target.value); searchVenues(e, 'reception'); setShowReceptionResults(true)} }
                          value={receptionVenue}
                          autocomplete="off"
                          placeholder='Reception Venue...'
                          style={{width:"100%"}}
                          name='receptionVenue'
                          type='text'
                      />
                      {showReceptionResults && receptionResults?.length > 0 &&
                          <div className='search-results'>
                              {receptionResults.map(v => <div onClick={() => {setShowReceptionResults(false);  setReceptionExactOptions(v.reception_exact_locations); setReceptionVenue(v.business_name);}} key={v.id}>{v.business_name}</div>)}
                          </div>
                      }
                  </div>
              </div>
              <div style={{marginTop:15, gap:15}} className='d-md-flex flex-wrap my_custom_container_stepone'>

                  {/* FAKE ONLY FOR SPACING */}
                  
                  {(ceremonyExactOptions?.length > 0 || receptionExactOptions?.length > 0) &&
                  <div style={{flex:1, marginLeft: '20px'}} className='floating_inputs_wrapper d-none d-xxl-flex'>
                      <label style={{display:'none'}} htmlFor='receptionVenue' className='signup-input-label-special'>
                          Reception Venue
                      </label>
                      <input 
                      style={{width:"100%", display:'none'}}
                          name='receptionVenue'
                          type='text'
                      />
                  </div>
                  }

                  
              {(!ceremonyExactOptions?.length > 0 && receptionExactOptions?.length > 0)  &&
                  <div style={{flex:1}} className='floating_inputs_wrapper d-none d-xxl-flex'>
                      <label style={{display:'none'}} htmlFor='receptionVenue' className='signup-input-label-special'>
                          Reception Venue
                      </label>
                      <input 
                      style={{width:"100%", display:'none'}}
                          name='receptionVenue'
                          type='text'
                      />
                  </div>
              }

                  {/* FOR LARGE SCREENS ONLY */}
                  {ceremonyExactOptions?.length > 0 && !weddingId &&
                  <div style={{flex:1}} className='floating_inputs_wrapper mb-20 d-none d-md-block'>
                      <label htmlFor='exactCeremonyLocation' style={{zIndex:9}} className='signup-input-label-special'>
                          Exact Location for Ceremony
                      </label>
                      <MyCustomDropdown                       
                          id='ceremonyexact'
                          type='exactCeremonyLocation'
                          onChange={handleDropdownChange}
                          value={exactCeremonyLocation}
                          items={ceremonyExactOptions}
                          placeholder='Exact location for ceremony...'
                      />
                  </div>
                  }
                  
              {(ceremonyExactOptions?.length > 0 && !receptionExactOptions?.length > 0)  &&
                  <div style={{flex:1}} className='floating_inputs_wrapper d-none d-xxl-flex'>
                      <label style={{display:'none'}} htmlFor='receptionVenue' className='signup-input-label-special'>
                          Reception Venue
                      </label>
                      <input 
                      style={{width:"100%", display:'none'}}
                          name='receptionVenue'
                          type='text'
                      />
                  </div>
              }

                  {receptionExactOptions?.length > 0 && !weddingId &&
                  <div style={{flex:1}} className='floating_inputs_wrapper mb-20 white_background_select'>
                      <label htmlFor='exactReceptionLocation' style={{zIndex:1}} className='signup-input-label-special white'>
                          Exact Location for Reception
                      </label>
                      <MyCustomDropdown 
                          type='exactReceptionLocation'
                          onChange={handleDropdownChange}
                          items={receptionExactOptions}
                          placeholder='Exact location for reception...'
                      />
                  </div>
                  }
              </div>
          </div>
          
      <div className='my_custom_container' style={{marginTop:30}}>
              <div className='font22 bold600'>
              Couple’s first names (or first initial)
              </div>
              <div style={{marginTop:30, gap:15}} className='d-md-flex flex-wrap'>
                  <div style={{flex:1}} className='floating_inputs_wrapper  mb-20 d-flex'>
                      <label htmlFor='partner1' className='signup-input-label-special'>
                          Partner 1
                      </label>
                      <input
                          style={{
                              width:"100%", 
                              // margin:'0 10px'
                          }}
                          onChange={(e)=>setPartner1(e.target.value)}
                          value={partner1}
                          name='partner1'
                          type='text'
                          placeholder='First name...'
                      />
                  </div>
                  <div style={{flex:1}} className='floating_inputs_wrapper  mb-20 d-flex'>
                      <label htmlFor='partner2' className='signup-input-label-special'>
                          Partner 2
                      </label>
                      <input 
                          style={{width:"100%"}}
                          onChange={(e)=>setPartner2(e.target.value)}
                          value={partner2}
                          name='partner2'
                          type='text'
                          placeholder='First name...'
                      />
                  </div>
                  <div style={{flex:1}} className='floating_inputs_wrapper  mb-20 d-none d-xxl-flex'>
                      <label style={{display:"none"}} className='signup-input-label'>
                          Partner 2
                      </label>
                      <input
                          style={{width:"100%",display:"none"}}
                          type='text'
                      />
                  </div>
              </div>
          </div>
          {!userId &&
          <>
          <div className='font22 bold600 my_custom_container'>
              Photographer Credit
          </div>
              <div style={{marginTop:30, gap:15}} className='d-md-flex flex-wrap my_custom_container_stepone'>
                <div style={{flex:1}} className='floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding'>
                    <label htmlFor='businessName' className='signup-input-label-special'>
                        Business Name
                    <span className="required-star">*</span>
                    </label>
                    <input 
                        onChange={(e)=> {e.persist(); setPhotographer(prev => ({...prev, business_name: e.target?.value})); searchPhotographers(e); setShowPhotographerResults(true) }}
                        value={photographer.business_name}
                        autocomplete="off"
                        placeholder='Business name...'
                        style={{
                            width:"100%", 
                            // margin:'0 10px'
                        }}
                        name='businessName'
                        type='text'
                    />
                    {showPhotographerResults && photographerResults?.length > 0 &&
                        <div className='search-results'>
                            {photographerResults.map(v => <div onClick={() => {setShowPhotographerResults(false); setPhotographer(v);}} key={v.id}>{v.business_name}</div>)}
                        </div>
                    }
                </div>
                <div style={{flex:1}} className='floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding'>
                    <label htmlFor='businessName' className='signup-input-label-special'>
                        Email
                    <span className="required-star">*</span>
                    </label>
                    <input 
                        onChange={(e)=> { e.persist(); setPhotographer(prev => ({...prev, email: e.target.value})); }}
                        value={photographer.email}
                        autocomplete="off"
                        placeholder='Email...'
                        style={{
                            width:"100%", 
                            // margin:'0 10px'
                        }}
                        name='email'
                        type='text'
                    />
                </div>
                </div>
                
              <div style={{marginTop:30, gap:15}} className='d-md-flex flex-wrap my_custom_container_stepone'>
                <div style={{flex:1}} className='floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding'>
                    <label htmlFor='businessName' className='signup-input-label-special'>
                        Website
                    <span className="required-star">*</span>
                    </label>
                    <input 
                        onChange={(e)=> {e.persist(); setPhotographer(prev => ({...prev, website_url: e.target?.value})); }}
                        value={photographer.website_url}
                        autocomplete="off"
                        placeholder='Website...'
                        style={{
                            width:"100%", 
                            // margin:'0 10px'
                        }}
                        name='website'
                        type='text'
                    />
                </div>
                <div style={{flex:1}} className='floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding'>
                    <label htmlFor='businessName' className='signup-input-label-special'>
                        Instagram
                    <span className="required-star">*</span>
                    </label>
                    <input 
                        onChange={(e)=> {e.persist(); setPhotographer(prev => ({...prev, instagram_url: e.target?.value})); }}
                        value={photographer.instagram_url}
                        autocomplete="off"
                        placeholder='Instagram full link...'
                        style={{
                            width:"100%", 
                            // margin:'0 10px'
                        }}
                        name='instagram'
                        type='text'
                    />
                </div>
              </div>
              <div style={{margin: '32px 0px', display: 'flex'}}>
                  <div style={{marginRight: '16px'}}>Are you the photographer?</div>
                <div className='radio-wrapper' style={{display: 'flex', columnGap: '10px'}}>
                  <div onClick={() => setIsPhotographer(true)}><img src={ isPhotographer ? RadioCircleFilled : RadioCircle} /> Yes</div>
                  <div onClick={() => setIsPhotographer(false)}><img src={ isPhotographer === false ? RadioCircleFilled : RadioCircle} /> No</div>
                </div>
              </div>
            </>
          }
          <div className='' style={{textDecoration: 'underline', fontSize: '22px', marginBottom: '32px', fontFamily: 'Montserrat', fontWeight: '600'}}>Optional: Tag <i>vendors</i> and <i>keywords</i> to get more visibility</div>
          <div className='my_custom_container' style={{paddingBottom: '24px', marginBottom: '24px', borderBottom: '1px solid #C0BDBD'}}>
              <div onClick={() => setShowTags(prev => !prev)} className='font18 bold600' style={{cursor: 'pointer'}}>
                  Select tags to help us choose weddings to feature (Optional)  
                  <img alt="" style={{width: '18px', marginLeft: '16px'}} src={DownArrow} />
              </div>
          {showTags &&
              <div className='keyword_container'>
                  {
                      allKeywords.map((item, index)=>(
                          <div key={index} onClick={() => setKeywords(prev => prev.includes(item) ? prev.filter(i => i !== item) : [...prev, item])} className={`noselect keyword_box ${keywords.includes(item)?" create-active":""}`}>
                              {item}
                          </div>
                      ))
                  }
              </div>
        }
          </div>
          <div className='my_custom_container' style={{borderBottom: '1px solid #C0BDBD', paddingBottom: '24px'}}>
              <div onClick={() => setShowVendors(prev => !prev)} className='font18 bold600' style={{cursor: 'pointer'}}>
              Tag this wedding's vendors to show on their page (Optional)
                  <img alt="" style={{width: '18px', marginLeft: '16px'}} src={DownArrow} />
              </div>
          {showVendors &&
              <div style={{marginTop:30, marginBottom:30}}>
              We’ll email any vendors and encourage them to share your wedding. 
                  {
                      Object.keys(vendorsFormKeys2).map((item)=>(
                        <div>
              
                        {resultsActiveOn &&
                            <div className='overlay transparent' onClick={() => setResultsActiveOn('')}></div>
                        }
                        <div style={{marginTop:15}} className='font18 bold600'>
                            {vendorsFormKeys2[item].header}
                        </div>
                        <div style={{marginTop:15}} className='d-flex '>
                            <div className='vendor_img_container'>
                                <img 
                                    src={vendorsFormKeys2[item].icon}
                                    alt={`${vendorsFormKeys2[item].header} logo`}
                                />
                            </div>
                            <div style={{ gap:15, flex:1}} className='d-md-flex flex-wrap'>
                                <div style={{flex:1}} className='floating_inputs_wrapper mb-20 mb-md-0 d-flex'>
                                    <label htmlFor={`vendorName`} className='signup-input-label-special'>
                                        Vendor Name
                                    </label>
                                    <input 
                                        style={{
                                            width:"100%", 
                                            // margin:'0 10px'
                                        }}
                                        onChange={(e)=>updateVendorName(item, e.target.value)}
                                        value={ vendorsFormKeys2[item].vendorName}
                                        autocomplete="off"
                                        name={`vendorName`}
                                        type='text'
                                        placeholder='Vendor name...'
                                    />
                                    {resultsActiveOn === item.name && vendorsFormKeys2[item].searchResults.length > 0 &&
                                    <div className='search-results'>
                                        {vendorsFormKeys2[item].searchResults.map(v => <div onClick={() => {setResultsActiveOn(''); updateVendorName(item, v.business_name, true);}} key={v.id}>{v.business_name}</div>)}
                                    </div>
                                    }
                                </div>
                                <div style={{flex:1}} className='floating_inputs_wrapper d-flex'>
                                    <label htmlFor={`vendorEmail`} className='signup-input-label-special'>
                                        Vendor Email (Optional)
                                    </label>
                                    <input 
                                        style={{width:"100%"}}
                                        onChange={(e)=>updateVendorEmail(item, e.target.value)}
                                        value={vendorsFormKeys2[item].vendorEmail}
                                        autocomplete="off"
                                        name={`vendorEmail`}
                                        type='text'
                                        placeholder='Vendor email...'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                      ))
                  }
              </div>
          }
          </div>
          <div style={{textAlign: 'right', marginBottom: '32px'}}>
            {Object.values(photos).length > 0 && (!thumbnails.length || thumbnails.length < 3) && <span style={{color: 'red', fontFamily: 'Montserrat', fontSize: '14px'}}>Select 3 thumbnail photos before you can publish!</span>}
            <button style={{marginLeft: '16px'}} onClick={(e) => submitForm(e)} className='publish-button' disabled={!weddingDate || !partner1 || ((!photographer.business_name || !photographer.email || !photographer.website_url || !photographer.instagram_url) && !userId && !weddingId) || (thumbnails.length < 3 && !weddingId)}>{weddingId ? 'Update' : 'Publish'} Album</button>
          </div>
          </div>
      </div>
    </>
  );
};

export default UploadPage