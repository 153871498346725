import React, { useEffect, useState } from "react"
import axios from 'axios'
import { AlbumsList } from '../AlbumsPage/AlbumsList'

export default function Albums({ vendor }) {
    const [ albums, setAlbums ] = useState([])
    useEffect(() => {
        axios.get(`/api/vendor-albums/${vendor.id}`)
        .then(r => setAlbums(r.data.weddings))
    }, [])
    return (
            <div style={{maxWidth: '1200px'}} className='vendor-dashboard-card'>
            <div style={{paddingLeft: '55px'}} className='vendor-dashboard-card-header'>Your Tagged Albums</div>
                {albums.length === 0 && <div className='vendor-dashboard-no-photos'>You don't have any tagged albums right now.</div>}
                <AlbumsList hideCreate={true} list={albums} />
            </div>
    )
}