import React, { useEffect, useState } from "react";
import axios from "axios";
import ModalWindow from './modal.component'
import EditPhotographer from "./edit-photographer.component";

const columns = ({ locations, data = {}, styles=[], setPhotographerEditing=null }) => [
        {
            title: 'ID',
            field: 'id',
            sort: true,
            value: (value) => <a target='_blank' href={`https://wedmatch.com/wedding-photographers/${data.business_name?.replace(/,/g, "").replace(/&/g, "").replace(new RegExp('/', 'g'), '').replace(/  /g, " ").replace(/ /g, "-").replace(/'/g, "").replace(/\+/g, "").replaceAll(/\./g, '')}`}>{value}</a>
        },
        {
            title: 'Edit',
            field: 'id',
            sort: true,
            value: (value) => 
                <a href="#" onClick={() => setPhotographerEditing(value)}>Edit</a>
        },
        {
            title: 'Business Name',
            field: 'business_name',
            sort: true,
            value: (value) => <a target='_blank' href={`/dashboard/${data.id}/photos`}>{value}</a>
        },
        {
            title: 'Primary Location',
            field: 'primary_location',
            value: (value) => locations.find(l => l.id == value)?.name,
            sort: true,
        },
        {
            title: 'Secondary Location',
            field: 'secondary_location',
            value: (value) => locations.find(l => l.id == value)?.name,
            sort: true,
        },
        {
            title: 'Third Location',
            field: 'third_location',
            value: (value) => locations.find(l => l.id == value)?.name,
            sort: true,
        },
        {
            title: 'Booking Rate',
            field: 'booking_rate',
            value: (value) => parseFloat(value).toFixed(2),
            sort: true,
        },
        {
            title: 'Top 5',
            field: 'times_in_top_five',
            sort: true,
        },
        {
            title: 'Top 10',
            field: 'times_in_top_ten',
            sort: true,
        },
        {
            title: 'Average Rank',
            field: 'average_rank',
            sort: true,
        },
        {
            title: '% in Top 5',
            field: 'percent_in_top_five',
            sort: true,
        },
        {
            title: 'Style',
            field: 'photo_style_fkey',
            value: (value) => styles.find(s => s.id == value)?.description,
            sort: true,
        },
        {
            title: 'Stars',
            field: 'stars',
            sort: true,
        },
        {
            title: 'Reviews',
            field: 'reviews',
            sort: true,
        },
        {
            title: 'Zip',
            field: 'zip_code',
            sort: true,
        },
        {
            title: 'Profile Complete',
            field: 'profile_complete',
            value: (value) => <span style={{color: value ? 'green' : 'red'}}>{value ? 'true' : 'false'}</span>,
            sort: true,
        },
        {
            title: 'Photos Complete',
            field: 'photos_complete',
            value: (value) => <span style={{color: value ? 'green' : 'red'}}>{value ? 'true' : 'false'}</span>,
            sort: true,
        },
        {
            title: 'Info Complete',
            field: 'info_complete',
            value: (value) => <span style={{color: value ? 'green' : 'red'}}>{value ? 'true' : 'false'}</span>,
            sort: true,
        },
        {
            title: 'Personality Complete',
            field: 'personality_complete',
            value: (value) => <span style={{color: value ? 'green' : 'red'}}>{value ? 'true' : 'false'}</span>,
            sort: true,
        },
        {
            title: 'Email',
            field: 'email',
            sort: true,
        },
        {
            title: 'Instagram',
            field: 'instagram_url',
            value: (value) => <a href={value} target="_blank">{value}</a>,
            sort: true,
        },
        {
            title: 'Weekend On 7',
            field: 'weekendon7',
            sort: true,
        },
        {
            title: 'Calendar Update',
            field: 'calendar_last_updated',
            value: (value) => <span>{value?.substring(0,10)}</span>,
            sort: true,
        },
        {
            title: 'Full Account Requested',
            field: 'requested_full_account',
            value: (value) => <span style={{color: value ? 'green' : 'red'}}>{value ? 'true' : 'false'}</span>,
            sort: true,
            filter: true,
            options: ['true']
        },
        {
            title: 'Account Type',
            field: 'account_type',
            filter: true,
            options: ['basic_photographer', 'photographer'],
            sort: true,
        },
        {
            title: '',
            field: 'business_name',
            value: (value) => <a target='_blank' href={`https://wedmatch.com/wedding-photographers/${value?.replace(/,/g, "").replace(/&/g, "").replace(new RegExp('/', 'g'), '').replace(/  /g, " ").replace(/ /g, "-").replace(/'/g, "").replace(/\+/g, "").replaceAll(/\./g, '')}`}>https://wedmatch.com/wedding-photographers/{value?.replace(/,/g, "").replace(/&/g, "").replace(new RegExp('/', 'g'), '').replace(/  /g, " ").replace(/ /g, "-").replace(/'/g, "").replace(/\+/g, "").replaceAll(/\./g, '')}</a>
        },
    ]

const presets = [
    {
        name: 'Top Active',
        filters: [{ property: 'profile_complete', value: true }],
        sortProperty: 'booking_rate',
        sortAsc: false
    },    
    {
        name: 'New Requests',
        filters: [{ property: 'requested_full_account', value: true }],
        sortProperty: 'id',
        sortAsc: false
    },
]
const PhotographerList = () => {
	const [photographers, setPhotographers] = useState([])
    const [locations, setLocations] = useState([])
    const [styles, setStyles ] = useState([])
    const [ sortProperty, setSortProperty ] = useState('id')
    const [ filters, setFilters ] = useState([])
    const [ activeColumns, setActiveColumns ] = useState(columns({ locations: []}).map(c => c.title))
    const [ sortAsc, setSortAsc ] = useState(true)
    const [ photographerEditing, setPhotographerEditing ] = useState(null)
	useEffect(() => {
        
        document.title="Photographer List | WedMatch"    
        axios.get('/auth/getuser')
        .then(r => {
            if(!r.data.admin){
                window.location="/login"
            }
        })
        axios.post('/api/get-photographers', { filters, sort: sortProperty, sortAsc })
        .then(r => {
            setPhotographers(r.data)
        })
        axios.get('/api/locations')
        .then(r => {
            setLocations(r.data)
        })
        axios.get('/api/styles')
        .then(r => {
            setStyles(r.data)
        })
    }, [])

    useEffect(() => {        
        setPhotographers([])
        axios.post('/api/get-photographers', { filters, sort: sortProperty, sortAsc })
        .then(r => {
            setPhotographers(r.data)
        })
    }, [sortProperty, filters, sortAsc])

    const changeFilter = (e, field) => {
        const value = e.target?.value
        if (value) {
            let arr = [...filters]
            if (filters.some(f => f.property === field)) {
                arr = arr.filter(p => p.property !== field)
            }
            setFilters([...arr, { property: field, value }])
        } else {
            setFilters(prev => [...prev.filter(p => p.property !== field)])
        }
    }

    const updateSort = (property) => {
        if (property === sortProperty) {
            setSortAsc(prev => !prev)
        } else {
            setSortProperty(property)
            setSortAsc(true)
        }
    }

    const applyPreset = (preset) => {
        if (preset) {
            setSortProperty(preset.sortProperty)
            setFilters(preset.filters)
            setSortAsc(preset.sortAsc)
        } else {
            setSortProperty('id')
            setFilters([])
            setSortAsc(true)
        }
    }
	return (
        <div>
            <select onChange={(e) => applyPreset(presets.find(p => p.name == e.target.value))}
                defaultValue=''>
                    <option value=''>--Presets--</option>
                {presets.map(p => <option value={p.name}>{p.name}</option>)}
            </select>
            {photographerEditing && <ModalWindow 
                Child={<EditPhotographer 
                    styles={styles}
                    photographer={photographers.find(p => p.id == photographerEditing)} 
                    locations={locations} />}
                close={() => setPhotographerEditing(null)}
                     />}
            <div style={{marginBottom: '16px'}} className='filters'>
            {columns({ locations, styles }).filter(c => c.filter).map(c =>
                <select value={filters.find(f => f.property === c.field)?.value ?? ''} onChange={(e) => changeFilter(e, c.field)} key={c.field}>
                    <option value=''>{c.title}</option>
                    {c.options.map(o => <option value={o}>{o}</option>)}
                </select>    
            )}
            </div>
		<table style={{textAlign: 'center'}}>
            <tr>
                {locations.length > 0 && columns({ locations }).filter(c => 
                    activeColumns.includes(c.title)).map(c => 
                        <th><div style={{cursor: c.sort ? 'pointer' : 'default'}} onClick={() => updateSort(c.field)}>{c.title}</div>
                            <div style={{cursor: 'pointer', color: 'red'}} onClick={() => setActiveColumns([...activeColumns.filter(ac => ac != c.title)])}>x</div>
                        </th>
                    )}
            </tr>
            {locations.length > 0 && photographers.map(p =>
            <tr key={p.id}>{columns({ locations, data: p, styles, setPhotographerEditing }).filter(c => activeColumns.includes(c.title)).map(c => <td>{c.value ? c.value(p[c.field]) : p[c.field]}</td>)}</tr>
            )}
        </table>
        </div>
	);
};

export default PhotographerList;
