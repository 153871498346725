export const calculateVenueScore = (photographer, weddings) => {
    const numberOfWeddings = weddings.filter(w => w == photographer).length
    if (numberOfWeddings === 0) {
        return 0
    } else if (numberOfWeddings > 1) {
        return 100
    } else {
        return 80
    }
  }
export const calculateLocationScore = (location, photographer, hours) => {
    if (photographer.primary_location == location) {
        return 100
    } else if (photographer.secondary_location == location || photographer.third_location == location) {
        if (!hours || hours > 6) {
            return 50
        }
        return 50 * (1 - 1/hours)
    } else {
        return 0
    }
  }

  export const calculateVendorLocationScore = (location, photographer, hours) => {
    if (photographer.primary_location == location) {
        return 100
    } else if (photographer.secondary_location == location || photographer.third_location == location) {
        if (!hours || hours > 6) {
            return 80
        }
        return 50 * (1 - 1/hours)
    } else {
        return 80
    }
  }

export const calculateStyleScore = (photoStyles, style) => {
    const scoring = [100, 66, 33, 0]
    if (!photoStyles.length) {
        return 0
    }
    if (photoStyles.some(s => !s.rank || !style)) {
        return 0
    } else {
        const rank = parseInt(photoStyles.find(s => s.id == style).rank)
        return scoring[rank - 1]
    }
  }

export const calculateBudgetScore = (selectedBudgets, photographer, hours) => {
    const stepPercentage = 0.1
    const pointsPerStepBelow = 10
    const pointsPerStepAbove = 25
      if(selectedBudgets.length){
          selectedBudgets.sort((a, b) => a > b ? -1 : 1)
          const maxBudget = budgets.find(bu => bu.id == selectedBudgets[0]).max
          const minBudget = budgets.find(bu => bu.id == selectedBudgets[selectedBudgets.length - 1]).min
          const rate = photographer[`weekendon${hours}`]
          if (rate < minBudget) {
            const stepsBelow = (minBudget - rate) / (minBudget*stepPercentage)
            return 100 - stepsBelow * pointsPerStepBelow
          } else if (rate > maxBudget) {
            const stepsAbove = (rate - maxBudget) / (maxBudget*stepPercentage)
            return 100 - stepsAbove * pointsPerStepAbove
          } else {
            return 100
          }
      } else {
        return 0
      }
  }

  export const calculateVendorBudgetScore = (selectedBudgets, photographer, hours) => {
    const stepPercentage = 0.1
    const pointsPerStepBelow = 10
    const pointsPerStepAbove = 25
      if(selectedBudgets.length){
          selectedBudgets.sort((a, b) => a > b ? -1 : 1)
          const maxBudget = budgets.find(bu => bu.id == selectedBudgets[0]).max
          const minBudget = budgets.find(bu => bu.id == selectedBudgets[selectedBudgets.length - 1]).min
          const rate = photographer[`weekendon${hours}`]
          if (rate < minBudget) {
            const stepsBelow = (minBudget - rate) / (minBudget*stepPercentage)
            return 100 - stepsBelow * pointsPerStepBelow
          } else if (rate > maxBudget) {
            const stepsAbove = (rate - maxBudget) / (maxBudget*stepPercentage)
            return 100 - stepsAbove * pointsPerStepAbove
          } else {
            return 100
          }
      } else {
        return 0
      }
  }

export const calculatePreferenceScore = (photographer, preference) => {
    if(preference == 'fly'){
        return (4 - photographer.fly)*33.3333333
    } else if(preference == 'whatever'){
        return (4 - photographer.ask)*33.3333333
    } else if(preference == 'charge'){
        return (4 - photographer.charge)*33.3333333
    } else if(preference == 'relax'){
        return (4 - photographer.relax)*33.3333333
    }
  }

export const calculateTagScore = (photographerId, bookedTags, selectedTags) => {
    const namedTags = selectedTags.map(t => importantTagMap.find(t2 => t2.id == t).name)
    const filtered = bookedTags.filter(t => t.photographer_fkey == photographerId).map(p => p.tags)
    const found = filtered.flat().filter(f => namedTags.includes(f)).length
    if (found > 4) return 100
    if (found > 3) return 90
    if (found > 2) return 80
    if (found > 1) return 70
    if (found > 0) return 60
    return 0
  }
export const calculateAttributeScores = (attributeRanges, attributes) => {
    return {
        flexible: attributes ? attributes.flexible / attributeRanges.flexible * 100 : 0,
        creative: attributes ? attributes.creative / attributeRanges.creative * 100 : 0,
        communicative: attributes ? attributes.communicative / attributeRanges.communicative * 100 : 0,
        experienced: attributes ? attributes.experienced / attributeRanges.experienced * 100 : 0,
        talented: attributes ? attributes.talented / attributeRanges.talented * 100 : 0,
        candid: attributes ? attributes.candid / attributeRanges.candid * 100 : 0,
        friendly: attributes ? attributes.friendly / attributeRanges.friendly * 100 : 0,
    }
  }

export const calculateRankScore = (photographer) => {
    const { average_rank } = photographer
    if (!average_rank) {
        return 0
    }
    if (average_rank > 6) {
        return 0
    } else {
        return (7 - average_rank) * 16.6
    }

}

  const budgets = [
    {
        id: 1, // $0-2,000
        min: 0,
        max: 2000,
        displayedMax: 2000
    },
    {
        id: 2, // $2,001-$3,000
        min: 1700,
        max: 3000,
        displayedMax: 3000
    },
    {
        id: 3, // $3,001-$4,000
        min: 2700,
        max: 4000,
        displayedMax: 4000
    },
    {
        id: 4, // $4,001 - $6,000
        min: 3700,
        max: 6000,
        displayedMax: 6000
    },
    {
        id: 5, // $6,000+
        min: 5700,
        max: 100001,
        displayedMax: null
    },
  ]

  const importantTagMap = [
    {
        id: 1,
        name: 'LGBTQ+'
    },
    {
        id: 2,
        name: 'People of Color'
    },
    {
        id: 3,
        name: 'Indian/Cultural'
    }
  ]
