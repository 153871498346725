import React, { useMemo } from "react";
import axios from 'axios';

export default function Info({ vendor, setVendor, originalValues }) {
    console.log(vendor)

    const handleChange = e => {
        setVendor({...vendor, [e.target.name]: e.target.value })
    }

    const handleSubmit = () => {
        // if (vendor.id) {
        //     axios.post('/api/update-vendor-info', {...vendor, vendorId: vendor.id})
        //     .then(r => window.location = window.location)
        // }
    }

	const uploadPhoto = (e) => {
		const formData = new FormData();
		formData.append("file", e.target.files[0]);
		formData.append("upload_preset", "avatar");
		axios.post("https://api.cloudinary.com/v1_1/dfjwlskop/image/upload", formData).then((p) => {
            console.log(p)
			const data = {
				image_url: p.data.secure_url,
                height: p.data.height,
                width: p.data.width,
                vendorId: vendor.id
			};
			setVendor({...vendor, 'profile_photo_url': p.data.secure_url})
			axios.post("/api/update-profile-image", {...data, vendorId: vendor.id}).then((r) => {
			});
		});
	}
    const changed = useMemo(() => {
        let ch = false
        fields.forEach(f => {
            if (originalValues[f.property] !== vendor[f.property]) {
                ch = true
            }
        })
        return ch
    }, [originalValues, vendor.first_name, vendor.last_name, vendor.business_name, vendor.website_url, vendor.instagram_url, vendor.zip_code, vendor.contact_email])
    return (
        <div className='vendor-dashboard-card'>
            <div className='vendor-dashboard-card-header'>Edit Profile</div>
            <div className='profile-photo-wrapper' style={{marginBottom: '16px'}}>
                <img style={{width: '72px', marginRight: '16px'}} src={vendor.profile_photo_url}/>
                <input
                        id='file-upload'
                        className="upload-button"
                        type="file"
                        name="image"
                        onChange={uploadPhoto}
                        style={{display: 'none'}}
                    />
                    <label className='vendor-dashboard-button vendor-dashboard-button-primary' style={{cursor: 'pointer', marginRight: '8px'}} for="file-upload" >Change Picture</label>
            </div>
            <div className='vendor-dashboard-text-inputs' style={{marginBottom: '24px'}}>
                {fields.map(field =>
                    <div key={field} className='vendor-dashboard-text-input-wrapper'>
                        <label for={field.property}>{field.label}</label>
                        <input name={field.property} onChange={handleChange} value={vendor[field.property]} placeholder={field.placeholder ?? field.label} />
                    </div>
                )}
            </div>
            <button disabled={!changed} onClick={handleSubmit} className='vendor-dashboard-button vendor-dashboard-button-primary vendor-dashboard-button-save'>Save Changes</button>
        </div>
    )
}

const fields = [
    {
        label: 'Business Name',
        property: 'business_name'
    },
    {
        label: 'Website URL',
        property: 'website_url'
    },
    {
        label: 'First Name',
        property: 'first_name'
    },
    {
        label: 'Last Name',
        property: 'last_name'
    },
    {
        label: 'Phone Number',
        property: 'phone_number'
    },
    {
        label: 'Business Location ZIP Code',
        placeholder: 'ZIP Code',
        property: 'zip_code'
    },
    {
        label: 'Instagram URL',
        property: 'instagram_url'
    },
    {
        label: 'Contact Email',
        property: 'contact_email'
    },
]