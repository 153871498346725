import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
// import NavbarNew from './Dashboard/navbarnew.component';
import NavbarNew from './Dashboard/navbarnew.component';



const ChangePasswordModal = ({ isOpen, onClose, onSubmit }) => {
  const [success, setSuccess] = useState(false)
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setPasswordError('');

    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    let accessToken = localStorage.getItem('auth_token');
    let refreshToken = localStorage.getItem('refresh_token');

    try {
      const passwordResponse = await axios.post('/api/change-password/', {
        accessToken: accessToken,
        refreshToken: refreshToken,
        newPassword: confirmPassword
      });
      if (passwordResponse.data.error) {
        setError(passwordResponse.data.error || 'An error occurred while updating the password');
        throw new Error(passwordResponse.data.error || 'Error updating password');
      }
      setSuccess(true);

    } catch (error) {
      if (error.response) {
        console.error('Server error:', error.response.data);
        setError(error.response.data.error || 'An error occurred on the server');
      } else if (error.request) {
        console.error('No response from server:', error.request);
        setError('No response from the server. Please try again.');
      } else {
        console.error('Error in request setup:', error.message);
        setError(error.message || 'An error occurred while updating the password');
      }
    }
  };


  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white w-full max-w-md mx-auto rounded-xl px-8 py-3 space-y-4">

        { success ?
          <div className="p-3">
            <div className="w-full flex justify-end">
              <button onClick={onClose} className="mb-4">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 1.5L1.5 10.5M1.5 1.5L10.5 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
            <p className="text-xl font-semibold">
              Password has been changed
            </p>
          </div>
          :
          <div>
            <h3 className="text-xl font-medium text-center mb-3">Change Password</h3>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="space-y-3">
                <input
                  type="password"
                  name="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full pl-3 pr-2 py-[14px] border rounded-md"
                  required
                />
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full pl-3 pr-2 py-[14px] border rounded-md"
                  required
                />
                {passwordError && <p className="text-red-500">{passwordError}</p>}
              </div>

              {error && <p className="w-[90%] text-red-500 text-left mb-4">{error}</p>}

              <div className="flex justify-end space-x-3">
                <button type="button" onClick={onClose} className="px-4 py-2 border rounded-full">Cancel</button>
                <button type="submit" className="px-4 py-2 bg-greenblue text-white rounded-full">Submit</button>
              </div>
            </form>
          </div>
        }

      </div>
    </div>
  );
};



const DeletePasswordModal = ({ isOpen, onClose }) => {
  const [success, setSuccess] = useState(false)
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const history = useNavigate();


  const deleteUser = async (e) => {
    const token = localStorage.getItem('auth_token')
    const userResponse = await axios.get('/api/supa-auth-test', {
      headers: { Authorization: token },
    });

		let userId = userResponse?.data?.data?.user?.id

    try {
      const response = await axios.post('/api/supa-delete-user', { userId });
      if (response) {
        onClose()
        history("/login")
      }

      if (response.data.error) {
        console.error('Error deleting user:', response.data.error);
        return;
      }

    } catch (error) {
      console.error('Error making request:', error);
    }
  };


  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="flex flex-col items-center bg-white w-[35%] mx-auto rounded-xl px-16 pt-10 pb-10 space-y-4">
        <button onClick={onClose} className="w-full flex justify-end mb-4">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 1.5L1.5 10.5M1.5 1.5L10.5 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
        <p className="mb-6 text-center text-xl font-medium">Are you sure you want to delete your account? This action cannot be undone.</p>

        <button onClick={() => deleteUser()} className="px-4 py-3 rounded-full bg-greenblue text-white">Delete Your Account</button>
      </div>
    </div>
  );
};



const AccountManagement = () => {
  const [openChangePassword, setOpenChangePassword] = useState(false); // State for modal
  const [openDeletePassword, setOpenDeletePassword] = useState(false)
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState('')
  const [updateSuccess, setUpdateSuccess] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const history = useNavigate();


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const fetchData = async (e) => {
    const token = localStorage.getItem('auth_token');

    if (!token) {
      history("/login");
    } else {
      const userResponse = await axios.get('/api/supa-auth-test', {
        headers: { Authorization: token },
      });

      if (!userResponse.data.user === null) {
        history("/login");
      }

      let userData = userResponse?.data?.data?.user?.user_metadata;

      if (!userData) {
        history("/login")
      }

      if (userData) {
        setFormData(prevState => ({
          ...prevState,
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          email: userData.email || '',
        }));
      }
    }
  };


  const logOut = () => {
    localStorage.clear()
    window.location.reload();
  }


  const handlePasswordSubmit = async (newPassword) => {
    setOpenChangePassword(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    let accessToken = localStorage.getItem('auth_token');
    let refreshToken = localStorage.getItem('refresh_token');


    try {
      const updateUserResponse = await axios.post('/api/update-user/', {
        accessToken: accessToken,
        refreshToken: refreshToken,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email
      });

      if (updateUserResponse.data.error) {
        setError(updateUserResponse.data.error || 'An error occurred while updating the password');
        throw new Error(updateUserResponse.data.error || 'Error updating password');
      }
      setEdit(false)
      setUpdateSuccess(true);

    } catch (error) {
      if (error.response) {
        setError(error.response.data.error || 'An error occurred on the server');
      } else if (error.request) {;
        setError('No response from the server. Please try again.');
      } else {
        setError(error.message || 'An error occurred while updating the password');
      }
    }
  };





  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
      <div className="w-full">
        <NavbarNew />
      </div>

      <div className="w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-14 pb-0 mt-5 rounded-3xl bg-white mobile:w-[90%]">
        <div className="w-full max-w-2xl mx-auto py-8 mobile:text-sm">
          <div className="px-16 mobile:px-3 mobile:mt-3">
            <h2 className="mb-3 text-2xl font-medium text-center mobile:text-xl">Manage Your Account</h2>
            <p className="text-center text-gray-600 mb-8">
              Update your account details below.
            </p>

            <div className="w-full flex justify-end mb-2 text-xs">
              <button onClick={() => setEdit(true)} className="underline">
                Edit
              </button>
            </div>

            <form onSubmit={handleSubmit} className={`${edit ? "" : "opacity-50"} space-y-4`}>
              <div className="flex space-x-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-1/2 pl-3 pr-2 py-[14px] border rounded-md"
                  disabled={!edit}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-1/2 pl-3 pr-2 py-[14px] border rounded-md"
                  disabled={!edit}
                  required
                />
              </div>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full pl-3 pr-2 py-[14px] border rounded-md"
                disabled={!edit}
                required
              />

              {edit &&
                <div className="flex justify-center">
                  <button
                    type="submit"
                    className="px-5 py-3 mt-[20px] rounded-full bg-greenblue text-white"
                  >
                    Save Changes
                  </button>
                </div>
              }
            </form>
            <div className="ml-0.5 mt-1.5 text-left cursor-pointer">
              <a onClick={() => setOpenChangePassword(true)} className="text-sm text-greenblue hover:underline mobile:text-xs">Change Password</a>
            </div>

            <p className="text-center text-sm mt-4 mobile:text-xs">

              <div className="mb-2.5 cursor-pointer">
                <a onClick={() => logOut()} className="text-sm text-greenblue hover:underline mobile:text-xs">Log Out</a>
              </div>


              <a onClick={() => setOpenDeletePassword(true)} className="text-red-700 hover:underline cursor-pointer">
                Delete My Wedmatch Account
              </a>
            </p>
          </div>
        </div>
      </div>

      <DeletePasswordModal
        isOpen={openDeletePassword}
        onClose={() => setOpenDeletePassword(false)}
      />

      {/* Render the change password modal */}
      <ChangePasswordModal
        isOpen={openChangePassword}
        onClose={() => setOpenChangePassword(false)}
        onSubmit={handlePasswordSubmit}
      />
    </div>
  );
};

export default AccountManagement;
