import React, { useState } from 'react'
import hamburger from '../../styles/dashboard-icons/hamburger.svg';
import logo from '../../styles/wedmatchlogo.png';
import axios from 'axios'
import {ReactComponent as Matches} from '../../styles/dashboard-icons/matches.svg';
import {ReactComponent as Photos} from '../../styles/dashboard-icons/photos.svg';
import {ReactComponent as Albums} from '../../styles/dashboard-icons/albums.svg';
import {ReactComponent as Pricing} from '../../styles/dashboard-icons/pricing.svg';
import {ReactComponent as Logout} from '../../styles/dashboard-icons/logout.svg';
import {ReactComponent as Profile} from '../../styles/dashboard-icons/profile.svg';
import './styles.css'
export default function Nav({ tab, setTab }) {
    const [ showNav, setShowNav ] = useState(false)
    
	const logout = () => {
		axios.post("/auth/logout");
		window.location = "/login";
	};

    return (
        <>
        <nav className='vendor-navbar vendor-navbar-desktop'>
            <div style={{textAlign: 'center', marginTop: '40px', marginBottom: '40px'}}><img src={logo} /></div>
            {navItems.map(n => <div onClick={() => { setTab(n.name); setShowNav(false)}} className={`vendor-nav-item ${tab === n.name ? 'selected' : ''}`}>{n.icon && <n.icon />}{n.name}</div>)}
            <div onClick={() => logout()} className={`vendor-nav-item`}><Logout />Logout</div>
        </nav>
        <div style={{background: 'white', marginBottom: '16px', padding: '12px 12px'}} className='mobile-only'>
        <div style={{textAlign: 'center'}} className='mobile-only'><img style={{position: 'absolute', left: '12px', top: '24px'}} onClick={() => setShowNav(prev => !prev)} src={hamburger} className='mobile-only' /><img style={{marginTop: '12px', width: '135px'}} src={logo} /></div>
        {showNav &&
        <nav className='vendor-navbar-mobile'>
            {navItems.map(n => <div onClick={() => { setTab(n.name); setShowNav(false)}} className={`vendor-nav-item ${tab === n.name ? 'selected' : ''}`}>{n.icon && <n.icon />}{n.name}</div>)}
            <div onClick={() => logout()} className={`vendor-nav-item`}><Logout />Logout</div>
        </nav>}
        </div>
        </>
    )
}

const navItems = [
    /*
    {
        name: 'Home',
        icon: home
    },
    */
    {
        name: 'My Profile',
        icon: Profile
    },
    {
        name: 'Calendar',
        icon: Profile
    },
    {
        name: 'Matches',
        icon: Matches
    },
    {
        name: 'Portfolio',
        icon: Photos
    },
    {
        name: 'Real Weddings',
        icon: Albums
    },
    {
        name: 'Pricing',
        icon: Pricing
    },
    {
        name: 'Preferred Vendors',
        icon: Matches
    },
]