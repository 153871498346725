import React from 'react'
import { uploadConfig } from "../../../utils/upload_config"

export const ErrorBanner = ({ type, total }) => {
    return(
        <div className='max_photos_warning'>
            {
                type==='max_photos'
                ?
                `You've chosen too many photos. 75 is maximum. Please delete atleast ${total-uploadConfig.MAX_NUMBER_OF_PHOTOS} photos.`
                :
                "Some photos are over 20MB. Please delete those photos to continue"

            }
        </div>
    )
}