import React from 'react'
import { MobileStepper } from './MobileStepper'
import { MyCustomStepper } from './Stepper'

export const CreatePageHeader = ({ currentStep, ...props }) => {
    return(
        <div className='my_custom_container d-md-flex justify-content-lg-between align-items-center flex-wrap' style={{marginBottom:50}}>
            <div style={{flex:1}} className='font24 bold700'>
                Create Album
            </div>

            {/* Shows only on large screens */}
            <div className='d-none d-lg-block'>
                <MyCustomStepper stepNumber={currentStep} {...props} />
            </div>

            {/* Shows only on small screens */}
            <div style={{flex:1}} className='d-block d-lg-none'>
                <div style={{margin:"10px 0"}} className="d-block d-md-none" />
                <MobileStepper stepNumber={currentStep} />
            </div>
        </div>
    )
}