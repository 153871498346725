import React from 'react';

const BasicPhotographer = () => {   
   
    return(
        <div>
           This is a placeholder page
        </div>
    )
}

export default BasicPhotographer;