import axios from "axios";
import React, { useEffect, useState } from "react";
import ModalWindow from './modal.component'
import EditVendor from "./edit-vendor.component";


const columns = ({ locations, data = {}, styles=[], setVendorEditing=null }) => [
    {
        title: 'Name',
        field: 'business_name'
    },        
    {
        title: 'ID',
        field: 'id'
    },      
    {
        title: 'Account Type',
        field: 'account_type'
    },        
    {
        title: 'Edit',
        field: 'id',
        sort: true,
        value: (value) => 
            <a href="#" onClick={() => setVendorEditing(value)}>Edit</a>
    }, 
    {
        title: 'Primary Location',
        field: 'primary_location',
        value: (value) => locations.find(l => l.id == value)?.name,
        sort: true,
    },
    {
        title: 'Secondary Location',
        field: 'secondary_location',
        value: (value) => locations.find(l => l.id == value)?.name,
        sort: true,
    },
    {
        title: 'Third Location',
        field: 'third_location',
        value: (value) => locations.find(l => l.id == value)?.name,
        sort: true,
    },
]
export default function VendorList() {
    const [ vendors, setVendors ] = useState([])
    const [locations, setLocations] = useState([])
    const [ vendorEditing, setVendorEditing ] = useState(null)
    useEffect(() => {
        axios.get('/api/vendor-accounts')
        .then(r => {
            console.log(r.data)
            setVendors(r.data)
        })
        document.title="Vendor List | WedMatch"    
        axios.get('/auth/getuser')
        .then(r => {
            if(!r.data.admin){
                window.location="/login"
            }
        })
        axios.get('/api/locations')
        .then(r => {
            setLocations(r.data)
        })
    }, [])
    return (
        <div>
            
            {vendorEditing && <ModalWindow 
                Child={<EditVendor 
                    vendor={vendors.find(p => p.id == vendorEditing)} 
                    locations={locations} />}
                close={() => setVendorEditing(null)}
                     />}
            <table>
                <tr>{columns({ locations }).map(c => <th key={c.title}>{c.title}</th>)}</tr>
                {vendors.map(v =>
                    <tr key={v.id}>{columns({ locations, data: v, setVendorEditing }).map(c => <td key={c.field}>{c.value ? c.value(v[c.field]) : v[c.field]}</td>)}</tr>
                )}

            </table>
        </div>
    )
}