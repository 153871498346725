import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";

const interestsData = [
	{
		id: 25,
		name: 'Hiking'
	},
	{
		id: 26,
		name: 'Cooking'
	},
	{
		id: 27,
		name: 'Dancing'
	},
	{
		id: 29,
		name: 'Concerts'
	},
	{
		id: 30,
		name: 'Art'
	},
	{
		id: 31,
		name: 'Pets'
	},
	{
		id: 32,
		name: 'Sports'
	},
	{
		id: 33,
		name: 'Reading'
	},
	{
		id: 34,
		name: 'Travel'
	},

]
const SelectBudget = () => {
    const [results, setResults] = useState([]);

    const queryParams = new URLSearchParams(window.location.search)
    const visible = queryParams.get("visible")

    useEffect(() => {

        document.title="Results | WedMatch"
        axios.get('/auth/getuser')
        .then(r => {
            if(!r.data.admin){
                window.location="/login"
            }
        })
        axios.get(`/api/temp-vendor-results/${visible ? 'visible' : 'all'}`)
        .then(result => {
            console.log(result.data)
            setResults(result.data)
        })


        }, [])
    const setVisible = (result, index) => {
    }

    const toggleContact = (result, index) => {
    }
    const Results = () => {
        return results.map((r, index) => {
            return(
                <tr>
                    {/* <td style={{border: '1px solid black', padding: '3px'}}><input type="checkbox" checked={r.photographers_visible} onChange={(e) => setVisible(r, index)} /></td> */}
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.email}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.month} {r.day}, {r.year}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.chosen_vendor_types.join(', ')}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.last_name}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.budget != 0 && r.budget}{(!r.budget || r.budget == 0) && r.selected_budgets && r.selected_budgets.map((b, index) => index < r.selected_budgets.length -1 ? b + ", " : b)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.hours_selected}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.style_ranking && r.style_ranking.map((s, index) => index != 3 ? s + ", " : s)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.interest_tags && <span>{r.interest_tags.map(i => {
                        return interestsData.find(int => int.id == i).name
                    }).join(', ')}</span>}</td>
                    {/* <td style={{border: '1px solid black', padding: '3px'}}>{r.attributes?.join(', ')}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.photographer_preference}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.how_important_getting_ready}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.how_important_ceremony}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.how_important_portraits}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.how_important_reception}</td>
                    <td onClick={() => toggleContact(r, index)}style={{border: '1px solid black', padding: '3px', cursor: 'pointer', textDecoration: 'underline'}}>{r.can_contact && 'true'}{!r.can_contact && 'false'}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.top_ten.length > 0 && (r.top_ten[0].images[0].business_name ?? r.top_ten[0].photographer?.business_name)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.top_ten.length > 1 && (r.top_ten[1].images[0].business_name ?? r.top_ten[1].photographer?.business_name)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.top_ten.length > 2 && (r.top_ten[2].images[0].business_name ?? r.top_ten[2].photographer?.business_name)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.top_ten.length > 3 && (r.top_ten[3].images[0].business_name ?? r.top_ten[3].photographer?.business_name)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.top_ten.length > 4 && (r.top_ten[4].images[0].business_name ?? r.top_ten[4].photographer?.business_name)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.top_ten.length > 5 && (r.top_ten[5].images[0].business_name ?? r.top_ten[5].photographer?.business_name)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.top_ten.length > 6 && (r.top_ten[6].images[0].business_name ?? r.top_ten[6].photographer?.business_name)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.top_ten.length > 7 && (r.top_ten[7].images[0].business_name ?? r.top_ten[7].photographer?.business_name)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.top_ten.length > 8 && (r.top_ten[8].images[0].business_name ?? r.top_ten[8].photographer?.business_name)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.top_ten.length > 9 && (r.top_ten[9].images[0].business_name ?? r.top_ten[9].photographer?.business_name)}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.created_at}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.booked ? 'Yes' : 'No'}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.referral_email}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.referral_code}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.personal_referral_code}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.location_name}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.venue_name}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.venue_vendor_fkey}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.no_venue && 'true'}{!r.no_venue && 'false'}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.private_venue && 'true'}{!r.private_venue && 'false'}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.photographers_style_1}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.photographers_style_2}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.photographers_over_budget}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.photographers_primary_city}</td>
                    <td style={{border: '1px solid black', padding: '3px'}}>{r.photographers_over_4_booking_rate}</td> */}
                </tr>

            )
        })
    }

    return(
        <div>
        <br /><br />
        <div><b>Results from those who finished the process NOT PHOTOGRAPHERS:</b></div>
        <table style={{overflowX: 'scroll'}}>
            <tr>
                {/* <th style={{border: '1px solid black', padding: '3px'}}>Visible</th> */}
                <th style={{border: '1px solid black', padding: '3px'}}>Email</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Date</th>
                <th style={{border: '1px solid black', padding: '3px'}}>First</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Last</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Budget</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Hours</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Style</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Interests</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Attributes</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Photog Preference</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Getting Ready</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Ceremony</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Portraits</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Reception</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Contact?</th>
                <th style={{border: '1px solid black', padding: '3px'}}>#1</th>
                <th style={{border: '1px solid black', padding: '3px'}}>#2</th>
                <th style={{border: '1px solid black', padding: '3px'}}>#3</th>
                <th style={{border: '1px solid black', padding: '3px'}}>#4</th>
                <th style={{border: '1px solid black', padding: '3px'}}>#5</th>
                <th style={{border: '1px solid black', padding: '3px'}}>#6</th>
                <th style={{border: '1px solid black', padding: '3px'}}>#7</th>
                <th style={{border: '1px solid black', padding: '3px'}}>#8</th>
                <th style={{border: '1px solid black', padding: '3px'}}>#9</th>
                <th style={{border: '1px solid black', padding: '3px'}}>#10</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Timestamp</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Booked</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Referral</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Referral Code</th>
                <th style={{border: '1px solid black', padding: '3px'}}>My Ref Code</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Location</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Venue Name</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Venue Vendor ID</th>
                <th style={{border: '1px solid black', padding: '3px'}}>No Venue</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Private Venue</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Style 1</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Style 2</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Over Budget</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Primary City</th>
                <th style={{border: '1px solid black', padding: '3px'}}>Over 4 BR</th>
            </tr>
        <Results />
        </table>
        </div>
    )
}

export default SelectBudget