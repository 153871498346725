import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import NavbarNew from ".././navbarnew.component";
import PhotographerIcon from "../../../styles/dashboard-icons/photographerIcon"
import FloristIcon from "../../../styles/dashboard-icons/floristIcon"
import VideographerIcon from "../../../styles/dashboard-icons/videographerIcon"
import djIcon from "../../../styles/dashboard-icons/djIcon"
import cakeDessertIcon from "../../../styles/dashboard-icons/cakeDessertIcon"
import hairMakeupIcon from "../../../styles/dashboard-icons/hairMakeupIcon"
import plannerIcon from "../../../styles/dashboard-icons/plannerIcon"
import UpArrow from  "../../../styles/dashboard-icons/upArrow"
import DownArrow from  "../../../styles/dashboard-icons/downArrow"
import SideBar from ".././sidebar-component"

import PhotographerCards from '.././photographer/photographerCards'
import VideographerCards from '.././videographer/videographerCards'
import FloristCards from  '.././florist/floristCards'
import DjCards from  '.././dj/djCards'
import PlannerCards from  '.././planner/plannerCards'
import BakerCards from  '.././baker/bakerCards'
import HairMakeupCards from  '.././hairMakeup/hairMakeupCards'

import { Mixpanel } from '../../../Mixpanel';

const Dashboard = () => {

  const [userData, setUserData] = useState()
  const [photogData, setPhotogData] = useState()
  const [firstName, setFirstName] = useState()
  const [vendorMode, setVendorMode] = useState('Photographer')
  const [blurScreen, setBlurScreen] = useState(false)
  const [weddingDetailsOpen, setWeddingDetailsOpen] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false)
  const history = useNavigate();


  const convertNumberToMonth = (monthNumber) => {
    // Since arrays are zero-indexed, subtract 1 from the monthNumber
    return monthNames[monthNumber - 1];
  };


  const calculateDaysBetween = () => {
    const futureDate = `${convertNumberToMonth(userData.month)} ${userData.day}, ${userData.year}`
    const today = new Date(); // Get today's date
    const future = new Date(futureDate); // Convert the future date string to a Date object

    // Calculate the difference in time (milliseconds)
    const diffInTime = future.getTime() - today.getTime();

    // Convert the time difference from milliseconds to days
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24)); // 1000ms * 3600s * 24hrs

    if (diffInDays === 1) {
      return (<span>{diffInDays} day</span>)
    } else {
      return (<span>{diffInDays} days</span>)
    }
  };


  useEffect(() => {
    if (!localStorage.getItem('auth_token')) {
      history('/login')
    }
		fetchData()
    fetchQuizAttributes()
	}, [])


  useEffect(() => {
		Mixpanel.track('View Dashboard Page');
  })


  const fetchData = async (e) => {
    const token = localStorage.getItem('auth_token')
    const userResponse = await axios.get('/api/supa-auth-test', {
      headers: { Authorization: token },
    });

		let userId = userResponse?.data?.data?.user?.id
    setFirstName(userResponse?.data?.data?.user?.user_metadata.first_name)


    if (userId) {
      try {
        const response = await axios.get('/api/get-quiz-attributes/', {
          params: { userId: userId }
        })
        if (response.data[0]) {
          setUserData(response.data[0])
          if (response.data[0].chosen_vendor_types.includes('Photographer')) {
            setVendorMode('Photographer');
          } else {
            setVendorMode(response.data[0].chosen_vendor_types[0])
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
	}



  const fetchQuizAttributes = async (e) => {
    const token = localStorage.getItem('auth_token')
    const userResponse = await axios.get('/api/supa-auth-test', {
      headers: { Authorization: token },
    });

    let userId = userResponse?.data?.data?.user?.id;
    setFirstName(userResponse?.data?.data?.user?.user_metadata.first_name);

    if (userId) {
      try {
        const response = await axios.get('/api/get-vendor-attributes/', {
          params: { userId: userId } // Only pass userId, no category
        });
        if (response.data && response.data.length > 0) {
          setPhotogData(response.data); // Set all data for that user
        }
      } catch (error) {
        console.log(error);
      }
    }
  }


  const renderAddCategories = () => {
    const addCategoriesArray = []

    Object.keys(pluralVendorsDic).forEach((key) => {
      if (!userData.chosen_vendor_types.includes(key)) {
          addCategoriesArray.push(key)
      }
    })
    return addCategoriesArray;
  }


  const addNewCategory = (category) => {
    history(`${addVendorNavDic[category]}?source=dashboard`)
  }


  const restartQuiz = () => {
    history('/')
  }



  const sortedVendorTypes = (cateogries) => {
    let sorted = Object.keys(chosenVendorTypesDic).filter(vendor => userData.chosen_vendor_types.includes(vendor))
    return sorted
  }


  // const testLoops = async () => {
  //   try {
  //     const response = await axios.post('/api/test-loops', {
  //       test: 'test'
  //     });

  //     if (response) {
  //       console.log(response);
  //     }
  //   } catch (error) {
  //     console.log('Error:', error);
  //   }
  // }


  // const testLoopsPhotog = async () => {
  //   try {
  //     const response = await axios.post('/api/test-loops-photog', {
  //       test: 'test'
  //     });

  //     if (response) {
  //       console.log(response);
  //     }
  //   } catch (error) {
  //     console.log('Error:', error);
  //   }
  // }




  return (
    <div className={`w-full min-h-screen flex flex-col items-center bg-[#F5F7FA] font-poppins z-5`}>
      <div className="w-full">
          <NavbarNew />
      </div>
     {userData && photogData &&
      <div className="w-full min-h-screen flex flex-col items-center bg-[#F5F7FA] font-poppins">
        <div className="w-full max-w-[1200px] mt-12 mb-8">

          <div className="flex mb-[44px] mobile:flex-col mobile:mb-6">
            <h1 className="text-2xl font-medium mobile:ml-6 mobile:text-base">{firstName}'s Wedding Vendor Manager</h1>
            <div className="relative ml-[32px] mobile:w-full mobile:ml-6 mobile:mt-4">
              {userData.wedding_location === '1' &&
                <button className={`px-4 py-2 bg-white rounded-xl text-black text-base border mobile:text-xs mobile:w-[165px]`} onClick={() => setOpenAddCategory(!openAddCategory)}>
                  + Add Vendor Category
                </button>
              }


              { openAddCategory &&
                <div className="absolute w-full h-max py-2 mt-2 bg-white rounded-xl text-base">
                  {renderAddCategories().length > 0 ?
                    <div className="max-h-[120px] flex flex-col z-10 overflow-y-auto overflow-x-none mobile:text-xs mobile:max-h-[70px]">
                      {renderAddCategories().map((category) => (
                        <button onClick={() => addNewCategory(category)} className="py-1.5 rounded-lg hover:bg-gray-100 ">
                          {pluralVendorsDic[category]}
                        </button>
                      ))}
                    </div>
                  :
                    <div>
                      More Categories Coming Soon
                    </div>
                  }
                </div>
              }
            </div>
          </div>


          <div className="w-full flex mobile:flex-col mobile:items-center mobile:text-sm">

            {/* Sidebar */}

            <div className={`${openAddCategory ? 'mobile:mt-20' : ''} transition-all duration-200`}>
              <SideBar photogData={photogData} userData={userData} vendorMode={vendorMode}/>
            </div>

            <div className="hidden mobile:flex w-[95%] mt-4 mobile:overflow-x-auto mobile:">
              {sortedVendorTypes(userData.chosen_vendor_types).map((vendor, index) => (
                <button key={index} className="w-[90px] flex flex-col items-center justify-center ml-8 mr-3" onClick={() => setVendorMode(vendor)}>
                  {icons[chosenVendorTypesDic[vendor]]?.svg}
                  <p className="w-max pb-2 mt-2.5 text-sm text-center" style={{ borderBottom: vendorMode === vendor ? "2px solid black" : "none" }}>{pluralVendorsDic[vendor]}</p>
                </button>
              ))}
            </div>


            <div className="w-full pl-8 mobile:flex mobile:pl-2 mobile:pr-2">
              <div className="w-full flex space-x-14 mobile:overflow-auto mobile:justify-center mobile:space-x-5 mobile:hidden">
                {sortedVendorTypes(userData.chosen_vendor_types).map((vendor, index) => (
                  <button key={index} className="w-[90px] flex flex-col items-center justify-center" onClick={() => setVendorMode(vendor)}>
                    {icons[chosenVendorTypesDic[vendor]]?.svg}
                    <p className="w-max pb-2 mt-2.5 text-sm text-center" style={{ borderBottom: vendorMode === vendor ? "2px solid black" : "none" }}>{pluralVendorsDic[vendor]}</p>
                  </button>
                ))}
              </div>



              {userData && photogData &&
              <div className="w-full mt-8">
                {vendorMode === "Photographer" && (
                  <PhotographerCards setBlurScreen={setBlurScreen} />
                )}

                {vendorMode === "Videographer" && (
                  <VideographerCards setBlurScreen={setBlurScreen} userData={userData} vendorPreferences={photogData} />
                )}

                {vendorMode === "Florist" && (
                  <FloristCards setBlurScreen={setBlurScreen} vendorPreferences={photogData}/>
                )}

                {vendorMode === "DJ" && (
                  <DjCards setBlurScreen={setBlurScreen} vendorPreferences={photogData}/>
                )}

                {vendorMode === "Planner/Coordinator" && (
                  <PlannerCards setBlurScreen={setBlurScreen} vendorPreferences={photogData}/>
                )}

                {vendorMode === "Bakers" && (
                  <BakerCards setBlurScreen={setBlurScreen} vendorPreferences={photogData}/>
                )}

                {vendorMode === "Hair/Makeup" && (
                  <HairMakeupCards setBlurScreen={setBlurScreen} vendorPreferences={photogData}/>
                )}
              </div>
            }

            </div>
          </div>

        </div>

      </div>
      }
    </div>
  )

}


export default Dashboard



const icons = {
  "Photographers": {
    "svg": PhotographerIcon
  },
  "Videographers": {
    "svg": VideographerIcon
  },
  "Florists": {
    "svg": FloristIcon
  },
  "DJs": {
    "svg" : djIcon
  },
  "Cake/Dessert": {
    "svg" : cakeDessertIcon
  },
  "Hair/Makeup": {
    "svg" : hairMakeupIcon
  },
  "Planners": {
    "svg" : plannerIcon
  }
}

const chosenVendorTypesDic = {
  "Photographer": "Photographers",
  "Videographer": "Videographers",
  "Florist": "Florists",
  "DJ": "DJs",
  "Bakers": "Cake/Dessert",
  "Hair/Makeup": "Hair/Makeup",
  "Planner/Coordinator": "Planners"
}

const pluralVendorsDic = {
  "Photographer": "Photographers",
  "Videographer": "Videographers",
  "Florist": "Florists",
  "DJ": "DJs",
  "Bakers": "Bakers",
  "Hair/Makeup": "Hair/Makeups",
  "Planner/Coordinator": "Planner/Coordinators"
}

const locationDic = {
  "1":	"Richmond, VA",
  "2":	"Charlottesville, VA",
  "3":	"Washington, DC",
  "4":	"Virginia Beach, VA"
}


const budgetDict = {
  1: { displayMin: 'Under $10k', displayMax: null },
  2: { displayMin: '$10k', displayMax: '$15k' },
  3: { displayMin: '$15k', displayMax: '$20k' },
  4: { displayMin: '$20k', displayMax: '$25k' },
  5: { displayMin: '$25k', displayMax: '$30k' },
  6: { displayMin: '$30k', displayMax: '$40k' },
  7: { displayMin: '$40k', displayMax: '$50k' },
  8: { displayMin: '$50k', displayMax: '$75k' },
  9: { displayMin: '$75k', displayMax: '$100k' },
  10: { displayMin: '$100k+', displayMax: null }
};


const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


const addVendorNavDic = {
  "Photographer": "/photographer",
  "Videographer": "/videographernewquiz",
  "Florist": "/floristnewquiz",
  "DJ": "/djnewquiz",
  "Bakers": "/cakedessertnewquiz",
  "Hair/Makeup": "/hairmakeupnewquiz",
  "Planner/Coordinator": "/plannernewquiz"
}