import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const CreatePhotographer = () => {
	const [businessName, setBusinessName] = useState('')
	const [email, setEmail] = useState('')
    const submitPhotographer = () => {
        const data = {
            business_name: businessName,
            email
        }
        axios.post('/api/photographer', data)
        .then(() => {
            setBusinessName('')
            setEmail('')
        })
    }
	return (
		<div style={{textAlign: 'center'}}>
            <br /><br />
            <input placeholder="Business Name" value={businessName} onChange={(e) => setBusinessName(e.target.value)} /><br />
            <input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} /><br />
            <button onClick={submitPhotographer}>Submit</button>
            <br /><br />
            <Link to="/photographerlist">Photographer List</Link>
        </div>
	);
};

export default CreatePhotographer;
