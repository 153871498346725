export const containerVarients = {
	visible: {
		x: 0,
		transition: { duration: 0.5 }
	},
	hidden: {
		x: "100vw"
	},
	leave: {
		x: "-100vw",
		transition: { duration: 0.5 }
	}
};

export const containerLeaveVarients = {
	visible: {
		x: "-100vw",
		opacity: 0,
		transition: { duration: 0.5 }
	},
	hidden: {
		x: 0
	},
};




export const footerVariants = {
	visible: {
		opacity: 1,
		transition: { duration: 0.5 }
	},
	hidden: {
		opacity: 0
	}
};

export const delayedFooterVariants = {
	visible: {
		opacity: 1,
		transition: { duration: 0.5, delay: 0.4 }
	},
	hidden: {
		opacity: 0
	}
};

export const imageVariants = {
	visible: {
		opacity: 1,
		transition: { duration: 0.5, delay: 0.1 }
	},
	hidden: {
		opacity: 0
	},
	exit: {
		scale: 0
	}
};
