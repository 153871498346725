import React from 'react'
export const UploadError = ({uploadNow}) => {

    const retryBtn = (e) => {
        e.preventDefault()
        uploadNow()
    }

    return(
        <div className=' mt-30 d-flex flex-column align-items-center'>
            <img 
                src='/images/icons/upload-error.svg'
                alt='Upload Error Icon'
            />
            <div className='text-center mt-15 text-warning bold600 font15'>
                Upload failed. Please check your network connection.
            </div>
            <div className='mt-15'>
                <button
                    style={{marginRight:10}} className='cancel_btn'
                    onClick={e=>e.preventDefault()}
                >
                    cancel
                </button>
                <button
                    className='retry_btn'
                    onClick={e=>retryBtn(e)}
                >
                    Retry
                </button>
            </div>
        </div>
    )
}