import React, { forwardRef } from 'react'

export const ImageBox = forwardRef((props, ref)=>{
    return (
        <div
            ref={ref}
            {...props}
        >
        </div>
    )
})