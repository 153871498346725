import React, { useEffect, useState, useRef, useCallback } from 'react'
import ScrollDownArrow from '../styles/scrolldownarrow.svg';
const ScrollJump = () => {
	const endRef = useRef(null);
	const handleScroll = useCallback((e) => {
		if(endRef && endRef.current){
			if(window.innerHeight + window.scrollY - 100 > endRef.current.offsetTop && !seenBottom){
				setSeenBottom(true)
			}			
		}
	}, [])
    useEffect(() => {
		window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const scrollToEnd = () => {
        endRef.current.scrollIntoView()
    }
	const [ seenBottom, setSeenBottom ] = useState(false)
    return (
        <>
            {!seenBottom && <div className="scroll-down-arrow"><img onClick={scrollToEnd} src={ScrollDownArrow} /></div>}	
            <span ref={endRef}></span>
        </>
    )
}

export default ScrollJump