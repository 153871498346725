import React, { useEffect } from "react"
import axios from 'axios'

export default function AuthorizeAccount() {
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")
    useEffect(() => {
        if (token) {
            axios.post('/auth/vendor-authorize', { token })
            .then(r => {
                window.location='/vendordashboard'
            })
        }
    })
    return (
        <div>Authorizing...</div>
    )
}