import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import NavbarNew from "./navbarnew.component";
import UpArrow from  "../../styles/dashboard-icons/upArrow"
import DownArrow from  "../../styles/dashboard-icons/downArrow"
import PhotographerPreferences from "./photographer/photographerPreferences"
import VideographerPreferences from "./videographer/videographerPreferences"
import PlannerPreferences from "./planner/plannerPreferences"
import FloristPreferences from "./florist/floristPreferences"
import BakerPreferences from "./baker/bakerPreferences"
import HairMakeupPreferences from "./hairMakeup/hairMakeupPreferences"
import DjPreferences from "./dj/djPreferences"



const SideBar = ({ photogData, userData, vendorMode }) => {
  const [weddingDetailsOpen, setWeddingDetailsOpen] = useState(false);
  const [vendorPreferencesOpen, setVendorPreferencesOpen] = useState(false);



  const convertNumberToMonth = (monthNumber) => {
    // Since arrays are zero-indexed, subtract 1 from the monthNumber
    return monthNames[monthNumber - 1];
  };


  const calculateDaysBetween = () => {
    const futureDate = `${convertNumberToMonth(userData.month)} ${userData.day}, ${userData.year}`
    const today = new Date(); // Get today's date
    const future = new Date(futureDate); // Convert the future date string to a Date object

    // Calculate the difference in time (milliseconds)
    const diffInTime = future.getTime() - today.getTime();

    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24)); // 1000ms * 3600s * 24hrs

    if (diffInDays === 1) {
      return (<span>{diffInDays} day</span>)
    } else {
      return (<span>{diffInDays} days</span>)
    }
  };

  useEffect(() => {
    // console.log(vendorMode)
  }, [])

  return (
    <div className="w-full">

      <div className="w-[250px] mobile:hidden">
        <div className="border bg-white rounded-xl p-4">
          <h2 className="text-xl font-semibold mb-4 mobile:text-lg">Wedding Details</h2>
          {/* Wedding details placeholder */}
          <div className="space-y-4">
            <div>
              <p className="text-sm">Wedding Location</p>
              <p className="mt-1 font-medium">{locationDic[userData.wedding_location]}</p>
            </div>

            <div>
              <p  className="text-sm">Wedding Venue</p>
              <p className="mt-1 font-medium">{userData.venue_name || 'TBD'}</p>
            </div>

            <div>
              <p className="text-sm">Wedding Date</p>
              <p className="mt-1 font-medium">{convertNumberToMonth(userData.month)} {userData.day}, {userData.year} (in {calculateDaysBetween()})</p>
            </div>

            <div>
              <p className="text-sm">Total Wedding Budget</p>
              <p className="mt-1 font-medium">{`${budgetDict[userData.budget].displayMin}${budgetDict[userData.budget].displayMax ? ' to '  : ''}${budgetDict[userData.budget].displayMax ?? '+'}`}</p>
            </div>
          </div>
        </div>

        {vendorMode === 'Photographer' &&
          <PhotographerPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'Videographer' &&
          <VideographerPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'Planner/Coordinator' &&
          <PlannerPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'Hair/Makeup' &&
          <HairMakeupPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'Bakers' &&
          <BakerPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'Florist' &&
          <FloristPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'DJ' &&
          <DjPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }
      </div>

      <div className="hidden w-[360px] mobile:flex mobile:flex-col">

        <div className="border bg-white rounded-xl px-4 py-3">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => setWeddingDetailsOpen(!weddingDetailsOpen)}
          >
            <div className="w-full flex justify-between items-center text-xl font-semibold mobile:text-base">
              <span>Wedding Details</span>
              <div>{weddingDetailsOpen ? <span>{UpArrow}</span>: <span>{DownArrow}</span>}</div>
            </div>
          </div>
          {weddingDetailsOpen && (
            <div className="space-y-6">
              <div className="flex justify-between items-center mt-4">
                <div className="">
                  <p className="text-sm">Wedding Location</p>
                  <p className="mt-1 font-medium">{locationDic[userData.wedding_location]}</p>
                </div>
                <div>
                  <p className="text-sm">Wedding Venue</p>
                  <p className="mt-1 font-medium">{userData.venue_name || 'TBD'}</p>
                </div>
              </div>

              <div className="flex justify-between items-center">
                <div className="pr-3">
                  <p className="text-sm">Wedding Date</p>
                  <p className="mt-1 font-medium">{convertNumberToMonth(userData.month)} {userData.day}, {userData.year} (in {calculateDaysBetween()})</p>
                </div>
                <div>
                  <p className="text-sm">Total Wedding Budget</p>
                  <p className="mt-1 font-medium">{`${budgetDict[userData.budget].displayMin}${budgetDict[userData.budget].displayMax ? ' to '  : ''}${budgetDict[userData.budget].displayMax ?? '+'}`}</p>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="">
        {vendorMode === 'Photographer' &&
          <PhotographerPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'Videographer' &&
          <VideographerPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'Planner/Coordinator' &&
          <PlannerPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'Hair/Makeup' &&
          <HairMakeupPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'Bakers' &&
          <BakerPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'Florist' &&
          <FloristPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }

        {vendorMode === 'DJ' &&
          <DjPreferences vendorPreferences={photogData} vendorPreferencesOpen={vendorPreferencesOpen} setVendorPreferencesOpen={setVendorPreferencesOpen}/>
        }
        </div>
      </div>

    </div>

  )
}

export default SideBar




const locationDic = {
  "1":	"Richmond, VA",
  "2":	"Charlottesville, VA",
  "3":	"Washington, DC",
  "4":	"Virginia Beach, VA"
}


const budgetDict = {
  1: { displayMin: 'Under $10k', displayMax: null },
  2: { displayMin: '$10k', displayMax: '$15k' },
  3: { displayMin: '$15k', displayMax: '$20k' },
  4: { displayMin: '$20k', displayMax: '$25k' },
  5: { displayMin: '$25k', displayMax: '$30k' },
  6: { displayMin: '$30k', displayMax: '$40k' },
  7: { displayMin: '$40k', displayMax: '$50k' },
  8: { displayMin: '$50k', displayMax: '$75k' },
  9: { displayMin: '$75k', displayMax: '$100k' },
  10: { displayMin: '$100k+', displayMax: null }
};


const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];