import React, { useContext, useEffect, useState, useRef } from "react";
import { AnswersContext } from "../../../AnswersContext";
import { useNavigate } from "react-router-dom";
import FooterNew from ".././footernew.component";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import closecircleX from "../../../styles/closecircleX"
import ScrollJump from "../../scroll-jump.component";

const getMinimumBudget = hoursSelected => {
	switch(hoursSelected) {
		case 3:
		case 4:
			return '$700'
		case 5:
			return '$900'
		case 6:
		case 7:
		case 8:
			return '$1,000'
		case 9:
		case 10:
			return '$1,500'
		default:
			return '$700'
	}
}

const CakeDessertNewQuiz = () => {

	const { toggleBoolean, toggleArraySelection, quizState, hoursSelected, setValue, setBooleanFalse } = useContext(AnswersContext);


	const [ nextStep, setNextStep ] = useState(true)
	const [ openMinBudget, setOpenMinBudget ] = useState(false) //bool
	const [ minBudget, setMinBudget ] = useState() //num
	const [ openMaxBudget, setOpenMaxBudget ] = useState(false) //bool
	const [ maxBudget, setMaxBudget ] = useState() //num
	const [ openNumHours, setOpenNumHours ] = useState(false) //bool
	const [ numHours, setNumHours ] = useState() //num
	const [ estimatedBudget, setEstimatedBudget] = useState(false)
	const minDropdownRef = useRef(null);
	const maxDropdownRef = useRef(null);
	const history = useNavigate();



	const handleClickOutside = (event) => {
		if (minDropdownRef.current && !minDropdownRef.current.contains(event.target)) {
      setOpenMinBudget(false);
    }
		if (maxDropdownRef.current && !maxDropdownRef.current.contains(event.target)) {
      setOpenMaxBudget(false);
    }
  };


	const renderMinBudgetDropdown = (budgetOptions) => {

		return (
			<span className="absolute w-full max-h-48 flex flex-col px-1 py-2 bg-white rounded-xl text-center shadow overflow-auto">
				{budgetOptions.map((minOption) => {
					const minOptionValue = parseFloat(minOption.replace('$', ''));

					if (quizState.bakerMinMax[1]) {
						const maxBudgetValue = parseFloat(quizState.bakerMinMax[1].replace('$', ''));
						if (maxBudgetValue > minOptionValue) {
							return (
								<span
									key={minOption}
									className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
									onClick={() => {
										quizState.bakerMinMax[0] = minOption;
										setOpenMinBudget(false);
									}}
								>
									{minOption}
								</span>
							);
						}
					} else {
						return (
							<span
								 key={minOption}
								 className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
								 onClick={() => {
									 quizState.bakerMinMax[0] = minOption;
									 setOpenMinBudget(false);
								 }}
							 >
								 {minOption}
						 </span>
						 );
					}

				})}
			</span>
		)
	}


	const renderMaxBudgetDropdown = (budgetOptions) => {
		return (
			<span className="absolute w-full max-h-48 flex flex-col px-1 py-2 bg-white rounded-xl text-center shadow overflow-auto">
				{budgetOptions.map((maxOption) => {
					let maxOptionValue = (maxOption.replace('$', ''));
					if (maxOptionValue.includes('+')) {
						maxOptionValue = parseFloat(maxOptionValue.replace('+', '')) + 1
					} else {
						maxOptionValue = parseFloat(maxOptionValue)
					}

					if (quizState.bakerMinMax[0]) {
						const minBudgetValue = parseFloat(quizState.bakerMinMax[0].replace('$', ''));

						if (maxOptionValue > minBudgetValue) {
							return (
								<span
									key={maxOption}
									className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
									onClick={() => {
										quizState.bakerMinMax[1] = maxOption;
										setOpenMaxBudget(false);
									}}
								>
									{maxOption}
								</span>
							);
						}
						return null
					}

					return (
						<span
							key={maxOption}
							className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
							onClick={() => {
								quizState.bakerMinMax[1] = maxOption;
								setOpenMaxBudget(false);
							}}
						>
							{maxOption}
						</span>
					);
				})}
			</span>
		);
	}


	useEffect(() => {
		if (quizState.bakerMinMax[0] && quizState.bakerMinMax[1]) {
			const e = {
				target: {
					name: 'bakerEstimatedBudget',
					checked: false
				}
			};

			setBooleanFalse(e);
		}
	}, [quizState.bakerMinMax[0], quizState.bakerMinMax[1]])


	useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


	useEffect(() => {
		if ((quizState.bakerMinMax[0] && quizState.bakerMinMax[1]) || quizState.bakerEstimatedBudget) {
			setNextStep(true)
		} else {
			setNextStep(false)
		}
	}, [quizState.bakerMinMax[0], quizState.bakerMinMax[1], quizState.bakerEstimatedBudget])




	const budgets =  [
			{
				id: 1,
				displayMinimum: getMinimumBudget(hoursSelected),
				displayMaximum: '$2,000'
			},
			{
				id: 2,
				displayMinimum: '$2,001',
				displayMaximum: '$3,000'
			},
			{
				id: 3,
				displayMinimum: '$3,001',
				displayMaximum: '$4,000'
			},
			{
				id: 4,
				displayMinimum: '$4,001',
				displayMaximum: '$6,000'
			},
			{
				id: 5,
				displayMinimum: '$6,000',
			},
	]


	const hoursOptions = [
		'2 Hours',
		'3 Hours',
		'4 Hours',
		'5 Hours',
		'6 Hours',
		'7 Hours',
		'8 Hours',
		'9 Hours',
		'10 Hours'
	]

	const budgetOptions = [
		'$200',
		'$400',
		'$600',
		'$800',
		'$1000',
		'$1200',
		'$1500',
		'$1500+'
	]

	return (
		<div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
			<div className="w-full">
					<NavbarNew />
			</div>

			<div className={`w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-3 pb-[24px] mt-5 rounded-3xl bg-white bg-white/50 mobile:w-[90%]`}>
				{/* Status Bar */}

				<Timeline phase="preferences"/>

				<div className="w-full flex flex-col items-center pt-10 pb-6 mx-12 border-t z-10">

					<div className="mb-7 text-center mobile:mb-10">
						<p className="mb-7 text-3xl font-medium mobile:mb-5 mobile:text-lg">Cake/Dessert Preferences</p>
						<p className="mobile:text-[13px]">Click or tap to fill in the blanks.</p>
					</div>

					<div className="w-[55%] flex flex-wrap justify-center items-center gap-y-5 text-lg mobile:w-full mobile:mb-6 mobile:text-sm">
						<span>I'm interested in a</span>

						<span
							className="px-4 mx-3 text-greenblue italic"
							style={{ borderBottom: `1px solid #0A8A95` }}
						>
							Wedding Cake
						</span>

						<span>and my budget is between</span>


						<span className="relative" ref={minDropdownRef}>
							<span
								className={`${quizState.bakerMinMax[0] ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-2 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${quizState.bakerMinMax[0] ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => setOpenMinBudget(!openMinBudget)}
							>
								{quizState.bakerMinMax[0] || 'Min'}
							</span>
							{openMinBudget && renderMinBudgetDropdown(budgetOptions)}
						</span>

						<span>and</span>

						<span className="relative" ref={maxDropdownRef}>
							<span
								className={`${quizState.bakerMinMax[1] ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-2 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${quizState.bakerMinMax[1] ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => setOpenMaxBudget(!openMaxBudget)}
							>
								{quizState.bakerMinMax[1] || 'Max'}
							</span>
							{openMaxBudget && renderMaxBudgetDropdown(budgetOptions)}
						</span>
					</div>


				</div>

				{/* <div className="w-full flex justify-center items-center mt-2 text-center mobile:text-sm">
					<label class="checkbox-container" style={{marginRight: '8px', marginTop: '0px'}}>
						<input
							name="bakerEstimatedBudget"
							checked={quizState.bakerEstimatedBudget}
							type="checkbox"
							onClick={(e) => {toggleBoolean(e)}}
						/>
						<span class="checkmark email-checkmark" />
					</label>
					<span className="ml-2">Use typical price range based on my wedding details</span>
				</div> */}

				<div>
						<FooterNew phase={"Bakers"} complete={nextStep}/>
				</div>

			</div>

		</div>
	);
};

export default CakeDessertNewQuiz;
