import { Fragment, useEffect, useState } from 'react';
import { ErrorBanner } from './ErrorBanner';
import { UploadError } from './UploadError';
import { Progressbar } from './Progressbar';
import { ListImageContainer } from './ListImageContainer';
import React from 'react';
import axios, { CancelToken } from 'axios';
import { uploadConfig } from '../../../utils/upload_config';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  // DragOverlay,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  // rectSwappingStrategy,
  rectSortingStrategy,
  // verticalListSortingStrategy
} from '@dnd-kit/sortable';
import Bugsnag from '@bugsnag/js';
const cloudName = 'dfjwlskop';
const cloudinary_preset = 'comparison';

export const UploadPage = props => {
  const {
    form,
    step3,
    setStepThree,
    maxSizeError,
    setMaxSizeError,
    networkOnline,
    thumbnails,
    setThumbnails,
    setUploadSection,
    uploadSuccessChecker,
    setUploadSuccessChecker,
  } = props;
  const uploadUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;
  const [totalProgress, setTotalProgress] = useState(0);
  // eslint-disable-next-line no-unused-vars

  const [activeId, setActiveId] = useState(null);
  const [sortableArray, setSortableArray] = useState(Object.keys(step3));
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      transition: {
        duration: 150, // milliseconds
        easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
      },
    })
  );
  const { descriptiveTitle } = form.steps.step1;
  const totalImages = Object.keys(step3).length;

  const uploadEachImage = item => {
    if (item.allowed === true) {
      if (item.started !== true) {
        // SET STARTED UPLOADING
        setStepThree(prev => {
          return {
            ...prev,
            [item.id]: {
              ...prev[item.id],
              started: true,
            },
          };
        });
        const config = {
          onUploadProgress: function (progressEvent) {
            setProgressOfEachImage(item.id, Math.round((progressEvent.loaded * 100) / progressEvent.total));
          },
          cancelToken: new CancelToken(cancel => {
            setStepThree(prev => {
              return {
                ...prev,
                [item.id]: {
                  ...prev[item.id],
                  cancel: cancel,
                },
              };
            });
          }),
        };
        const data = new FormData();
        data.append('file', item.imageFile);
        //data.append("api_key", cloudinary_api_key);
        //data.append("api_secret", cloudinary_api_secret);
        data.append('upload_preset', cloudinary_preset);
        data.append('folder', encodeURIComponent(descriptiveTitle));

        axios
          .post(uploadUrl, data, config)
          .then(response => {
            if (response.status === 200) {
              controlSuccessAndErrorUpload('success');
              setUploadedImages(item.id, response.data);
            }
          })
          .catch(err => {
            if (axios.isCancel(err)) {
              console.log('Request canceled', err.message);

              Bugsnag.notify(err);
            } else {
              let errorMessage = err?.response?.data?.error?.message;

              Bugsnag.notify(err, event => {
                event.addMetadata('details', err?.response?.data);
              });

              controlSuccessAndErrorUpload('error');
              setUploadError(item.id, errorMessage);
            }
          });
      }
    }
  };

  const uploadNow = () => {
    let moreThan75 = Object.keys(step3).length > 75;
    if (!moreThan75) {
      Object.entries(step3).forEach(([key, item]) => {
        uploadEachImage(item);
      });
      controlTotalProgress();
    }
  };

  const controlTotalProgress = () => {
    let total = 0;
    Object.entries(step3).forEach(([key, item]) => {
      total += item.progress;
    });
    setTotalProgress(Math.round(total / totalImages));
  };

  useEffect(() => {
    uploadNow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step3]);

  const setProgressOfEachImage = (id, progress) => {
    setStepThree(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          progress,
        },
      };
    });
  };

  const setUploadedImages = (id, data) => {
    setStepThree(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          imgUrl: data.url,
          uploaded: true,
          cloudinaryResponse: data,
        },
      };
    });
  };

  const setUploadError = (id, msg) => {
    setStepThree(prev => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          error: true,
          uploaded: false,
          msg,
        },
      };
    });
  };

  const cancelAll = () => {
    Object.entries(step3).forEach(([key, item]) => {
      if (!item.uploaded) {
        if (item.cancel) {
          item.cancel('cancelled by user');
        }
        removeItem(key);
      }
    });
  };
  const removeItem = (id, deleteFromCloudinary) => {
    if (deleteFromCloudinary) {
      setSortableArray(prev => prev.filter(i => i !== id));
      setStepThree(prev => {
        const { [id]: removedProperty, ...rest } = prev;
        return {
          ...rest,
        };
      });
    } else {
      setMaxSizeError(prev => [...prev.filter(i => i.id !== id)]);
      if (step3[id].cancel) step3[id].cancel('Cancelled by user!');
      setSortableArray(prev => prev.filter(i => i !== id));
      setStepThree(prev => {
        const { [id]: removedProperty, ...rest } = prev;
        return {
          ...rest,
        };
      });
    }
  };

  const calculateMaxSizeError = () => {
    let error = maxSizeError.map(item => item.err);
    return error.includes(true);
  };

  const controlSuccessAndErrorUpload = (type, deleted) => {
    setUploadSuccessChecker(prev => {
      return {
        ...prev,
        [type]: deleted ? prev[type] - 1 : prev[type] + 1,
      };
    });
  };

  const handleDragStart = event => {
    const { active } = event;

    setActiveId(active.id);
  };
  const onDragEnd = result => {
    const { active, over } = result;

    // if (active.id !== over.id) {
    //     const oldIndex = Object.keys(step3).indexOf(active.id);
    //     const newIndex = Object.keys(step3).indexOf(over.id);
    //     let tempObj = {}
    //     arrayMove(Object.keys(step3), oldIndex, newIndex).forEach(item=> {
    //         tempObj = {
    //             ...tempObj,
    //             [item]:step3[item]
    //         }
    //     })
    //     setStepThree(tempObj)
    // }
    // setActiveId(null);
    if (active.id !== over.id) {
      setSortableArray(items => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  };
  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={onDragEnd}>
      <div>
        {totalProgress === 100 ? (
          <div className="progress_box">
            <img style={{ marginBottom: 31 }} src="/images/vendor-img/upload-success.svg" alt="Upload Success" />
            <div className="font24 bold600 text-center">
              Successfully Uploaded {uploadSuccessChecker.success} Photos to {descriptiveTitle}
            </div>
            {uploadSuccessChecker.error > 0 && (
              <div className="font22 bold500 text-center text-warning">
                Error in uploading {uploadSuccessChecker.error} Photos to {descriptiveTitle}
              </div>
            )}
            <button
              onClick={e => {
                e.preventDefault();
                setUploadSection(false);
              }}
              className="add_more_btn mt-30"
            >
              Add More Photos
            </button>
          </div>
        ) : (
          <div className="progress_box">
            <div className="font24 bold600 text-center">
              Uploading {totalImages} Photo(s) to {descriptiveTitle}
            </div>
            <div className="progress_bar_section">
              <div className="font12 bold400 d-flex justify-content-between">
                <div className="d-flex align-items-center text-light-grey">
                  <span>{totalProgress}%</span>
                  {/* <img
                                        style={{margin:"0 10px"}}
                                        className='d-block'
                                        src='/images/icons/upload-dot.svg'
                                        alt='Pause Icon'
                                    /> */}
                  {/* <span>{timeRemaining} remaining</span> */}
                </div>
                <div className="d-flex align-items-center">
                  {/* <div className='circle-29 pause_bg'>
                                        <img
                                            src='/images/icons/pause-icon.svg'
                                            alt='Pause Icon'
                                        />
                                    </div> */}
                  <div onClick={() => cancelAll()} className="circle-29 cancel_bg">
                    <img src="/images/icons/cancel-icon.svg" alt="Cancel Cross" />
                  </div>
                </div>
              </div>
              <Progressbar progress={totalProgress} />
            </div>
            {!networkOnline && <UploadError uploadNow={uploadNow} />}
          </div>
        )}
        {totalImages > uploadConfig.MAX_NUMBER_OF_PHOTOS && (
          <ErrorBanner
            total={totalImages}
            type="max_photos" // max_photos || max_size
          />
        )}
        {calculateMaxSizeError() && (
          <ErrorBanner
            total={totalImages}
            type="max_size" // max_photos || max_size
          />
        )}
        {thumbnails.length !== 3 && (
          <div>
            <div className="bold500 font19" style={{ marginTop: 50 }}>
              <b>IMPORTANT:</b> Select the 3 photos to show in the thumbnail for this wedding
            </div>
            <div>Photo #1 will be cropped vertical, Photo #2, and #3 horizontal</div>
          </div>
        )}
        <SortableContext items={sortableArray} strategy={rectSortingStrategy}>
          <div className="images_list_container">
            {sortableArray.map((key, index) => (
              <Fragment key={index}>
                <ListImageContainer
                  selectMode={true}
                  thumbnails={thumbnails}
                  setThumbnails={setThumbnails}
                  item={step3[key]}
                  id={key}
                  removeItem={removeItem}
                  uploadEachImage={uploadEachImage}
                  index={index}
                  extra={index > 49 || step3[key]?.imageFile?.size > uploadConfig.MAX_SIZE_OF_PHOTO}
                  // deleteItem={deleteItem}
                />
              </Fragment>
            ))}
          </div>
        </SortableContext>
        {/* </DragDropContext> */}
      </div>
    </DndContext>
  );
};