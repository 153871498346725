import React, { useState, useEffect, useContext } from "react";
import { AnswersContext } from "../../AnswersContext";
import axios from "axios";
import NavbarNew from "./navbarnew.component";
import Timeline from "./timeline.component";
import FooterNew from "./footernew.component";
import { Mixpanel } from '../../Mixpanel';
import ScrollJump from "../scroll-jump.component";

const FooterRender = (props) => {
	const { styleNotImportant, photoStyles } = props
	
	if(styleNotImportant || (photoStyles.filter(s => s.rank == 1).length > 0 && photoStyles.filter(s => s.rank == 2).length > 0 && photoStyles.filter(s => s.rank == 3).length > 0 && photoStyles.filter(s => s.rank == 4).length > 0)){
		return <FooterNew to="/tags" from="/budget" buttonText="Next: Tags" otherText="Style & Attributes 1/4" />;
	} else{
		return <FooterNew inactive={true} to="" from="/date" buttonText="Next: Tags" otherText="Style & Attributes 1/4" />;
	}
};

const PhotoStyle = () => {
	const [ finished, setFinished ] = useState(false);

	const {
		quizState,
		retrieveLocalStorage,
		setValue,
		photoStylesFromUrl,
		toggleBoolean,
	} = useContext(AnswersContext);
	
	useEffect(() => {
		retrieveLocalStorage()
		Mixpanel.track('View Photo Style Page');
        axios.get('/api/styles')
        .then(r => {
			const tempStyles = r.data
			if (photoStylesFromUrl) {
				tempStyles[0].rank = photoStylesFromUrl.substring(0,1)
				tempStyles[1].rank = photoStylesFromUrl.substring(1,2)
				tempStyles[2].rank = photoStylesFromUrl.substring(2,3)
				tempStyles[3].rank = photoStylesFromUrl.substring(3,4)
			}
            setValue('photoStyles', tempStyles)
        })
	}, [])
    const rankPhoto = (index) => {
        var arr = [...quizState.photoStyles]
        if(arr[index].rank){
            var i;
            for (i = 0; i < arr.length; i++){
                if(arr[i].rank >= arr[index].rank){
                    arr[i].rank = null
                }
            }
        } else if(quizState.photoStyles.filter(s => s.rank == 1).length == 0){
            arr[index].rank = 1
        } else if(quizState.photoStyles.filter(s => s.rank == 2).length == 0){
            arr[index].rank = 2
        } else if(quizState.photoStyles.filter(s => s.rank == 3).length == 0){
            arr[index].rank = 3
        } else if(quizState.photoStyles.filter(s => s.rank == 4).length == 0){
            arr[index].rank = 4
        }
        setValue('photoStyles', arr)
    }
	return (
		<div className="question-page">
			<NavbarNew />
			<Timeline phase="howimportant" />
			<div className="question-container"
			>
				<div>
					<div className="header">Editing Style</div>
					<div className="subheader">
					We’ll use these rankings to get a general idea of the style of photographers you like.
					</div>
				</div>

				<div>
				<div className="question">Click to rank these editing styles 1-4</div>
                <div className="photo-style-container">
                    {quizState.photoStyles.map((p, index) => (
                    <div style={{position: 'relative', cursor: 'pointer'}} onClick={() => rankPhoto(index)}>
                        <img style={{maxWidth: '100%'}} src={p.image_url.replace('/upload/', '/upload/w_300/')} />
                        {p.rank &&<div className="photo-rank">{p.rank}</div>}
                    </div>)
                    )}
                </div>
				<label class="checkbox-container">
									<input 
										checked={quizState.styleNotImportant}
										type="checkbox"
										name='styleNotImportant'
										onClick={toggleBoolean}										
									/>
									<span class="checkmark email-checkmark" />
				</label>
				<ScrollJump />
				<span className="checkbox-label">Editing style is <b>not</b> important to me</span>
			</div>
            </div>
			<FooterRender styleNotImportant={quizState.styleNotImportant} photoStyles={quizState.photoStyles} />
		</div>
	);
};

export default PhotoStyle;
