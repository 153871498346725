import React from 'react'
export const AlbumPageHeader = () => {
    return(
        <div>
            <div className='top_bar album_container d-flex'>
                <div className='text-dark-grey bold600 font22'>
                    All Albums
                </div>
                {/*
                <div className='d-md-flex'>
                    <span className='position-relative d-none d-md-flex'>
                        <input 
                            type='text'
                            style={{marginRight:10}}
                            className='create_album_searchbar'
                            placeholder='Search for albums'
                        />
                        <img 
                            className='position-absolute'
                            style={{left:20,top: "50%",transform: "translateY(-50%)"}}
                            src='/images/icons/search-icon.png'
                            alt='search Icon'
                        />
                    </span>
                    <div className='calendar_dropdown_btn'>
                        <img 
                            style={{marginRight:10}}
                            src='/images/icons/calendar-icon.png'
                            alt='Calendar Icon'
                        />
                        <div style={{marginRight:10}} className='font18 bold500 text-light-grey'>Create Date</div>
                        <img 
                            src='/images/icons/arrow-down.png'
                            alt='Arrow Down'
                        />
                    </div>
                </div>
                */}
                <div style={{flex:1, margin:"20px 0"}} className='position-relative d-flex d-md-none'>
                    <input 
                        type='text'
                        style={{width:"100%"}}
                        className='create_album_searchbar'
                        placeholder='Search for albums'
                    />
                    <img 
                        className='position-absolute'
                        style={{left:20,top: "50%",transform: "translateY(-50%)"}}
                        src='/images/icons/search-icon.png'
                        alt='search Icon'
                    />
                </div>
            </div>
        </div>
    )
}