import React, { useEffect, useState } from "react";
import logo from "../../styles/wedmatch-logo.png";
import Twitch from "../../styles/twitch.svg";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { supabase } from '../../supabaseClient';


const NavbarNew = () => {

    const history = useNavigate();

    return (
        <div className="navbar-new">
            <div className="navbar-left">
                <img onClick={() => window.location.href = "https://www.wedmatch.com"} className="navbar-logo cursor-pointer" src={logo} />
            </div>
            <div className="navbar-center">
                <img onClick={() => window.location.href = "https://www.wedmatch.com"}  style={{width: '124px', marginLeft: '24px'}} className="navbar-logo-mobile" src={logo} />
            </div>
            <div className="navbar-right-mobile">
                {/* <img src={Twitch} style={{width: '24px'}} /> */}
            </div>
        </div>
    );
};

export default NavbarNew;