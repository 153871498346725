import React from 'react';
import { Link } from 'react-router-dom';

const AccountTypeSelect = () => {   
   
    return(
        <div>
           <Link to="/vendorsignup">Photographer</Link>
        </div>
    )
}

export default AccountTypeSelect;