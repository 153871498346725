import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import DashboardNav from './dashboard-nav.component';
import { UserContext } from '../UserContext';
import axios from 'axios';
import styled from 'styled-components';

import ReactTooltip from 'react-tooltip';

import { PhotographerContext } from '../PhotographerContext';

const TimeOfDayOptions = ['Getting Ready', 'Ceremony', 'Reception', 'Formal Dances', 'Couples Portrait', 'Wedding Party'];

const WinLossBox = styled.div`
  background-color: white;
  color: black;
  padding-bottom: 5px;
  width: 100%;
`;

const TimeOfDayDisplay = props => {
  const [hovered, setHovered] = useState(0);
  const [ photosToShow, setPhotosToShow ] = useState(50)
  return (
    <div>
      <div className="dashboard-text-2">
        {props.title}
        <br />
      </div>
      <Tip>{props.tip}</Tip>
      <div className="photo-type-wrapper">
        {props.photos.slice(0, photosToShow).map((p, index) => {
          return (
            <div key={p.id} className="dashboard-photo-wrapper">
              <div
                onPointerEnter={() => setHovered(p.id)}
                onPointerLeave={() => setHovered(0)}
                onMouseUp={() => setHovered(p.id)}
              >
                <StyledImage className="dashboard-photo" key={`${p.id}-image`} src={p.image_url} />
                {props.showIds && <div>{p.id}</div>}
                {hovered == p.id && props.pretty_name === 'Unassigned' && (
                  <div className="tag-button" style={{left: '15px', right: 'inherit'}}>
                    <i
                      onClick={() => {
                        props.deletePhoto(p.id);
                      }}
                      class="fas fa-trash"
                    ></i>
                  </div>
                )}
                {hovered == p.id && (
                  <div className="tag-button">
                    <i
                      onClick={() => {
                        props.setViewingPhoto(p);
                        props.setViewingTimeOfDay(props.pretty_name);
                        props.setViewingPhotoIndex(index);
                      }}
                      class="fas fa-tags"
                    ></i>
                  </div>
                )}
                {hovered == p.id && (
                  <div className="times-of-day">
                    <div>
                      {props.pretty_name !== 'Unassigned' && (
                        <span onClick={() => props.unassignPhoto(p.id, props.pretty_name)} className="time-of-day-option">
                          Unassign
                        </span>
                      )}
                    </div>
                    {TimeOfDayOptions.map(text => (
                      <div key={text}>
                        {props.pretty_name !== text && (
                          <span
                            onClick={() => props.assignImage(p.id, text, props.pretty_name)}
                            className="time-of-day-option"
                          >
                            {text}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <WinLossBox>
                {p.wins}/{p.wins + p.losses}{' '}
                <span data-tip="This is the % of time a couple picked this photo instead of the other photo it was paired with.">
                  ({p.pick_rate || 0}% picked)
                </span>
              </WinLossBox>
              <ReactTooltip backgroundColor="#3D8893" place="bottom" />
            </div>
          );
        })}
      </div>
      {photosToShow < props.photos.length && <button style={{cursor: 'pointer', marginTop: '8px'}} onClick={() => setPhotosToShow(prev => prev + 50)}>Show More</button>}
    </div>
  );
};

const TagDisplay = props => {
  const [tags, setTags] = useState([]);
  const [addedTags, setAddedTags] = useState([]);
  useEffect(() => {
    axios.get('/api/tags').then(r => setTags(r.data));
    axios.get('/api/phototags/' + props.photo.id).then(r => {
      setAddedTags(r.data);
    });
  }, []);
  useEffect(() => {
    axios.get('/api/phototags/' + props.photo.id).then(r => {
      setAddedTags(r.data);
    });
  }, [props.photo]);
  const toggleTag = id => {
    axios.post('/api/phototag', { photo: props.photo.id, tag: id }).then(() => {
      axios.get('/api/phototags/' + props.photo.id).then(r => {
        setAddedTags(r.data);
      });
    });
  };
  return (
    <div>
      {tags
        .filter(t => t.is_season)
        .map(t => (
          <button
            className={addedTags.some(f => f.tag_fkey == t.id) ? 'active-tag' : 'inactive-tag'}
            onClick={() => toggleTag(t.id)}
          >
            {t.name}
          </button>
        ))}
      <br />
      {tags
        .filter(t => t.is_important)
        .map(t => (
          <button
            className={addedTags.some(f => f.tag_fkey == t.id) ? 'active-tag' : 'inactive-tag'}
            onClick={() => toggleTag(t.id)}
          >
            {t.name}
          </button>
        ))}
      <br />
      {tags
        .filter(t => t.is_location)
        .map(t => (
          <button
            className={addedTags.some(f => f.tag_fkey == t.id) ? 'active-tag' : 'inactive-tag'}
            onClick={() => toggleTag(t.id)}
          >
            {t.name}
          </button>
        ))}
      <br />
      {tags
        .filter(t => t.is_style)
        .map(t => (
          <button
            className={addedTags.some(f => f.tag_fkey == t.id) ? 'active-tag' : 'inactive-tag'}
            onClick={() => toggleTag(t.id)}
          >
            {t.name}
          </button>
        ))}
      <br />
      {tags
        .filter(t => t.is_interest)
        .map(t => (
          <button
            className={addedTags.some(f => f.tag_fkey == t.id) ? 'active-tag' : 'inactive-tag'}
            onClick={() => toggleTag(t.id)}
          >
            {t.name}
          </button>
        ))}
    </div>
  );
};

const StyledImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  padding: 10px;
`;

const UploadImagesSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  max-width: 95%;
  width: 300px;
`;

const Category = styled.div`
  width: 100%;
  background-color: #f4f6fb;
  padding: 20px 0;
  margin-bottom: 40px;
  text-align: center;
`;

const Tip = styled.div`
  width: 90%;
  text-align: center;
  font-size: 12px;
  margin: 5px auto 10px;
  color: #7e7e7e;
`;

const DashboardPhotos = () => {
  let { id } = useParams();
  const { photographer, setPhotographer, setActivePage } = useContext(UserContext);
  const [gettingReadyPhotos, setGettingReadyPhotos] = useState([]);
  const [ceremonyPhotos, setCeremonyPhotos] = useState([]);
  const [receptionPhotos, setReceptionPhotos] = useState([]);
  const [formalDancesPhotos, setFormalDancesPhotos] = useState([]);
  const [couplesPortraitPhotos, setCouplesPortraitPhotos] = useState([]);
  const [weddingPartyPhotos, setWeddingPartyPhotos] = useState([]);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [unassignedPhotos, setUnassignedPhotos] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [viewingPhoto, setViewingPhoto] = useState(null);
  const [viewingTimeOfDay, setViewingTimeOfDay] = useState('Getting Ready');
  const [viewingPhotoIndex, setViewingPhotoIndex] = useState(0);
  const [nextButton, setNextButton] = useState(true);
  const [tooBig, setTooBig] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [toggleNav, setToggleNav] = useState(false);
  const [ businessName, setBusinessName ] = useState('')
  const [ imageNamePrefix, setImageNamePrefix ] = useState('')
  const { setPhotosComplete, profileComplete, photosTouched, setPhotosTouched, setPersonalityTouched } =
    useContext(PhotographerContext);

  const queryParams = new URLSearchParams(window.location.search)
  const showIds = queryParams.get('showids')

  useEffect(() => {
    setPhotosTouched(false);
    setPersonalityTouched(false);
    setActivePage('photosDashboard');
    axios.get('/api/unassigned/' + id).then(result => {
      setUnassignedPhotos(result.data);
    });

    axios.get('/api/photographerdata/' + id)
    .then(r => {
      setBusinessName(r.data.business_name)
      setImageNamePrefix(r.data.image_name_prefix ?? r.data.name ?? '')
    })
    refreshPhotos('Reception');
    refreshPhotos('Formal Dances');
    refreshPhotos('Couples Portrait');
    refreshPhotos('Wedding Party');
    refreshPhotos('Getting Ready');
    refreshPhotos('Ceremony');
    setPhotographer(id);
    setLoaded(true);
  }, []);

  useEffect(() => {
    setTooBig(false);
    var photosLength = uploadedPhotos.length;
    var finishedUploading = 0;
    if (uploadedPhotos) {
      if (uploadedPhotos.length > 0) {
        setUploading(true);
      }
      for (var i = 0; i < uploadedPhotos.length; i++) {
        const formData = new FormData();

        formData.append('image', uploadedPhotos[i]);
        formData.append('id', id);
        if (uploadedPhotos[i].size > 20971520) {
          setTooBig(true);
          setUploading(false);
        }
        formData.append('file', uploadedPhotos[i]);
        formData.append('upload_preset', 'comparison');
        formData.append('public_id', `${imageNamePrefix?.replace(/ /g, '-').replace(/&/g, 'and').replace(/\?/g, '').replace(/#/g, '').replace(/\\/g, '').replace(/%/g, '').replace(/</g, '').replace(/>/g, '').replace(/\+/g, '')}-wedding-photographer-${businessName?.replace(/ /g, '-').replace(/&/g, 'and').replace(/\?/g, '').replace(/#/g, '').replace(/\\/g, '').replace(/%/g, '').replace(/</g, '').replace(/>/g, '').replace(/\+/g, '')}-${Date.now()}-${i}`);
        axios
          .post('https://api.cloudinary.com/v1_1/dfjwlskop/image/upload', formData)
          .then(p => {
            const data = {
              id,
              image_url: p.data.secure_url,
              width: p.data.width,
              height: p.data.height,
              aspect_ratio: p.data.width / p.data.height,
            };
            axios.post('/api/cloud-upload', data).then(r => {
              setUnassignedPhotos(r.data);
              finishedUploading += 1;
              if (finishedUploading == photosLength) {
                setUploading(false);
              }
            });
          })
          .catch(e => {
            console.log(e);
            axios.post('/api/log-error', { error: e });
          });
      }
    }
  }, [uploadedPhotos]);

  useEffect(() => {
    if (photosTouched) {
      if (
        gettingReadyPhotos.length >= 5 &&
        ceremonyPhotos.length >= 5 &&
        formalDancesPhotos.length >= 5 &&
        receptionPhotos.length >= 5 &&
        couplesPortraitPhotos.length >= 5 &&
        weddingPartyPhotos.length >= 5
      ) {
        setPhotosComplete(true);
      } else {
        setPhotosComplete(false);
      }
    }
  }, [gettingReadyPhotos, ceremonyPhotos, formalDancesPhotos, receptionPhotos, couplesPortraitPhotos, weddingPartyPhotos]);

  const uploadImages = e => {
    setUploadedPhotos(e.target.files);
  };

  const assignImage = (image_id, time_of_day, from_time_of_day) => {
    setPhotosTouched(true);
    axios.post('/api/assign-image/' + image_id + '/' + time_of_day).then(() => {
      axios.get('/api/unassigned/' + id).then(result => {
        setUnassignedPhotos(result.data);
      });
      refreshPhotos(time_of_day);
      if (from_time_of_day) {
        refreshPhotos(from_time_of_day);
      }
    });
  };

  const refreshPhotos = time_of_day => {
    switch (time_of_day) {
      case 'Getting Ready':
        axios.get('/api/photographer-photos/Getting Ready/' + id).then(result => {
          setGettingReadyPhotos(result.data);
        });
        break;
      case 'Ceremony':
        axios.get('/api/photographer-photos/Ceremony/' + id).then(result => {
          setCeremonyPhotos(result.data);
        });
        break;
      case 'Reception':
        axios.get('/api/photographer-photos/Reception/' + id).then(result => {
          setReceptionPhotos(result.data);
        });
        break;
      case 'Wedding Party':
        axios.get('/api/photographer-photos/Wedding Party/' + id).then(result => {
          setWeddingPartyPhotos(result.data);
        });
        break;
      case 'Couples Portrait':
        axios.get('/api/photographer-photos/Couples Portrait/' + id).then(result => {
          setCouplesPortraitPhotos(result.data);
        });
        break;
      case 'Formal Dances':
        axios.get('/api/photographer-photos/Formal Dances/' + id).then(result => {
          setFormalDancesPhotos(result.data);
        });
        break;
      default:
        break;
    }
  };

  const unassignPhoto = (image_id, time_of_day) => {
    setPhotosTouched(true);
    axios.post('/api/unassign-image/' + image_id).then(() => {
      axios.get('/api/unassigned/' + id).then(result => {
        setUnassignedPhotos(result.data);
      });
      refreshPhotos(time_of_day);
    });
  };

  const deletePhoto = (image_id) => {
    axios.post('/api/delete-image/' + image_id)
    .then(r => {
      axios.get('/api/unassigned/' + id).then(result => {
        setUnassignedPhotos(result.data);
      });
      refreshPhotos('Reception');
      refreshPhotos('Formal Dances');
      refreshPhotos('Couples Portrait');
      refreshPhotos('Wedding Party');
      refreshPhotos('Getting Ready');
      refreshPhotos('Ceremony');
    })
  }

  const nextPhoto = () => {
    switch (viewingTimeOfDay) {
      case 'Getting Ready':
        setViewingPhoto(gettingReadyPhotos[viewingPhotoIndex + 1]);
        setViewingPhotoIndex(viewingPhotoIndex + 1);
        break;
      case 'Ceremony':
        setViewingPhoto(ceremonyPhotos[viewingPhotoIndex + 1]);
        setViewingPhotoIndex(viewingPhotoIndex + 1);
        break;
      case 'Reception':
        setViewingPhoto(receptionPhotos[viewingPhotoIndex + 1]);
        setViewingPhotoIndex(viewingPhotoIndex + 1);
        break;
      case 'Wedding Party':
        setViewingPhoto(weddingPartyPhotos[viewingPhotoIndex + 1]);
        setViewingPhotoIndex(viewingPhotoIndex + 1);
        break;
      case 'Couples Portrait':
        setViewingPhoto(couplesPortraitPhotos[viewingPhotoIndex + 1]);
        setViewingPhotoIndex(viewingPhotoIndex + 1);
        break;
      case 'Formal Dances':
        setViewingPhoto(formalDancesPhotos[viewingPhotoIndex + 1]);
        setViewingPhotoIndex(viewingPhotoIndex + 1);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (viewingTimeOfDay === 'Getting Ready' && gettingReadyPhotos.length < viewingPhotoIndex) {
      setNextButton(false);
    } else if (viewingTimeOfDay === 'Ceremony' && ceremonyPhotos.length < viewingPhotoIndex) {
      setNextButton(false);
    } else if (viewingTimeOfDay === 'Couples Portrait' && couplesPortraitPhotos.length < viewingPhotoIndex) {
      setNextButton(false);
    } else if (viewingTimeOfDay === 'Reception' && receptionPhotos.length < viewingPhotoIndex) {
      setNextButton(false);
    } else if (viewingTimeOfDay === 'Wedding Party' && weddingPartyPhotos.length < viewingPhotoIndex) {
      setNextButton(false);
    } else if (viewingTimeOfDay === 'Formal Dances' && formalDancesPhotos.length < viewingPhotoIndex) {
      setNextButton(false);
    }
  }, [viewingPhoto]);

  return (
    <div className="dashboard-container">
      <DashboardNav toggleNav={toggleNav} />

      {loaded && (
        <div
          className="dashboard-sub-container "
          onClick={() => {
            setToggleNav(!toggleNav);
          }}
        >
          {viewingPhoto && (
            <div className="overlay">
              <div className="close-button" onClick={() => setViewingPhoto(null)}>
                x
              </div>
              <div className="lightbox">
                <img
                  alt="tagging"
                  className="tag-photo"
                  style={{ width: viewingPhoto.aspect_ratio < 1 ? '300px' : '500px', maxWidth: '100%' }}
                  src={viewingPhoto.image_url}
                />
                <br />
                {nextButton && (
                  <div className="next-photo" onClick={() => nextPhoto()}>
                    ᐅ
                  </div>
                )}
                <TagDisplay photo={viewingPhoto} />
              </div>
            </div>
          )}
          {profileComplete && <span className="profile-active">Active</span>}
          {!profileComplete && <span className="profile-inactive">Inactive</span>}
          <UploadImagesSection>
            <div className="dashboard-text-1">
              Upload Images
              <br /> <span style={{ fontSize: '.9rem' }}>(20 mb per file max)</span>
            </div>
            <label className="custom-file-upload">
              <input className="upload-button" type="file" name="image" multiple onChange={uploadImages} />
              Choose File
            </label>
          </UploadImagesSection>
          {tooBig && <div className="profile-inactive">File Too Large. All photos must be less than 20mb.</div>}
          {uploading && <div className="">Uploading...</div>}
          <br />
          <div style={{ fontSize: '.9rem' }}>
            Upload not working? Upload{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://u.pcloud.com/#page=puplink&code=rNa7ZrtQjMSTvt8Qma8A8WIFi9bh7FRNV"
            >
              HERE
            </a>{' '}
            instead and we will manually add to your dashboard.
          </div>
          <hr class="seperator" />
          <Category className="special-category">
            <TimeOfDayDisplay
              showIds={showIds}
              title="Unassigned Photos"
              tip="Click each unassigned photo to assign it to the most relevant part of the wedding day."
              assignImage={assignImage}
              unassignPhoto={unassignPhoto}
              setViewingPhoto={setViewingPhoto}
              setViewingPhotoIndex={setViewingPhotoIndex}
              setViewingTimeOfDay={setViewingTimeOfDay}
              deletePhoto={deletePhoto}
              photos={unassignedPhotos}
              pretty_name="Unassigned"
            />
            <br />
          </Category>
          <Category>
            <TimeOfDayDisplay
              showIds={showIds}
              title={`Getting Ready Photos ${gettingReadyPhotos.length} of 5 required`}
              tip="Getting ready photos can include any part of the preparation for the wedding."
              assignImage={assignImage}
              unassignPhoto={unassignPhoto}
              setViewingPhoto={setViewingPhoto}
              setViewingPhotoIndex={setViewingPhotoIndex}
              setViewingTimeOfDay={setViewingTimeOfDay}
              photos={gettingReadyPhotos}
              pretty_name="Getting Ready"
            />
            <br />
          </Category>
          <Category>
            <TimeOfDayDisplay
              showIds={showIds}
              title={`Ceremony Photos ${ceremonyPhotos.length} of 5 required`}
              tip="These must be photos from a real wedding ceremony. Styled shoots and workshop photos are not allowed."
              assignImage={assignImage}
              unassignPhoto={unassignPhoto}
              setViewingPhoto={setViewingPhoto}
              setViewingPhotoIndex={setViewingPhotoIndex}
              setViewingTimeOfDay={setViewingTimeOfDay}
              photos={ceremonyPhotos}
              pretty_name="Ceremony"
            />
            <br />
          </Category>
          <Category>
            <TimeOfDayDisplay
              showIds={showIds}
              title={`Wedding Party Photos ${weddingPartyPhotos.length} of 5 required`}
              tip="Wedding party photos can include photos of any members of the wedding party, with or without the couple."
              assignImage={assignImage}
              unassignPhoto={unassignPhoto}
              setViewingPhoto={setViewingPhoto}
              setViewingPhotoIndex={setViewingPhotoIndex}
              setViewingTimeOfDay={setViewingTimeOfDay}
              photos={weddingPartyPhotos}
              pretty_name="Wedding Party"
            />
            <br />
          </Category>
          <Category>
            <TimeOfDayDisplay
              showIds={showIds}
              title={`Couples Portrait Photos ${couplesPortraitPhotos.length} of 5 required`}
              tip="These can be any portraits of the couple, or either of them individually."
              assignImage={assignImage}
              unassignPhoto={unassignPhoto}
              setViewingPhoto={setViewingPhoto}
              setViewingPhotoIndex={setViewingPhotoIndex}
              setViewingTimeOfDay={setViewingTimeOfDay}
              photos={couplesPortraitPhotos}
              pretty_name="Couples Portrait"
            />
            <br />
          </Category>
          <Category>
            <TimeOfDayDisplay
              showIds={showIds}
              title={`Formal Dances Photos ${formalDancesPhotos.length} of 5 required`}
              tip="These should be either of the couple’s first dance, or the parent dances."
              assignImage={assignImage}
              unassignPhoto={unassignPhoto}
              setViewingPhoto={setViewingPhoto}
              setViewingPhotoIndex={setViewingPhotoIndex}
              setViewingTimeOfDay={setViewingTimeOfDay}
              photos={formalDancesPhotos}
              pretty_name="Formal Dances"
            />
            <br />
          </Category>

          <Category>
            <TimeOfDayDisplay
              showIds={showIds}
              title={`General Reception Photos ${receptionPhotos.length} of 5 required`}
              tip="These are general reception photos, or any other formal reception events, but not intended to be detail photos."
              assignImage={assignImage}
              unassignPhoto={unassignPhoto}
              setViewingPhoto={setViewingPhoto}
              setViewingPhotoIndex={setViewingPhotoIndex}
              setViewingTimeOfDay={setViewingTimeOfDay}
              photos={receptionPhotos}
              pretty_name="Reception"
            />
            <br />
          </Category>
        </div>
      )}
    </div>
  );
};

export default DashboardPhotos;
