import React from 'react';

const items = [
    {
        id:1,
        text:'Wedding Details',
        top:"92%",
        bottom:"92%",
        marginLeft:-0
    },
    {
        id:2,
        text:'Vendors',
        top:"90%",
        bottom:"90%",
        marginLeft:-30
    },
    {
        id:3,
        text:'Upload',
        top:"100%",
        bottom:"100%",
        marginLeft:-30
    },
]

const NumberCircle = ({number, active, completed}) => {

    return(
        <div 
            className='number_circle font15 bold600'
            style={{
                color:active || completed?"white":"rgba(27, 45, 73, 0.5)",
                backgroundColor:active || completed?"#0A8A95":"transparent",
                border:active || completed?"none":"1px solid rgba(27, 45, 73, 0.5)",
            }}
        >
            {
                completed
                ?
                <img 
                    src='/images/icons/check-icon.png'
                    alt='check-icon'
                />
                :
                number
            }
        </div>
    )
}

export const MyCustomStepper = ({ stepNumber, validateStep3 }) => {
   /**
     * @summary Check if completed
    */
    const checkCompleted = (number) => {
        let num = parseInt(number)
        if(stepNumber===2 && num===1) {
            return true
        }
        else if(stepNumber===3 && num===1) {
            return true
        }
        else if(stepNumber===3 && num===2) {
            return true
        }
        else if(stepNumber===3 && num===3 && validateStep3()) {
            return true
        }
        else{
            return false
        }
    }
    return(
        <div className='stepper_container'>
            {
                items.map((item)=>(
                    <div key={item.id} className='d-flex justify-content-center'>
                        <div 
                            style={{
                                // clipPath: `polygon(0% 0%, ${item.top} 0%, 100% 50%, ${item.bottom} 100%, 0% 100%)`,
                                marginLeft:item?.marginLeft,
                                // borderRight: "10px solid rgba(27, 45, 73, 0.5)",
                                background:checkCompleted(item.id)?"#ECFBFC":""
                            }} 
                            className='stepper_item'
                        >
                            <NumberCircle
                                completed={checkCompleted(item.id)}
                                stepNumber={stepNumber}
                                active={stepNumber===item.id} 
                                number={item.id} 
                            />
                            <span className={`font15 bold500 ${stepNumber===item.id?" text-blue":" text-light-grey"}`}>
                                {item.text}
                            </span>
                        </div>
                        {
                            item.id !==3 &&
                            <div 
                                style={{
                                    position: 'relative',
                                    height: 0,
                                    width: 0,
                                    borderTop: "24px solid transparent",
                                    borderBottom: "24px solid transparent",
                                    borderLeft: `30px solid ${checkCompleted(item.id)?"#ECFBFC":"transparent"}`,
                                }}
                            >
                                {
                                    item.id !==3 &&
                                    <img 
                                        style={{
                                            position:"absolute",
                                            top:-25,
                                            left: -32,
                                        }}
                                        height={52}
                                        src='/images/icons/right-angle.png'
                                        alt='right angle'
                                    />
                                }
                            </div>
                        }
                    </div>
                ))
            }
        </div>
    )
}