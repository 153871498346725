import React, { useEffect, useState, useContext } from 'react'
import { Link } from "react-router-dom"
import axios from 'axios'
import DashboardNav from '../dashboard-nav.component'
import { useParams } from "react-router-dom";
import { UserContext } from '../../UserContext';

const emptyImages = [
    {
        id:1,
        image:'/images/empty-album/empty-1.png',
        dimensions:{
            left:"-12.92%", 
            top:"-7.804%"
        }
    },
    {
        id:2,
        image:'/images/empty-album/empty-2.png',
        dimensions:{
            left:"-5.609%", 
            top:"37.07%"
        }
    },
    {
        id:3,
        image:'/images/empty-album/empty-3.png',
        dimensions:{
            left:"13.658%", 
            bottom:0
        }
    },
    {
        id:4,
        image:'/images/empty-album/empty-4.png',
        dimensions:{
            right:"40.97%",
            bottom:"-12.682%",
        }
    },
    {
        id:5,
        image:'/images/empty-album/empty-5.png',
        dimensions:{
            right:"11.951%",
            bottom:0,
        }
    },
    {
        id:6,
        image:'/images/empty-album/empty-6.png',
        dimensions:{
            right:"-5.365%",
            top:"37.073%",
        }
    },
    {
        id:7,
        image:'/images/empty-album/empty-7.png',
        dimensions:{
            right:"-13.292%",
            top:"-7.804%",
        }
    },
]

export const EmptyAlbums = () => {
	let { id } = useParams();
	const [ toggleNav, setToggleNav ] = useState(false);
	const { setPhotographer, setActivePage } = useContext(UserContext);
    
    useEffect(() => {
        setActivePage('albumDashboard')
        setPhotographer(id);
    }, [])

    return(
		<div className="dashboard-container">
			<div
				className="dashboard-sub-container "
                style={{padding: '0px', display: 'inherit', marginLeft: '0px'}}
				onClick={() => {
					setToggleNav(!toggleNav);
				}}>
        <div className='album_container'>
            <div style={{margin:'50px auto'}} className='empty_half_circle'>
                <div>
                    <div className='position-relative font23 bold600 text-center'>
                        You don't have any albums

                        {/* STARS */}
                        <img 
                            style={{ left:1.15, top:-2}}
                            className='position-absolute'
                            src='/images/icons/star.png'
                            alt='star'
                        />
                        <img 
                            style={{ right:2, bottom:-7}}
                            className='position-absolute'
                            src='/images/icons/star.png'
                            alt='star'
                        />
                        <img 
                            style={{ top:16, left:67}}
                            className='position-absolute'
                            src='/images/icons/star.png'
                            alt='star'
                        />
                        <img 
                            style={{ top:16, right:100}}
                            className='position-absolute'
                            src='/images/icons/star.png'
                            alt='star'
                        />
                    </div>
                    <a href='/weddingupload' target="_blank" rel="noopener noreferrer" className='text-decoration-none'>
                        <div className='empty_album_card'>
                            <span className='text-center'>
                                <img 
                                    src='/images/icons/add-icon.png'
                                    alt='Add Icon'
                                />
                                <div className='font15 text-blue bold600 spacer-y1'>Create Album</div>
                            </span>
                        </div>
                    </a>
                </div>
                <div className='d-none d-lg-block'>
                    {
                        emptyImages.map((item)=>(
                            <div
                                key={item.id} 
                                className='empty_image_container'
                                style={{...item.dimensions}}
                            >
                                <img 
                                    style={{width:'100%'}}
                                    src={item.image}
                                    alt='Wedding'
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
        </div>
        </div>
    )
}