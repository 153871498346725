import React from "react";
import { motion } from "framer-motion";
import Navbar from "./navbar.component";
import { containerVarients } from "../animations/animations";

const Success = () => {
	return (
		<div className="container-app">
			<Navbar className="navbar-app" />
			<motion.div className="sub-container" variants={containerVarients} initial="hidden" animate="visible">
				<div
					className="sub-container-heading"
					style={{
						fontSize: "1.5rem"
					}}>
					All done! We'll email you within 24 hours with your results.
				</div>
			</motion.div>
		</div>
	);
};

export default Success;
