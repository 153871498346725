import React, { useState } from 'react';
import axios from 'axios';

const EditPhotographer = ({ photographer, locations, styles }) => {  
    const { 
        id: photographerId,
        primary_location,
        business_name,
    secondary_location,
        third_location,
        stars,
        reviews,
        account_type,
        photo_style_fkey    
    } = photographer

    const updatePhotoStyle = (id) => {
        axios.post('/api/photo-style', {id: id, photographer: photographerId})
    }
    const updateAccountType = (accountType) => {
        axios.post('/api/update-account-type', {accountType, id: photographerId})
    }
    const updateReviews = (reviews) => {
        axios.post('/api/update-reviews', {reviews, id: photographerId})
    }
    const updateStars = (stars) => {
        axios.post('/api/update-stars', {stars, id: photographerId})
    }
    const updatePrimaryLocation = (locationId) => {
        axios.post('/api/update-primary-location', {location: locationId, id: photographerId})
    }
    const updateSecondaryLocation = (locationId) => {
        axios.post('/api/update-secondary-location', {location: locationId, id: photographerId})
    }
    const updateThirdLocation = (locationId) => {
        axios.post('/api/update-third-location', {location: locationId, id: photographerId})
    }
    return(
        <div style={{padding: '24px'}}>
            <div style={{textAlign: 'center'}}>{business_name}</div>
            
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="primary">Primary Location: </label>         
            <select 
                style={{background: 'white'}} name='primary' defaultValue={primary_location}  onChange={(e) => updatePrimaryLocation(e.target.value)}>
                <option value="">------</option>            
                {locations.map(l =><option value={l.id}>{l.name}</option>)}
            </select>
        </div>
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="secondary">Secondary Location: </label>         
            <select 
                style={{background: 'white'}} name='secondary' defaultValue={secondary_location}  onChange={(e) => updateSecondaryLocation(e.target.value)}>
                <option value="">------</option>            
                {locations.map(l =><option value={l.id}>{l.name}</option>)}
            </select>
        </div>
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="third">Third Location: </label>         
            <select 
                style={{background: 'white'}} name='third' defaultValue={third_location}  onChange={(e) => updateThirdLocation(e.target.value)}>
                <option value="">------</option>            
                {locations.map(l =><option value={l.id}>{l.name}</option>)}
            </select>
        </div>
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="type">Account Type: </label>  
            <select 
                name='type'
                style={{background: 'white'}}
                defaultValue={account_type} 
                onChange={(e) => updateAccountType(e.target.value)}>
                    <option value='basic_photographer'>Basic</option>
                    <option value='photographer'>Full</option>
            </select>
        </div>
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="stars">Stars: </label>          
            <input 
                style={{width: '80px', background: 'white'}} 
                type="number" defaultValue={stars} 
                onChange={(e) => updateStars(e.target.value)} />
        </div>       
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="reviews">Reviews: </label>          
            <input 
                style={{width: '80px', background: 'white'}} 
                type="number" defaultValue={reviews} 
                onChange={(e) => updateReviews(e.target.value)} />
        </div>     
		<div className="" style={{width: '100%'}}>
            <label className="" htmlFor="style">Style: </label>          
        <select name='style' value={photo_style_fkey}  onChange={(e) => updatePhotoStyle(e.target.value)}>
            <option value="">Photo Style</option>            
            {styles.map(s =><option value={s.id}>{s.description}</option>)}
        </select>
        </div>     

        </div>
    )
}

export default EditPhotographer