// import { uploadConfig } from "../../../utils/upload_config";
import React, { useEffect, useState } from "react";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Progressbar } from "./Progressbar";
import { ImageBox } from "./ImageBox";

export const ListImageContainer = (props) => {
    const { item, thumbnails, setThumbnails, removeItem, extra, uploadEachImage, selectMode, id } = props
    const [ hover, setHover ] = useState(false)
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        isDragging,
        transition,
      } = useSortable({id});

    const thumbnailIndex = thumbnails.indexOf(item.id)
    const handleClick = () => {
        if(selectMode) {
            if(!thumbnails.includes(item.id)) {
                if(thumbnails.length < 3) {
                    setThumbnails(prev=>[...prev, item.id])
                }
            } else {
                setThumbnails(prev=>prev.filter(i=>i!==item.id))
            }
        }
    }
    // const grid = 8;
    
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        backgroundImage:`url('${item.imgUrl}')`,
        backgroundcolor:`black`,
        // opacity:isDragging?0.2:1
    };
    
    return(
        // <div 
        // className={`individual_img_box ${selectMode ? " cursor-move":""}`} 
        // onMouseEnter={()=>setHover(true)}
        // onMouseLeave={()=>setHover(false)}
        // ref={setNodeRef} 
        // >
            <ImageBox 
                className={`individual_img_box ${selectMode ? "":""}`} 
                ref={setNodeRef} 
                style={style} 
                {...attributes} 
                onMouseEnter={()=>setHover(true)}
                onMouseLeave={()=>setHover(false)}
                // {...listeners}
            >
                {
                    selectMode &&
                    !isDragging &&
                        <>
                            {
                                hover &&
                                <>
                                    <div 
                                        className='circle-29'
                                        onClick={()=>removeItem(item.id, true)}
                                        style={{
                                            position: 'absolute',
                                            zIndex:1000,
                                            background: "#F5F9FA",
                                            top:15,
                                            right:15,
                                            border: "1px solid #F64006"
                                        }}
                                    >
                                <img 
                                    className='noselect'
                                    src='/images/icons/bin.png'
                                    alt='Cancel Icon'
                                />
                            </div> 
                            <div 
                                    className='d-flex align-items-center justify-content-center cursor-move'
                                    {...listeners}
                                    // onClick={()=>removeItem(item.id, true)}
                                    style={{
                                        position: 'absolute',
                                        zIndex:1000,
                                        // background: "#F5F9FA",
                                        bottom:15,
                                        right:15,
                                        // border: "1px solid #F64006"
                                    }}
                                >
                                    <img 
                                        className='noselect'
                                        src='/images/icons/move-icon.png'
                                        alt='Move Icon'
                                    />
                                    </div>
                                </>
                            }
                        </>
                    }
                    {
                        !extra && item?.progress!==100 &&
                        <>
                            <div className='img_upload_overlay'>
                                <Progressbar progress={item?.progress} />
                            </div>
                            <div 
                                className='circle-29'
                                onClick={()=>removeItem(item.id)}
                                style={{
                                    position: 'absolute',
                                    zIndex:1000,
                                    background: "#F5F9FA",
                                    top:-10,
                                    right:-10,
                                    border: "3px solid rgba(196, 196, 196, 0.47)"
                                }}
                            >
                                <img 
                                    className='noselect'
                                    src='/images/icons/cancel-icon.svg'
                                    alt='Cancel Icon'
                                />
                            </div>
                        </>
                    }
                    {
                        item?.error &&
                        <>
                            <div className='img_upload_overlay_delete'>
                            </div>
                            <div onClick={()=>uploadEachImage(item)} className='text-warning bold700 font22'>
                                {/* {item?.error.msg} */}
                                Retry
                            </div>
                            <div 
                                className='circle-29'
                                onClick={()=>removeItem(item.id)}
                                style={{
                                    position: 'absolute',
                                    zIndex:1000,
                                    background: "#F5F9FA",
                                    top:-10,
                                    right:-10,
                                    border: "1px solid #F64006"
                                }}
                            >
                                <img 
                                    className='noselect'
                                    src='/images/icons/bin.png'
                                    alt='Cancel Icon'
                                />
                            </div> 
                        </>
                    }
                    {
                        extra
                        &&
                        <>
                            <div className='img_upload_overlay_delete'>
                            </div>
                            <div 
                                className='circle-29'
                                onClick={()=>removeItem(item.id)}
                                style={{
                                    position: 'absolute',
                                    zIndex:1000,
                                    background: "#F5F9FA",
                                    top:-10,
                                    right:-10,
                                    border: "1px solid #F64006"
                                }}
                            >
                                <img 
                                    className='noselect'
                                    src='/images/icons/bin.png'
                                    alt='Cancel Icon'
                                />
                            </div>
                        </>
                    }
            </ImageBox>
    )
}