import React, {useEffect, useState, useContext} from 'react';
import { AnswersContext } from "../../AnswersContext";
import axios from 'axios';
import FooterNew from "./footernew.component";
import NavbarNew from "./navbarnew.component";
import Timeline from "./timeline.component";
import { Mixpanel } from '../../Mixpanel';
import ScrollJump from '../scroll-jump.component';

const FooterRender = (props) => {    
    if(props.styleTags.length || props.importantTags.length || props.userOnlyTags.length || props.locationTags.length)
    {
        return (        
        <FooterNew
            button={true}
            to="/dotogether"
            from="/howimportant"
            buttonText="Next: Attributes"
            otherText="Style & Attributes 3/5"
            stopAnimating="true"
        />
    );
        } else{
            return (        
                <FooterNew
                    button={true}
                    to="#"
                    inactive="true"
                    from="/howimportant"
                    buttonText="Next: Attributes"
                    otherText="Style & Attributes 3/5"
                    stopAnimating="true"
                />
            );
        }
    
};

const TagsNew = () => {   
    const [ tags, setTags ] = useState([])
    
	const {
        toggleArraySelection,
        quizState,
	} = useContext(AnswersContext);

   useEffect(() => {
    Mixpanel.track('View Style Page');
    axios.get('/api/all-tags')
    .then(r => setTags(r.data))
   }, [])

    return(
		<div className="question-page">
			<NavbarNew />
			<Timeline phase="tags" />
            <div className="question-container">
            <div>
                <div className="header">Style</div>
                <div className="subheader">
                You’ll compare pictures from our photographers that are relevant to your wedding style and interests.
                </div>
			</div>
            <div>
                <div className="question">
                    Select any of these words that describe this wedding.
                </div>
                <div>
                <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '25px', marginLeft: '-6px'}}>
                    
                {tags.filter(t => t.is_style).map(t =>
                    <button
                        className={`question-button question-tag-button ${quizState.styleTags.length && quizState.styleTags.some(i => i == t.id) ? 'selected' : ''}`}
                        selected={quizState.styleTags.length && quizState.styleTags.some(i => i == t.id)}
                        onClick={() => toggleArraySelection('styleTags', t.id)}
                        >
                        {t.name == "Church/House of Worship" ? "House of Worship" : t.name}
                    </button>
                )}
                {tags.filter(t => t.is_important).map(t =>
                    <button
                    className={`question-button question-tag-button ${quizState.importantTags.length && quizState.importantTags.some(i => i == t.id) ? 'selected' : ''}`}
                        selected={quizState.importantTags.length && quizState.importantTags.some(i => i == t.id)}
                        onClick={() => toggleArraySelection('importantTags', t.id)}
                        >
                        {t.name == "Indian/Cultural" ? "Cultural Wedding" : t.name}
                    </button>
                )}
                {tags.filter(t => t.is_location).map(t =>
                    <button
                        className={`question-button question-tag-button ${quizState.locationTags.length && quizState.locationTags.some(i => i == t.id) ? 'selected' : ''}`}
                        selected={quizState.locationTags.length && quizState.locationTags.some(i => i == t.id)}
                        onClick={() => toggleArraySelection('locationTags', t.id)}
                        >
                        {t.name}
                    </button>
                )}
                {tags.filter(t => t.user_only).map(t =>
                    <button
                    className={`question-button question-tag-button ${quizState.userOnlyTags.length && quizState.userOnlyTags.some(i => i == t.id) ? 'selected' : ''}`}
                        selected={quizState.userOnlyTags.length && quizState.userOnlyTags.some(i => i == t.id)}
                        onClick={() => toggleArraySelection('userOnlyTags', t.id)}
                        >
                        {t.name}
                    </button>
                )}
                </div>
                <ScrollJump />
            </div>
            </div>
            </div>
            
			<FooterRender styleTags={quizState.styleTags} locationTags={quizState.locationTags} userOnlyTags={quizState.userOnlyTags} importantTags={quizState.importantTags} />
        </div>
    )
}

export default TagsNew;