import styled from 'styled-components';

export const StyledInput = styled.input`
	padding: 8px;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 1.2rem;
	-webkit-box-shadow: 0px 3px 8px -6px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 3px 8px -6px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 3px 8px -6px rgba(0, 0, 0, 0.2);
	text-align: 'center';
	max-width: 50%;
	color: #6a6868;
`;