import React, { useState, useEffect } from 'react'
import axios from 'axios'

const WeddingList = () => {   
    const [ weddings, setWeddings ] = useState([])
    useEffect(() => {
        axios.get('/api/weddings')
        .then(r => {
            console.log(r)
            setWeddings(r.data)
        })
    }, [])
   
    return(
        <table>
            <tr><th>Wedding ID</th><th>Photographer</th><th>Ceremony</th><th>Reception</th></tr>
           {weddings.map(w => 
            <tr>
                <td><a href={`/fullsizephotos/${w.id}`}>{w.id}</a></td>
                <td>{w.photographer_business_name}</td>
                <td>{w.ceremony_venue_name}</td>
                <td>{w.reception_venue_name}</td>
            </tr>
           )}
        </table>
    )
}

export default WeddingList;