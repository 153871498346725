import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import axios from "axios";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import FooterNew from ".././footernew.component";
import { Mixpanel } from '../../../Mixpanel';
import InstructionsPopup from ".././instructions-popup.component";
import backArrow from '../../../styles/back-arrow.svg'
import ScrollJump from "../../scroll-jump.component";

// const FooterRender = (props) => {
// 	const { styleNotImportant, photoStyles } = props

// 	if (styleNotImportant || (photoStyles.filter(s => s.rank == 1).length > 0 && photoStyles.filter(s => s.rank == 2).length > 0 && photoStyles.filter(s => s.rank == 3).length > 0 && photoStyles.filter(s => s.rank == 4).length > 0)){
// 		return <FooterNew to="/tags" from="/budget" buttonText="Next: Tags" otherText="Style & Attributes 1/4" />;
// 	} else{
// 		return <FooterNew inactive={true} to="" from="/date" buttonText="Next: Tags" otherText="Style & Attributes 1/4" />;
// 	}
// };

const PhotoStyle = () => {
	const [ finished, setFinished ] = useState(false);
	const [ nextStep, setNextStep ] = useState(false)
	const location = useLocation();
	const history = useNavigate();

	const setDelay = (e) => {
		e.preventDefault(); //prevent transition
		window.setTimeout(() => {
				history("/");
		}, 500);
	};

	const goBack = (e) => {
		e.preventDefault(); //prevent transition
		const searchParams = new URLSearchParams(location.search);
		const source = searchParams.get('source');

		if (source === 'dashboard') {
			window.setTimeout(() => {
				history("/dotogether?source=dashboard");
			}, 500);
		return
		}


			window.setTimeout(() => {
					history("/dotogether");
			}, 500);
	};

	const {
		quizState,
		retrieveLocalStorage,
		setValue,
		photoStylesFromUrl,
		toggleBoolean,
	} = useContext(AnswersContext);

	useEffect(() => {
		retrieveLocalStorage()
		Mixpanel.track('View Photo Style Page');
        axios.get('/api/styles')
        .then(r => {
			const tempStyles = r.data
			if (photoStylesFromUrl) {
				tempStyles[0].rank = photoStylesFromUrl.substring(0,1)
				tempStyles[1].rank = photoStylesFromUrl.substring(1,2)
				tempStyles[2].rank = photoStylesFromUrl.substring(2,3)
				tempStyles[3].rank = photoStylesFromUrl.substring(3,4)
			}
            setValue('photoStyles', tempStyles)
        })
	}, [])

	useEffect(() => {
		if (quizState.styleNotImportant || (quizState.photoStyles.filter(s => s.rank == 1).length > 0 && quizState.photoStyles.filter(s => s.rank == 2).length > 0 && quizState.photoStyles.filter(s => s.rank == 3).length > 0 && quizState.photoStyles.filter(s => s.rank == 4).length > 0)) {
			setFinished(true)
		} else {
			setFinished(false)
		}
	})

	const rankPhoto = (index) => {
			var arr = [...quizState.photoStyles]
			if(arr[index].rank){
					var i;
					for (i = 0; i < arr.length; i++){
							if(arr[i].rank >= arr[index].rank){
									arr[i].rank = null
							}
					}
			} else if(quizState.photoStyles.filter(s => s.rank == 1).length == 0){
					arr[index].rank = 1
			} else if(quizState.photoStyles.filter(s => s.rank == 2).length == 0){
					arr[index].rank = 2
			} else if(quizState.photoStyles.filter(s => s.rank == 3).length == 0){
					arr[index].rank = 3
			} else if(quizState.photoStyles.filter(s => s.rank == 4).length == 0){
					arr[index].rank = 4
			}
			setValue('photoStyles', arr)
	}

	return (
		<div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
			{nextStep && <InstructionsPopup/>}
			<div className="w-full">
					<NavbarNew />
			</div>

			<div className="w-[75%] max-w-[1000px] flex flex-col items-center mt-5 pt-3 pb-2 rounded-3xl bg-white mobile:w-[90%]">
				<Timeline phase="photostyle"/>

				<div className="w-full flex flex-col items-center pt-10 pb-2 mx-12 border-t z-10">

						<div>
							<div className="text-center text-3xl font-medium mobile:text-lg">Photo Editing Style</div>
							<div className="mt-3 text-center text-[#444444] mobile:text-xs mobile:px-10">Click to rank your preference for these editing styles from 1 to 4.</div>
						</div>

						<div className="w-[80%] flex flex-col items-center mt-8">
							<div className="photo-style-container">
									{quizState.photoStyles.map((p, index) => (
									<div style={{position: 'relative', cursor: 'pointer'}} onClick={() => rankPhoto(index)}>
											<img style={{maxWidth: '100%'}} src={p.image_url.replace('/upload/', '/upload/w_300/')} />
											{p.rank &&<div className="photo-rank">{p.rank}</div>}
									</div>)
									)}
							</div>

							<div className="flex justify-center items-center mt-4">
								<label class="checkbox-container" style={{marginRight: '8px', marginTop: '0px'}}>
									<input
										checked={quizState.styleNotImportant}
										type="checkbox"
										name='styleNotImportant'
										onClick={toggleBoolean}
									/>
									<span class="checkmark email-checkmark" />
								</label>
								<span className="text-[#444444] mobile:text-xs mobile:ml-2 ">Editing style is <span className="font-medium">not</span> important to me</span>
							</div>

						</div>

				</div>

				<div className="mobile:hidden w-full flex justify-center space-x-4">

					<button className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-white border border-[10px] text-[#0A8A95] z-1 ' onClick={(e) => goBack(e)}>
							Back
					</button>

					{finished ?
						<button className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-[#0A8A95] opacity-100 text-white z-1' onClick={(e) => setNextStep(true)}>
								Next: Photo Comparison
						</button>
						:
						<div className='w-[290px] h-[50px] flex items-center justify-center my-12 rounded-full bg-[#0A8A95] opacity-40 text-white z-1'>
								Next: Photo Comparison
						</div>
						}
				</div>

			<div className="hidden mobile:flex w-full justify-center bg-white z-10">
				<div className="footer-new">
				<div className="footer-back">
						<button className={`flex items-center`} onClick={(e) => goBack(e)} >
							<img src={backArrow} style={{marginRight: '10px'}}/>
							<p className="text-[#17A2B8] hover:text-[#007bff] text-sm">Back</p>
						</button>
					</div>

					<div className="footer-right">
						<div>
							<button className={`footer-next-button ${!finished ? 'inactive' : ''}`} onClick={(e) => setNextStep(true)}>
								<span className="text-xs">Next: Photo Comparison</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>
	);
};

export default PhotoStyle;
