import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";

const ForgotPassword = () => {
	const [ email, setEmail ] = useState("");
	const [ error, setError ] = useState("");
	const [ complete, setComplete ] = useState(false)
    const [ message, setMessage ] = useState('')
    
	const data = {
		email,
	};
	const submit = (e) => {
		e.preventDefault();
		axios.post("/auth/request-password-reset", data).then((r) => {
            setMessage('If an account exists with this email address, you should receive an email shortly')
		});
	};
    return(         
        <div className="signup-page">
         <div className="signup-right signup-right-create">
            <div className="signup-right-header">Reset Password</div>
            <div className="signup-inputs">
				<div className="photographer-signup-input-wrapper create" style={{width: '100%'}}>
					<label className="photographer-signup-input-label create" htmlFor="email">Email</label>
               <input
					style={{width: '100%'}}
                    type="email"
                    placeholder="Email address..."
                    name="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
				</div>
                <button className="signup-button" disabled={!email.length || !email.includes('@') || !email.includes('.')}
                    onClick={submit}>SUBMIT
                </button>	
				<div className="have-an-account"><Link className="signup-link" to="/login">SIGN IN</Link></div>
                <div>{message}</div>
            </div>
        </div>
        </div>
    )
}

export default ForgotPassword