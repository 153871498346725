import React from 'react'
import { AlbumCard } from "./Components/AlbumCard"
import { CreateCard } from "./Components/CreateCard"

export const AlbumsList = ({list, hideCreate}) => {

    return(
        <div className='album_container'>
            <div className='d-flex flex-wrap justify-content-center justify-content-xl-start'>
                {!hideCreate && <CreateCard />}
                {
                    list.map((item)=>(
                        <AlbumCard linkToEdit={!hideCreate} key={item.id} album={item} />
                    ))
                }
            </div>
        </div>
    )
}