import React from 'react';
import infologo from '../../styles/info.svg';
import command from '../../styles/command.svg';
import image from '../../styles/image.svg';
import trello from '../../styles/trello.svg';
import step4 from '../../styles/step4.svg';
import BlankBar from '../../styles/blank-bar.svg';
import BudgetBar from '../../styles/budget-bar.svg';
import LocationBar from '../../styles/location-bar.svg';
import DateBar from '../../styles/date-bar.svg';
import DoTogetherBar from '../../styles/do-together-bar.svg';
import EmailBar from '../../styles/email-bar.svg';
import HowImportantBar from '../../styles/how-important-bar.svg';
import ResultsBar from '../../styles/results-bar.svg';
import TagsBar from '../../styles/tags-bar.svg';
import Results2 from '../../styles/results2.svg';
import Results1 from '../../styles/results1.svg';
import Style3 from '../../styles/style3.png';
import Style2 from '../../styles/style2.png';
import Style1 from '../../styles/style1.png';
import PhotoStep from '../../styles/photostep.svg';
import Details3 from '../../styles/details3.svg';
import Details2 from '../../styles/details2.svg';
import Details1 from '../../styles/details1.svg';
import ResultsInactive from '../../styles/results-inactive.svg';
import PhotosInactive from '../../styles/photos-inactive.svg';
import StyleInactive from '../../styles/style-inactive.png';
import TimelineComplete from '../../styles/timeline-complete.svg';

const Timeline = (props) => {   
   var detailSrc = ""
   var styleSrc = ""
   var photosSrc = ""
   var resultsSrc = ""
   var detailText = ""
   var styleText = ""
   var resultsText = ""
   var photosText = ""
   switch(props.phase){
       case "location":
           detailSrc = Details1
           styleSrc = StyleInactive
           resultsSrc = ResultsInactive
           photosSrc = PhotosInactive
           detailText = "Step 1/4"
           break;
        case "budget":
            detailSrc = Details3
            styleSrc = StyleInactive
            resultsSrc = ResultsInactive
            photosSrc = PhotosInactive
            detailText = "Step 4/4"
            break;
        case "date":
            detailSrc = Details2
            styleSrc = StyleInactive
            resultsSrc = ResultsInactive
            photosSrc = PhotosInactive
            detailText = "Step 2/4"
            break;
        case "tags":
            detailSrc = TimelineComplete
            styleSrc = Style2
            resultsSrc = ResultsInactive
            photosSrc = PhotosInactive
            styleText = "Step 2/3"
            break;
        case "dotogether":
            detailSrc = TimelineComplete
            styleSrc = Style3
            resultsSrc = ResultsInactive
            photosSrc = PhotosInactive
            styleText = "Step 3/3"
            break;
        case "howimportant":
            detailSrc = Details3
            styleSrc = StyleInactive
            resultsSrc = ResultsInactive
            photosSrc = PhotosInactive
            detailText = "Step 4/4"
            break;
        case "email":
            detailSrc = TimelineComplete
            styleSrc = TimelineComplete
            resultsSrc = Results1
            photosSrc = TimelineComplete
            resultsText = "Step 1/1"
            break;
        case "results":
            detailSrc = TimelineComplete
            styleSrc = TimelineComplete
            resultsSrc = Results2
            photosSrc = TimelineComplete
            resultsText = "Step 1/1"
            break;
        case "photos":
            detailSrc = TimelineComplete
            styleSrc = TimelineComplete
            resultsSrc = Results2
            photosSrc = PhotoStep
            photosText = "Step 1/1"
            break;
   }
   
    return(
        <div className="timeline">
        <div className="steps">
           <div className="timeline-item" style={{marginTop: props.phase == 'photos' ? 0 : 25}}>
               <img className="timeline-logo" src={infologo} />
               <span className={`timeline-text ${(props.phase == "howimportant" || props.phase == 'location' || props.phase == 'date' || props.phase == 'budget') ? "timeline-active" : ""}`}>Wedding Details</span>
           </div>
           <div className="timeline-item" style={{marginTop: props.phase == 'photos' ? 0 : 25}}>
               <img className="timeline-logo" src={command} />
               <span className={`timeline-text ${(props.phase == "dotogether" || props.phase == "tags") ? "timeline-active" : ""}`}>Style & Attributes</span>
           </div>
           <div className="timeline-item" style={{marginTop: props.phase == 'photos' ? 0 : 25}}>
               <img className="timeline-logo" src={image} />
               <span className={`timeline-text ${(props.phase == "photos" || props.phase == "email") ? "timeline-active" : ""}`}>Photo Preferences</span>
           </div> 
           <div className="timeline-item" style={{marginTop: props.phase == 'photos' ? 0 : 25}}>
               <img className="timeline-logo" src={trello} />
               <span className={`timeline-text ${props.phase == "results" ? "timeline-active" : ""}`}>Results</span>
           </div>
        </div>
        <div className="steps-mobile">

           <div className="timeline-item timeline-border">
                <div><img className="timeline-logo timeline-logo-mobile" src={detailSrc} /></div>               
               {(props.phase == 'location' || props.phase == 'date' || props.phase == 'budget'|| props.phase == 'howimportant') && 
                <div className="mobile-timeline-text-wrapper">
                <span className="timeline-step-text">{detailText}</span><br />
                    <span className="timeline-text-mobile">Wedding Details</span>
                </div>
                }
           </div>

           <div className="timeline-item timeline-border">
               <div><img className="timeline-logo timeline-logo-mobile" src={styleSrc} /></div>
               {(props.phase == "dotogether" || props.phase == "tags") &&
                <div className="mobile-timeline-text-wrapper"> 
                    <span className="timeline-step-text">{styleText}</span><br />
                    <span className="timeline-text-mobile">Style & Attributes</span>
                </div>
                }
           </div>

           <div className="timeline-item timeline-border">
                <div><img className="timeline-logo timeline-logo-mobile" src={photosSrc} /></div>
                {(props.phase == "photos" ) &&
                <div className="mobile-timeline-text-wrapper"> 
                    <span className="timeline-step-text">{photosText}</span><br />
                    <span className="timeline-text-mobile">Photo Preferences</span>
                </div>
                }
           </div> 

           <div className="timeline-item">
               <div><img className="timeline-logo timeline-logo-mobile" src={resultsSrc} /></div>
               {(props.phase == "results" || props.phase == "email") &&
               <div className="mobile-timeline-text-wrapper" style={{paddingRight: '20px'}}> 
                    <span className="timeline-step-text">{resultsText}</span><br />
                    <span className="timeline-text-mobile">Final Results</span>
               </div>
                }
           </div>
        </div>
        <div className="timeline-bar-wrapper">
        <img className="timeline-bar" src={
            props.phase == "budget" ? DateBar : 
            props.phase == "location" ? LocationBar : 
            props.phase == 'date' ? DateBar : 
            props.phase == 'dotogether' ? HowImportantBar : 
            props.phase == 'email' ? EmailBar : 
            props.phase == 'howimportant' ? DateBar : 
            props.phase == 'results' ? ResultsBar : 
            props.phase == 'photos' ? BlankBar : 
            props.phase == 'tags' ? DoTogetherBar : 
            step4} 
            />
        </div>
        </div>
    )
}

export default Timeline;