import React, { useEffect, useState } from "react"
import axios from 'axios'

function PhotoWrapper({p, buttonOnClick, buttonText, deleteOnClick, disabled=false }) {
  const [ showOverlay, setShowOverlay ] = useState(false)
  return (
    <div onClick={() => setShowOverlay(prev => !prev)} className='vendor-photo-wrapper' key={p.id}>
      <div className='vendor-image-wrapper' style={{width: '100%', height: '100%'}}><img key={p.id} src={p.image_url} /></div>
      <div style={showOverlay ? {display:'flex'} : {}} className='vendor-photo-overlay'>
        {deleteOnClick &&
        <div className="tag-button" style={{left: '15px', right: 'inherit'}}>
          <i
            onClick={() => {
              deleteOnClick(p.id);
            }}
            style={{color: '#e55d5d'}}
            class="fas fa-trash"
          ></i>
        </div>}
        {disabled && <div style={{color: 'white', width: '100%', textAlign: 'center', background: 'black'}}>Max of 20 photos reached</div>}
        {!disabled &&<div style={{textAlign: 'center', width: '100%'}}><button disabled={disabled} className='vendor-dashboard-button vendor-dashboard-button-primary' onClick={() => buttonOnClick(p.id)}>{buttonText}</button></div>}
      </div>
    </div>
  )
}


export default function Photos({ vendor }) {
    const [ photos, setPhotos ] = useState([])
    const [ uploading, setUploading] = useState(false)
    const [ tooBig, setTooBig] = useState(false)
    const [ videoUrls, setVideoUrls ] = useState(vendor.additional_properties?.videoUrls ?? [{ url: '', isValid: true }])
    useEffect(() => {        
        axios.get(`/api/vendor-photos/${vendor.id}`).then(result => {
            setPhotos(result.data);
          });
    }, [])

    const uploadImages = e => {
        setUploading(true)
        let photosLength = e.target.files.length;
        let finishedUploading = 0;
        if (e.target.files) {
          if (e.target.files.length > 0) {
            // setUploading(true);
          }
          for (var i = 0; i < e.target.files.length; i++) {
            const formData = new FormData();
    
            formData.append('image', e.target.files[i]);
            if (e.target.files[i].size > 20971520) {
              setTooBig(true);
              setUploading(false);
            }
            formData.append('file', e.target.files[i]);
            formData.append('upload_preset', 'comparison');
            axios
              .post('https://api.cloudinary.com/v1_1/dfjwlskop/image/upload', formData)
              .then(p => {
                const data = {
                  image_url: p.data.secure_url,
                  width: p.data.width,
                  height: p.data.height,
                  aspect_ratio: p.data.width / p.data.height,
                  vendorId: vendor.id
                };
                axios.post('/api/vendor-photo-upload', data).then(r => {
                  setPhotos(r.data);
                  finishedUploading += 1;
                  if (finishedUploading == photosLength) {
                    setUploading(false);
                  }
                });
              })
              .catch(e => {
                console.log(e);
                axios.post('/api/log-error', { error: e });
              });
          }
        }
    }

    const addToPortfolio = id => {
      setPhotos(prev => prev.filter(p => p.id != id))
      axios.post('/api/vendor-photo-toggle-active', { id, timeOfDay: 'active', vendorId: vendor.id })
      .then(r => setPhotos(r.data))
    }
    
    const removeFromPortfolio = id => {
      setPhotos(prev => prev.filter(p => p.id != id))
      axios.post('/api/vendor-photo-toggle-active', { id, timeOfDay: null, vendorId: vendor.id })
      .then(r => setPhotos(r.data))
    }
    
    const deletePhoto = id => {      
      setPhotos(prev => prev.filter(p => p.id != id))
      axios.post('/api/vendor-photo-delete', { id, vendorId: vendor.id })
      
    }
    const isValidVideoURL = (url) => {
      const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|vimeo\.com)\/.+$/;
      return pattern.test(url);
    }

    const updateVideoUrl = (e, index) => {
      const before = [...videoUrls.slice(0, index)]
      const after = [...videoUrls.slice(index + 1, videoUrls.length)]
      const url = e.target.value
      let inserting = null
      if (isValidVideoURL(e.target.value)) {
        inserting = { url, isValid: true }
      } else {        
        inserting = { url, isValid: e.target.value ? false : true }
      }      
      setVideoUrls(prev => {
        if (url) {          
          return [...before, inserting, ...after]
        }
        return [...before, ...after]
      })
    }

    const handleSubmit = () => {
        axios.post('/api/update-vendor-properties', {videoUrls, vendorId: vendor.id})
        .then(r => window.location = window.location)
    }

    useEffect(() => {
      console.log(videoUrls)
    }, [videoUrls])

    if (vendor.vendor_type == 6) {
      return (
        <div style={{maxWidth: '535px'}} className='vendor-dashboard-card'>
          
          <div className='vendor-photo-section-card-header line-under'><div className='vendor-dashboard-card-header'>Video Portfolio</div><div className='vendor-dashboard-photo-rules'>Max. 10 links</div></div>
          {videoUrls.map((v, index) => 
          <div style={{marginBottom: '8px'}} className='vendor-dashboard-text-input-wrapper'>
            <input style={{width: '100%' }} value={v.url} onChange={e => updateVideoUrl(e, index)} />
            {!v.isValid && <div>URL format invalid</div>}
          </div>
          )}
          {videoUrls.length < 10 && <button className='vendor-dashboard-button vendor-dashboard-button-secondary' style={{marginTop: '8px'}} onClick={() => setVideoUrls(prev => [...prev, { url: '', isValid: true }])}>Add Link</button>}
          
          <div style={{marginTop: '24px'}}>
            <button disabled={videoUrls.some(v => !v.isValid) || !videoUrls.some(v => v.url && v.isValid)} onClick={handleSubmit} className='vendor-dashboard-button vendor-dashboard-button-primary vendor-dashboard-button-save'>Save Changes</button>
          </div>
        </div>
      )
    }
    return (
      <>
            <div style={{maxWidth: '535px'}} className='vendor-dashboard-card'>
            <div className='vendor-photo-section-card-header line-under'><div className='vendor-dashboard-card-header'>Upload Portfolio Photos</div><div className='vendor-dashboard-photo-rules'>20 mb per file max</div></div>
            <input
                        id='file-upload'
                        className="upload-button"
                        type="file"
                        name="image"
                        multiple
                        onChange={uploadImages}
                        style={{display: 'none'}}
                    />
              <label className='vendor-dashboard-button vendor-dashboard-button-primary' style={{cursor: 'pointer', marginRight: '8px'}} for="file-upload" >Choose Files</label>
         
          {tooBig && <div className="profile-inactive">File Too Large. All photos must be less than 20mb.</div>}
          {uploading && <div className="">Uploading...</div>}
          <br />
          </div>
          
          <div style={{maxWidth: '535px'}} className='vendor-dashboard-card'>
          <div className='vendor-dashboard-card-header line-under'>Inactive</div>
          <div className='vendor-photos-wrapper'>
            
          {photos.filter(p => !p.time_of_day).length === 0 && <div className='vendor-dashboard-no-photos'>No photos yet</div>}
          {photos.filter(p => !p.time_of_day).map(p => 
            <PhotoWrapper deleteOnClick={deletePhoto} key={p.id} p={p} disabled={photos.filter(p => p.time_of_day).length > 19} buttonOnClick={addToPortfolio} buttonText='Add to Portfolio' />
          )}
            </div>
            </div>
          <div style={{maxWidth: '535px'}} className='vendor-dashboard-card'>
          <div className='vendor-photo-section-card-header line-under'><div className='vendor-dashboard-card-header'>Portfolio</div><div className='vendor-dashboard-photo-rules'>Min. 10 photos</div></div>
          <div className='vendor-photos-wrapper'>
            {photos.filter(p => p.time_of_day).length === 0 && <div className='vendor-dashboard-no-photos'>No portolio photos yet</div>}
          {photos.filter(p => p.time_of_day).map(p => 
            <PhotoWrapper key={p.id} p={p} buttonOnClick={removeFromPortfolio} buttonText='Remove From Portfolio' />
          )}
            </div>
          </div>
          </>

    )
}