
import React, { useState, useEffect, useContext } from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import SharePopup from '.././share-popup.component';
import _ from 'lodash';
import { Mixpanel } from '../../../Mixpanel';
import '../../../styles/results.css'
import verifiedbadge from '../../../styles/verified-badge.svg';
import ShareLogo from '../../../styles/share.svg';
import Instagram from '../../../styles/instagram.svg';
import Website from '../../../styles/website.svg';
import Email from '../../../styles/email.svg';
import ArrowRight from '../../../styles/arrow-right.svg';
import TickCircle from '../../../styles/tick-circle.svg';
import Calendar from '../../../styles/calendar.svg';
import Location from '../../../styles/location.svg';
import closecircleX from "../../../styles/closecircleX"
import Slider from 'react-slick';
import UpArrow from  "../../../styles/dashboard-icons/upArrow"
import DownArrow from  "../../../styles/dashboard-icons/downArrow"


import { calculateAttributeScores, calculateBudgetScore, calculateLocationScore, calculatePreferenceScore, calculateStyleScore, calculateTagScore, calculateVenueScore, calculateRankScore } from '../../../utils/scoring';


function vendorClicked(name, type) {
	Mixpanel.track(`Vendor ${type} Clicked`, { "Name": name})
}


const Quotes = ({ quotes }) => {
	const [ currentQuote, setCurrentQuote ] = useState(0)
	return (
		<div className='quote-box'>
			<div className='flex-between' style={{alignItems: 'center'}}><div className='header-small'>Verified Review Quotes</div><div className='quote-badge'>{quotes[currentQuote].attribute}</div></div>
			<div className='quote-body'>"{quotes[currentQuote].quote}"</div>
			<div className='flex-between'>
				<div className='quote-credit'><span className='quote-author'>{quotes[currentQuote].name}</span><span className='quote-date'> ● {quotes[currentQuote].date.split('-')[1]}/{quotes[currentQuote].date.split('-')[2].split('T')[0]}/{quotes[currentQuote].date.split('-')[0]}</span></div>
				<div className='quote-arrows'>
					<button onClick={() => setCurrentQuote(prev => prev - 1)} disabled={currentQuote === 0} className='quote-arrow'><img alt='next' className='mirror' src={ArrowRight} /></button>
					<button onClick={() => setCurrentQuote(prev => prev + 1)} disabled={currentQuote === quotes.length - 1} className='quote-arrow'><img alt='next' src={ArrowRight} /></button>
				</div>
			</div>
		</div>
	)
}



const FloristCards = ({ vendorPreferences, vendorMode, vendorPreferencesOpen, setVendorPreferencesOpen }) => {


  const [ vendors, setVendors ] = useState([])
  const { quizState, matchups, setValue, scoredPhotographers, handleChangeText } = useContext(AnswersContext);
  const [ scoredSelections, setScoredSelections ] = useState()
  const [ locations, setLocations ] = useState([])
  const [ blurCard, setBlurCard ] = useState()
  const [ openEmail, setOpenEmail] = useState(null)
  const [ blurScreen, setBlurScreen] = useState(false)
  const [ reviewQuotes, setReviewQuotes ] = useState([])
  const [ extraPhotos, setExtraPhotos ] = useState([])


  // const vendorData = vendorPreferences.find(item => item.vendor_category === 'videographer');




  const saveResults = async (scoredSelectionsTemp) => {
    const token = localStorage.getItem('auth_token');

    // Fetch user info from auth
    const userResponse = await axios.get('/api/supa-auth-test', {
        headers: { Authorization: token },
    });

    let userId = userResponse.data.data.user.id;

    // Save the scored results to the backend
    const savedResults = await axios.post('/api/save-vendor-results', {
        scoredResults: scoredSelectionsTemp.slice(0, 10),
        userId: userId,
        category: 'florist'
    });
    setScoredSelections(scoredSelectionsTemp)
};


useEffect(() => (
    fetchMultiVendors()

), [])

useEffect(() => {
    Mixpanel.track('View Videographer Results Page');
    axios.get('/api/locations')
    .then(r => setLocations(r.data))
}, [])


const fetchMultiVendors = () => {
  quizState.year = 2024
  quizState.month = 11
  quizState.day = 5
  if (quizState.month && quizState.year && quizState.day) {
      axios.get(`/api/vendor-by-date/${quizState.year}-${quizState.month}-${quizState.day}`)
      .then(r => {
        if (r.data) {
            setVendors(r.data)
            calculateVendors(r.data)
        }
      })
  } else {
      console.log('no date')
  }
}


const getPhotos = (vendorIds) => {
    axios.post('/api/vendor-photos-results', {
        vendorIds, // This is the array of vendor IDs you want to pass
    })
    .then(r => {
        setExtraPhotos(r.data)
    })

    axios.post('/api/vendor-review-quotes', {
        vendorIds, // This is the array of vendor IDs you want to pass
      })
      .then(r => {
            if (r.data) {
               setReviewQuotes(r.data.quotes)
            }
      })

}


const calculateVendors = (vendors) => {
  quizState.chosenVendorTypes = ["3"]
  const neededVendors = vendors.filter(v => quizState.chosenVendorTypes.includes(v.vendor_type.toString()))

  const vendorIds = []

  neededVendors.forEach((v) => {
    vendorIds.push(v.id)
  })

  getPhotos(vendorIds)

  const scored = neededVendors.map(vendor => {
    const vend = {...vendor}
    const vendorType = vendorTypeMap[vendor.vendor_type]

    const vendorData = vendorPreferences.find(item => item.vendor_category === 'florist')

    // let servicesScore = 100
    // if (vendorType.servicesOfferedMap && quizState[vendorType.rootProperty] && quizState[vendorType.rootProperty].services) {
    //     if (typeof quizState[vendorType.rootProperty].services === 'object') {
    //         quizState[vendorType.rootProperty].services.forEach(s => {
    //             if (vend.pricing_properties[vendorType.servicesOfferedMap[s]]) {
    //                 servicesScore = 0
    //             }
    //         })
    //     } else {
    //         if (vend.pricing_properties[vendorType.servicesOfferedMap[quizState[vendorType.rootProperty].services]]) {
    //             servicesScore = 0
    //         }
    //     }
    // }
    // vend.servicesScore = servicesScore

    quizState.weddingLocation = 1
    const locationScore = calculateLocationScore(quizState.weddingLocation, vend)
    vend.locationScore = locationScore

    let pricingScores = 0
    let displayBudgetScore = 0
    vendorType.pricingScores.filter(s => !s.condition || s.condition(quizState)).forEach(s => {


        // const userMin = typeof s.userMin ==='function' ? s.userMin(quizState) : _.get(quizState, s.userMin)
        // const userMax = typeof s.userMin ==='function' ? s.userMax(quizState) : _.get(quizState, s.userMax)
        const userMin = parseFloat(vendorData.photographer_min_max[0].replace('$', ''))
        const userMax = parseFloat(vendorData.photographer_min_max[1].replace('$', ''))
        const vendorMinProperty = typeof s.vendorMin ==='function' ? s.vendorMin(quizState) : s.vendorMin
        const vendorMaxProperty = typeof s.vendorMax ==='function' ? s.vendorMax(quizState) : s.vendorMax
        // console.log(vendorMaxProperty)
        let vendorMin = 0
        let vendorMax = 0
        if (typeof vendorMinProperty === 'object') {
            vendorMinProperty.forEach(p => {
                vendorMin += parseInt(vendor.pricing_properties[p])
            })
        } else {
            vendorMin += parseInt(vendor.pricing_properties[vendorMinProperty])
        }
        if (typeof vendorMaxProperty === 'object') {
            vendorMaxProperty.forEach(p => {
                vendorMax += parseInt(vendor.pricing_properties[p])
            })
        } else {
            // console.log('was it this one?')
            // console.log(vendor.pricing_properties[vendorMaxProperty])
            // console.log(vendor)
            vendorMax += parseInt(vendor.pricing_properties[vendorMaxProperty])
        }
        let exceptionScore = 1000
        if (s.exceptions) {
            s.exceptions.forEach(e => {
                if (vend.pricing_properties[e.condition.vendorProperty] && e.condition.quizCondition(quizState)) {
                    exceptionScore = e.score
                }
            })
        }
        // console.log(vendorMax)
        const absoluteMinimum = vend.pricing_properties[vendorType.absoluteMin]
        const score = getMinMaxScore(absoluteMinimum && vendorMin < absoluteMinimum ? absoluteMinimum : vendorMin, absoluteMinimum && vendorMin < absoluteMinimum ? absoluteMinimum : vendorMax, userMin, userMax)
        displayBudgetScore = getDisplayMinMaxScore(absoluteMinimum && vendorMin < absoluteMinimum ? absoluteMinimum : vendorMin, absoluteMinimum && vendorMin < absoluteMinimum ? absoluteMinimum : vendorMax, userMin, userMax)
        vend[s.label] = exceptionScore !== 1000 ? exceptionScore : score
        pricingScores += (exceptionScore !== 1000 ? exceptionScore : score)
    })
    // console.log(locationScore)
    // console.log(pricingScores)
    let vendorLocationWeight = 25
    let vendorBudgetWeight = 25
    vend.budgetScore = pricingScores
    vend.displayBudgetScore = displayBudgetScore
    vend.locationScore = locationScore
    vend.totalScore = (locationScore * vendorLocationWeight) + (pricingScores * vendorBudgetWeight)
    return vend
  })



  saveResults(scored)
}

  const testarray = []

  return (
    <div className="w-full">
        {blurScreen && (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm z-40" />
        )}

        {scoredSelections &&
        <div className="w-full flex flex-col space-y-6 mobile:w-[300px] overflow-hidden mobile:ml-1.5 mobile:mt-5 mobile:hidden">
            {scoredSelections.sort((a, b) => b.totalScore - a.totalScore).slice(0,5).map((s, index) => {
                if (Number(s.displayBudgetScore) > 0) {
                    return (
                        <div className={`relative w-[930px] flex flex-col pt-3 pb-2.5 px-2 border bg-white rounded-xl`}>
                            {openEmail === s.id && (
                                <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 px-5 pt-2.5 pb-4 border bg-white rounded-2xl shadow-lg z-50'>
                                    <div className="w-full flex justify-end">
                                        <button onClick={() => (setOpenEmail(null), setBlurScreen(false))} className="">{closecircleX}</button>
                                    </div>
                                    <p className="text-lg font-medium mt-3">{s.business_name}'s Email: </p>
                                    <p className="mt-3 text-center">{s.contact_email}</p>
                                </div>
                            )}

                            <div className="flex">
                                <div className='relative w-[400px] ml-0.5'>
                                    <div className='result-card-left'>
                                        <div className='image-scroller'>
                                            {testarray.concat(extraPhotos.filter(p => p.vendor_fkey == s.id).slice(0, 10)).map((i, index) =>
                                                <div key={index}>
                                                    <img alt='selected' key={index} src={i.image_url} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="absolute w-full flex justify-center mt-2.5">
                                        {/* <Weddings photographer={s.photographer} /> */}
                                    </div>
                                </div>



                                <div className='w-full pl-3 pr-1'>
                                        <div className='w-full flex items-center'>
                                            <div className="w-[85px] h-[85px] mr-3">
                                                <img src={s.url} alt=""/>
                                            </div>

                                            <div className="w-full ">
                                                <div className='w-full'>
                                                    <div className='w-full flex justify-between mobile:flex-col mobile:mt-10'>

                                                        <div className=''>
                                                            <div className='flex text-xl font-medium'>
                                                                <p>{s.business_name}</p>
                                                                <img alt='' className="ml-1.5" src={verifiedbadge} />
                                                            </div>
                                                            <div className='mt-0.5 text-sm text-[#8C8C8C]'>{locations.length > 0 && s.primary_location && locations.find(l => l.id == s.primary_location).name}</div>
                                                        </div>


                                                        <div className='flex'>
                                                            {/* <div className='percent-match-wrapper'>
                                                                <div className='text-lg font-medium'>{Math.round((s.totalScore)/100)}%</div>
                                                                <div className='percentage-bar-background' style={{width: `${Math.round((s.totalScore)/30)}%`}}></div>
                                                                <div className='percentage-bar'><span className="text-xs text-[#8C8C8C]">Match</span></div>
                                                            </div> */}
                                                            <div className='result-left percent-match-wrapper' style={{position: 'relative'}}>
                                                                <div className='percentage'>{Math.round(((s.displayBudgetScore * 25) + (s.locationScore * 25)) / 50)}%</div>
                                                                <div className='percentage-bar-background'></div>
                                                                <div className='percentage-bar' style={{width: `${Math.round(((s.displayBudgetScore * 40) + (s.locationScore * 10))/50)}%`}}></div>
                                                                <div style={{marginTop: '6px', fontSize: '10px'}} className=''>Match</div>
                                                            </div>
                                                            <div className='flex items-center justify-center px-[12px] py-2 ml-3 rounded-xl text-xl text-greenblue font-medium bg-[#F3F7F8]'>
                                                                <span>#</span><span>{index + 1}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="h-[30px] flex items-center">
                                                    {/* <Tokens photographer={s.photographer} /> */}
                                                </div>
                                            </div>
                                    </div>

                                    <div className='result-middle'>
                                            <div className='stats'>
                                                <div className='header-small'>Match Stats</div>
                                                    {/* {false && Object.entries(s.photographer.attributes).sort((a, b) => a[1] > b[1] ? -1 : 1).slice(0, 3).map((o, index) => <Stat key={index} name={o[0]} score={Math.round(o[1]*20 + 80)} />)} */}
                                                    <Stat name='Location' score={Math.round(s.locationScore)} />
                                                    <Stat name='Budget' score={Math.round(s.displayBudgetScore)} />
                                                    {/* <Stat name='Editing Style' score={Math.round(s.photographer.styleScore/2) + 50} /> */}
                                            </div>
                                            <div className='quotes'>
                                                {/* {reviewQuotes.filter(q => q.vendor_id == s.id).length > 0 && <Quotes quotes={reviewQuotes.filter(q => q.vendor_id == s.id)} />} */}
                                                {reviewQuotes.filter(q => q.vendor_id == s.id).length > 0 && <Quotes quotes={reviewQuotes.filter(q => q.vendor_id == s.id)} />}
                                                {!reviewQuotes.filter(q => q.vendor_id ==  s.id).length > 0 && <div className='quote-box'><div className='no-reviews'>This florist doesn't have reviews yet.</div></div>}
                                            </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`pl-[38px] pr-3 mt-2.5`}>
                                <div className='w-full flex justify-end'>
                                    {s.instagram_url && <a onClick = {(e) => vendorClicked(s.business_name, 'instagram')} target='_blank' rel='noopener noreferrer' href={s.instagram_url.substr(0,4) != "http" ? `https://${s.instagram_url}` : s.instagram_url}>
                                        <button className='round-button'><img alt='' src={Instagram} /></button>
                                    </a>}
                                    <button onClick = {(e) => vendorClicked(s.business_name, 'email')}  target='_blank' rel='noopener noreferrer' href={`mailto: ${s.email}`}>
                                        <button onClick = {() => (setOpenEmail(s.id), setBlurScreen(true))} className='social-button'><img alt='' src={Email} />Email</button>
                                    </button>
                                    <a className="mt-[1px]" onClick = {(e) => vendorClicked(s.business_name, 'website')} target='_blank' rel='noopener noreferrer' href={s.website_url.substr(0,4) != "http" ? `https://${s.website_url}` : s.website_url}>
                                        <button className='social-button inverted'><img alt='' src={Website} />Website</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                }
            }
            )}
        </div>
        }

        {scoredSelections &&
            <div className="hidden mobile:flex mobile:flex-col w-full">
                {scoredSelections.sort((a, b) => b.totalScore - a.totalScore).slice(0,5).map((s, index) => {
                    if (Number(s.displayBudgetScore) > 0) {
                        return (
                            <div className='result-card result-card-mobile'>
                                <div className='result-top' style={{display: 'flex'}}>
                                    <div className="w-[80px] h-[80px]">
                                        <img src={s.url} alt=""/>
                                    </div>
                                    <div style={{width: '100%', paddingTop: '6px', marginLeft: '10px'}}>
                                        <div className='result-top-right-top'>
                                            <div className='result-top-right' style={{display: 'flex'}}>
                                                <div className='result-left'>
                                                    <div className='header' style={{marginBottom: 0, color: 'black'}}>
                                                        {s.business_name}<img alt='' className="verified-badge" src={verifiedbadge} style={{marginLeft: '10px'}}/>
                                                    </div>
                                                    <div className='subheader'>{locations.length > 0 && s.primary_location && locations.find(l => l.id == s.primary_location).name}</div>
                                                </div>
                                                <div className='result-right rank'>
                                                    <span>#</span><span style={{fontSize: '24px'}}>{index + 1}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex-between' style={{alignItems: 'center', marginBottom: '3px'}}>
                                    {/* <Tokens photographer={s} /> */}

                                    <div style={{position: 'relative'}} className='percent-match-wrapper'>
                                        <div className='percentage'>{Math.round(((s.displayBudgetScore * 40) + (s.locationScore * 10))/50)}%</div>
                                        <div className='percentage-bar-background'></div>
                                        <div className='percentage-bar' style={{width: `${Math.round(((s.displayBudgetScore * 40) + (s.locationScore * 10))/50)}%`}}></div>
                                        <div className='subheader' style={{marginTop: '6px', marginBottom: '16px', fontSize: '10px'}}>Match</div>
                                    </div>
                                </div>

                                <div className='image-scroller'>
                                    {testarray.concat(extraPhotos.filter(p => p.vendor_fkey == s.id).slice(0, 10)).map((i, index) =>
                                        <div key={index}>
                                            <img alt='selected' key={index} src={i.image_url} />
                                        </div>
                                    )}
                                </div>

                                <div className='quotes'>
                                    {reviewQuotes.filter(q => q.vendor_id == s.id).length > 0 && <Quotes quotes={reviewQuotes.filter(q => q.vendor_id == s.id)} />}
                                    {!reviewQuotes.filter(q => q.vendor_id == s.id).length > 0 && <div className='quote-box'><div className='no-reviews'>This florist doesn't have reviews yet.</div></div>}
                                </div>

                                <div className='stats'>
                                    <div className='header-small'>Match Stats</div>
                                    <Stat name='Location' score={Math.round(s.locationScore)} />
                                    <Stat name='Budget' score={Math.round(s.displayBudgetScore)} />
                                </div>

                                <div className="relative text-xs">
                                    {openEmail === s.id && (
                                        <div className='absolute bottom-[130px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 px-[30px] pt-2.5 pb-4 border bg-white rounded-2xl shadow-lg z-50'>
                                            <div className="w-full flex justify-end">
                                                <button onClick={() => (setOpenEmail(null), setBlurScreen(false))} className="">{closecircleX}</button>
                                            </div>
                                            <p className="text-lg font-medium mt-3">{s.business_name}'s Email: </p>
                                            <p className="mt-3 text-center text-sm">{s.contact_email}</p>
                                        </div>
                                    )}
                                </div>

                                <div className='social'>
                                    {s.instagram_url && <a onClick={(e) => vendorClicked(s.business_name, 'instagram')} target='_blank' rel='noopener noreferrer' href={s.instagram_url.substr(0,4) != "http" ? `https://${s.instagram_url}` : s.instagram_url}>
                                        <button className='round-button'><img alt='' src={Instagram} /></button>
                                    </a>}
                                    <div onClick={(e) => vendorClicked(s.business_name, 'email')} target='_blank' rel='noopener noreferrer'>
                                        <button onClick={() => (setOpenEmail(s.id), setBlurScreen(true))} className='social-button'><img alt='' src={Email} />Email</button>
                                    </div>
                                    <a onClick={(e) => vendorClicked(s.business_name, 'website')} target='_blank' rel='noopener noreferrer' href={s.website_url.substr(0,4) != "http" ? `https://${s.website_url}` : s.website_url}>
                                        <button className='social-button inverted'><img alt='' src={Website} />Website</button>
                                    </a>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        }
    </div>
  )
}

export default FloristCards



const vendorRemap = {
  "Planner": "13",
  "Hair and Makeup": "7",
  "Florists": "3",
  "Videographers": "6",
  "DJs": "5",
  "Cake Bakers": "2"
}






const vendorTypeMap = {
  'details': {
      components: [
          {
              property: 'weddingDate',
              alternateProperty: 'dateNotDecided',
              jsx: <span>I'm getting married on </span>,
              displayModal: 'WeddingDate',
              label: 'select date',
              value: quizState => {
                  if (quizState.dateNotDecided) return 'no date yet'
                  if (typeof quizState.weddingDate !== 'object') return quizState.weddingDate
                  return `${quizState.weddingDate.getMonth() + 1}/${quizState.weddingDate.getDate()}/${quizState.weddingDate.getFullYear()}`
              }
          },
          {
              property: 'weddingLocation',
              jsx: <span> in </span>,
              displayModal: 'WeddingLocation',
              label: 'select location',
              value: (quizState, locations) => {
                  if (quizState.weddingLocation) {
                      return `${locations.find(l => l.id == quizState.weddingLocation)?.name} ${quizState.venueName ? `at ${quizState.venueName}` : ''}`
                  }
              }
          },
          {
              property: 'overallBudgetMin',
              jsx: <span>&nbsp;with a total wedding budget between </span>,
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              property: 'overallBudgetMax',
              jsx: <span> and </span>,
              label: 'max',
              small: true,
              type: 'money',
          },
          {
              property: 'minGuests',
              jsx: <span>. I plan to have between </span>,
              label: 'min',
              type: 'number',
              small: true,
          },
          {
              property: 'maxGuests',
              jsx: <span> and </span>,
              label: 'max',
              type: 'number',
              small: true,
          },
          {
              property: 'chosenTags',
              jsx: ' guests. Some words that describe my wedding are ',
              label: 'select tags',
              optionsProperty: 'tags',
              header: 'Tags',
              subheader: `We'll use your choices here to show you a custom set of real wedding photos that best match your big day.`,
              question: 'Select any of these words that describe this wedding.',
              displayModal: 'Options',
              value: (quizState, locations, tags) => quizState.chosenTags?.map(t => tags.find(ta => ta.id == t)?.name).join(', ')
          },
          {
              jsx: '.'
          }

      ],
  },
  13: { // Planner
      rootProperty: 'planner',
      pricingScores: [

          {
              label: 'Budget (dayOf)',
              vendorMin: 'dayOfMinimum',
              vendorMax: 'dayOfMaximum',
              userMin: 'planner.budgetMin',
              userMax: 'planner.budgetMax',
              condition: (quizState) => quizState.planner?.services === "Day-of Coordination Only"
          },
          {
              label: 'Budget (partialPlanning)',
              vendorMin: 'partialPlanningMinimum',
              vendorMax: 'partialPlanningMaximum',
              userMin: 'planner.budgetMin',
              userMax: 'planner.budgetMax',
              condition: (quizState) => quizState.planner?.services === "Partial planning"
          },
          {
              label: 'Budget (fullService)',
              vendorMin: 'fullServiceMinimum',
              vendorMax: 'fullServiceMaximum',
              userMin: 'planner.budgetMin',
              userMax: 'planner.budgetMax',
              condition: (quizState) => quizState.planner?.services === "Full service"
          },
          {
              label: 'Budget (overall)',
              vendorMin: 'overallBudgetMinimum',
              vendorMax: 'overallBudgetMaximum',
              userMin: 'overallBudgetMin',
              userMax: 'overallBudgetMax',
          },
      ],
      components: [
          {
              jsx: <span>I'm looking for</span>,
              label: 'select services',
              property: 'planner.services',
              selectOne: true,
              options: ['Full service', 'Day-of Coordination Only', 'Partial planning'],
              header: 'Wedding Planning Services',
              subheader: 'We collect detailed pricing from planners to make sure they match your budget.',
              question: 'Which planning services are you interested in?',
              displayModal: 'Options',
              value: (quizState) => `${quizState.planner.services} planning`
          },
          {
              jsx: <span>. My budget for a wedding planner is between </span>,
              property: 'planner.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'planner.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          }
      ],
      servicesOfferedMap: {
          'Day-of Coordination Only': 'dayOfCoordinationDisabled',
          'Full service': 'fullServiceDisabled',
          'Partial planning': 'partialPlanningDisabled'
      }

  },
  3: { // Florist
      pricingScores: [
          {
              label: 'Budget',
              vendorMin: 'averageSpendMinimum',
              vendorMax: 'averageSpendMaximum',
              userMin: 'florist.budgetMin',
              userMax: 'florist.budgetMax',
          },

      ],
      components: [
          {
              jsx: <span>I'm looking for </span>,
              label: 'select flowers',
              property: 'florist.services',
              options: ['Bridal Bouquet', 'Bridesmaids Bouquets', 'Boutonnieres', 'Ceremony Decor', 'Centerpieces'],
              header: 'Florist Services',
              subheader: 'We collect detailed pricing from florists to make sure they match your budget.',
              question: 'Which florist services are you interested in?',
              displayModal: 'Options',
              value: (quizState) => quizState.florist?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for flowers is between </span>,
              property: 'florist.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'florist.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          }
      ],
  },
  2: { // Cake
      pricingScores: [
          {
              label: 'Budget',
              vendorMin: 'perPersonMinimum',
              vendorMax: 'perPersonMaximum',
              userMin: (quizState) => {
                  return cleanNumber(quizState.baker.budgetMin) / ((cleanNumber(quizState.minGuests) + cleanNumber(quizState.maxGuests))/2)
              },
              userMax: (quizState) => {
                  return cleanNumber(quizState.baker.budgetMax) / ((cleanNumber(quizState.minGuests) + cleanNumber(quizState.maxGuests))/2)
              }
          },

      ],
      components: [
          {
              jsx: <span>I'm looking for</span>,
              label: 'select desserts',
              property: 'baker.services',
              options: ['Cake', 'Cookies and Macarons', 'Cupcakes', 'Mini Desserts', 'Pies', 'Donuts', 'Pastries', 'Candies'],
              header: 'Baker Services',
              subheader: 'We collect detailed pricing from bakers to make sure they match your budget.',
              question: 'Which baker services are you interested in?',
              displayModal: 'Options',
              value: (quizState) => quizState.baker?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for desserts is between </span>,
              property: 'baker.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'baker.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          }
      ],

  },
  5: { // DJ
      pricingScores: [
          {
              label: 'Budget',
              vendorMin: (quizState) => {
                  const serviceMap = {
                      'Reception Coverage': 'average',
                      'Ceremony Coverage': 'ceremonyAverage',
                      'Photo-booth': 'photobooth',
                      'Up-lighting': 'uplighting'
                  }
                  if (quizState.dj.services.length === 1) {
                      if(quizState.dj.services[0] === 'Reception Coverage') {
                          return 'average'
                      }
                      if (quizState.dj.services[0] === 'Ceremony Coverage') {
                          return 'ceremonyAverage'
                      }
                  }
                  if (quizState.dj.services.length === 2) {
                      if (quizState.dj.services.includes('Reception Coverage') && quizState.dj.services.includes('Ceremony Coverage')) {
                          return 'receptionCeremonyAverage'
                      }

                  }
                  if (quizState.dj.services.includes('Reception Coverage') && quizState.dj.services.includes('Ceremony Coverage')) {
                      return ['receptionCeremonyAverage', ...quizState.dj.services.filter(s => s !== 'Reception Coverage' && s !== 'Ceremony Coverage').map(s => serviceMap[s])]
                  }
                  return quizState.dj.services.map(s => serviceMap[s])
              },
              vendorMax: (quizState) => {
                  const serviceMap = {
                      'Reception Coverage': 'average',
                      'Ceremony Coverage': 'ceremonyAverage',
                      'Photo-booth': 'photobooth',
                      'Up-lighting': 'uplighting'
                  }
                  if (quizState.dj.services.length === 1) {
                      if(quizState.dj.services[0] === 'Reception Coverage') {
                          return 'average'
                      }
                      if (quizState.dj.services[0] === 'Ceremony Coverage') {
                          return 'ceremonyAverage'
                      }
                  }
                  if (quizState.dj.services.length === 2) {
                      if (quizState.dj.services.includes('Reception Coverage') && quizState.dj.services.includes('Ceremony Coverage')) {
                          return 'receptionCeremonyAverage'
                      }

                  }
                  if (quizState.dj.services.includes('Reception Coverage') && quizState.dj.services.includes('Ceremony Coverage')) {
                      return ['receptionCeremonyAverage', ...quizState.dj.services.filter(s => s !== 'Reception Coverage' && s !== 'Ceremony Coverage').map(s => serviceMap[s])]
                  }
                  return quizState.dj.services.map(s => serviceMap[s])
              },
              userMin: 'dj.budgetMin',
              userMax: 'dj.budgetMax',
              exceptions: [
                  {
                      score: -100,
                      condition: {
                          vendorProperty: 'ceremonyOnlyDisabled',
                          quizCondition: quizState => quizState.dj.services.includes('Ceremony Coverage') && quizState.dj.services.length === 1
                      }
                  }
              ]
          },

      ],
      components: [
          {
              jsx: <span>I'm interested in</span>,
              label: 'select services',
              property: 'dj.services',
              options: ['Reception Coverage', 'Ceremony Coverage', 'Up-lighting', 'Photo-booth'],
              header: 'DJ Services',
              subheader: 'We collect detailed pricing from DJs to make sure they match your budget.',
              question: 'Which DJ services are you interested in?',
              displayModal: 'Options',
              messageCondition: (quizState) => quizState.dj?.services?.length && !quizState.dj?.services?.includes('Reception Coverage') && !quizState.dj?.services?.includes('Ceremony Coverage'),
              message: 'Reception and/or Ceremony coverage required',
              disableCondition: (quizState) => !quizState.dj?.services?.includes('Reception Coverage') && !quizState.dj?.services?.includes('Ceremony Coverage'),
              value: (quizState) => quizState.dj?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for these services is between </span>,
              property: 'dj.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'dj.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          }
      ],
  },
  15: { // Photographer
      components: [
          {
              jsx: <span>I'm interested in</span>,
              label: 'select services',
              property: 'photographer.services',
              options: ['Getting Ready', 'Ceremony', 'Couples Portraits', 'Wedding Party',  'Reception', 'Formal Dance'],
              header: 'Photography Coverage',
              subheader: 'We collect detailed pricing from photographers to make sure they match your budget.',
              question: 'Which photographer services are you interested in?',
              displayModal: 'Options',
              value: (quizState) => quizState.photographer?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for these services is between </span>,
              property: 'photographer.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'photographer.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          },
          {
              property: 'photographer.minHours',
              jsx: <span>. I'd like between </span>,
              label: 'min',
              type: 'number',
              small: true,
          },
          {
              property: 'photographer.maxHours',
              jsx: <span> and </span>,
              label: 'max',
              type: 'number',
              small: true,
          },
          {
              jsx: <span> hours of coverage and I most care about my photographer being </span>,
              label: 'select attributes',
              property: 'photographer.personality',
              // options: attributeOptions,
              header: 'Photographer Personality',
              subheader: 'What is most important to you in a photographer?',
              question: 'What is most important to you in a photographer?',
              displayModal: 'Options',
              value: (quizState) => quizState.photographer.personality.join(', ')
          },
          {
              property: 'photoStyles',
              alternateProperty: 'styleNotImportant',
              jsx: <span> My favorite photographer editing style is </span>,
              displayModal: 'PhotoStyles',
              label: 'Photo Style',
              value: quizState => {
                  if (!quizState.photoStyles.find(s => s.rank)) return 'no style yet'
                  return quizState.photoStyles.find(s => s.rank === 1).description
              }
          },
      ],
  },
  6: { // Videographer
      pricingScores: [
          {
              label: 'Budget (From coverageAverage)',
              vendorMin: 'coverageAverageMinimum',
              vendorMax: 'coverageAverageMaximum',
              userMin: 'videographer.budgetMin',
              userMax: 'videographer.budgetMax',
              condition: (quizState) => {
                 // return quizState.videographer?.services?.includes('Ceremony Coverage') || quizState.videographer?.services?.includes('Reception Coverage') || quizState.videographer?.services?.includes('Drone Coverage') || quizState?.videographer?.services?.includes('Highlight Film')
                 return true
              }
          },
          {
              label: 'Budget (From averageSpend)',
              vendorMin: 'averageSpendMinimum',
              vendorMax: 'averageSpendMaximum',
              userMin: 'videographer.budgetMin',
              userMax: 'videographer.budgetMax',
              condition: (quizState) => {
                  // return !(quizState.videographer?.services?.includes('Ceremony Coverage') || quizState.videographer?.services?.includes('Reception Coverage') || quizState.videographer?.services?.includes('Drone Coverage') || quizState?.videographer?.services?.includes('Highlight Film'))
                  return true
              }
          },

      ],
      components: [
          {
              jsx: <span>I'm interested in</span>,
              label: 'select services',
              property: 'videographer.services',
              options: ['Reception Coverage', 'Ceremony Coverage', 'Drone Coverage', 'Highlight Film', 'Full-length Film'],
              header: 'Videography Coverage',
              subheader: 'We collect detailed pricing from videographers to make sure they match your budget.',
              question: 'Which videographer services are you interested in?',
              displayModal: 'Options',
              value: (quizState) => quizState.videographer?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for video is between </span>,
              property: 'videographer.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'videographer.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          },
      ],
  },
  7: { // Hair/makeup
      /*

          'minimumTotal': "5000",
          'bridalHairMinimum': "500",
          "bridalHairMaximum": "6000",
          'bridalMakeupMinimum': "500",
          "bridalMakeupMaximum": "6000",
          'bridesmaidHairMinimum': "500",
          "bridesmaidHairMaximum": "6000",
          'bridesmaidMakeupMinimum': "500",
          "bridesmaidMakeupMaximum": "6000",
          "minimumDisabled": false,
          "bridalHairDisabled": false,
          "bridalMakeupDisabled": false,
          "bridesmaidHairDisabled": false,
          "bridesmaidMakeupDisabled": false,
      */
      absoluteMin: 'minimumTotal',
      pricingScores: [
          {
              label: 'Budget',
              vendorMin: (quizState) => {
                  const properties = []
                  const services = quizState.hair?.services
                  if (services) {
                      if (services.includes('Bridal Hair')) {
                          properties.push('bridalHairMinimum')
                      }
                      if (services.includes('Bridal Makeup')) {
                          properties.push('bridalMakeupMinimum')
                      }
                      if (services.includes('Bridesmaids Makeup')) {
                          Array.from(Array(quizState.hair.bridesmaidsMakeup ? parseInt(quizState.hair.bridesmaidsMakeup) : 0)).forEach(a => {
                              properties.push('bridesmaidMakeupMinimum')
                          })
                      }
                      if (services.includes('Bridesmaids Hair')) {
                          Array.from(Array(quizState.hair.bridesmaidsHair ? parseInt(quizState.hair.bridesmaidsHair) : 0)).forEach(a => {
                              properties.push('bridesmaidHairMinimum')
                          })
                      }
                  }
                  return properties
              },
              vendorMax: (quizState) => {
                  const properties = []
                  const services = quizState.hair?.services
                  if (services) {
                      if (services.includes('Bridal Hair')) {
                          properties.push('bridalHairMaximum')
                      }
                      if (services.includes('Bridal Makeup')) {
                          properties.push('bridalMakeupMaximum')
                      }
                      if (services.includes('Bridesmaids Makeup')) {
                          Array.from(Array(quizState.hair.bridesmaidsMakeup ? parseInt(quizState.hair.bridesmaidsMakeup) : 0)).forEach(a => {
                              properties.push('bridesmaidMakeupMaximum')
                          })
                      }
                      if (services.includes('Bridesmaids Hair')) {
                          Array.from(Array(quizState.hair.bridesmaidsHair ? parseInt(quizState.hair.bridesmaidsHair) : 0)).forEach(a => {
                              properties.push('bridesmaidHairMaximum')
                          })
                      }
                  }
                  return properties
              },
              userMin: 'hair.budgetMin',
              userMax: 'hair.budgetMax',
              exceptions: [
                  {
                      score: -100,
                      condition: {
                          vendorProperty: 'bridalHairDisabled',
                          quizCondition: quizState => quizState.hair?.services.includes('Bridal Hair')
                      },
                  },
                  {
                      score: -100,
                      condition: {
                          vendorProperty: 'bridalMakeupDisabled',
                          quizCondition: quizState => quizState.hair?.services.includes('Bridal Makeup')
                      }
                  },
                  {
                      score: -100,
                      condition: {
                          vendorProperty: 'bridesmaidHairDisabled',
                          quizCondition: quizState => quizState.hair?.services.includes('Bridesmaid Hair')
                      },
                  },
                  {
                      score: -100,
                      condition: {
                          vendorProperty: 'bridesmaidMakeupDisabled',
                          quizCondition: quizState => quizState.hair?.services.includes('Bridesmaid Makeup')
                      },
                  }
              ]
          },

      ],
      components: [
          {
              jsx: <span>I'm interested in</span>,
              label: 'select services',
              property: 'hair.services',
              options: ['Bridal Hair', 'Bridal Makeup', 'Bridesmaids Hair', 'Bridesmaids Makeup', 'More'],
              header: 'Hair and Makeup',
              subheader: 'We collect detailed pricing from hair and makeup vendors to make sure they match your budget.',
              question: 'Which hair and makeup services are you interested in?',
              displayModal: 'HairAndMakeupOptions',
              value: (quizState) => quizState.hair?.services?.join(', ')
          },
          {
              jsx: <span>. My budget for these services is between </span>,
              property: 'hair.budgetMin',
              label: 'min',
              small: true,
              type: 'money',
          },
          {
              jsx: <span> and </span>,
              property: 'hair.budgetMax',
              label: 'max',
              small: true,
              type: 'money',
          },
      ],
  },
}




const getMinMaxScore = (vMin, vMax, uMin, uMax) => {
    // console.log(vMin, vMax)
    if (uMin && uMax) {
        const userMin = cleanNumber(uMin)
        const userMax = cleanNumber(uMax)
        const vendorMin = parseInt(vMin)
        const vendorMax = parseInt(vMax)
        if (vendorMin >= userMin && vendorMax <= userMax) {
            return 100
        }
        if (userMax > vendorMax && userMin > vendorMin) {
            return 95
        }
        if (userMin > vendorMax && userMax > vendorMax) {
            if (userMin <= vendorMax * 1.5) {
                return 70
            }
            return 30
        }
        if (userMin >= vendorMin && userMax <= vendorMax) {
            return 70
        }
        if (userMax >= vendorMin && userMax <= vendorMax && userMin < vendorMin) {
            return 60
        }
        if (userMax > vendorMin * .5) {
            return 30
        }
        return -100
    }
    return -100
  }


  const getDisplayMinMaxScore = (vMin, vMax, uMin, uMax) => {
      // console.log(vMin, vMax)
      if (uMin && uMax) {
          const userMin = cleanNumber(uMin)
          const userMax = cleanNumber(uMax)
          const vendorMin = parseInt(vMin)
          const vendorMax = parseInt(vMax)
          // console.log(userMin, userMax)
          // console.log(vendorMin, vendorMax)
          if (vendorMin >= userMin && vendorMax <= userMax) {
              return 100
          }
          if (userMax > vendorMax && userMin > vendorMin) {
            return 95
          }
          if (userMin > vendorMax && userMax > vendorMax) {
              if (userMin <= vendorMax * 1.5) {
                  return 85
              }
              return 50
          }
          if (userMin >= vendorMin && userMax <= vendorMax) {
              return 85
          }
          if (userMax >= vendorMin && userMax <= vendorMax && userMin < vendorMin) {
              return 70
          }
          if (userMax > vendorMin * .5) {
              return 50
          }
          return -100
      }
      return -100
    }



const cleanNumber = number => {
  if (typeof number === 'number') {
      return number
  }
  if (number || number === 0) {
      return parseInt(number.replace('$', '').replace(/,/g, ''))
  } return 0
}



const vendorsTestData = [
  {
      business_name: 'Test Videographer',
      vendor_type: 6,
      pricing_properties: {
          "offSeasonMaximum": "5000",
          "offSeasonMinimum": "3500",
          "averageSpendMaximum": "5500",
          "averageSpendMinimum": "3900",
          "coverageAverageMaximum": "4500",
          "coverageAverageMinimum": "3500"
      }
  },
  {
      business_name: 'Test Planner',
      vendor_type: 13,
      pricing_properties: {
          "dayOfMinimum": "3500",
          "dayOfMaximum": "5000",
          "partialPlanningMinimum": "500",
          "partialPlanningMaximum": "3900",
          "fullServiceMinimum": "3500",
          "fullServiceMaximum": "6000",
          "overallBudgetMinimum": "10000",
          "overallBudgetMaximum": "20000",
          "dayOfCoordinationDisabled": false,
          "partialPlanningDisabled": true,
          "fullServiceDisabled": false
      }
  },
  {
      business_name: 'Test Florist',
      vendor_type: 3,
      pricing_properties: {
          "averageSpendMinimum": "3500",
          "averageSpendMaximum": "5000",
      }
  },
  {
      business_name: 'Test Baker',
      vendor_type: 2,
      pricing_properties: {
          'perPersonMinimum': "5",
          'perPersonMaximum': "10",
      }
  },
  {
      business_name: 'Test DJ',
      vendor_type: 5,
      pricing_properties: {

          'average': "5000",
          'extraHourAverage': "500",
          "ceremonyOnlyDisabled": true,
          "receptionCeremonyAverage": "6000",
          "uplightingDisabled": false,
          "photoboothDisabled": false,
          "photobooth": "500",
          "ceremonyAverage": "3000",
          "uplighting": "600",
      }
  },
  {
      business_name: 'Test Makeup',
      vendor_type: 7,
      pricing_properties: {
          'minimumTotal': "5000",
          'bridalHairMinimum': "500",
          "bridalHairMaximum": "6000",
          'bridalMakeupMinimum': "500",
          "bridalMakeupMaximum": "6000",
          'bridesmaidHairMinimum': "500",
          "bridesmaidHairMaximum": "6000",
          'bridesmaidMakeupMinimum': "500",
          "bridesmaidMakeupMaximum": "6000",
          "minimumDisabled": false,
          "bridalHairDisabled": false,
          "bridalMakeupDisabled": false,
          "bridesmaidHairDisabled": false,
          "bridesmaidMakeupDisabled": false,
      }
  }
]


const Stat = ({name, score}) => {
	return (
		<div className='stat-wrapper'>
			<div className='stat-text-wrapper'>
				<div className='stat-name'>{name}</div>
				<div className='stat-score-wrapper'><span className='stat-score'>{score}</span><span className='stat-out-of'>% Match</span></div>
			</div>
			<div className='stat-bar'>
				<div className='stat-bar-background'></div>
				<div className='stat-bar-bar' style={{width: `${score}%`}}></div>
				<div className='stat-bar-circle' style={{left: `calc(${score}% - 5px)`}}></div>
			</div>
		</div>
	)
}



const testReview = [
    {
        "quote_id": 36086,
        "vendor_id": 53264,
        "quote": "\"Hire Mirna- we're so glad we did.\"",
        "attribute": "Creative",
        "rating": 70,
        "date": "2021-12-11T05:00:00.000Z"
    },
    {
        "quote_id": 36084,
        "vendor_id": 53264,
        "quote": "\"Mirna did such an awesome job as day of planner and florist for our wedding at Market at Grelen.\"",
        "attribute": "Experienced",
        "rating": 85,
        "date": "2021-12-11T05:00:00.000Z"
    },
    {
        "quote_id": 36085,
        "vendor_id": 53264,
        "quote": "\"The day was an absolute dream and the reception was gorgeous- I truly felt like it was the romantic and intimate setting I had always hoped for.\"",
        "attribute": "Organized",
        "rating": 70,
        "date": "2021-12-11T05:00:00.000Z"
    }
]