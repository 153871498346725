import React, { useState, useEffect } from 'react';
import infologo from '../../styles/info.svg';
import command from '../../styles/command.svg';
import image from '../../styles/image.svg';
import trello from '../../styles/trello.svg';
import step4 from '../../styles/step4.svg';
import BlankBar from '../../styles/blank-bar.svg';
import BudgetBar from '../../styles/budget-bar.svg';
import LocationBar from '../../styles/location-bar.svg';
import DateBar from '../../styles/date-bar.svg';
import DoTogetherBar from '../../styles/do-together-bar.svg';
import EmailBar from '../../styles/email-bar.svg';
import HowImportantBar from '../../styles/how-important-bar.svg';
import ResultsBar from '../../styles/results-bar.svg';
import TagsBar from '../../styles/tags-bar.svg';
import Results2 from '../../styles/results2.svg';
import Results1 from '../../styles/results1.svg';
import Style3 from '../../styles/style3.png';
import Style2 from '../../styles/style2.png';
import Style1 from '../../styles/style1.png';
import PhotoStep from '../../styles/photostep.svg';
import Details3 from '../../styles/details3.svg';
import Details2 from '../../styles/details2.svg';
import Details1 from '../../styles/details1.svg';
import ResultsInactive from '../../styles/results-inactive.svg';
import PhotosInactive from '../../styles/photos-inactive.svg';
import StyleInactive from '../../styles/style-inactive.png';
import TimelineComplete from '../../styles/timeline-complete.svg';


const detailsIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 12.95H8C7.59 12.95 7.25 12.61 7.25 12.2C7.25 11.79 7.59 11.45 8 11.45H15C15.41 11.45 15.75 11.79 15.75 12.2C15.75 12.61 15.41 12.95 15 12.95Z" fill="white"/>
        <path d="M12.38 16.95H8C7.59 16.95 7.25 16.61 7.25 16.2C7.25 15.79 7.59 15.45 8 15.45H12.38C12.79 15.45 13.13 15.79 13.13 16.2C13.13 16.61 12.79 16.95 12.38 16.95Z" fill="white"/>
        <path d="M14 6.75H10C9.04 6.75 7.25 6.75 7.25 4C7.25 1.25 9.04 1.25 10 1.25H14C14.96 1.25 16.75 1.25 16.75 4C16.75 4.96 16.75 6.75 14 6.75ZM10 2.75C9.01 2.75 8.75 2.75 8.75 4C8.75 5.25 9.01 5.25 10 5.25H14C15.25 5.25 15.25 4.99 15.25 4C15.25 2.75 14.99 2.75 14 2.75H10Z" fill="white"/>
        <path d="M15 22.7499H9C3.38 22.7499 2.25 20.1699 2.25 15.9999V9.99993C2.25 5.43993 3.9 3.48993 7.96 3.27993C8.36 3.25993 8.73 3.56993 8.75 3.98993C8.77 4.40993 8.45 4.74993 8.04 4.76993C5.2 4.92993 3.75 5.77993 3.75 9.99993V15.9999C3.75 19.6999 4.48 21.2499 9 21.2499H15C19.52 21.2499 20.25 19.6999 20.25 15.9999V9.99993C20.25 5.77993 18.8 4.92993 15.96 4.76993C15.55 4.74993 15.23 4.38993 15.25 3.97993C15.27 3.56993 15.63 3.24993 16.04 3.26993C20.1 3.48993 21.75 5.43993 21.75 9.98993V15.9899C21.75 20.1699 20.62 22.7499 15 22.7499Z" fill="white"/>
    </svg>
)


const vendorsIcon = (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.27 11.25H16.23C14.22 11.25 13.25 10.36 13.25 8.52V3.98C13.25 2.14 14.23 1.25 16.23 1.25H20.27C22.28 1.25 23.25 2.14 23.25 3.98V8.51C23.25 10.36 22.27 11.25 20.27 11.25ZM16.23 2.75C14.89 2.75 14.75 3.13 14.75 3.98V8.51C14.75 9.37 14.89 9.74 16.23 9.74H20.27C21.61 9.74 21.75 9.36 21.75 8.51V3.98C21.75 3.12 21.61 2.75 20.27 2.75H16.23Z" fill="white"/>
        <path d="M20.27 22.75H16.23C14.22 22.75 13.25 21.77 13.25 19.77V15.73C13.25 13.72 14.23 12.75 16.23 12.75H20.27C22.28 12.75 23.25 13.73 23.25 15.73V19.77C23.25 21.77 22.27 22.75 20.27 22.75ZM16.23 14.25C15.05 14.25 14.75 14.55 14.75 15.73V19.77C14.75 20.95 15.05 21.25 16.23 21.25H20.27C21.45 21.25 21.75 20.95 21.75 19.77V15.73C21.75 14.55 21.45 14.25 20.27 14.25H16.23Z" fill="white"/>
        <path d="M8.77 11.25H4.73C2.72 11.25 1.75 10.36 1.75 8.52V3.98C1.75 2.14 2.73 1.25 4.73 1.25H8.77C10.78 1.25 11.75 2.14 11.75 3.98V8.51C11.75 10.36 10.77 11.25 8.77 11.25ZM4.73 2.75C3.39 2.75 3.25 3.13 3.25 3.98V8.51C3.25 9.37 3.39 9.74 4.73 9.74H8.77C10.11 9.74 10.25 9.36 10.25 8.51V3.98C10.25 3.12 10.11 2.75 8.77 2.75H4.73Z" fill="white"/>
        <path d="M8.77 22.75H4.73C2.72 22.75 1.75 21.77 1.75 19.77V15.73C1.75 13.72 2.73 12.75 4.73 12.75H8.77C10.78 12.75 11.75 13.73 11.75 15.73V19.77C11.75 21.77 10.77 22.75 8.77 22.75ZM4.73 14.25C3.55 14.25 3.25 14.55 3.25 15.73V19.77C3.25 20.95 3.55 21.25 4.73 21.25H8.77C9.95 21.25 10.25 20.95 10.25 19.77V15.73C10.25 14.55 9.95 14.25 8.77 14.25H4.73Z" fill="white"/>
    </svg>
)


const resultsIcon = (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.98117 20.6901C9.48117 20.6901 9.13118 20.5201 8.92118 20.3601C8.52118 20.0701 7.90117 19.3201 8.29117 17.6401L8.86115 15.1801C8.91115 14.9701 8.80116 14.5901 8.65116 14.4301L6.66117 12.4401C5.61117 11.3901 5.70116 10.4801 5.84116 10.0301C5.98116 9.58006 6.44116 8.79005 7.90116 8.54005L10.4512 8.12006C10.6412 8.09006 10.9512 7.86006 11.0312 7.69006L12.4412 4.87006C13.1212 3.51006 14.0212 3.31006 14.5012 3.31006C14.9812 3.31006 15.8812 3.51006 16.5612 4.87006L17.9712 7.69006C18.0612 7.86006 18.3611 8.09006 18.5511 8.12006L21.1012 8.54005C22.5612 8.78005 23.0212 9.58006 23.1612 10.0301C23.3012 10.4801 23.3912 11.3901 22.3412 12.4401L20.3512 14.4301C20.2012 14.5801 20.0912 14.9701 20.1412 15.1801L20.7112 17.6401C21.1012 19.3301 20.4811 20.0701 20.0811 20.3601C19.6811 20.6501 18.7912 21.0201 17.2912 20.1301L14.9012 18.7101C14.7012 18.5901 14.2811 18.5901 14.0811 18.7101L11.6912 20.1301C11.0012 20.5501 10.4212 20.6901 9.98117 20.6901ZM14.5012 4.81006C14.3412 4.81006 14.0512 5.00005 13.7812 5.54005L12.3712 8.36006C12.0712 8.97006 11.3612 9.49007 10.6912 9.60007L8.14118 10.0201C7.57118 10.1201 7.31117 10.3301 7.26117 10.4901C7.21117 10.6501 7.30115 10.9701 7.71115 11.3801L9.70117 13.3701C10.2112 13.8801 10.4812 14.8101 10.3212 15.5101L9.75116 17.9701C9.58116 18.6801 9.69115 19.0601 9.80115 19.1501C9.91115 19.2301 10.3112 19.2201 10.9312 18.8401L13.3311 17.4201C14.0011 17.0201 15.0112 17.0201 15.6712 17.4201L18.0612 18.8401C18.6912 19.2101 19.0912 19.2301 19.2012 19.1501C19.3112 19.0701 19.4212 18.6901 19.2512 17.9701L18.6812 15.5101C18.5212 14.8001 18.7811 13.8801 19.3011 13.3701L21.2912 11.3801C21.7012 10.9701 21.7912 10.6401 21.7412 10.4901C21.6912 10.3401 21.4311 10.1201 20.8611 10.0201L18.3112 9.60007C17.6412 9.49007 16.9312 8.97006 16.6312 8.36006L15.2212 5.54005C14.9512 5.00005 14.6612 4.81006 14.5012 4.81006Z" fill="white"/>
        <path d="M8.5 5.75H2.5C2.09 5.75 1.75 5.41 1.75 5C1.75 4.59 2.09 4.25 2.5 4.25H8.5C8.91 4.25 9.25 4.59 9.25 5C9.25 5.41 8.91 5.75 8.5 5.75Z" fill="white"/>
        <path d="M5.5 19.75H2.5C2.09 19.75 1.75 19.41 1.75 19C1.75 18.59 2.09 18.25 2.5 18.25H5.5C5.91 18.25 6.25 18.59 6.25 19C6.25 19.41 5.91 19.75 5.5 19.75Z" fill="white"/>
        <path d="M3.5 12.75H2.5C2.09 12.75 1.75 12.41 1.75 12C1.75 11.59 2.09 11.25 2.5 11.25H3.5C3.91 11.25 4.25 11.59 4.25 12C4.25 12.41 3.91 12.75 3.5 12.75Z" fill="white"/>
    </svg>
)


const Timeline = (props) => {



   const [detailsFillStep, setDetailsFillStep] = useState(0);
   const [vendorsFillStep, setVendorsFillStep] = useState(0);
   const [resultsFillStep, setResultsFillStep] = useState(0);
   const [detailsBricks, setDetailsBricks] = useState(0);
   const [vendorBricks, setVendorBricks] = useState(0)
   const [totalBricks, setTotalBricks] = useState(20)
   const [isMobile, setMobile] = useState(false)
   const phase = {
        'location': {
            'details' : 0,
            'vendors' : 0,
            'results' : 0,
            'detailsBricks': 0,
            'vendorBricks': 0
        },
        'venue': {
            'details' : 1,
            'vendors' : 0,
            'results' : 0,
            'detailsBricks': isMobile ? 1 : 2,
            'vendorBricks': 0
        },
        'vendortypes': {
            'details' : 2,
            'vendors' : 0,
            'results' : 0,
            'detailsBricks': isMobile ? 2 : 4,
            'vendorBricks': 0
        },
        'date': {
            'details' : 3,
            'vendors' : 0,
            'results' : 0,
            'detailsBricks': isMobile ? 3 : 6,
            'vendorBricks': 0
        },
        'dateSelected': {
            'details' : 4,
            'vendors' : 0,
            'results' : 0,
            'detailsBricks': isMobile ? 4 : 8,
            'vendorBricks': 0
        },
        'budget': {
            'details' : 5,
            'vendors' : 0,
            'results' : 0,
            'detailsBricks': isMobile ? 5 : 10,
            'vendorBricks': 0
        },
        'guest': {
            'details' : 6,
            'vendors' : 0,
            'results' : 0,
            'detailsBricks': isMobile ? 6 : 13,
            'vendorBricks': 0
        },
        'tags': {
            'details' : 7,
            'vendors' : 0,
            'results' : 0,
            'detailsBricks': isMobile ? 8 : 16,
            'vendorBricks': 0
        },
        'preferences': {
            'details' : 8,
            'vendors' : 1,
            'results' : 0,
            'detailsBricks': isMobile ? 9 : 20,
            'vendorBricks': 2
        },
        'howimportant': {
            'details' : 8,
            'vendors' : 2,
            'results' : 0,
            'detailsBricks': 20,
            'vendorBricks': 4
        },
        'dotogether': {
            'details' : 8,
            'vendors' : 4,
            'results' : 0,
            'detailsBricks': 20,
            'vendorBricks': 8
        },
        'photostyle': {
            'details' : 8,
            'vendors' : 6,
            'results' : 0,
            'detailsBricks': 20,
            'vendorBricks': 10
        },
        'selectionquiz': {
            'details' : 8,
            'vendors' : 8,
            'results' : 4,
            'detailsBricks': 20,
            'vendorBricks': 10
        },
        'signup': {
            'details' : 8,
            'vendors' : 8,
            'results' : 6,
            'detailsBricks': 20,
            'vendorBricks': 20
        }
    }

   useEffect(() => {
    const handleResize = () => {
        setMobile(window.innerWidth <= 500 ? true : false);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


   const getConicGradient = (step) => {
     const degree = step * 45; // Each step represents a 60 degree increment
     return `conic-gradient(#0A8A95 ${degree}deg, transparent 0deg)`;
   };


   const detailsStyle = {
     position: 'relative',
     borderRadius: '50%', // rounded-full
     border: '1px solid #0A8A95',
     background: getConicGradient(detailsFillStep), // Apply conic gradient based on step
     transition: 'background 0.5s ease-out'
   };


   const vendorsStyle = {
    position: 'relative',
    borderRadius: '50%', // rounded-full
    border: '1px solid #0A8A95',
    background: getConicGradient(vendorsFillStep), // Apply conic gradient based on step
    transition: 'background 0.5s ease-out'
  };


  const resultsStyle = {
    position: 'relative',
    borderRadius: '50%', // rounded-full
    border: '1px solid #0A8A95',
    background: getConicGradient(resultsFillStep), // Apply conic gradient based on step
    transition: 'background 0.5s ease-out'
  };


  const brickStyle = {
    width: isMobile ? '5%' : '5.5px',
    height: isMobile ? '60%' : '2px',
    backgroundColor: '#0A8A95',
    margin: '0px 2px',
    opacity: 1,
  };


  const containerStyle = {
    borderBottom: isMobile ? '' : '1px solid #D9D9D9'
  }


  const emptyBrickStyle = {
    ...brickStyle,
    backgroundColor: 'gray',
    opacity: 0.2,
  };

   useEffect(() => {
    setDetailsFillStep(phase[props.phase].details)
    setVendorsFillStep(phase[props.phase].vendors)
    setResultsFillStep(phase[props.phase].results)
    setDetailsBricks(phase[props.phase].detailsBricks)
    setVendorBricks(phase[props.phase].vendorBricks)
   }, [props.phase])

    return(
        <div style={containerStyle} className="w-[95%] flex justify-center pt-[25px] pb-[55px] mobile:pt-[10px] mobile:pb-[1px]">

            <div className="w-[55%] flex justify-center items-center mobile:w-[95%]">
                <div className="relative flex flex-col items-center">
                    <div style={detailsStyle} className="w-[48px] h-[48px] flex items-center justify-center mobile:w-[36px] mobile:h-[36px]">
                        <div></div>
                        {detailsIcon}
                    </div>
                    <p className="absolute top-[51px] mobile:top-10 mobile:text-xs">Details</p>
                </div>

                <div className="w-[40%] h-1 srounded overflow-hidden flex mobile:w-[25%]">
                    {[...Array(isMobile ? 10 : 20)].map((_, index) => (
                    <div
                        key={index}
                        style={index < detailsBricks ? brickStyle : emptyBrickStyle}
                    ></div>
                    ))}
                </div>

                <div className="relative flex flex-col items-center">
                    {vendorsFillStep === 0 ?
                    <div className="w-[48px] h-[48px] flex justify-center items-center bg-gray-400 rounded-full opacity-50 mobile:w-[36px] mobile:h-[36px]">
                        {vendorsIcon}
                    </div>
                    :
                    <div style={vendorsStyle} className="w-[48px] h-[48px] flex items-center justify-center mobile:w-[36px] mobile:h-[36px]">
                        {vendorsIcon}
                    </div>
                    }
                    <p className="absolute top-[51px] mobile:top-10 mobile:text-xs">Vendors</p>
                </div>

                <div className="w-[40%] h-1 rounded overflow-hidden flex mobile:w-[25%]">
                    {[...Array(isMobile ? 10 : 20)].map((_, index) => (
                    <div
                        key={index}
                        style={index < vendorBricks ? brickStyle : emptyBrickStyle}
                    ></div>
                    ))}
                </div>

                <div className="relative flex flex-col items-center">
                    {resultsFillStep === 0 ?
                    <div className="w-[48px] h-[48px] flex justify-center items-center bg-gray-400 rounded-full opacity-50 mobile:w-[36px] mobile:h-[36px]">
                        {resultsIcon}
                    </div>
                    :
                    <div style={resultsStyle} className="w-[48px] h-[48px] flex items-center justify-center mobile:w-[36px] mobile:h-[36px]">
                        {resultsIcon}
                    </div>
                    }
                    <p className="absolute top-[51px] mobile:top-10 mobile:text-xs">Results</p>
                </div>
            </div>

        </div>
    )
}

export default Timeline;