import { useDropzone } from 'react-dropzone';
import React, { useCallback } from 'react';
import Bugsnag from '@bugsnag/js';

export const CustomDropzone = ({ onDrop, photos }) => {
  const onDropRejected = useCallback(rejectedFiles => {
    const error = new Error('Dropzone upload failed!');
    const errorDetails = { rejectedFiles };

    Bugsnag.notify(error, event => {
      event.addMetadata('details', errorDetails);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: 'image/jpeg, image/png, image/jpg, image/svg+xml',
    onDrop,
    onDropRejected,
  });

  const openDialog = e => {
    e.preventDefault();
    open();
  };

  return (
    <div className="">
      <div className="font22 bold600 ">Add up to 15 photos to display on your page.</div>
      <div className="font15 bold400" style={{ marginTop: 15, marginBottom: 50, color: 'rgba(0, 0, 0, 0.8)' }}>**This link will expire in 5 days, at which time you will need to request a new link</div>
      <div {...getRootProps()} className="dropzone_and_notes d-flex position-relative">
        <input {...getInputProps()} />
        {
          <div style={{ flex: 1, margin: 'auto 0px' }}>
            <div className="d-flex justify-content-center">
              <img src="/images/icons/upload-img-icon.svg" alt="Upload Icon" />
            </div>
            <div className="font16 bold600 text-center">Drag and drop photos here</div>
            <div className="dropzone_notes mx-20 mx-sm-auto">
              {Object.keys(photos).length !== 0 ? (
                <div>{Object.keys(photos).length} Image(s) added</div>
              ) : (
                <div className="d-flex justify-content-between flex-wrap">
                  <div>
                    <div className="font15 bold400 spacer-y2 d-flex align-items-center">
                      <img style={{ marginRight: 10 }} src="/images/icons/dot-icon.svg" alt="Dot Icon" />
                      Choose 1-15 photos
                    </div>
                    <div className="font15 bold400 d-flex align-items-center">
                      <img style={{ marginRight: 10 }} src="/images/icons/dot-icon.svg" alt="Dot Icon" />
                      Upload JPG files under 20MB each
                    </div>
                  </div>
                  <div>
                    <div className="font15 bold400 spacer-y2 d-flex align-items-center">
                      <img style={{ marginRight: 10 }} src="/images/icons/dot-icon.svg" alt="Dot Icon" />
                      No watermarks
                    </div>
                    <div className="font15 bold400 d-flex align-items-center">
                      <img style={{ marginRight: 10 }} src="/images/icons/dot-icon.svg" alt="Dot Icon" />
                      High resolution photos
                    </div>
                  </div>
                </div>
              )}

              {/* ABSOLUTE CONTAINER FOR NOTES */}
              <div className="position-absolute little_note_box">Notes</div>
            </div>
            <button onClick={openDialog} className="add_photos_btn mx-auto">
              Add Photos
            </button>
          </div>
        }
        
        {isDragActive && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'black',
              color: 'white',
              opacity: 0.7,
            }}
            className="d-flex justify-content-center text22 bold600 align-items-center"
          >
            Just drop them here!
          </div>
        )}
      </div>
    </div>
  );
};
