import React, { useState } from 'react'
import axios from 'axios'
import { MyCustomDropdown } from '../Components/MyCustomDropdown';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const locationItems = [
    "On the Beach",
    "Inside the Building",
    "Under the Palm Trees",
    "Metropol Banquet",
    "Bexx Secret Garden",
    "Elli Banquet & Catering",
    "Santa Monica Proper Hotel",
]

export const TellUsAboutYourWedding = ({ step1, setForm, handleInput, stepOneErrors, validateStepOne }) => {
    const [venueResults, setVenueResults] = useState([])
    const [receptionResults, setReceptionResults] = useState([])
    const [showCeremonyResults, setShowCeremonyResults ] = useState(false)
    const [showReceptionResults, setShowReceptionResults ] = useState(false)
    const [ceremonyExactOptions, setCeremonyExactOptions] = useState([])
    const [receptionExactOptions, setReceptionExactOptions] = useState([])
    const handleDateInput = (date) => {
        setForm(prev=>{
            return {
                ...prev,
                steps:{
                    ...prev.steps,
                    step1:{
                        ...prev.steps.step1,
                        weddingDate:new Date(date).getTime()
                    }
                }
            }
        })
    }

    const handleDropdownChange = (type, value) => {
        setForm(prev=>{
            return {
                ...prev,
                steps:{
                    ...prev.steps,
                    step1:{
                        ...prev.steps.step1,
                        [type]:value
                    }
                }
            }
        })
    }

    
    const searchVenues = (e, fieldToUpdate) => {
        if(fieldToUpdate === 'ceremony'){
            if(e.target.value?.length > 1){
                axios.get('/api/get-vendors/14/' + e.target.value)
                .then(r => {
                    setVenueResults(r.data.vendors)
                })
            } else{
                setVenueResults([])
            }
        } else{
            if(e.target.value?.length > 1){
                axios.get('/api/get-vendors/14/' + e.target.value)
                .then(r => setReceptionResults(r.data.vendors))
            } else{
                setReceptionResults([])
            }
        }
    }

    return(
        <div className='' style={{marginTop:30}}>
            {((showCeremonyResults && venueResults?.length) > 0 || (showReceptionResults && receptionResults?.length > 0)) &&
                <div className='overlay transparent' onClick={() => setShowCeremonyResults(false)}></div>
            }
            <div className='font22 bold600 my_custom_container'>
                Tell us about this wedding
            </div>
            <div style={{marginTop:30, gap:15}} className='d-md-flex flex-wrap my_custom_container_stepone'>
                <div style={{flex:1}} className={`floating_inputs_wrapper transparent_background_padding mb-20  ${!stepOneErrors.weddingDate.error ? " error":" "}`}>
                    <label style={{zIndex:9}} htmlFor='weddingDate' className='signup-input-label-special'>
                        Wedding Date
                    </label>
                    <DatePicker
                        selected={step1.weddingDate}
                        onChange={(date) => handleDateInput(date)}
                        className='date_input'
                        placeholderText="MM/DD/YYYY"
                    />
                    <img 
                        className='calendar_icon_positioning'
                        src='/images/icons/calendar-icon.png'
                        alt='Calendar Icon'
                    />
                </div>
                <div style={{flex:1}} className='floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding'>
                    <label htmlFor='ceremonyVenue' className='signup-input-label-special'>
                        Ceremony Venue
                    </label>
                    <input 
                        className= {stepOneErrors.ceremonyVenue.error ? " error":" "}
                        onChange={(e)=> {handleInput(e); searchVenues(e, 'ceremony'); setShowCeremonyResults(true) }}
                        onBlur={(e)=>validateStepOne(e)}
                        value={step1.ceremonyVenue}
                        autocomplete="off"
                        placeholder='Ceremony Venue...'
                        style={{
                            width:"100%", 
                            // margin:'0 10px'
                        }}
                        name='ceremonyVenue'
                        type='text'
                    />
                    {showCeremonyResults && venueResults?.length > 0 &&
                        <div className='search-results'>
                            {venueResults.map(v => <div onClick={() => {setShowCeremonyResults(false); setCeremonyExactOptions(v.ceremony_exact_locations); handleInput({target: {value: v.business_name, name: 'ceremonyVenue'}, noPersist: true});}} key={v.id}>{v.business_name}</div>)}
                        </div>
                    }
                </div>
                {/* FOR MOBILE DESIGN ONLY */}
                {ceremonyExactOptions?.length > 0 &&
                <div style={{flex:1}} className='floating_inputs_wrapper mb-20 d-block d-md-none white_background_select'>
                    <label htmlFor='exactCeremonyLocation' style={{zIndex:9}} className='signup-input-label-special white'>
                        Exact Location for Ceremony
                    </label>
                    <MyCustomDropdown 
                        items={ceremonyExactOptions}
                        type='exactCeremonyLocation'
                        onChange={handleDropdownChange}
                        placeholder='Exact location for ceremony...' 
                    />
                </div>
                }



                <div style={{flex:1}} className='floating_inputs_wrapper mb-0 mb-md-20 d-flex transparent_background_padding'>
                    <label htmlFor='receptionVenue' className='signup-input-label-special'>
                        Reception Venue
                    </label>
                    <input 
                        className= {stepOneErrors.receptionVenue.error ? " error":" "}
                        onChange={(e)=>{handleInput(e); searchVenues(e, 'reception'); setShowReceptionResults(true)} }
                        onBlur={(e)=>validateStepOne(e)}
                        value={step1.receptionVenue}
                        autocomplete="off"
                        placeholder='Reception Venue...'
                        style={{width:"100%"}}
                        name='receptionVenue'
                        type='text'
                    />
                    {showReceptionResults && receptionResults?.length > 0 &&
                        <div className='search-results'>
                            {receptionResults.map(v => <div onClick={() => {setShowReceptionResults(false);  setReceptionExactOptions(v.reception_exact_locations); handleInput({target: {value: v.business_name, name: 'receptionVenue'}, noPersist: true});}} key={v.id}>{v.business_name}</div>)}
                        </div>
                    }
                </div>
            </div>
            <div style={{marginTop:15, gap:15}} className='d-md-flex flex-wrap my_custom_container_stepone'>

                 {/* FAKE ONLY FOR SPACING */}
                 
                {(ceremonyExactOptions?.length > 0 || receptionExactOptions?.length > 0) &&
                <div style={{flex:1, marginLeft: '20px'}} className='floating_inputs_wrapper d-none d-xxl-flex'>
                    <label style={{display:'none'}} htmlFor='receptionVenue' className='signup-input-label-special'>
                        Reception Venue
                    </label>
                    <input 
                     style={{width:"100%", display:'none'}}
                        name='receptionVenue'
                        type='text'
                    />
                </div>
                }

                
            {(!ceremonyExactOptions?.length > 0 && receptionExactOptions?.length > 0)  &&
                <div style={{flex:1}} className='floating_inputs_wrapper d-none d-xxl-flex'>
                    <label style={{display:'none'}} htmlFor='receptionVenue' className='signup-input-label-special'>
                        Reception Venue
                    </label>
                    <input 
                     style={{width:"100%", display:'none'}}
                        name='receptionVenue'
                        type='text'
                    />
                </div>
            }

                {/* FOR LARGE SCREENS ONLY */}
                {ceremonyExactOptions?.length > 0 &&
                <div style={{flex:1}} className='floating_inputs_wrapper mb-20 d-none d-md-block'>
                    <label htmlFor='exactCeremonyLocation' style={{zIndex:9}} className='signup-input-label-special'>
                        Exact Location for Ceremony
                    </label>
                    <MyCustomDropdown 
                        type='exactCeremonyLocation'
                        onChange={handleDropdownChange}
                        items={ceremonyExactOptions}
                        placeholder='Exact location for ceremony...'
                    />
                </div>
                }
                
            {(ceremonyExactOptions?.length > 0 && !receptionExactOptions?.length > 0)  &&
                <div style={{flex:1}} className='floating_inputs_wrapper d-none d-xxl-flex'>
                    <label style={{display:'none'}} htmlFor='receptionVenue' className='signup-input-label-special'>
                        Reception Venue
                    </label>
                    <input 
                     style={{width:"100%", display:'none'}}
                        name='receptionVenue'
                        type='text'
                    />
                </div>
            }

                {receptionExactOptions?.length > 0 &&
                <div style={{flex:1}} className='floating_inputs_wrapper mb-20 white_background_select'>
                    <label htmlFor='exactReceptionLocation' style={{zIndex:1}} className='signup-input-label-special white'>
                        Exact Location for Reception
                    </label>
                    <MyCustomDropdown 
                        type='exactReceptionLocation'
                        onChange={handleDropdownChange}
                        items={receptionExactOptions}
                        placeholder='Exact location for reception...'
                    />
                </div>
                }
            </div>
        </div>
    )
}