import React, { useState } from "react";
import axios from "axios";

const CreateReferral = () => {
	const [code, setCode] = useState('')
    const [email, setEmail] = useState('')
    const [ error, setError ] = useState('')
    const submitReferral = () => {
        const data = {
            code,
            email
        }
        axios.post('/api/referral', data)
        .then((r) => {
            if(r.data.msg == "Code Taken"){
                setError("Code Taken")
                setCode('')
            } else{
                setError("Success")
                setCode('')
                setEmail('')
            }
        })
    }
	return (
		<div style={{textAlign: 'center'}}>
            <br /><br />
            <input placeholder="Code" value={code} onChange={(e) => setCode(e.target.value)} /><br />
            <input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} /><br />
            <button onClick={submitReferral}>Submit</button>
            {error}
            <br /><br />
        </div>
	);
};

export default CreateReferral;
