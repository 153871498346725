import React, { useContext, useEffect, useState, useRef } from "react";
import { AnswersContext } from "../../../AnswersContext";
import { useNavigate } from "react-router-dom";
import FooterNew from ".././footernew.component";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import closecircleX from "../../../styles/closecircleX"
import ScrollJump from "../../scroll-jump.component";

const getMinimumBudget = hoursSelected => {
	switch(hoursSelected) {
		case 3:
		case 4:
			return '$700'
		case 5:
			return '$900'
		case 6:
		case 7:
		case 8:
			return '$1,000'
		case 9:
		case 10:
			return '$1,500'
		default:
			return '$700'
	}
}

const FloristNewQuiz = () => {

	const { toggleBoolean, toggleArraySelection, quizState, hoursSelected, setValue, setBooleanFalse } = useContext(AnswersContext);


	const [ nextStep, setNextStep ] = useState(false)
	const [ openPartsDay, setOpenPartsDay ] = useState(false) //bool
	const [ partDay, setPartDay ] = useState() //string
	const [ displayPartDay, setDisplaySetDay ] = useState() //string
	const [ openMinBudget, setOpenMinBudget ] = useState(false) //bool
	const [ minBudget, setMinBudget ] = useState() //num
	const [ openMaxBudget, setOpenMaxBudget ] = useState(false) //bool
	const [ maxBudget, setMaxBudget ] = useState() //num
	const [ openNumHours, setOpenNumHours ] = useState(false) //bool
	const [ numHours, setNumHours ] = useState() //num
	const [ estimatedBudget, setEstimatedBudget] = useState(false)
	const minDropdownRef = useRef(null);
	const maxDropdownRef = useRef(null);
	const history = useNavigate();


	const handleClickOutside = (event) => {
		if (minDropdownRef.current && !minDropdownRef.current.contains(event.target)) {
      setOpenMinBudget(false);
    }
		if (maxDropdownRef.current && !maxDropdownRef.current.contains(event.target)) {
      setOpenMaxBudget(false);
    }
  };


	const confirmPartsOfDay = () => {
		setOpenPartsDay(false)
		setDisplaySetDay(partDay)
	}


	const renderMinBudgetDropdown = (budgetOptions) => {

		return (
			<span className="absolute w-full max-h-48 flex flex-col px-1 py-2 bg-white rounded-xl text-center shadow overflow-auto">
				{budgetOptions.map((minOption) => {
					const minOptionValue = parseFloat(minOption.replace('$', ''));

					if (quizState.floristMinMax[1]) {
						const maxBudgetValue = parseFloat(quizState.floristMinMax[1].replace('$', ''));
						if (maxBudgetValue > minOptionValue) {
							console.log(minOption)
							return (
								<span
									key={minOption}
									className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
									onClick={() => {
										quizState.floristMinMax[0] = minOption;
										setOpenMinBudget(false);
									}}
								>
									{minOption}
								</span>
							);
						}
					} else {
						return (
							<span
								 key={minOption}
								 className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
								 onClick={() => {
									 quizState.floristMinMax[0] = minOption;
									 setOpenMinBudget(false);
								 }}
							 >
								 {minOption}
						 </span>
						 );
					}

				})}
			</span>
		)
	}


	const renderMaxBudgetDropdown = (budgetOptions) => {
		return (
			<span className="absolute w-full max-h-48 flex flex-col px-1 py-2 bg-white rounded-xl text-center shadow overflow-auto">
				{budgetOptions.map((maxOption) => {
					let maxOptionValue = (maxOption.replace('$', ''));
					if (maxOptionValue.includes('+')) {
						maxOptionValue = parseFloat(maxOptionValue.replace('+', '')) + 1
					} else {
						maxOptionValue = parseFloat(maxOptionValue)
					}

					if (quizState.floristMinMax[0]) {
						const minBudgetValue = parseFloat(quizState.floristMinMax[0].replace('$', ''));

						if (maxOptionValue > minBudgetValue) {
							return (
								<span
									key={maxOption}
									className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
									onClick={() => {
										quizState.floristMinMax[1] = maxOption;
										setOpenMaxBudget(false);
									}}
								>
									{maxOption}
								</span>
							);
						}
						return null
					}

					return (
						<span
							key={maxOption}
							className="hover:bg-gray-100 px-2 py-1.5 rounded-lg cursor-pointer"
							onClick={() => {
								quizState.floristMinMax[1] = maxOption;
								setOpenMaxBudget(false);
							}}
						>
							{maxOption}
						</span>
					);
				})}
			</span>
		);
	}


	useEffect(() => {
		if (quizState.floristMinMax[0] && quizState.floristMinMax[1]) {
			const e = {
				target: {
					name: 'floristEstimatedBudget',
					checked: false
				}
			};

			setBooleanFalse(e);
		}
	}, [quizState.floristMinMax[0], quizState.floristMinMax[1]])


	useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


	useEffect(() => {
		if (quizState.noBudget) {
			setValue('selectedBudgets', [])
		}
	}, [quizState.noBudget])


	useEffect(() => {
		if (quizState.selectedBudgets.length && quizState.noBudget) {
			setValue('noBudget', false)
		}
	}, [quizState.selectedBudgets])



	useEffect(() => {
		if ((quizState.floristMinMax[0] && quizState.floristMinMax[1]) || quizState.floristEstimatedBudget) {
			setNextStep(true)
		} else {
			setNextStep(false)
		}
	}, [quizState.floristMinMax[0], quizState.floristMinMax[1], quizState.floristEstimatedBudget])


	const budgets =  [
			{
				id: 1,
				displayMinimum: getMinimumBudget(hoursSelected),
				displayMaximum: '$2,000'
			},
			{
				id: 2,
				displayMinimum: '$2,001',
				displayMaximum: '$3,000'
			},
			{
				id: 3,
				displayMinimum: '$3,001',
				displayMaximum: '$4,000'
			},
			{
				id: 4,
				displayMinimum: '$4,001',
				displayMaximum: '$6,000'
			},
			{
				id: 5,
				displayMinimum: '$6,000',
			},
	]


	const partsOfDay = [
		"All Parts of the Day",
		"Getting Ready",
		"Ceremony",
		"Couple's Portraits",
		"Reception"
	]


	const hoursOptions = [
		'2 Hours',
		'3 Hours',
		'4 Hours',
		'5 Hours',
		'6 Hours',
		'7 Hours',
		'8 Hours',
		'9 Hours',
		'10 Hours'
	]


	const budgetOptions = [
		'$1000',
		'$1500',
		'$2000',
		'$2500',
		'$3000',
		'$3500',
		'$4000',
		'$5000',
		'$6000',
		'$7500',
		'$7500+'
	]




	return (
		<div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
			<div className="w-full">
					<NavbarNew />
			</div>

			<div className={`w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-3 pb-[24px] mt-5 rounded-3xl bg-white bg-white/50 mobile:w-[90%]`}>
				{/* Status Bar */}

				{openPartsDay &&
					<div className={`${openPartsDay ? 'backdrop-blur-sm' : ''} absolute top-20 inset-0 flex justify-center items-center shadow z-20`}>
						<div className="w-[550px] h-[295px] flex flex-col pl-4 pt-3 pb-2 border rounded-2xl bg-white mobile:w-[95%] mobile:h-max mobile:text-sm">

							<button className="w-full flex justify-end px-3 py-2" onClick={() => (setPartDay(displayPartDay), setOpenPartsDay(false))}>
								{closecircleX}
							</button>

							<div className="mb-4 text-xl font-medium mobile:text-lg">Parts of Day For Photography Coverage</div>

							<div className="w-full flex flex-wrap mb-2">
								{partsOfDay.map((part, id) => (
									<button className={`${part === partDay ? 'bg-greenblue text-white' : ''} mr-2 mb-2.5 px-3 py-2 border rounded-xl`} onClick={() => {setPartDay(part)}}>
										{part}
									</button>
								))}
							</div>

							<div className="w-full flex justify-end pr-4">
								<button className="px-5 py-[12px] rounded-full bg-greenblue text-sm text-white" onClick={() => (confirmPartsOfDay())}>
									Confirm
								</button>
							</div>
						</div>
					</div>
				}

				<Timeline phase="preferences"/>

				<div className="w-full flex flex-col items-center pt-10 pb-6 mx-12 border-t z-10">

					<div className="mb-7 text-center mobile:mb-10">
						<p className="mb-7 text-3xl font-medium mobile:mb-5 mobile:text-lg">Florist Preferences</p>
						<p className="mobile:text-[13px]">Click or tap to fill in the blanks.</p>
					</div>

					<div className="w-[55%] flex flex-wrap justify-center items-center gap-y-5 text-lg mobile:w-full mobile:mb-6 mobile:text-sm">
						<span>I'm interested in</span>

						<span
							className="px-4 mx-3 text-greenblue italic"
							style={{ borderBottom: `1px solid #0A8A95` }}
						>
							a Florist
						</span>

						<span>and my budget is between</span>


						<span className="relative" ref={minDropdownRef}>
							<span
								className={`${quizState.floristMinMax[0] ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-2 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${quizState.floristMinMax[0] ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => setOpenMinBudget(!openMinBudget)}
							>
								{quizState.floristMinMax[0] || 'Min'}
							</span>
							{openMinBudget && renderMinBudgetDropdown(budgetOptions)}
						</span>

						<span>and</span>

						<span className="relative" ref={maxDropdownRef}>
							<span
								className={`${quizState.floristMinMax[1] ? 'text-greenblue' : 'text-[#A6A6A6]'} px-4 mx-2 italic cursor-pointer`}
								style={{ borderBottom: `1px solid ${quizState.floristMinMax[1] ? '#0A8A95' : '#A6A6A6'}` }}
								onClick={() => setOpenMaxBudget(!openMaxBudget)}
							>
								{quizState.floristMinMax[1] || 'Max'}
							</span>
							{openMaxBudget && renderMaxBudgetDropdown(budgetOptions)}
						</span>
					</div>


				</div>

				{/* <div className="w-full flex justify-center items-center mt-2 text-center mobile:text-sm">
					<label class="checkbox-container" style={{marginRight: '8px', marginTop: '0px'}}>
						<input
							name="floristEstimatedBudget"
							checked={quizState.floristEstimatedBudget}
							type="checkbox"
							onClick={(e) => {toggleBoolean(e)}}
						/>
						<span class="checkmark email-checkmark" />
					</label>
					<span className="">Use typical price range based on my wedding details</span>
				</div> */}

				<div>
						<FooterNew phase={"Florist"} complete={nextStep}/>
				</div>

			</div>

		</div>
	);
};

export default FloristNewQuiz;
