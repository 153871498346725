import React from 'react'
export const Progressbar = ({ progress }) => {
    return(
        <div className='progress_bg position-relative'>
            <div className='current_progress'>
                <div 
                    style={{
                        backgroundColor:"#0A8A95", 
                        height:"100%", 
                        width:progress+"%", 
                        borderRadius: 100,
                        transition: "all 0.5s"
                    }}
                />
            </div>
        </div>
    )
}