import React from 'react'

export const TellUsAboutCouple = ({ step1, setForm, handleInput, stepOneErrors, validateStepOne }) => {
    return(
        <div className='my_custom_container' style={{marginTop:30}}>
            <div className='font22 bold600'>
                Tell us about the couple
            </div>
            <div style={{marginTop:30, gap:15}} className='d-md-flex flex-wrap'>
                <div style={{flex:1}} className='floating_inputs_wrapper  mb-20 d-flex'>
                    <label htmlFor='partner1' className='signup-input-label'>
                        Partner 1
                    </label>
                    <input
                        className= {stepOneErrors.partner1.error ? " error":" "}
                        onBlur={(e)=>validateStepOne(e)} 
                        style={{
                            width:"100%", 
                            // margin:'0 10px'
                        }}
                        onChange={(e)=>handleInput(e)}
                        value={step1.partner1}
                        name='partner1'
                        type='text'
                        placeholder='First name...'
                    />
                </div>
                <div style={{flex:1}} className='floating_inputs_wrapper  mb-20 d-flex'>
                    <label htmlFor='partner2' className='signup-input-label'>
                        Partner 2
                    </label>
                    <input 
                        className= {stepOneErrors.partner2.error ? " error":" "}
                        onBlur={(e)=>validateStepOne(e)}
                        style={{width:"100%"}}
                        onChange={(e)=>handleInput(e)}
                        value={step1.partner2}
                        name='partner2'
                        type='text'
                        placeholder='First name...'
                    />
                </div>
                <div style={{flex:1}} className='floating_inputs_wrapper  mb-20 d-none d-xxl-flex'>
                    <label style={{display:"none"}} className='signup-input-label'>
                        Partner 2
                    </label>
                    <input
                        style={{width:"100%",display:"none"}}
                        type='text'
                    />
                </div>
            </div>
            <div style={{marginTop:15, gap:15}} className='d-flex flex-wrap'>
                <div style={{flex:1}} className='floating_inputs_wrapper  mb-20 d-flex'>
                    <label htmlFor='descriptiveTitle' className='signup-input-label'>
                        Descriptive Title
                    </label>
                    <input 
                        className= {stepOneErrors.descriptiveTitle.error ? " error":" "}
                        onBlur={(e)=>validateStepOne(e)}
                        style={{
                            width:"100%", 
                        }}
                        onChange={(e)=> e.target.value.length < 100 ? handleInput(e) : {}}
                        value={step1.descriptiveTitle}
                        name='descriptiveTitle'
                        type='text'
                        placeholder='Give this wedding a unique title...'
                    />
                </div>
            </div>
        </div>
    )
}