import React from 'react';

const ModalWrapper = ({ Child, mixpanelEvent, close, noBackground }) => {   
   
    return(
        <div className='modal-background'>
        <div className='modal-window' style={noBackground ? {background: 'none'} : {}}>
            
            <button
                type="button"
                onClick={() => {
                    close()
                }} 
                style={{right: '12px', fontSize: '32px', position: 'absolute'}}
                >
              x
            </button>
            {Child}
        </div>
       
    </div>
    )
}

export default ModalWrapper