import React from 'react'
import { Link } from "react-router-dom"

export const CreateCard = () => {    
    return(
        <a href='/weddingupload' target="_blank" rel="noopener noreferrer" className='create_album_card text-decoration-none '>
            <span className='text-center'>
                <img 
                    src='/images/icons/add-icon.png'
                    alt='Add Icon'
                />
                <div className='font15 text-blue bold500'>Create Album</div>
            </span>
        </a>
    )
}