import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { UserContext } from "../UserContext";
import axios from "axios";
import { PhotographerContext } from "../PhotographerContext";

const StyledNavbarDashboard = styled.div`
	width: 280px;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: fixed;
	left: 0;
	top: 0;
	font-family: NexaRegular;
	background-color: white;
`;

const Logo = styled.div`
	height: 200px;
	position: relative;
`;

const Navlist = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	height: 50%;
`;

const MenuIcon = styled.img`
	position: absolute;
	height: 30px;
	width: 30px;
	left: 20px;
	top: 10px;
`;

const DashboardNav = ({ toggleNav }) => {
	const { photographer, activePage, setActivePage } = useContext(UserContext);	
	const [ showNav, setShowNav ] = useState(window.innerWidth >= 1000);
	const [ accountType, setAccountType ] = useState('basic_photographer')
	const [ showPopup, setShowPopup ] = useState(false)
	const [ requestedFullAccount, setRequestedFullAccount ] = useState(false)
	const [ loaded, setLoaded ] = useState(false)
	const [ referralCode, setReferralCode ] = useState('')
	const [ businessName, setBusinessName ] = useState('')
	const {
		photosComplete,
		setPhotosComplete,
		infoComplete,
		setInfoComplete,
		pricingComplete,
		setPricingComplete,
		setProfileComplete,
		pricingTouched,
		personalityComplete,
		setPersonalityComplete,
		personalityTouched,
		infoTouched,
		photosTouched
	} = useContext(PhotographerContext);
	const logout = () => {
		axios.post("/auth/logout");
		window.location = "/login";
	};

	//Load completion from database, set profile complete if the rest of the stuff is complete
	useEffect(() => {
		if(photographer && photographer != -1)
		{
			axios.get('/api/photographerdata/' + photographer)
			.then(r => {
				setBusinessName(r.data.business_name)
				setAccountType(r.data.account_type)
				setProfileComplete(r.data.profile_complete)
				setInfoComplete(r.data.info_complete)
				setPricingComplete(r.data.pricing_complete)
				setPhotosComplete(r.data.photos_complete)
				setPersonalityComplete(r.data.personality_complete)
				setRequestedFullAccount(r.data.requested_full_account)
				if(r.data.info_complete && r.data.pricing_complete && r.data.photos_complete && r.data.personality_complete){
					setProfileComplete(true)
					axios.post('/api/update-profile-complete', {profileComplete: true, id: photographer})
				} else{
				}
				setLoaded(true)
			})
		}
	}, [photographer])

	useEffect(() => {
		if(infoTouched){
			axios.post('/api/update-info-complete', {infoComplete, id: photographer})
			.then(r => {
				if(r.data.info_complete && r.data.pricing_complete && r.data.photos_complete && r.data.personality_complete){
					setProfileComplete(true)
					axios.post('/api/update-profile-complete', {profileComplete: true, id: photographer})
				}
			})
			if(!infoComplete){
				axios.post('/api/update-profile-complete', {profileComplete: false, id: photographer})
				setProfileComplete(false)
			}
		}

	}, [infoTouched, infoComplete])
	
	useEffect(() => {
		if(pricingTouched){
			axios.post('/api/update-pricing-complete', {pricingComplete, id: photographer})
			.then(r => {
				if(r.data.info_complete && r.data.pricing_complete && r.data.photos_complete && r.data.personality_complete){
					setProfileComplete(true)
					axios.post('/api/update-profile-complete', {profileComplete: true, id: photographer})
				}
			})
			if(!pricingComplete){
				axios.post('/api/update-profile-complete', {profileComplete: false, id: photographer})
				setProfileComplete(false)
			}
		}

	}, [pricingTouched, pricingComplete])
	
	useEffect(() => {
		if(personalityTouched){
			axios.post('/api/update-personality-complete', {personalityComplete, id: photographer})
			.then(r => {
				if(r.data.info_complete && r.data.pricing_complete && r.data.photos_complete && r.data.personality_complete){
					setProfileComplete(true)
					axios.post('/api/update-profile-complete', {profileComplete: true, id: photographer})
				}
			})
			if(!personalityComplete){
				axios.post('/api/update-profile-complete', {profileComplete: false, id: photographer})
				setProfileComplete(false)
			}
		}

	}, [personalityTouched, personalityComplete])

	useEffect(() => {
		if(photosTouched){
			axios.post('/api/update-photos-complete', {photosComplete, id: photographer})
			.then(r => {
				if(r.data.info_complete && r.data.pricing_complete && r.data.photos_complete && r.data.personality_complete){
					setProfileComplete(true)
					axios.post('/api/update-profile-complete', {profileComplete: true, id: photographer})
				}
			})
			if(!photosComplete){
				axios.post('/api/update-profile-complete', {profileComplete: false, id: photographer})
				setProfileComplete(false)
			}
		}

	}, [photosTouched, photosComplete])
	
	useEffect(() => {		
        document.title="Dashboard | WedMatch"
		axios.get("/auth/getuser").then((r) => {
			if (!r.data.id) {
				window.location = "/login";
				return;
			}
		});		
	}, []);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth >= 1000) {
				setShowNav(true);
			} else setShowNav(false);
		}

		window.addEventListener("resize", handleResize);
	});

	useEffect(
		() => {
			if (toggleNav && window.innerWidth <= 1000 && showNav === true) {
				setShowNav(false);
			}
			
		},
		[ toggleNav ]
	);

	useEffect(() => {
		setReferralCode(businessName?.replace(/[^a-zA-Z ]/g, "").toLowerCase().replace('  ', ' ').split(' ').filter(s => s !== 'and' && s !== 'the' && s !== 'of' && s !== 'by').slice(0,2).join(''))
	}, [businessName])

	const requestFull = () => {
		axios.post('/auth/request-full')
		.then(r => setRequestedFullAccount(true))
	}

	return (
		<>
		<div className='referral-banner'>Already booked? Send couples to WedMatch and receive a $75 gift card when they book. - &nbsp;<a style={{color: 'inherit'}} href={`https://wedmatch.com?${referralCode}`}>www.wedmatch.com?{referralCode}</a></div>
		<div style={{ posiotn: "relative" }}>
			{showPopup &&
				<div className='signup-overlay'>
					<div style={{textAlign: 'left'}} className='signup-modal'>
						<div className='request-header'>Book more weddings?</div>
						<div className='request-subheader'>JOIN THE MATCHING QUIZ</div>
						<div className='request-body'>If i’m approved to join the matching quiz, I agree to pay a flat $150 referral fee for any wedding booking greater than $1500.</div>
						<div className='request-disclaimer'>There are no other fees.</div>
						<div className='request-more-information'>More information here:<br /><a target="_blank" rel="noopener noreferrer" style={{color: 'inherit'}} href='https://wedmatch.com/photographers/'>https://wedmatch.com/photographers/</a></div>
						<div style={{display: 'flex', justifyContent: 'space-around'}}><button style={{width: '160px', fontSize: '14px'}} onClick={() => setShowPopup(false)}className='agree-button decline'>Cancel</button><button style={{width: '160px', fontSize: '14px'}} onClick={() => {requestFull(); setShowPopup(false);}} className='agree-button'>Request to Join</button></div>
					</div>
				</div>
			}
			<MenuIcon
				src={require("../styles/menu-icon.png")}
				onClick={() => {
					setShowNav(!showNav);
				}}
			/>
			<StyledNavbarDashboard className={showNav ? "" : "hide-nav"}>
				<Logo>
					<img src={require("../styles/wedmatch-logo.png")} alt="wed-match" className="dashboard-logo" />
				</Logo>
				<Navlist>
					<Link
						onClick={() => accountType === 'photographer' ? setActivePage('photoDashboard') : {}}
						disabled={accountType !== 'photographer'}
						activeClassName="active"
						className={`navlink-dashboard${(activePage=='photoDashboard' || activePage=='photosDashboard') ? ' nav-active' : ''} ${accountType !== 'photographer' ? 'disabled' : ''}`}
						to={accountType === 'photographer' ? `/dashboard/${photographer}/photos` : '#'}>
						Photos
					</Link>

					<Link 
						
						onClick={() => setActivePage('infoDashboard')}
						activeClassName="active" 
						className={`navlink-dashboard${activePage=='infoDashboard' ? ' nav-active' : ''}`}
						to={`/dashboard/${photographer}/info`}>
						Info
					</Link>

					<Link
						onClick={() => accountType === 'photographer' ? setActivePage('pricingDashboard') : {}}
						activeClassName="active"
						className={`navlink-dashboard${activePage=='pricingDashboard' ? ' nav-active' : ''} ${accountType !== 'photographer' ? 'disabled' : ''}`}
						to={accountType === 'photographer' ? `/dashboard/${photographer}/pricing` : '#'}>
						Pricing
					</Link>

					<Link
						onClick={() => accountType === 'photographer' ? setActivePage('personalityDashboard') : {}}
						activeClassName="active"
						className={`navlink-dashboard${activePage=='personalityDashboard' ? ' nav-active' : ''} ${accountType !== 'photographer' ? 'disabled' : ''}`}
						to={accountType === 'photographer' ? `/dashboard/${photographer}/personality` : '#'}>
						Personality
					</Link>
					<Link
						onClick={() => accountType === 'photographer' ? setActivePage('availabilityDashboard') : {}}
						activeClassName="active"
						className={`navlink-dashboard${activePage=='availabilityDashboard' ? ' nav-active' : ''} ${accountType !== 'photographer' ? 'disabled' : ''}`}
						to={accountType === 'photographer' ? `/dashboard/${photographer}/availability` : '#'}>
						Availability
					</Link>
					<Link
						onClick={() => accountType === 'photographer' ? setActivePage('matchesDashboard') : {}}
						activeClassName="active"
						className={`navlink-dashboard${activePage=='matchesDashboard' ? ' nav-active' : ''} ${accountType !== 'photographer' ? 'disabled' : ''}`}
						to={accountType === 'photographer' ? `/dashboard/${photographer}/matches` : '#'}>
						Matches
					</Link>
					<Link
						onClick={() => setActivePage('albumDashboard')}
						activeClassName="active"
						className={`navlink-dashboard${activePage=='albumDashboard' ? ' nav-active' : ''}`}
						to={`/dashboard/${photographer}/listalbums`}>
						Real Weddings
					</Link>
					<Link
						onClick={() => setActivePage('preferredVendors')}
						activeClassName="active"
						className={`navlink-dashboard${activePage=='preferredVendors' ? ' nav-active' : ''}`}
						to={`/dashboard/${photographer}/preferredvendors`}>
						Preferred Vendors
					</Link>

					<div className="navlink-dashboard" onClick={logout}>
						Log Out
					</div>
				</Navlist>
				<div className='want-to-book-wrapper'>
					{accountType !== 'photographer' && !requestedFullAccount && loaded &&
						<div className='want-to-book'>
							Want to book risk-free weddings through our matching quiz?
							<button onClick={() => setShowPopup(true)} style={{margin: '0px', marginTop: '36px'}} className='footer-next-button'>Learn More</button>
						</div>
					}
					{accountType !== 'photographer' && requestedFullAccount && loaded &&
						<div className='want-to-book'>
							Your request to be included in the matching quiz has been received
						</div>
					}
					</div>
			</StyledNavbarDashboard>
		</div>
		</>
	);
};

export default DashboardNav;
