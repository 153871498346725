import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import camera from '../styles/signupcamera.svg';
import clock from '../styles/clock.svg';
import free from '../styles/free.svg';
import logo from '../styles/wedmatchlogo.png';

const Login = () => {
	document.title="Login to your account | WedMatch"
	const [ password, setPassword ] = useState("");
	const [ email, setEmail ] = useState("");
	const [ error, setError ] = useState("");
	const [ complete, setComplete ] = useState(false)
	const data = {
		email,
		password
	};
	const submit = (e) => {
		e.preventDefault();
		axios.post("/auth/login", data).then((r) => {
			if (r.data.passed) {
				if (r.data.photographerId) {
					if(r.data.accountType === 'photographer'){
						window.location = "/dashboard/" + r.data.photographerId + "/photos";
					} else{
						window.location = "/dashboard/" + r.data.photographerId + "/listalbums";
					}
				} else {
					window.location='/vendordashboard'
				}
			} else {
				setError(r.data.msg);
			}
		});
	};

	useEffect(() => {
		if(email.includes('@') && email.includes('.') && password.length > 4){
			setComplete(true)
		}
	}, [email, password])
	return (
		<div>
	<div className="signup-page">
            <div className="signup-left">
                <img src={logo} />
                <div className="signup-left-section">
                    <img className="signup-icon" src={camera} />
                    <div className="signup-left-section-header">100+ Vendors in each state</div>
                    <div className="signup-left-section-body">We’ve pre-vetted over 100 wedding vendors in each state in every style and price range.</div>
                </div>
                <div className="signup-left-section">
                    <img className="signup-icon" src={free} />
                    <div className="signup-left-section-header">100% Free forever</div>
                    <div className="signup-left-section-body">WedMatch is always free for couples to use, and free for vendors to join</div>
                </div>
                <div className="signup-left-section">
                    <img className="signup-icon" src={clock} />
                    <div className="signup-left-section-header">The most advanced matching</div>
                    <div className="signup-left-section-body">We match you with couples that are a perfect fit by using the most advanced algorithms and AI.</div>
                </div>
            </div>
            <div className="signup-right signup-right-create">
                <div className="signup-right-header">Sign In <span className="for">for</span> Vendors</div>
                <div className="signup-inputs">
                    {//<div className="divider"></div>
                    }
				<div className="photographer-signup-input-wrapper create" style={{width: '100%'}}>
					<label className="photographer-signup-input-label create" htmlFor="email">Email</label>
               <input
					style={{width: '100%'}}
                    type="email"
                    placeholder="Email address..."
                    name="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
				</div>
				<div className="photographer-signup-input-wrapper create" style={{width: '100%'}}>
					<label className="photographer-signup-input-label create" htmlFor="password">Password</label>
					<input
						style={{width: '100%'}}
						placeholder="Password..."
						name="Password"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>
				<button className="signup-button" disabled={!complete}
                    onClick={submit}>SIGN IN</button>
					<div>{error}</div>
					<div className="have-an-account">Don't have an account? <Link className="signup-link" to="/createaccount">SIGN UP</Link></div>
					<div className="have-an-account"><Link className="signup-link" to="/forgotpassword">Forgot passsword?</Link></div>
            </div>
		</div>
		</div>
		</div>
	);
};

export default Login;