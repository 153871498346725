import './CreatePage.css'
import React, { useEffect, useState, useContext } from 'react'
import { useParams } from "react-router-dom";
import axios from 'axios'
import { UserContext } from '../../UserContext';
import { CreatePageHeader } from './Components/CreatePageHeader'
import { StepOneWeddingDetails } from './StepOneWeddingDetails/StepOneIndex'
import { StepTwoVendors } from './StepTwoVendors/StepTwoIndex'
import { useNavigate } from 'react-router'
import { StepThreeUploads } from './StepThreeUploads/StepThreeIndex'
import { Header } from '../Header/Header';
import DashboardNav from '../dashboard-nav.component'

const initialForm = {
    steps:{
        step1:{
            stepNumber:"1",
            keywords:[],
            weddingDate:"",
            ceremonyVenue:'',
            receptionVenue:'',
            exactCeremonyLocation:'',
            exactReceptionLocation:'',
            partner1:'',
            partner2:'',
            descriptiveTitle:'',
        },
        step2:{
            stepNumber:"2",
            bridalShop:{
                vendorName:'',
                vendorEmail:'',
            },
            weddingCake:{
                vendorName:'',
                vendorEmail:'',
            },
            catering:{
                vendorName:'',
                vendorEmail:'',
            },
            dj:{
                vendorName:'',
                vendorEmail:'',
            },
            florist:{
                vendorName:'',
                vendorEmail:'',
            },
            lighting:{
                vendorName:'',
                vendorEmail:'',
            },
            makeup:{
                vendorName:'',
                vendorEmail:'',
            },
            officiant:{
                vendorName:'',
                vendorEmail:'',
            },
            photobooth:{
                vendorName:'',
                vendorEmail:'',
            },
            planner:{
                vendorName:'',
                vendorEmail:'',
            },
            rentals:{
                vendorName:'',
                vendorEmail:'',
            },
            transportation:{
                vendorName:'',
                vendorEmail:'',
            },
        },
        step3:{
            allImages:[]
        }
    }
}

export const CreatePage = () => {
	let { id } = useParams();

    const [ currentStep, setCurrentStep ] = useState(1)
    const [ form, setForm ] = useState(initialForm)
    const [ stepThree, setStepThree ] = useState({})
    const [ thumbnails, setThumbnails ] = useState([])
	const [ toggleNav, setToggleNav ] = useState(false);
	const { setPhotographer, setActivePage } = useContext(UserContext);
    const history = useNavigate()

    const [ stepOneErrors, setStepOneErrors ] = useState({
        keywords:{
            error:false,
            msg:'Please select atleast one item!'
        },
        weddingDate:{
            error:false,
            msg:'Please fill the required field!'
        },
        ceremonyVenue:{
            error:false,
            msg:'Please fill the required field!'
        },
        receptionVenue:{
            error:false,
            msg:'Please fill the required field!'
        },
        exactCeremonyLocation:{
            error:false,
            msg:'Please fill the required field!'
        },
        exactReceptionLocation:{
            error:false,
            msg:'Please fill the required field!'
        },
        partner1:{
            error:false,
            msg:'Please fill the required field!'
        },
        partner2:{
            error:false,
            msg:'Please fill the required field!'
        },
        descriptiveTitle:{
            error:false,
            msg:'Please fill the required field!'
        },
    })

    
    useEffect(() => {
        setActivePage('albumDashboard')
        setPhotographer(id);
    }, [])

    useEffect(()=>{        
        (async()=> {
            let formFromStorage1 = await sessionStorage.getItem("stepOne")
            let formFromStorage2 = await sessionStorage.getItem("stepTwo")
            let stepOne = JSON.parse(formFromStorage1)
            let stepTwo = JSON.parse(formFromStorage2)
            if(formFromStorage1) setForm(prev=>{
                return {
                    ...prev,
                    steps:{
                        ...prev.steps,
                        step1:stepOne
                    }
                }
            })
            if(formFromStorage2) setForm(prev=>{
                return {
                    ...prev,
                    steps:{
                        ...prev.steps,
                        step2:stepTwo
                    }
                }
            })
        })()
    },[])

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
    }, [currentStep])

    /**
     * @summary controls the back button
     */
    const controlBackBtn = () => {
        if(currentStep===3) {
            setCurrentStep(2)
        }
        else if(currentStep===2) {
            setCurrentStep(1)
        }
        else {
            history("/albums")
        }
    }
    /**
     * @summary Text to be shown on next according to the state of the form
     */
    const nextButtonText = () => {
        if(currentStep===1) {
            return "Next: Vendors"
        }
        else if(currentStep===2) {
            return "Next: Upload"
        }
        else return "Finish"
    }

    /**
     * @summary Checking if user can move to step 2
     * @returns true or false
     */
    const checkIfReadyForStep2 = () => {
        const { partner1, partner2, descriptiveTitle, ceremonyVenue, receptionVenue, weddingDate } = form?.steps?.step1
        if(partner1!=="" && partner2!=="" && descriptiveTitle!=="" && (ceremonyVenue !== "" || receptionVenue !== "") && weddingDate!== "") {
            return true
        }
        else return false
    }

    /**
     * @summary Checking if user can move to step 3
     * @returns true or false
     */
    const checkIfReadyForStep3 = () => {
        // const { weddingCake, bridalShop } = form?.steps?.step2
        if( true ) {
            return true
        }
        else return false
    }
    /**
     * @summary Checking if user can finish
     * @returns true or false
     */
    const checkIfReadyToFinish = () => {
        let allImages = []
        allImages = Object.entries(stepThree).map(([key, item])=>item.uploaded)
        // console.log(allImages)
        if( allImages.length===0 || allImages.includes(false) ) {
            return false
        }
        else return true
    }

    /**
     * @summary Activate or not to activate next button according to the conditions
     * @returns true or false
     */
    const setActiveNextButton = () => {
        if(currentStep===1) {
            if(checkIfReadyForStep2()) return true
            else return false
        }
        else if(currentStep===2) {
            if(checkIfReadyForStep3()) return true
            else return false
        }
        else return false
    }

    /**
     * @summary Controlling next button and validating inputs
     */
    const controlNextBtn = (e) => {
        e.preventDefault()
        if(currentStep===1) {
            if(checkIfReadyForStep2()) {
                setCurrentStep(2)
                sessionStorage.setItem("stepOne", JSON.stringify(form.steps.step1))
            }
        }
        else if(currentStep===2) {
            if(checkIfReadyForStep3()) {
                setCurrentStep(3)
                sessionStorage.setItem("stepTwo", JSON.stringify(form.steps.step2))
            }
        }
    }

    const submitForm = (e) => {
        e.preventDefault()
        sessionStorage.clear()
        axios.post('/api/create-wedding', { form, photos: Object.values(stepThree), thumbnails, id })
        .then(r => {
            if(r.data.msg == 'success'){ 
                window.location='/dashboard/' + id + '/listalbums'
            }
        })
    }

    return(
        <div>
            <DashboardNav toggleNav={toggleNav} />
            <Header />
        <form className='album-upload-background create-album-page'>
            <div className='' style={{paddingTop:5,paddingBottom:5, marginTop:30}}>
                <CreatePageHeader
                    validateStep1={checkIfReadyForStep2} 
                    validateStep2={checkIfReadyForStep3} 
                    validateStep3={checkIfReadyToFinish} 
                    currentStep={currentStep} 
                />

                {/* STEP 1 => Wedding Details */}
                {
                    currentStep===1
                    &&
                    <StepOneWeddingDetails 
                        step1={form?.steps?.step1} 
                        setForm={setForm} 
                        setCurrentStep={setCurrentStep}
                        stepOneErrors={stepOneErrors} 
                        setStepOneErrors={setStepOneErrors} 
                    />
                }

                {/* STEP 2 => Vendors */}
                {
                    currentStep===2
                    &&
                    <StepTwoVendors 
                        step2={form?.steps?.step2} 
                        setForm={setForm} 
                        setCurrentStep={setCurrentStep} 
                    />
                }

                {/* STEP 3 => Vendors */}
                {
                    currentStep===3
                    &&
                    <StepThreeUploads 
                        form={form}
                        step3={stepThree} 
                        setForm={setForm} 
                        thumbnails={thumbnails} 
                        setThumbnails={setThumbnails} 
                        setStepThree={setStepThree} 
                        setCurrentStep={setCurrentStep} 
                    />
                }

                {/* FOOTER */}
                <div className='my_custom_container'>
                    <div style={{margin:"30px 0"}} className='d-flex justify-content-between align-items-center'>
                            <div onClick={controlBackBtn} className='font16 bold500 cursor-pointer'>
                                <img 
                                    style={{marginRight:10}}
                                    src='/images/icons/angle-left.png'
                                    alt='Angle Left'
                                /> 
                                Back
                            </div>
                        {
                            currentStep!==3
                            &&
                            <button 
                                onClick={e=>controlNextBtn(e)} 
                                className={`next_btn ${setActiveNextButton()?" create-active":""}`}
                            >
                                {nextButtonText()}
                            </button>
                        }
                        {
                            currentStep===3 &&
                            checkIfReadyToFinish() &&
                            <button
                                onClick={submitForm} 
                                style={{marginLeft:'auto'}}
                                disabled={thumbnails.length < 3}
                                className={`next_btn ${thumbnails.length===3?" create-active":""}`}
                            >
                                Publish
                            </button>
                        }
                    </div>
                </div>
            </div>
        </form>
        </div>
    )
}