import React, { useState, useEffect } from 'react';
import axios from 'axios'

const CalendarList = () => {   
    const [ photographerId, setPhotographerId ] = useState(null)
    const [ photographers, setPhotographers ] = useState([])
    const [ entries, setEntries ] = useState([])

    useEffect(() => {
        axios.get('/api/photographer-calendars')
        .then(r => {
            setPhotographers(r.data)
        })
    }, [])
    useEffect(() => {
        if (photographerId) {
            axios.get(`/api/calendar-events/${photographerId}`)
            .then(r => setEntries(r.data.events))
        }
    }, [photographerId])
    return(
        <div>
            <select onChange={(e) => setPhotographerId(e.target.value)}>
                <option value={null}>---</option>
                {photographers.map(p =>
                    <option value={p.id}>{p.business_name} {!p.cronofy.ids && 'NO IDS'}</option>
                )}
            </select>
           <table>
           <tr><th>Start</th><th>End</th><th>Summary</th><th>Description</th></tr>
            {entries.map(e =>
                <tr><td>{e.start}</td><td>{e.end}</td><td>{e.summary}</td><td>{e.description}</td></tr>    
            )}
           </table>
        </div>
    )
}

export default CalendarList;