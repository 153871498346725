import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { createClient } from '@supabase/supabase-js';
import { useLocation } from 'react-router-dom';
import NavbarNew from './NewQuiz/navbarnew.component';


const PasswordResetPage = () => {
  const supabase = createClient('https://zzhiugmthrvzmeyznffd.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inp6aGl1Z210aHJ2em1leXpuZmZkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjExNDQ4OTIsImV4cCI6MjAzNjcyMDg5Mn0.zuIK4-Uwx9moh225qtJzAzDgEYQfE6AmVuwuM3Sanow')

  const location = useLocation()
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [resetToken, setResetToken] = useState()

  useEffect(() => {
    const hash = location.hash;
    console.log(hash, 'Full hash');

    if (hash.includes('access_token')) {
      const params = new URLSearchParams(hash.slice(1));
      const token = params.get('access_token');
      console.log('Access Token:', token);

      if (token) {
        setResetToken(token); // Only set the token if it exists
      }
    }
  }, [location]);


  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');


    try {
      const passwordResponse = await axios.post('/api/update-password/', {
        resetToken: resetToken,
        newPassword: newPassword
      });

      // Check if password was updated successfully
      if (passwordResponse.data.error) {
        throw new Error(passwordResponse.data.error || 'Error updating password');
      }

      setSuccess(true);

    } catch (error) {
      console.error('Error updating password:', error);
      setError(error.message || 'An error occurred while updating the password');
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
      <div className="w-full">
        <NavbarNew />
      </div>

      <div className="w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-14 pb-0 mt-5 rounded-3xl bg-white mobile:w-[90%]">
        {success ? (
          <div className="w-full max-w-2xl mx-auto py-8 mobile:text-sm">
            <div className="px-16 mobile:px-3 mobile:mt-3">
              <h2 className="mb-3 text-2xl font-medium text-center mobile:text-xl">
                Password Reset Successful
              </h2>
              <p className="text-center text-gray-600 mb-8">
                Your password has been updated. You can now sign in with your new password.
              </p>
              <div className="flex justify-center">
                <a href="/login" className="px-5 py-3 rounded-full bg-greenblue text-white">
                  Go to Login
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full max-w-2xl mx-auto py-8 mobile:text-sm">
            <div className="px-16 mobile:px-3 mobile:mt-3">
              <h2 className="mb-3 text-2xl font-medium text-center mobile:text-xl">
                Update Password
              </h2>
              <p className="text-center text-gray-600 mb-8">
                Enter a new password for your account.
              </p>

              {error && <p className="text-red-500 text-center mb-4">{error}</p>}

              <form className="space-y-4">
                <input
                  type="password"
                  name="newPassword"
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full pl-3 pr-2 py-[14px] border rounded-md"
                  required
                />

                <div className="flex flex-col items-center justify-center">
                  <button
                    onClick={handlePasswordUpdate}
                    className="px-5 py-3 mt-[20px] rounded-full bg-greenblue text-white"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Updating...' : 'Update Password'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordResetPage;