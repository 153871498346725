import { AlbumsList } from './AlbumsList'
import { AlbumPageHeader } from "./Components/AlbumPageHeader"
import './AlbumsPage.css'
import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from '../../UserContext';
import { useParams } from "react-router-dom";
import axios from 'axios'
import { EmptyAlbums } from './EmptyAlbums'
import { Header } from '../Header/Header';
import DashboardNav from '../dashboard-nav.component'

export const AlbumsPage = () => {
	let { id } = useParams();
	const [ toggleNav, setToggleNav ] = useState(false);
	const { setPhotographer, setActivePage } = useContext(UserContext);
    
    useEffect(() => {
        setActivePage('albumDashboard')
        setPhotographer(id);
    }, [])
    const [ loadedAlbums, setLoadedAlbums ] = useState([])
    const [ showPopup, setShowPopup ] = useState(false)
    const [ loaded, setLoaded ] = useState(false)
    useEffect(()=>{
        axios.get("/api/photographer-weddings/" + id)
        .then((resp)=>{
            setShowPopup(resp.data.shouldSeeReviewPopup)
            setLoadedAlbums(resp.data.weddings)
            setLoaded(true)
        })
    },[])

    return(
		<div className="dashboard-container">
			<DashboardNav toggleNav={toggleNav} />
			<div
				className="dashboard-sub-container "
                style={{padding: '0px', display: 'inherit'}}
				onClick={() => {
					setToggleNav(!toggleNav);
				}}>
        <div>
             {showPopup &&
            <div className='signup-overlay'>
                <div className='signup-modal'>
                    <div className='signup-modal-header' style={{textAlign: 'center'}}>Thank you</div>
                    <div className='signup-modal-faded'>We’ll review your website and email you with more information. In the meantime, you can upload weddings to your favorite venue’s WedMatch page. <a target="_blank" rel="noopener noreferrer" href="https://wedmatch.com/vendor-portfolio">Learn More</a></div>
                    <button className='agree-button' style={{width: '100%'}} onClick={() => setShowPopup(false)}>Got it</button>
                </div>
            </div>
        }
            <Header />
            <div className='album-upload-background' style={{marginTop: '0px'}}>
                {loaded &&
                <div className='d-flex flex-column'>
                    <AlbumPageHeader />
                    {
                        (!loadedAlbums || loadedAlbums.length===0)
                        ?
                        <EmptyAlbums />
                        :
                        <AlbumsList list={loadedAlbums} />
                    }
                </div>
                }
            </div>
        </div>
        </div>
        </div>
    )
}