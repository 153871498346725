import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import Navbar from "./navbar.component";


const Signup = () => {
    let { code, id } = useParams();
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const data = {
        id,
        code,
        password
    }
	const submit = () => {
        if(password.length < 8){
            setError('Must be at least 8 characters')
        } else{
            axios.post('/auth/signup', data)
            .then(() => window.location="/login")
        }
    }
	return (
	<div className="container-app">
			<Navbar />
            <div className="sub-container" style={{  marginTop: "40px", alignItems: "center"}}>
		
			<div className="auth-text">
                Choose a password</div>
                <input
                    className="authentication-input"
                    placeholder="Password"
                    type="password" name="password"
                    value={password} onChange={(e) => setPassword(e.target.value)} />	
            <div className="error">{error}</div>
                <button className="custom-file-upload"
                    onClick={submit}>Submit</button>		
            </div>
		</div>
	);
};

export default Signup;
