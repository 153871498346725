// AuthContext.js
import { createContext, useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";

// Create the context
export const AuthContext = createContext();

// Create the provider component
export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null); // Initially, session is null
  const [sessionLoading, setLoading] = useState(true); // To handle session load
  const history = useNavigate();

  const fetchSession = async () => {
    const accessToken = localStorage.getItem('auth_token');
    const refreshToken = localStorage.getItem('refresh_token');

    if (!accessToken || !refreshToken) {
      setLoading(false); // No tokens found, stop loading
      return;
    }

    try {
      const response = await axios.post('/api/validate-supa-login', {
        accessToken: accessToken,
        refreshToken: refreshToken,
      });

      if (response.status === 200) {
        setSession(response.data.session);
      }
    } catch (error) {
      console.error('Error validating session:', error);
    } finally {
      setLoading(false); // Set loading to false when the session check is done
    }
  };

  useEffect(() => {
    fetchSession(); // Fetch session when the component mounts
  }, []);

  return (
    <AuthContext.Provider value={{ session, setSession, sessionLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
