import React, { useContext } from "react";
import { AnswersContext } from "../../AnswersContext";
import FooterNew from "./footernew.component";
import NavbarNew from "./navbarnew.component";
import Timeline from "./timeline.component";
import { containerVarients } from "../../animations/animations";
import ScrollJump from "../scroll-jump.component";

const DoTogetherNew = () => {

	const FooterRender = () => {
		if (quizState.attributes.length && quizState.preference) {
			return (
				<FooterNew to="/vendortypes" from="/tags" button={true} buttonText="Next: Vendors" otherText="Style & Attributes 4/5" />
			);
		} else {
			return <FooterNew button={true} to="#" from="/tags" inactive={true} otherText="Style & Attributes 4/5"  buttonText="Next: Vendors" />;
		}
	};

	const {
		quizState,
		setValue,
		toggleArraySelection,
	} = useContext(AnswersContext);

	return (
		<div id="main" className="question-page">
			<NavbarNew />
			<Timeline phase="dotogether" />
			<div
				className="question-container"
				variants={containerVarients}
				initial="hidden"
				animate="visible"
				>
				<div>
					<div className="header">Attributes</div>
					<div className="subheader">
					We've analyzed reviews to give points to photographers with reviews mentioning these attributes.
					</div>
				</div>
				<div>
					<div className="question">
						Which 3-5 attributes do you want your photographer to be strongest in?
					</div>
					<div className="header">*Pick 3-5 for best results</div>
					<div className="flex flex-wrap">
						{attributeOptions.map(a =>
							<button style={{width: 'inherit' }} className={`question-button ${quizState.attributes.includes(a) ? 'selected' : ''}`}  onClick={() => toggleArraySelection('attributes', a)}>{a.charAt(0).toUpperCase()
								+ a.slice(1)}</button>
						)}
					</div>
					</div>
                    <div>
                        <br /><br />
					<div className="header">Working Style</div>
					<div className="subheader">
					Your photographer's approach to the wedding day should match your needs.
					</div>
				</div>
				<div>
					<div className="question">
						I'd most like my photographer to:
					</div>
					<div className="flex" style={{marginLeft: '-5px'}}>
						<button
							className={`question-button question-tag-button ${quizState.preference == 'charge' ? 'selected' : ''}`}
							selected={quizState.preference == "charge"} onClick={() => setValue('preference', "charge")}>
							Take charge
						</button>
						<button
							className={`question-button question-tag-button ${quizState.preference == 'fly' ? 'selected' : ''}`}
							selected={quizState.preference == "fly"}
							onClick={() => setValue('preference', "fly")}>
							Be a fly on the wall
						</button>
						<button
							className={`question-button question-tag-button ${quizState.preference == 'relax' ? 'selected' : ''}`}
							selected={quizState.preference == "relax"} onClick={() => setValue('preference', "relax")}>
							Help me relax
						</button>
						<ScrollJump />
						<button
							className={`question-button question-tag-button ${quizState.preference == 'whatever' ? 'selected' : ''}`}
							selected={quizState.preference == "whatever"} onClick={() => setValue('preference', "whatever")}>
							Do whatever I ask
						</button>
					</div>
				</div>
			</div>
			<FooterRender />
		</div>
	);
};

export default DoTogetherNew;

const attributeOptions = [
    'flexible',
    'adaptable',
    'patient',
    'creative',
    'artistic',
    'unique',
    'communicative',
    'responsive',
    'organized',
    'experienced',
    'professional',
    'knowledgeable',
    'assertive',
    'talented',
    'skilled',
    'documentary',
    'capture moment',
    'candid',
    'photojournalistic',
    'friendly',
    'helpful',
    'supportive'
]