import React, { useState } from "react"
import OutsideClickHandler from "../../../utils/OutsideClickHandler"
import { CardDropdown } from "../Components/CardDropdown"
const dropdownItems = [
    {
        id:1,
        text:'Edit',
        icon:'pen',
        action:()=>console.log("EDIT CLICKED")
    },
    {
        id:2,
        text:'Delete Album',
        icon:'bin',
        action:()=>console.log("DELETE CLICKED")
    },
]

export const AlbumCard = ({album, linkToEdit }) => {

    const [ open, setOpen ] = useState(false)
    const [ showOverlay, setShowOverlay ] = useState(false)

    const overlay = () => {
        return(
            <span className='bold600 font14 text-center view_all_overlay'>
                View All Photos
                <img
                    style={{position:'absolute', bottom:0}} 
                    src='/images/icons/all-photo-elipse.png'
                    alt='ELIPSE FOR STYLING'
                />
                <span 
                    className='text-dark-grey bold600' 
                    style={{position:'absolute', bottom:5, fontSize:11}}
                >
                    {album.length} Photos
                </span>
            </span>
        )
    }

    return(
        <div className='album_card'>
            <div className=''>

                <a target="_blank" href={`https://wedmatch.com/wedding/${album[0].wedding_fkey}`}><div 
                    className='d-flex overflow-hidden' 
                    style={{height:220, backgroundColor:'white'}}
                >
                        <div
                            onMouseEnter={()=>setShowOverlay(1)}
                            onMouseLeave={()=>setShowOverlay(false)} 
                            className='overflow-hidden d-flex align-items-center position-relative' 
                            style={{
                                flex:1, 
                                backgroundImage:`url(${album.find(a => a.thumbnail_sort === 0) ? album.find(a => a.thumbnail_sort === 0).image_url : album.filter(a => a.thumbnail)[0]?.image_url})`, 
                                backgroundRepeat:'no-repeat', 
                                backgroundSize:'cover',
                                backgroundPosition:'center',
                                marginRight:5
                            }}
                        >
                            {/*
                                showOverlay===1
                                &&
                                overlay()
                            */}
                        </div>
                    <div className='overflow-hidden d-flex align-items-center flex-column' style={{flex:1}}>
                            <div 
                                onMouseEnter={()=>setShowOverlay(2)}
                                onMouseLeave={()=>setShowOverlay(false)}
                                className='overflow-hidden position-relative' 
                                style={{
                                    flex:1, 
                                    width:"100%", 
                                    backgroundImage:`url(${album.find(a => a.thumbnail_sort === 1) ? album.find(a => a.thumbnail_sort === 1).image_url : album.filter(a => a.thumbnail)[1]?.image_url})`,
                                    marginBottom:5,
                                    backgroundPosition:'center',
                                    backgroundRepeat:'no-repeat', 
                                    backgroundSize:'cover',
                                }} 
                            >
                            {/* Overlay */}
                            {/*
                                showOverlay===2
                                &&
                                overlay()
                            */}
                            </div>
                            <div 
                                onMouseEnter={()=>setShowOverlay(3)}
                                onMouseLeave={()=>setShowOverlay(false)}
                                className='overflow-hidden position-relative' 
                                style={{
                                    flex:1, 
                                    width:"100%", 
                                    backgroundImage:`url(${album.find(a => a.thumbnail_sort === 2) ? album.find(a => a.thumbnail_sort === 2).image_url : album.filter(a => a.thumbnail)[2]?.image_url})`,
                                    backgroundRepeat:'no-repeat', 
                                    backgroundPosition:'center',
                                    backgroundSize:'cover',
                                }} 
                            >

                            {/* Overlay */}
                            {/*
                                    showOverlay===3
                                    &&
                                    overlay()
                            */}
                        </div>
                    </div>
                </div>
                </a>
                <div className='' style={{height:"100%", padding:'10px 10px 10px 10px'}}>
                    <div className='font16 bold600 spacer-y2'>{album[0].partner1} & {album[0].partner2}</div>
                    <div className='font14 bold400 text-light-grey spacer-y1 d-flex align-items-center'>
                            <img
                                className='d-block spacer-r2'
                                src='/images/icons/marker-icon.png'
                                alt='Map Marker Icon'
                            />
                        {album[0].ceremony_venue_name}
                        </div>
                    <div className='font14 bold400 text-light-grey spacer-y1 d-flex align-items-center'>
                            <img
                                className='d-block spacer-r2'
                                src='/images/icons/calendar-light.png'
                                alt='Calendar Icon'
                            />
                        {album[0].wedding_date}
                        </div>
                </div>
            </div>
            {linkToEdit && <a target="_blank" href={`/editwedding/${album[0].wedding_fkey}`}><button className='vendor-dashboard-button vendor-dashboard-primary-button'>Edit Wedding</button></a>}
            {/*
            <OutsideClickHandler action={()=>setOpen(false)}>
                <div onClick={()=>{setOpen(prev=>!prev)}} className='card_dropdown_btn'>
                    <img
                        src='/images/icons/menu-dots.png'
                        alt='Menu Dots'
                    />
                        <CardDropdown
                            open={open} 
                            setOpen={setOpen} 
                            items={dropdownItems} 
                        />
                </div>
            </OutsideClickHandler>
            */}
        </div>
    )
}