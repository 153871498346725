import React from "react";
import styled from "styled-components";
import wed from "../styles/logo_wed.png";
import match from "../styles/logo_match.png";
import heart from "../styles/logo_heart.png";

const Logo = styled.div`
	width: 100%;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const Navbar = ({ percentageCompleted, pulse }) => {
	if (percentageCompleted) {
		if (percentageCompleted >= 1) {
			return (
				<Logo>
				<img src={wed} className="logo" alt="wed" />
				<img
					src={require(`../logos/${percentageCompleted}.png`)}
					className={pulse ? "logo heart big-logo" : "logo big-logo"}
					/>
					<img src={match} className="logo" alt="wed" />
			</Logo>
			);
		} else
			return (
				<Logo>
				<img src={wed} className="logo" alt="wed" />
				<img
					src={require(`../logos/${percentageCompleted}.png`)}
					className={pulse ? "logo heart " : "logo "}
					/>
						<img src={match} className="logo" alt="wed" />
			</Logo>
			);
	} else
		return (
			<Logo>
				<img src={wed} className="logo" alt="wed" />
				<img src={heart} className="logo" alt="heart" />
				<img src={match} className="logo" alt="wed" />
			</Logo>
		);
};

export default Navbar;
