import React, { useContext, useEffect } from "react";
import { AnswersContext } from "../../AnswersContext";
import FooterNew from "./footernew.component";
import NavbarNew from "./navbarnew.component";
import Timeline from "./timeline.component";
import ScrollJump from "../scroll-jump.component";

const getMinimumBudget = hoursSelected => {
	switch(hoursSelected) {
		case 3:
		case 4:
			return '$700'
		case 5:
			return '$900'
		case 6:
		case 7:
		case 8:
			return '$1,000'
		case 9:
		case 10:
			return '$1,500'
		default:
			return '$700'
	}
}

const Budget = () => {

	const FooterRender = () => {
		if (quizState.selectedBudgets.length>0 || quizState.noBudget) {
			return <FooterNew to="/photostyle" from="/howimportant" button={true} buttonText="Next: Style" otherText="Basic Information 4/4" />;
		} else {
			return <FooterNew to="#" from="/howimportant" button={true} buttonText="Next: Style" otherText="Basic Information 4/4" inactive={true} />;
		}
	};

	const { toggleBoolean, toggleArraySelection, quizState, hoursSelected, setValue } = useContext(AnswersContext);

	useEffect(() => {
		if (quizState.noBudget) {
			setValue('selectedBudgets', [])
		}
	}, [quizState.noBudget])

	useEffect(() => {
		if (quizState.selectedBudgets.length && quizState.noBudget) {
			setValue('noBudget', false)
		}
	}, [quizState.selectedBudgets])

	const budgets =  [
			{
				id: 1,
				displayMinimum: getMinimumBudget(hoursSelected),
				displayMaximum: '$2,000'
			},
			{
				id: 2,
				displayMinimum: '$2,001',
				displayMaximum: '$3,000'
			},
			{
				id: 3,
				displayMinimum: '$3,001',
				displayMaximum: '$4,000'
			},
			{
				id: 4,
				displayMinimum: '$4,001',
				displayMaximum: '$6,000'
			},
			{
				id: 5,
				displayMinimum: '$6,000',
			},
		]

	return (
		<div className="question-page">
			<NavbarNew className="navbar-app" />
			<Timeline phase="budget" />
			<div
				className="question-container"
				initial="hidden">

				<div>
					<div className="header">Budget</div>
					<div className="subheader">We'll match you with photographer in your budget - we have their detailed pricing.</div>
				</div>
				<div>
					<div className="question">What is your approximate photography budget?</div>
					<div style={{display: 'flex', marginBottom: '25px', flexWrap: 'wrap', marginLeft: '-5px'}}>
						{budgets.map(b =>
							<button
								className={`question-button ${quizState.selectedBudgets.some(selectedBudget => selectedBudget == b.id) ? 'selected' : ''}`}
								onClick={() =>{ toggleArraySelection('selectedBudgets', b.id)}}>
								{b.displayMinimum}{b.displayMaximum ? '-' : ''}{b.displayMaximum ?? '+'}
							</button>
						)}
					</div>
				</div>
				<div>
					<label class="checkbox-container">
						<input
							name='noBudget'
							checked={quizState.noBudget}
							type="checkbox"
							onClick={toggleBoolean}
						/>
						<span class="checkmark email-checkmark" />
					</label>
					<ScrollJump />
					<span className="checkbox-label">I'm not sure, show me a variety.</span>
				</div>
			</div>
			<FooterRender className=".footer" />
		</div>
	);
};

export default Budget;
