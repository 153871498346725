import React, { useState, useEffect, useReducer, useMemo } from 'react';
import axios from 'axios'
import _ from 'lodash';
import QuizReducer from '../reducers/quizReducer'
import DownArrow from '../styles/down-arrow.svg';
import Search from '../styles/search.svg';

const importantTagMap = [
    {
        id: 1,
        name: 'LGBTQ+'
    },
    {
        id: 2,
        name: 'People of Color'
    },
    {
        id: 3,
        name: 'Indian/Cultural'
    }
]
// TODO: Gives score of 0 for < 15 review quotes
const budgets = [
    {
        id: 1, // $0-2,000
        min: 0,
        max: 2000,
        displayedMax: 2000
    },
    {
        id: 2, // $2,001-$3,000
        min: 1700,
        max: 3000,
        displayedMax: 3000
    },
    {
        id: 3, // $3,001-$4,000
        min: 2700,
        max: 4000,
        displayedMax: 4000
    },
    {
        id: 4, // $4,001 - $6,000
        min: 3700,
        max: 6000,
        displayedMax: 6000
    },
    {
        id: 5, // $6,000+
        min: 5700,
        max: 100001,
        displayedMax: null
    },
]

const attributeMap = {
    'flexible': 'flexible',
    'adaptable': 'flexible',
    'patient': 'flexible',
    'creative': 'creative',
    'artistic': 'creative',
    'unique': 'creative',
    'communicative': 'communicative',
    'responsive': 'communicative',
    'organized': 'communicative',
    'experienced': 'experienced',
    'professional': 'experienced',
    'knowledgeable': 'experienced',
    'assertive': 'experienced',
    'talented': 'talented',
    'skilled': 'talented',
    'documentary': 'candid',
    'capture moment': 'candid',
    'candid': 'candid',
    'photojournalistic': 'candid',
    'friendly': 'friendly',
    'helpful': 'friendly',
    'supportive': 'friendly'
  }

const baseAttributes = ['flexible', 'creative', 'communicative', 'experienced', 'talented', 'candid', 'friendly']

const attributeOptions = [
    'flexible',
    'adaptable',
    'patient',
    'creative', 
    'artistic',
    'unique',
    'communicative',
    'responsive', 
    'organized',
    'experienced',
    'professional',
    'knowledgeable', 
    'assertive',
    'talented',
    'skilled',
    'documentary', 
    'capture moment',
    'candid',
    'photojournalistic',
    'friendly', 
    'helpful',
    'supportive'
]

const calculateRankScore = (photographer) => {
    const { average_rank } = photographer
    if (!average_rank) {
        return 0
    }
    if (average_rank > 6) {
        return 0
    } else {
        return (7 - average_rank) * 16.6
    }
    
}
const calculateVenueScore = (photographer, weddings) => {
    const numberOfWeddings = weddings.filter(w => w == photographer).length
    if (numberOfWeddings === 0) {
        return 0
    } else if (numberOfWeddings > 1) {
        return 100
    } else {
        return 80
    }
}
const calculateLocationScore = (location, photographer, hours) => {
    if (photographer.primary_location == location) {
        return 100
    } else if (photographer.secondary_location == location || photographer.third_location == location) {
        if (!hours || hours > 6) {
            return 50
        }
        return 50 * (1 - 1/hours)
    } else {
        return 0
    }
}

const calculateStyleScore = (photoStyles, style) => {
    const scoring = [100, 66, 33, 0]
    if (!photoStyles.length) {
        return 0
    }
    if (photoStyles.some(s => !s.rank || !style)) {
        return 0
    } else {
        const rank = parseInt(photoStyles.find(s => s.id == style).rank)
        return scoring[rank - 1]
    }
}
const calculateBudgetScore = (selectedBudgets, photographer, hours) => {
    const stepPercentage = 0.1
    const pointsPerStepBelow = 10
    const pointsPerStepAbove = 25
      if(selectedBudgets.length){
          selectedBudgets.sort((a, b) => a > b ? -1 : 1)
          const maxBudget = budgets.find(bu => bu.id == selectedBudgets[0]).max
          const minBudget = budgets.find(bu => bu.id == selectedBudgets[selectedBudgets.length - 1]).min
          const rate = photographer[`weekendon${hours}`]
          if (rate < minBudget) {
            const stepsBelow = (minBudget - rate) / (minBudget*stepPercentage)
            return 100 - stepsBelow * pointsPerStepBelow
          } else if (rate > maxBudget) {
            const stepsAbove = (rate - maxBudget) / (maxBudget*stepPercentage)
            return 100 - stepsAbove * pointsPerStepAbove
          } else {
            return 100
          }
      } else { 
        return 0
      }
  }

const getMinimumBudget = hoursSelected => {
	switch(parseInt(hoursSelected)) {
		case 3:
		case 4:
			return '$700'
		case 5:
			return '$900'
		case 6:
		case 7:
		case 8:
			return '$1,000'
		case 9:
		case 10:
			return '$1,500'
		default:
			return '$700'
	}
}

const sortByStyle = (styleNotImportant,  photoStyles, photographerArray, weddingLocation) => {
    if(photoStyles.length && !styleNotImportant){
        var ranked = []
        var newPhotographerArray = []
        ranked.push(photoStyles.find(s => s.rank == 1).id)
        ranked.push(photoStyles.find(s => s.rank == 2).id)
        ranked.push(photoStyles.find(s => s.rank == 3).id)
        ranked.push(photoStyles.find(s => s.rank == 4).id)
        ranked.map(r => {
            var thisRank = photographerArray.filter(p => p[0].photo_style_fkey == r && parseFloat(p[0].booking_rate) >= .07).sort((a, b) => parseFloat(a[0].booking_rate) > parseFloat(b[0].booking_rate) ? -1 : 1)				
            if(weddingLocation){
                var correctLocation = _.shuffle(photographerArray.filter(p => p[0].primary_location == weddingLocation && p[0].photo_style_fkey == r && parseFloat(p[0].booking_rate) < .07))
                var incorrectLocation = _.shuffle(photographerArray.filter(p => p[0].primary_location != weddingLocation && p[0].photo_style_fkey == r && parseFloat(p[0].booking_rate) < .07))
                thisRank = thisRank.concat(correctLocation)
                thisRank = thisRank.concat(incorrectLocation)
            }
            else{
            thisRank = thisRank.concat(_.shuffle(photographerArray.filter(p => p[0].photo_style_fkey == r && parseFloat(p[0].booking_rate) < .07)))
            }
            newPhotographerArray = newPhotographerArray.concat(thisRank)

        })
        return newPhotographerArray
    } else{				
        var arr = []
        arr = arr.concat(photographerArray.filter(p => parseFloat(p[0].booking_rate) >= .07).sort((a, b) => parseFloat(a[0].booking_rate) > parseFloat(b[0].booking_rate) ? -1 : 1))
        arr = arr.concat(_.shuffle(photographerArray.filter(p => parseFloat(p[0].booking_rate) < .07)))
        return _.shuffle(photographerArray)
    }
}


const handleTimeOfDay = ({extraLocations, photos, usedPhotographers, weddingLocation, styleNotImportant, photoStyles }) => {
    const startingArray = Object.values(_.groupBy(photos, "photographer_fkey")).filter(p => usedPhotographers.filter(u => u == p[0].photographer_fkey).length == 0)
    if(extraLocations){
        const matched = startingArray.filter(a => a[0].primary_location == weddingLocation)
        const notMatched = startingArray.filter(a => a[0].primary_location != weddingLocation)
        return matched.concat(sortByStyle(styleNotImportant, photoStyles, notMatched, weddingLocation).slice(0, 10 - matched.length))
    }
    return sortByStyle(styleNotImportant, photoStyles, startingArray, weddingLocation).slice(0, 10)
}

const calculatePreferenceScore = (photographer, preference) => {    
    if(preference == 'fly'){
        return (4 - photographer.fly)*33.3333333
    } else if(preference == 'whatever'){
        return (4 - photographer.ask)*33.3333333 
    } else if(preference == 'charge'){
        return (4 - photographer.charge)*33.3333333 
    } else if(preference == 'relax'){
        return (4 - photographer.relax)*33.3333333 
    }
}

const initialQuizState = {
attributes: [],
  day: '',
  email: '',
  firstName: '',
  month: '',
  preference: '',
  venueName: '',
  weddingLocation: '',
  year: '',
  ceremonyImportance: 3,
  gettingReadyImportance: 3,
  hoursSelected: null,
  optionsTotal: 0,
  portraitsImportance: 3,
  receptionImportance: 3,
  timesOfDay: 0,
  venueVendorId: null,
  canContact: true,
  ceremonyChecked: true,
  dateNotDecided: false,
  gettingReadyChecked: true,
  moreLocations: false,
  noBudget: false,
  portraitsChecked: true,
  receptionChecked: true,
  privateVenue: false,
  styleNotImportant: false,
  skipVendors: false,
  ceremony: [],
  chosenVendorTypes: [],
  couplesPortrait: [],
  formalDance: [],
  generalReception: [],
  gettingReady: [],
  importantTags: [],
  locationTags: [],
  matchups: [],
  options1: [],
  options2: [],
  photoStyles: [],
  selectedBudgets: [],
  selections: [],
  styleTags: [],
  usedPhotographers: [],
  userOnlyTags: [],
  weddingParty: [],
}

const getAttributeRanges = photographers => {
    return {
            flexible: photographers.reduce(function(prev, current) { return (prev.flexible > current.flexible) ? prev : current}).flexible,
            creative: photographers.reduce(function(prev, current) { return (prev.creative > current.creative) ? prev : current}).creative,
            communicative: photographers.reduce(function(prev, current) { return (prev.communicative > current.communicative) ? prev : current}).communicative,
            experienced: photographers.reduce(function(prev, current) { return (prev.experienced > current.experienced) ? prev : current}).experienced,
            talented: photographers.reduce(function(prev, current) { return (prev.talented > current.talented) ? prev : current}).talented,
            candid: photographers.reduce(function(prev, current) { return (prev.candid > current.candid) ? prev : current}).candid,
            friendly: photographers.reduce(function(prev, current) { return (prev.friendly > current.friendly) ? prev : current}).friendly,
        }
}

const calculateTagScore = (photographerId, bookedTags, selectedTags) => {
    const namedTags = selectedTags.map(t => importantTagMap.find(t2 => t2.id == t).name)
    const filtered = bookedTags.filter(t => t.photographer_fkey == photographerId).map(p => p.tags)
    const found = filtered.flat().filter(f => namedTags.includes(f)).length
    if (found > 4) return 100
    if (found > 3) return 90
    if (found > 2) return 80
    if (found > 1) return 70
    if (found > 0) return 60
    return 0
}
const calculateAttributeScores = (attributeRanges, attributes) => {
    return {
        flexible: attributes ? attributes.flexible / attributeRanges.flexible * 100 : 0,
        creative: attributes ? attributes.creative / attributeRanges.creative * 100 : 0,
        communicative: attributes ? attributes.communicative / attributeRanges.communicative * 100 : 0,
        experienced: attributes ? attributes.experienced / attributeRanges.experienced * 100 : 0,
        talented: attributes ? attributes.talented / attributeRanges.talented * 100 : 0,
        candid: attributes ? attributes.candid / attributeRanges.candid * 100 : 0,
        friendly: attributes ? attributes.friendly / attributeRanges.friendly * 100 : 0,
    }
}



const AlgorithmTest = () => {   
    const [quizState, dispatch] = useReducer(QuizReducer, initialQuizState)
    const [ scoredPhotographers, setScoredPhotographers ] = useState([])
    const [ photographers, setPhotographers ] = useState([])
	const [ locations, setLocations ] = useState([])
	const [ showLocations, setShowLocations ] = useState(false)
    const [ venueResults, setVenueResults ] = useState([])
    const [ showVenueResults, setShowVenueResults ] = useState(false)
    const [ weddings, setWeddings ] = useState([])
    const [ tags, setTags ] = useState([])
    const [ bookedTags, setBookedTags ] = useState([])
    const [ budgetWeight, setBudgetWeight ] = useState(40)
    const [ attributeWeight, setAttributeWeight ] = useState(20)
    const [ locationWeight, setLocationWeight ] = useState(10)
    const [ styleWeight, setStyleWeight ] = useState(10)
    const [ venueWeight, setVenueWeight ] = useState(3)
    const [ tagWeight, setTagWeight ] = useState(10)
    const [ bookingWeight, setBookingWeight ] = useState(10)
    const [ albumWeight, setAlbumWeight ] = useState(1)
    const [ taggingWeight, setTaggingWeight ] = useState(1)
    const [ calendarWeight, setCalendarWeight ] = useState(10)
    const [ matchingWeight, setMatchingWeight ] = useState(3)
    const [ preferenceWeight, setPreferenceWeight ] = useState(3)
    const [ rankWeight, setRankWeight ] = useState(3)

    const handleChangeText = e => {
        dispatch({
        type: 'SET VALUE',
        field: e.target.name,
        value: e.target.value,
        })
    }

    const toggleBoolean = e => {
        dispatch({
        type: 'TOGGLE BOOLEAN',
        field: e.target.name,
        })
    }

    const toggleArraySelection = (field, id) => {
        dispatch({
        type: 'TOGGLE ARRAY SELECTION',
        field,
        id,
        })
    }

    const setValue = (field, value) => {
        dispatch({
        type: 'SET VALUE',
        field,
        value
        })
    }

    const pushToArray = (field, value) => {
        dispatch({
        type: 'PUSH TO ARRAY',
        field,
        value
        })
    }

    const budgets =  useMemo(() => 
    {
        return [
            {
                id: 1,
                displayMinimum: getMinimumBudget(quizState.hoursSelected),
                displayMaximum: '$2,000'
            },
            {
                id: 2,
                displayMinimum: '$2,001',
                displayMaximum: '$3,000'
            },
            {
                id: 3,
                displayMinimum: '$3,001',
                displayMaximum: '$4,000'
            },
            {
                id: 4,
                displayMinimum: '$4,001',
                displayMaximum: '$6,000'
            },
            {
                id: 5,
                displayMinimum: '$6,000',
            },
        ]
    }, [quizState.hoursSelected])
    useEffect(() => {
        axios.get(`/api/photographer-by-date/`)
        .then(r => {
            setPhotographers(r.data)
            axios.post('/api/get-booked-tags', { photographerIds: r.data.map(p => p.id)})
                .then(r => {
                    setBookedTags(r.data)
                })
        })
        axios.get('/api/styles')
        .then(r => {
            setValue('photoStyles', r.data)
        })
		axios.get('/api/locations')
        .then(r => {
            setLocations(r.data)
        }) 
        axios.get('/api/all-tags')
        .then(r => setTags(r.data))
    }, [])

    useEffect(() => {
        if (quizState.month && quizState.year.length === 4 && quizState.day) {
            axios.get(`/api/photographer-by-date/${quizState.year}-${quizState.month}-${quizState.day}`)
            .then(r => {
                setPhotographers(r.data)
                axios.post('/api/get-booked-tags', { photographerIds: r.data.map(p => p.id)})
                .then(r => {
                    setBookedTags(r.data)
                })
                if (quizState.venueVendorId) {
                    axios.post('/api/get-wedding-experience', { photographerIds: photographers.map(p => p.id), vendorId: quizState.venueVendorId})
                    .then(r => {
                        setWeddings(r.data)
                    })
                } else {
                    setWeddings([])
                }
            })
        } else {
            axios.post('/api/get-booked-tags', { photographerIds: photographers.map(p => p.id)})
            .then(r => {
                setBookedTags(r.data)
            })
            if (quizState.venueVendorId) {
                axios.post('/api/get-wedding-experience', { photographerIds: photographers.map(p => p.id), vendorId: quizState.venueVendorId})
                .then(r => {
                    setWeddings(r.data)
                })
            } else {
                setWeddings([])
            }
        }
        
    }, [quizState.year, quizState.month, quizState.day, quizState.venueVendorId])

    const baseAttributeWeights = useMemo(() => {
        const numberOfAttributes = quizState.attributes.length
        return baseAttributes.map(a => {
            return {
                name: a,
                weight: numberOfAttributes > 0 ? quizState.attributes.filter(at => attributeMap[at] === a).length / numberOfAttributes : 0
            }
        })
    }, [quizState.attributes])


    const searchVenues = (e) => {
        if(e.target.value?.length > 1){
            axios.get('/api/get-vendors/14/' + e.target.value)
            .then(r => {
                setVenueResults(r.data.vendors)
            })
        } else{
            setVenueResults([])
        }
    }

    const rankPhoto = (index) => {
        var arr = [...quizState.photoStyles]
        if(arr[index].rank){
            var i;
            for (i = 0; i < arr.length; i++){
                if(arr[i].rank >= arr[index].rank){
                    arr[i].rank = null
                }
            }
        } else if(quizState.photoStyles.filter(s => s.rank == 1).length == 0){
            arr[index].rank = 1
        } else if(quizState.photoStyles.filter(s => s.rank == 2).length == 0){
            arr[index].rank = 2
        } else if(quizState.photoStyles.filter(s => s.rank == 3).length == 0){
            arr[index].rank = 3
        } else if(quizState.photoStyles.filter(s => s.rank == 4).length == 0){
            arr[index].rank = 4
        }
        setValue('photoStyles', arr)
    }

    
    useEffect(() => {
        const newArr = [...photographers]
        const attributeRanges = newArr.length ? getAttributeRanges(newArr.filter(p => p.attributes).map(p => p.attributes)) : null
        const scoredArray = newArr.map(p => {
            const attributeScores = calculateAttributeScores(attributeRanges, p.attributes)
            let attributeScore = 0
            baseAttributeWeights.forEach(a => {
                attributeScore += ((attributeScores[a.name] ?? 0) * a.weight)
            })
            const newObject = {
                ...p,
                budgetScore: calculateBudgetScore(quizState.selectedBudgets, p, quizState.hoursSelected),
                attributeScores,
                attributeScore: attributeScore,
                locationScore: calculateLocationScore(quizState.weddingLocation, p, quizState.hoursSelected),
                styleScore: calculateStyleScore(quizState.photoStyles, p.photo_style_fkey),
                venueScore: calculateVenueScore(p.id, weddings),
                tagScore: calculateTagScore(p.id, bookedTags, quizState.importantTags),
                rankScore: calculateRankScore(p),
                preferenceScore: quizState.preference ? calculatePreferenceScore(p, quizState.preference) : 0
            }
            newObject.totalScore = parseFloat(parseFloat(budgetWeight)*parseFloat(newObject.budgetScore)) + 
                parseFloat(attributeWeight*parseFloat(newObject.attributeScore)) + 
                parseFloat(locationWeight*parseFloat(newObject.locationScore)) + 
                parseFloat(styleWeight*parseFloat(newObject.styleScore)) + 
                parseFloat(venueWeight*parseFloat(newObject.venueScore)) + 
                parseFloat(tagWeight*parseFloat(newObject.tagScore)) + 
                parseFloat(bookingWeight*parseFloat(newObject.booking_score)) + 
                parseFloat(albumWeight * parseFloat(newObject.album_score)) + 
                parseFloat(taggingWeight*parseFloat(newObject.tagging_score)) +
                parseFloat(calendarWeight*parseFloat(newObject.calendar_score)) +
                parseFloat(matchingWeight*parseFloat(newObject.matching_score)) +
                parseFloat(preferenceWeight*parseFloat(newObject.preferenceScore)) +
                parseFloat(rankWeight*parseFloat(newObject.rankScore))

            return newObject
        })
        setScoredPhotographers(scoredArray)
    }, [rankWeight, quizState.importantTags, quizState.preference, preferenceWeight, quizState.selectedBudgets, quizState.hoursSelected, photographers, quizState.attributes, quizState.weddingLocation, quizState.photoStyles, weddings, bookedTags, budgetWeight, attributeWeight, locationWeight, styleWeight, venueWeight, tagWeight, bookingWeight, albumWeight, taggingWeight, calendarWeight, matchingWeight])

    return(
        <div>
            Date: <input style={{width: '60px'}} type='number' value={quizState.month} placeholder='mm' onChange={e => setValue('month', e.target.value)} /><input style={{width: '60px'}} type='number' value={quizState.day} placeholder='dd' onChange={e => setValue('day', e.target.value)} /><input style={{width: '60px'}} type='number' value={quizState.year} placeholder='yyyy' onChange={e => setValue('year', e.target.value)} />
            Hours: <input type='number' value={quizState.hoursSelected} onChange={e => setValue('hoursSelected', e.target.value)} />
            <div>
                {attributeOptions.map(a =>
                    <button style={{width: 'inherit' }} className={`question-button ${quizState.attributes.includes(a) ? 'selected' : ''}`}  onClick={() => toggleArraySelection('attributes', a)}>{a}</button>
                )}
            </div>
            
				<div>
					{locations.length > 0 &&
					<div onClick={() => setShowLocations(!showLocations)} className="location-dropdown">
						<div style={{display: 'flex', justifyContent: 'space-between'}} >{!quizState.weddingLocation && <span className="dropdown-placeholder">Your venue location</span>}{quizState.weddingLocation && <span className="dropdown-chosen">{locations.find(l => l.id == quizState.weddingLocation).name}</span>} <img style={{width: '16px'}} src={DownArrow} /> </div>
						{showLocations && <div className="location-options">{locations.map(l => <div className="location-option" onClick={() => {setValue('weddingLocation', l.id); setShowLocations(!showLocations)}}>{l.name}</div>)}</div>}
					</div>
					}
				</div>
				{quizState.weddingLocation &&
				<div style={{marginTop: '50px'}}>
					{showVenueResults && venueResults?.length > 0 &&
                    <div className='overlay transparent' onClick={() => setShowVenueResults(false)}></div>
                    }     
					<div style={{position: 'relative', zIndex: '6'}}>
						<input name='venueName' className="question-input searchbox-input" placeholder="Search your venue" type="text" value={quizState.venueName} onChange={(e) => { handleChangeText(e); setValue('venueVendorId', null); searchVenues(e); setShowVenueResults(true); }}/>
						<img className='search-icon' src={Search} />
						{showVenueResults && venueResults?.length > 0 &&
							<div className='search-results'>
								{venueResults.map(v => <div onClick={() => {
									setShowVenueResults(false); 
									setValue('venueName', v.business_name); 
									setValue('venueVendorId', v.id);
								}} key={v.id}>{v.business_name}</div>)}
							</div>
						}
					</div>
                </div>
                }
            <div style={{display: 'flex', marginBottom: '25px', flexWrap: 'wrap', marginLeft: '-5px'}}>
                {budgets.map(b =>						
                    <button 
                        className={`question-button ${quizState.selectedBudgets.some(selectedBudget => selectedBudget == b.id) ? 'selected' : ''}`} 
                        onClick={() =>{ toggleArraySelection('selectedBudgets', b.id)}}>
                        {b.displayMinimum}{b.displayMaximum ? '-' : ''}{b.displayMaximum ?? '+'}
                    </button>
                )}
            </div>
            
            <div className="photo-style-container">
                    {quizState.photoStyles.map((p, index) => (
                    <div style={{position: 'relative', cursor: 'pointer'}} onClick={() => rankPhoto(index)}>
                        <img style={{maxWidth: '100%'}} src={p.image_url.replace('/upload/', '/upload/w_300/')} />
                        {p.rank &&<div className="photo-rank">{p.rank}</div>}
                    </div>)
                    )}
                </div>
                <div>
                {tags.filter(t => t.is_important).map(t =>
                    <button
                    className={`question-button question-tag-button ${quizState.importantTags.length && quizState.importantTags.some(i => i == t.id) ? 'selected' : ''}`}
                        selected={quizState.importantTags.length && quizState.importantTags.some(i => i == t.id)}
                        onClick={() => toggleArraySelection('importantTags', t.id)}
                        >
                        {t.name == "Indian/Cultural" ? "Cultural Wedding" : t.name}
                    </button>
                )}
                </div>
                <div style={{marginLeft: '-5px'}}>
						<button
							className={`question-button question-tag-button ${quizState.preference == 'charge' ? 'selected' : ''}`}
							selected={quizState.preference == "charge"} onClick={() => setValue('preference', "charge")}>
							Take charge
						</button>
						<button 
							className={`question-button question-tag-button ${quizState.preference == 'fly' ? 'selected' : ''}`}
							selected={quizState.preference == "fly"} 
							onClick={() => setValue('preference', "fly")}>
							Be a fly on the wall
						</button>
						<button
							className={`question-button question-tag-button ${quizState.preference == 'relax' ? 'selected' : ''}`}
							selected={quizState.preference == "relax"} onClick={() => setValue('preference', "relax")}>
							Help me relax
						</button>
						<button
							className={`question-button question-tag-button ${quizState.preference == 'whatever' ? 'selected' : ''}`}
							selected={quizState.preference == "whatever"} onClick={() => setValue('preference', "whatever")}>
							Do whatever I ask
						</button>
					</div>
                <div>
                    <div>WEIGHTS</div>
                    Budget: <input style={{width: '50px'}} type='number' value={budgetWeight} onChange={e => setBudgetWeight(parseFloat(e.target.value))} />
                    Attributes: <input style={{width: '50px'}} type='number' value={attributeWeight} onChange={e => setAttributeWeight(parseFloat(e.target.value))} />
                    Location: <input style={{width: '50px'}} type='number' value={locationWeight} onChange={e => setLocationWeight(parseFloat(e.target.value))} />
                    Style: <input style={{width: '50px'}} type='number' value={styleWeight} onChange={e => setStyleWeight(parseFloat(e.target.value))} />
                    Venue: <input style={{width: '50px'}} type='number' value={venueWeight} onChange={e => setVenueWeight(parseFloat(e.target.value))} />
                    Selected Tags: <input style={{width: '50px'}} type='number' value={tagWeight} onChange={e => setTagWeight(parseFloat(e.target.value))} />
                    Booking: <input style={{width: '50px'}} type='number' value={bookingWeight} onChange={e => setBookingWeight(parseFloat(e.target.value))} />
                    Albums: <input style={{width: '50px'}} type='number' value={albumWeight} onChange={e => setAlbumWeight(parseFloat(e.target.value))} />
                    Tagging: <input style={{width: '50px'}} type='number' value={taggingWeight} onChange={e => setTaggingWeight(parseFloat(e.target.value))} />
                    Calendar: <input style={{width: '50px'}} type='number' value={calendarWeight} onChange={e => setCalendarWeight(parseFloat(e.target.value))} />
                    Matching: <input style={{width: '50px'}} type='number' value={matchingWeight} onChange={e => setMatchingWeight(parseFloat(e.target.value))} />
                    Working: <input style={{width: '50px'}} type='number' value={preferenceWeight} onChange={e => setPreferenceWeight(parseFloat(e.target.value))} />
                    Rank: <input style={{width: '50px'}} type='number' value={rankWeight} onChange={e => setRankWeight(parseFloat(e.target.value))} />
                </div>
            <table>
                <tbody>
                    <tr><th>Name</th><th>Budget Score</th><th>WeekendOn{quizState.hoursSelected}</th>{baseAttributes.map(b => <th>{b}</th>)}<th>Attributes</th><th>Loc.</th><th>Style</th><th>Venue</th><th>Tags</th><th>Booking</th><th>Albums</th><th>Tagging</th><th>Calendar</th><th>Matching</th><th>Working</th><th>Rank</th><th>Total</th></tr>
                    {scoredPhotographers.sort((a, b) => a.totalScore > b.totalScore || !b.totalScore ? -1 : 1).map((p, index) =>
                            <tr style={index == 9 ? {borderBottom: '2px solid black'} : {}} key={p.id}>
                                <td>{p.business_name}</td>
                                <td>{Math.round(p.budgetScore * 10) / 10}</td>
                                <td>{quizState.hoursSelected ? p[`weekendon${quizState.hoursSelected}`] : ''}</td>
                                {baseAttributes.map(b => <td>{Math.round(p.attributeScores[b] * 10) / 10}</td>)}
                                <td>{Math.round(p.attributeScore * 10) / 10}</td>
                                <td>{Math.round(p.locationScore*10)/10}</td>
                                <td>{Math.round(p.styleScore*10)/10}</td>
                                <td>{Math.round(p.venueScore*10)/10}</td>
                                <td>{Math.round(p.tagScore*10)/10}</td>
                                <td>{p.booking_score}</td>
                                <td>{p.album_score}</td>
                                <td>{Math.round(p.tagging_score*10)/10}</td>
                                <td>{Math.round(p.calendar_score*10)/10}</td>
                                <td>{Math.round(p.matching_score*10)/10}</td>
                                <td>{Math.round(p.preferenceScore*10)/10}</td>
                                <td>{Math.round(p.rankScore*10)/10} ({p.average_rank})</td>
                                <td>{Math.round(p.totalScore*10)/10}</td>
                            
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}

export default AlgorithmTest;