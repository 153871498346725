import React from 'react'
const allKeywords = [
    "Backyard",
    "Ballroom",
    "Beach",
    "Black Tie",
    "City",
    "Cultural Wedding",
    "Elopement",
    "House of Worship",
    "LGBTQ+",
    "Micro Wedding",
    "Monuments",
    "Mountains",
    "Multi-day Wedding",
    "Nature",
    "Offbeat",
    "Outdoor Ceremony",
    "Party",
    "People of Color",
    "Rustic",
    "Vineyard",
]

export const WeddingKeywords = ({step1, setForm, stepOneErrors}) => {


    /**
     * @summary Toggle adding or removing an item from the main form list
     */
    const addOrRemoveItem = (item) => {
        if(step1) {
            if(step1?.keywords.includes(item)) {
                setForm(prev=>{
                    return {
                        ...prev,
                        steps:{
                            ...prev.steps,
                            step1:{
                                ...prev.steps.step1,
                                keywords:prev.steps.step1.keywords.filter(i=>i!==item)
                            }
                        }
                    }
                })
            }
            else {
                setForm(prev=>{
                    return {
                        ...prev,
                        steps:{
                            ...prev.steps,
                            step1:{
                                ...prev.steps.step1,
                                keywords:[...prev.steps.step1.keywords, item]
                            }
                        }
                    }
                })
            }
        }
    } 
    return(
        <div className='my_custom_container'>
            <div className='font22 bold600'>
                Select any of these words that describe this wedding
            </div>
            <div className='keyword_container'>
                {
                    allKeywords.map((item, index)=>(
                        <div key={index} onClick={()=>addOrRemoveItem(item)} className={`noselect keyword_box ${step1?.keywords.includes(item)?" create-active":""}`}>
                            {item}
                        </div>
                    ))
                }
            </div>
            {
                stepOneErrors.keywords.error &&
                <div className='spacer-y2 text-warning bold500'>
                    {stepOneErrors.keywords.msg}
                </div>
            }
        </div>
    )
}