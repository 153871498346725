import React, { useState, useEffect } from "react";
import axios from "axios";
import '../styles/photographer-signup.css';
import camera from '../styles/signupcamera.svg';
import clock from '../styles/clock.svg';
import free from '../styles/free.svg';
import logo from '../styles/wedmatchlogo.png';
import infologo from '../styles/signupinfo.svg';
import { Link } from 'react-router-dom';

// make more clear for vendors signing up
const accountOptions = [{shortName: 'full', description: 'Full Account (Match with couples and upload real wedding galleries)'}, {shortName: 'basic', description: 'Gallery-Only Account (Upload real wedding galleries only)'}]
const TextInput = (props) => {
    const changeHandler = props.onChange ? props.onChange : () => {}
    return(
        <div style={{width: props.width, ...props.style}} className="photographer-signup-input-wrapper">
        <label className="photographer-signup-input-label" htmlFor={props.valueName}>{props.valueName}{!props.notRequired &&<span className="required-star">*</span>}</label>
        <input style={{width: props.width, ...props.style}} type={props.type ? props.type : "text"} id={props.valueName} placeholder={props.placeholder} value={props.value} onChange={(e) => { props.setValue(e.target.value); changeHandler(e.target.value)}} />
     </div>
    )
}

const CheckboxInput = (props) => {
    return(
    <div style={{width: '100%', marginBottom: '12px'}}>
        <label style={{marginTop: '0px', marginLeft: '0px', marginBottom: '-6px'}} class="checkbox-container">
            <input
                checked={props.value}
                type="checkbox"
                onClick={() => {props.setValue(!props.value)}}
            />
            <span class="checkmark email-checkmark" />
        </label>
        <span className="checkbox-label" style={{fontSize: '15px', fontWeight: '500', fontFamily: 'Montserrat', marginLeft: '0px'}}>{props.text}</span>
    </div>
    )
}


const VendorSignup = () => {
	const [ password, setPassword ] = useState("");
	const [ email, setEmail ] = useState("");
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [website, setWebsite] = useState('')
    const [instagram, setInstagram] = useState('')
    const [accountType, setAccountType] = useState('')
    const [zip, setZip] = useState('')
    const [ complete, setComplete ] = useState(false)
    const [ showPopup, setShowPopup ] = useState(false)
    const [ vendorTypes, setVendorTypes ] = useState([])
    const [ vendorType, setVendorType ] = useState(null)
    const [ searchResults, setSearchResults ] = useState([])
    const [ acceptedTerms, setAcceptedTerms] = useState(false)


    const [error, setError] = useState('')

    useEffect(() => {
        axios.get('/api/all-vendor-types')
        .then(r => {
            setVendorTypes(r.data)
        })
    }, [])

	const submit = () => {
            const data = {
                accountType,
                businessName,
                email,
                firstName,
                instagram,
                lastName,
                password,
                vendorType,
                website,
                zip,
            }
            axios.post('/auth/vendorsignup', data)
            .then(r => {
                console.log(r)
                if (r.data.photographerId) {
                    window.location=`/dashboard/${r.data.photographerId}/listalbums`
                } else if (!r.data.msg && r.data.email) {
                    window.location=`/vendordashboard?from=signup&email=${r.data.email}`
                } else if (!r.data.msg) {
                    window.location='/vendordashboard'
                } else {
                    setError(r.data.msg)
                }
            })
    }

    const searchBusinessName = (term) => {
        if (vendorType != 15) {
            axios.get(`/api/get-vendors/${term}`)
            .then(r => {
                setSearchResults(r.data.vendors)
            })
        } else {
            axios.get(`/api/get-photographers/${term}`)
            .then(r => {
                setSearchResults(r.data.photographers)
            })
        }
    }

    useEffect(() => {
        if(accountType === 'full' && !acceptedTerms && vendorType && vendorTypes.find(t => t.id == vendorType).referral_fee) {
            setShowPopup(true)
        }
    }, [accountType, vendorType])

    useEffect(() => {
        if(firstName && lastName && businessName && (instagram || website) && vendorType && zip && accountType && email.includes('@') && email.includes('.') && password.length > 6){
            if(!complete){
                setComplete(true)
            }
        } else{
            setComplete(false)
        }
    }, [firstName, lastName, businessName, zip, accountType, complete, email, password, vendorType, accountType, website, instagram])

	return (
        <div onClick={() => setSearchResults([])}>
            <div className="banners">
           <div id="banner"></div>
            <div id="banner2"></div>
            <div id="banner3"></div>
            <div id="banner4"></div>
            </div>
	<div className="signup-page">
        {showPopup &&
            <div className='signup-overlay'>
                <div className='w-[620px] max-h-[75%] overflow-auto px-12 pt-5 pb-4 mx-4 mb-16 bg-white rounded-2xl text-[#444444] font-poppins'>
                    <div className="mb-3 text-left"><img src={infologo} /></div>
                    <div className='mb-3 text-left text-2xl text-black font-medium'>Fee Agreement: <span>{vendorTypes.find(t => t.id == vendorType).name}</span></div>
                    <div className="text-left text-base">If I’m approved to be a part of the matching quiz, I agree to the following fee structure:</div>
                    <ul className="space-y-0.5 pl-4 pr-2 my-4 list-outside list-disc">
                        <li>There will be a {vendorTypes.find(t => t.id == vendorType).referral_tier1}% booking fee</li>
                        <li>Fee is capped at a max of $300</li>
                    </ul>
                    <div className='my-4 text-[#333333] italic'>You don’t pay until a couple books, and there are no other fees</div>
                    <div className="flex justify-center space-x-2 mt-10">
                        <button className='w-[48%] h-[52px] border rounded-full text-[#A4A4A4]' onClick={() => { setShowPopup(false); setAccountType('basic'); }}>Decline</button>
                        <button className='w-[48%] h-[52px] border rounded-full bg-[#0A8A95] text-white' onClick={() => { setShowPopup(false); setAcceptedTerms(true); }}>I Agree</button>
                    </div>
                </div>
            </div>
        }
            <div className="signup-left" style={{marginTop: '105px'}}>
                <img src={logo} />
                <div className="signup-left-section">
                    <img className="signup-icon" src={camera} />
                    <div className="signup-left-section-header">Your dream couples, 100% Risk Free</div>
                    <div className="signup-left-section-body">You only pay for each successful booking, so you’ll never have to worry if it’s worth it!</div>
                </div>
                <div className="signup-left-section">
                    <img className="signup-icon" src={free} />
                    <div className="signup-left-section-header">The most advanced matching</div>
                    <div className="signup-left-section-body">We match you with couples that are a perfect fit by using the most advanced algorithms and AI. </div>
                </div>
                <div className="signup-left-section">
                    <img className="signup-icon" src={clock} />
                    <div className="signup-left-section-header">Super Simple Setup</div>
                    <div className="signup-left-section-body">We don’t make you write long bios or fill out complicated dashboards.</div>
                </div>
            </div>
            <div className="signup-right">
                <div className="signup-right-header">Sign Up <span className="for">for</span> Vendors</div>
                <div className="signup-inputs">

				<div className="photographer-signup-input-wrapper" style={{width: '100%'}}>
					<label className="photographer-signup-input-label" htmlFor="email">Email</label>
               <input
					style={{width: '100%'}}
                    type="email"
                    placeholder="Email address..."
                    name="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
				</div>
				<div className="photographer-signup-input-wrapper" style={{width: '100%', marginBottom: '64px'}}>
					<label className="photographer-signup-input-label" htmlFor="password">Password</label>
					<input
						style={{width: '100%'}}
						placeholder="Password..."
						name="Password"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
                </div>
                <div className="photographer-signup-input-wrapper" style={{width:'100%'}}>
                    <label className="photographer-signup-input-label" htmlFor="how-hear">What type of vendor are you?</label>
                    <select className="w-full px-3 py-[16px] border font-montserrat text-[#1B2D49CC]" value={vendorType} onChange={(e) => setVendorType(e.target.value)}>
                        <option>Please Select</option>
                        {vendorTypes.map(h => (h.name && <option key={h.id} value={h.id}>{h.name}</option>))}
                    </select>
                </div>

                <div style={{position: 'relative', width: '100%'}}>
                <TextInput style={{width: '100%'}} notRequired placeholder="Business name..." value={businessName} onChange={searchBusinessName} setValue={setBusinessName} valueName="Business Name"/>

                {searchResults.length > 0 && <div className='search-results'>
                    {searchResults.map(s => <div onClick={(e) => { e.stopPropagation(); setBusinessName(s.business_name); setSearchResults([])}} key={s.id}>{s.business_name}</div>)}
                </div>}
                </div>
                <TextInput placeholder="First name..." notRequired value={firstName} setValue={setFirstName} valueName="First Name" />
                <TextInput placeholder="Last name..." notRequired value={lastName} setValue={setLastName} valueName="Last Name" />
                <TextInput placeholder="Zip code..." notRequired value={zip} setValue={setZip} valueName="Zip Code" />
                <TextInput placeholder="Website..." notRequired value={website} setValue={setWebsite} valueName="Website URL" />
                <TextInput placeholder="Instagram username..." notRequired value={instagram} setValue={setInstagram} valueName="Instagram URL" />
                <div className="photographer-signup-input-wrapper" style={{width:'100%'}}>
                    <label className="photographer-signup-input-label" htmlFor="how-hear">Which type of account would you like to create?</label>
                    <select className="mobile:h-[90px] mobile:mt-[20px] w-full px-3 py-[16px] border font-montserrat text-[#1B2D49CC] text-wrap" value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                        <option value='' className="">Please Select</option>
                        {accountOptions.map(h => <option key={h.id} value={h.shortName}>{h.description}</option>)}
                    </select>
                </div>
            </div>
            <div className="error">{error}</div>
                <button className="signup-button" disabled={!complete}
                    onClick={submit}>COMPLETE SETUP</button>

				<div className="have-an-account">Already have an account? <Link className="signup-link" to="/login">SIGN IN</Link></div>
            </div>
		</div>

        </div>
	);
};

export default VendorSignup;
