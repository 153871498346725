import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import axios from "axios";

const OauthCalendar = () => {   
    const { id } = useParams();
    const [items, setItems] = useState([])
    useEffect(() => {
        axios.get(`/api/photographer-oauth/${id}`).then(r => {
            setItems(r.data)
        })
    }, [])

    return(
		<div>
            <table style={{tableLayout: 'fixed'}}>
                {items.map(i => 
                    <tr><td style={{width:'100px'}}>{i.summary}</td><td>{i.start.dateTime}</td><td>{i.end.dateTime}</td><td style={{width:'200px'}}>{i.description}</td><td>{i.location}</td></tr>    
                )}
            </table>
        </div>
    )
}

export default OauthCalendar;