import _ from 'lodash'

const reducer = (state, action) => {
    switch (action.type) {
        case 'SET VALUE': {
            localStorage[action.field] = JSON.stringify(action.value)
            const old = {...state}
            _.set(old, action.field, action.value)
          return old
        }
        case 'TOGGLE BOOLEAN': {
            localStorage[action.field] = !state[action.field]
            return {
                ...state,
                [action.field]: !state[action.field]
            }
        }
        case 'SET BOOLEAN FALSE': {
            localStorage[action.field] = false;
            return {
                ...state,
                [action.field]: false,
            };
        }
        case 'TOGGLE ARRAY SELECTION': {
            /*
            const currentlyChecked = state[action.field]?.includes(action.id) ?? false
            const existing = state[action.field] ?? []
            const newArray = currentlyChecked ? state[action.field].filter(f => f !== action.id) : [...existing, action.id]
            localStorage[action.field] = JSON.stringify(newArray)
            return {
                ...state,
                [action.field]: newArray
            }
            */
           const old = {...state}
           const currentValue = _.get(old, action.field)
           if (currentValue) {
            const currentlyChecked = currentValue?.includes(action.id) ?? false
            const newArray = currentlyChecked ? _.get(old, action.field).filter(f => f !== action.id) : [...currentValue, action.id]
            _.set(old, action.field, newArray)
            // localStorage = old
            return old
           } else {
                _.set(old, action.field, [action.id])
                return old
           }

        }
        case 'PUSH TO ARRAY': {
            const newArray = [...state[action.field], action.value]
            localStorage[action.field] = JSON.stringify(newArray)
            return {
                ...state,
                [action.field]: newArray
            }
        }
        default: return state
    }
  }

  export default reducer