import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import NavbarNew from './NewQuiz/navbarnew.component';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordReset = async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post('/api/password-reset', { email });
      setSuccess(true);
      console.log('Password reset email sent');
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred during password reset');
      } else {
        setError('An error occurred during password reset. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
      <div className="w-full">
        <NavbarNew />
      </div>

      <div className="w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-14 pb-0 mt-5 rounded-3xl bg-white mobile:w-[90%]">
        {success ? (
          <p className="h-full pb-14 text-center">Password reset email sent. Check your inbox.</p>
        ) : (
          <div className="w-full max-w-2xl mx-auto py-8 mobile:text-sm">
            <div className="px-16 mobile:px-3 mobile:mt-3">
              <h2 className="mb-3 text-2xl font-medium text-center mobile:text-xl">
                Reset Password
              </h2>
              <p className="text-center text-gray-600 mb-8">
                Enter your email to reset your password.
              </p>

              {error && <p className="text-red-500 text-center mb-4">{error}</p>}

              <form className="space-y-4">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full pl-3 pr-2 py-[14px] border rounded-md"
                  required
                />

                <div className="flex flex-col items-center justify-center">
                  <button
                    onClick={handlePasswordReset}
                    className="px-5 py-3 mt-[20px] rounded-full bg-greenblue text-white"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Resetting...' : 'Reset Password'}
                  </button>

                  <a href="/login" className="mt-3 text-greenblue font-medium">
                    Sign In
                  </a>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;