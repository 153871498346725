import React from 'react'
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar"
import 'react-circular-progressbar/dist/styles.css';

export const MobileStepper = ({ stepNumber }) => {

    let progressbarStyles = (percentage) => buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0.25,
        
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
        
            // Text size
            textSize: '16px',
        
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,
        
            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',
        
            // Colors
            pathColor: `rgba(10, 138, 149, 1)`,
            textColor: '#f88',
            trailColor: 'linear-gradient(90deg, rgba(226,238,239,1) 0%, rgba(10,138,149,1) 18%)',
            backgroundColor: '#3e98c7',
        })

    const stepText = () => {
        if(stepNumber===1) {
            return "Wedding Details"
        }
        else if (stepNumber===2) {
            return "Vendors"
        }
        else return "Uploads"
    }

    return(
        <div className='mobile_stepper'>
            <div style={{width:74, height:74, marginRight:20}}>
                <CircularProgressbarWithChildren styles={progressbarStyles((stepNumber/3)*100)} value={(stepNumber/3)*100}>
                    <div className='circle_40'>
                        {stepNumber}
                    </div>
                </CircularProgressbarWithChildren>
            </div>
            <div className='my-auto'>
                <div className='font16 bold600 text-blue spacer-y2'>
                    Step {stepNumber}/3
                </div>
                <div className='font20 bold600 text-dark-grey'>
                    {stepText()}
                </div>
            </div>
        </div>
    )
}