import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AnswersContext } from "../../../AnswersContext";
import { AuthContext } from "../../../AuthContext";
import axios from "axios";
import NavbarNew from ".././navbarnew.component";
import Timeline from ".././timeline.component";
import FooterNew from ".././footernew.component";
import { containerVarients } from "../../../animations/animations";
import { Mixpanel } from '../../../Mixpanel';
import blueLocation from '../../../styles/blueLocation'
import searchIcon from '../../../styles/searchIcon'
import DownArrow from '../../../styles/down-arrow.svg';
import Search from '../../../styles/search.svg';
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const WeddingLocationNewQuiz = (props) => {
	document.title="Pick your location | WedMatch"

	const { session, sessionLoading } = useContext(AuthContext);
	const { toggleBoolean,	setPhotoStylesFromUrl,	handleChangeText, setValue, quizState	} = useContext(AnswersContext);
	const [ phase, setPhase] = useState('location')
	const [ locations, setLocations ] = useState([])
	const [ showLocations, setShowLocations ] = useState(false)
	const [ venueResults, setVenueResults ] = useState([])
	const [ showVenueResults, setShowVenueResults ] = useState(false)
	const [ nextStep, setNextStep] = useState(false)
  const { ranks } = useParams();
	const [loading, setLoading] = useState(true);
	const locationsDropdownRef = useRef(null);
	const venueDropDownRef = useRef(null);
	setPhotoStylesFromUrl(ranks)


	//ANIMATION WORK
	const [ addLeaveAnimation, setAddLeaveAnimation ] = useState(false);
	const history = useNavigate();


  useEffect(() => {
    if (!sessionLoading) { // Wait until loading is complete
      if (session) {
        history('/home'); // Redirect if session exists
      } else {
				setLoading(false)
			}
    }
  }, [session, sessionLoading]);


	const searchVenues = (e) => {
		if(e.target.value?.length > 1){
				axios.get('/api/get-vendors/14/' + e.target.value)
				.then(r => {
						setVenueResults(r.data.vendors)
				})
		} else{
				setVenueResults([])
		}
	}


	const handleClickOutside = (event) => {
    if (locationsDropdownRef.current && !locationsDropdownRef.current.contains(event.target)) {
      setShowLocations(false);
    }
		if (venueDropDownRef.current && !venueDropDownRef.current.contains(event.target)) {
      setShowVenueResults(false);
    }
  };


	// const setDelay = (e) => {
	// 	e.preventDefault(); //prevent transition
	// 	setAddLeaveAnimation(true);
	// 	Mixpanel.track('Wedding Location Submit Clicked',{
	// 		'Wedding Location': quizState.weddingLocation
	// 	});

	// 	window.setTimeout(() => {
	// 		history("/vendortypesnewquiz");
	// 	}, 500);
	// };


	useEffect(() => {
		if (quizState.weddingLocation && (quizState.noVenue || quizState.venueName || quizState.privateVenue)) {
			setNextStep(true)
		} else {
			setNextStep(false)
		}
	}, [quizState.weddingLocation, quizState.noVenue, quizState.venueName, quizState.privateVenue])


	useEffect(() => {
		Mixpanel.track('View Location Page');
		axios.get('/api/locations')
				.then(r => {
						setLocations(r.data)
				})
				// var referral = props.location.search.replace("?","")
				// if(referral.indexOf("&") != -1){
				// 		referral = referral.substring(0,referral.indexOf("&"))
				// }

				const authToken = localStorage.getItem('auth_token');
				const refreshToken = localStorage.getItem('refresh_token');

				localStorage.clear();

					// Restore auth_token and refresh_token
				if (authToken) {
						localStorage.setItem('auth_token', authToken);
				}
				if (refreshToken) {
						localStorage.setItem('refresh_token', refreshToken);
				}

			window.pintrk('track', 'lead', {
			lead_type: 'location'
		})
		// axios.post('/api/submit-referral', {code: referral})
	}, [])


	useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


	if (loading) {
		return null
	}

	if (loading === false ) {
		return (
			<div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
				<div className="w-full">
					<NavbarNew />
				</div>

				<div className="w-[75%] max-w-[1000px] flex flex-col items-center mt-5 px-3 pt-3 pb-0 rounded-3xl bg-white mobile:w-[90%]">
					{/* Status Bar */}
					<Timeline phase={phase} />

					<div className="w-full flex flex-col items-center pt-10 pb-6 mx-12 border-t z-10">
						{/* Select Location */}
						<div className="w-full flex flex-col items-center">
							<p className="text-center text-[28px] font-medium mobile:text-lg">Where will your wedding take place?</p>
							<p className="w-[70%] mt-2 text-[#444444] text-center mobile:text-[13px] mobile:w-full">Select the city in the dropdown that's closest to your wedding location. If you're not close to any of them, we're not in your area yet.</p>

							{locations.length > 0 &&
								<div className="w-[50%] h-[55px] mt-4 border border-black-4 rounded-xl mobile:w-[90%]" onClick={() => setShowLocations(!showLocations)} ref={locationsDropdownRef}>

									<div className="w-full h-full flex justify-between items-center px-3">
										{!quizState.weddingLocation && <span className="text-gray-300 mobile:text-sm">Your venue location</span>}

										{quizState.weddingLocation &&
										<span className="mobile:text-sm">
											{locations.find(l => l.id == quizState.weddingLocation).name}
										</span>
										}

										<img className="w-4" src={DownArrow} />
									</div>

									{showLocations &&
										<div className="w-full px-1 py-1 mt-0.5 bg-white rounded-xl shadow mobile:text-sm">
											{locations.map(l =>
												<div className="hover:bg-gray-200 flex items-center px-2.5 py-2.5 rounded-xl" onClick={() => {setValue('weddingLocation', l.id); setShowLocations(!showLocations); setPhase('venue')}}>
													{blueLocation}
													<span className="ml-2">{l.name}</span>
												</div>)}
										</div>
									}
								</div>
							}
						</div>
					</div>


					{quizState.weddingLocation &&
					<div className="w-full flex flex-col items-center">
						<p className="text-center text-[28px] font-medium mobile:text-lg">Which venue have you booked?</p>

						<div className="relative w-[50%] h-[55px] flex items-center ml-1 mt-2 px-3 border border-2 rounded-xl mobile:w-[90%]" ref={venueDropDownRef}>
							<input name='venueName' className="w-full mobile:text-sm" placeholder='Search for your venue' type="text" value={quizState.venueName} onChange={(e) => { handleChangeText(e); setValue('venueVendorId', null); searchVenues(e); setShowVenueResults(true); }}/>
							{searchIcon}
							{showVenueResults && venueResults?.length > 0 &&
								<div className='absolute right-0 top-[55px] w-full max-h-[200px] px-1 py-2 rounded-xl bg-white shadow overflow-auto z-10 mobile:text-sm'>
									{venueResults.map(v =>
										<div className="px-2 py-2 cursor-pointer hover:bg-gray-100" onClick={() => {
										setShowVenueResults(false);
										setValue('venueName', v.business_name);
										setValue('venueVendorId', v.id);
										}} key={v.id}>{v.business_name}
										</div>)}
								</div>
							}
						</div>

						<div className="mobile:w-full w-[50%] flex justify-start space-x-6 mt-2 mobile:flex-col mobile:space-x-0 mobile:ml-8 mobile:pb-3">
							<div className="flex items-center ml-1.5 mt-2 mobile:mb-2">
								<label class="checkbox-container" style={{marginRight: '8px', marginTop: '0px'}}>
									<input
										name='noVenue'
										checked={quizState.venueName=='' && quizState.noVenue && quizState.venueVendorId == null}
										type="checkbox"
										onClick={(e) => {setValue('venueVendorId', null); setValue('venueName', ''); toggleBoolean(e)}}
									/>
									<span class="checkmark email-checkmark" />
								</label>
								<p className="mobile:text-[13px]">I haven't booked a venue yet</p>
							</div>
						</div>
					</div>
					}

					<div>
						<FooterNew phase={"location"} complete={nextStep}/>
					</div>
				</div>
			</div>
		);
	}


};

export default WeddingLocationNewQuiz;
