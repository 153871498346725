import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AnswersContext } from "../../AnswersContext";
import axios from "axios";
import NavbarNew from "./navbarnew.component";
import Timeline from "./timeline.component";
import FooterNew from "./footernew.component";
import { containerVarients } from "../../animations/animations";
import { Mixpanel } from '../../Mixpanel';
import Lock from '../../styles/lock.svg';
import Stars from '../../styles/couplesstars.svg';
import ScrollJump from "../scroll-jump.component";

const EmailNew = () => {
	const {
		handleChangeText,
		toggleBoolean,
		retrieveLocalStorage,
		quizState,
	} = useContext(AnswersContext);
	
	useEffect(() => {
		Mixpanel.track('View Email Page');
		retrieveLocalStorage()
	  }, []);

	const FooterRender = () => {
	
		if (quizState.email.includes(".") && quizState.email.includes("@") && quizState.email == confirmedEmail) {
			return (
				<FooterNew
					button={true}
					noLink={true} 
					to=""
					clickEvent={setDelay}
					from="/photographerpreferences"
					buttonText="See My Matches"
					otherText=""
				/>
			);
		} else {
			return <FooterNew buttonOnClick={() => {SubmitEvent();}} button={true} to="#" from="/photographerpreferences" inactive={true} buttonText="See My Matches" />;
		}
	};


    const [ confirmedEmail, setConfirmedEmail] = useState('')
	
	//ANIMATION WORK
	const [ addLeaveAnimation, setAddLeaveAnimation ] = useState(false);
	const history = useNavigate();

	const setDelay = (e) => {
		e.preventDefault(); //prevent transition
		window.gtag_report_conversion('http://app.wedmatch.com/results')
		axios.post('/api/log-visit', {email: quizState.email})
		setAddLeaveAnimation(true);
		Mixpanel.track('Email Form Submit',{
			'type':'success'
		});
		Mixpanel.identify(quizState.email);
		Mixpanel.people.set({
			$first_name: quizState.firstName,
			$last_name: quizState.lastName,
			$email: quizState.email,
			'cermony_date': quizState.day + '-' + quizState.month + '-' + quizState.year,
		});
		window.setTimeout(() => {
			history("/results");
		}, 500);
	};
	function SubmitEvent(){
		Mixpanel.track('Email Form Submit',{
			'type':'fail'
		});
	}

	return (
		
		<div className="question-page">
		<NavbarNew />
		<Timeline phase="email" />
			<div
				className="question-container"
				variants={containerVarients}
				initial="hidden"
				animate={addLeaveAnimation ? "leave" : "visible"}>
					
				<div className="couples-love couples-love-mobile">
					<div className="couples-love-header">COUPLES LOVE US</div>
					<img src={Stars} />
					<div className="couples-body">We're rated 5 out of 5 stars because couples have so much success finding photographers they love.</div>
				</div>
				<div className="email-page-container">
					
					<div>
					<div>
						<div className="header">Almost Done!</div>
						<div className="subheader">
						You'll get your matches instantly right here, but we'll also email you a copy for safe keeping.
						</div>
					</div>

					<div>
						<div/>

						<input 
							className="question-input email-question-input email-name-input"
							placeholder="First Name"
							type="text"
							name='firstName'
							value={quizState.firstName}
							onChange={handleChangeText}/>

						<input 
							className="question-input email-question-input email-name-input" 
							placeholder="Last Name" 
							name='lastName'
							type="text"  
							value={quizState.lastName} 
							onChange={handleChangeText}/>
						<br />
						<input
						style={{width: '420px', maxWidth: '100%'}}
							className="question-input email-question-input"
							name="email"
							id="email"
							placeholder="Email Address"
							onChange={handleChangeText}
							value={quizState.email}
							autocomplete="on"
							type="text"
						/>
						<input
						style={{width: '420px', maxWidth: '100%'}}
							className="question-input email-question-input"
							name="confirmemail"
							id="confirmemail"
							placeholder="Confirm Email"
							onChange={(e) => setConfirmedEmail(e.target.value)}
							value={confirmedEmail}
							autocomplete="on"
							type="text"
						/>
						<br />
							<label class="checkbox-container">
										<input 
											checked={quizState.canContact}
											type="checkbox"
											name='canContact'
											onChange={toggleBoolean}										
										/>
										<span class="checkmark email-checkmark" />
							</label>
							<ScrollJump />
								<span className="subheader">My top 5 matching photographers can contact me with pricing and availability.</span>
								
					</div>
				<div className="email-privacy">
					<div><img src={Lock} /></div>
					<span className="email-privacy-text">We respect your privacy. We won't share your information without your permission.</span>
				</div>
			 </div>
			 <div className="couples-love couples-love-desktop">
					<div className="couples-love-header">COUPLES LOVE US</div>
					<img src={Stars} />
					<div className="couples-body">We're rated 5 out of 5 stars because couples have so much success finding photographers they love.</div>
				</div>
				</div>
			</div>
			<FooterRender  />
		</div>
	);
};

export default EmailNew;
