import React, { useCallback, useEffect, useState } from 'react';
import { MyCustomDropzone } from './MyCustomDropzone';
import { UploadPage } from './UploadPage';
import './StepThree.css';
import { uniqueId } from '../../../utils/functions';
import { uploadConfig } from '../../../utils/upload_config';

export const StepThreeUploads = props => {
  const { setForm, step3, form, setStepThree, thumbnails, setThumbnails } = props;
  const [maxSizeError, setMaxSizeError] = useState([]);
  const [networkOnline, setNetworkOnline] = useState(true);
  const [uploadSection, setUploadSection] = useState(false);
  const [uploadSuccessChecker, setUploadSuccessChecker] = useState({
    success: 0,
    error: 0,
  });
  const onDrop = useCallback(acceptedFiles => {
    let allImages = {};

    acceptedFiles.forEach((file, index) => {
      let id = uniqueId();
      if (file.size > uploadConfig.MAX_SIZE_OF_PHOTO) {
        setMaxSizeError(prev => [...prev, { id, err: true }]);
      } else {
        setMaxSizeError(prev => [...prev, { id, err: false }]);
      }
      let imgObj = {
        id,
        imageFile: file,
        preview: URL.createObjectURL(file),
        progress: 0,
        uploaded: false,
        allowed: !(file.size > uploadConfig.MAX_SIZE_OF_PHOTO),
        error: false,
      };
      allImages = {
        ...allImages,
        [id]: imgObj,
      };
    });

    setStepThree(prev => {
      return { ...prev, ...allImages };
    });

    setUploadSection(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    window.addEventListener('offline', function (e) {
      setNetworkOnline(false);
    });
    window.addEventListener('online', function (e) {
      setNetworkOnline(true);
    });
    return window.removeEventListener('offline', function (e) {
      setNetworkOnline(false);
    });
  }, []);

  useEffect(() => {
    if (Object.keys(step3).length !== 0) setUploadSection(true);
    if (Object.keys(step3).length === 0) setUploadSection(false);
  }, [step3]);

  return (
    <div className="my_custom_container" style={{ marginTop: 30 }}>
      {!uploadSection ? (
        <MyCustomDropzone step3={step3} onDrop={onDrop} />
      ) : (
        <UploadPage
          form={form}
          step3={step3}
          setStepThree={setStepThree}
          setForm={setForm}
          maxSizeError={maxSizeError}
          setMaxSizeError={setMaxSizeError}
          thumbnails={thumbnails}
          setThumbnails={setThumbnails}
          networkOnline={networkOnline}
          setUploadSection={setUploadSection}
          uploadSuccessChecker={uploadSuccessChecker}
          setUploadSuccessChecker={setUploadSuccessChecker}
        />
      )}
    </div>
  );
};
