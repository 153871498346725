import React from 'react'
import Select from "react-select";


export const MyCustomDropdown = ({ items, placeholder, onChange, type, value, id }) => {
    console.log(items)
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontWeight: "normal",
            fontSize: "15px",
            borderBottom: '1px solid rgba(196, 196, 196, 0.47)',
            color: state.isSelected ? '#0A8A95' : '"#1B2D49"',
            // padding: 20,
            // margin: "0 20px",
        }),
        menu:(provided)=>({
            ...provided,
            zIndex:2,
            // paddingLeft: 20,
            // paddingRight: 20,
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
          display: "flex",
          height: 50,
          paddingLeft: 20,
          background: "#FFFFF",
          border: "1px solid rgba(196, 196, 196, 0.47)",
          color: "#1B2D49",
          fontFamily: 'Montserrat',
          fontStyle: "normal",
          fontWeight: "normal",
          fontSize: 14,
        //   width: "100%",
        }),
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.5 : 1;
          const transition = 'opacity 300ms';
      
          return { ...provided, opacity, transition };
        }
    }

    return(
        <div>
            <Select
                // defaultValue={colourOptions[2]}
                options={items.map(item=>{return{label:item, value:item}})}
                onChange={(e)=>onChange(type, e.value)}
                placeholder={placeholder}
                styles={customStyles}
                isSearchable={false}
                inputId={id}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                    ...theme.colors,
                    primary25: '#F5F9FA',
                    primary75: '#0A8A95',
                    primary50: '#0A8A95',
                    primary: 'white',
                    },
                })}
            />
        </div>
    )
}
