import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../UserContext";
import DashboardNav from "./dashboard-nav.component";
import axios from "axios";
import PreferredVendors from './NewVendorDashboard/preferredVendors.component'

const DashboardPreferredVendors = () => {
	let { id } = useParams();
	const { setPhotographer, setActivePage } = useContext(UserContext);
    const [ photographerData, setPhotographerData] = useState(null)
    console.log("HELLOOOOO")

	useEffect(() => {
		setActivePage('preferredVendors')
		setPhotographer(id);
		axios.get("/api/photographerdata/" + id).then((result) => {
            setPhotographerData(result.data)
            console.log(result)
		})
	}, []);

	const [ toggleNav, setToggleNav ] = useState(false);

	return (
		<div className="dashboard-container">
			<DashboardNav toggleNav={toggleNav} />
			<div
				className="dashboard-sub-container "
				onClick={() => {
					setToggleNav(!toggleNav);
				}}>
                    {photographerData && <PreferredVendors vendor={{ preferred_vendors: photographerData?.preferred_vendors}} photographerId={id} />}
			</div>
		</div>
	);
};

export default DashboardPreferredVendors;
