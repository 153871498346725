import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../UserContext";
import DashboardNav from "./dashboard-nav.component";
import axios from "axios";
import styled from "styled-components";
import { PhotographerContext } from "../PhotographerContext";
import QuestionMark from "../styles/tooltip.png"

const Category = styled.div`
	background-color: #f4f6fb;
	padding: 20px 0;
	margin-bottom: 10px;
	text-align: center;
`;

const Table = styled.table`
	border-spacing: 10px;
	max-width: 95vw;
	text-align: center;
	font-size: 15px;
	@media only screen and (max-device-width: 500px) {
		border-spacing: 5px ;
	}
`;

const ToggleButton = (props) => {
	return(		
		<button
		className={`${props.month ? 'month' : 'day'} ${props.value ? "selected" : ""}`}
		type="checkbox"
		onClick={() => props.setValue(!props.value)}>
		{props.text}
	</button>
	)
}

const DashboardPricing = () => {
	let { id } = useParams();
	const [ toggleNav, setToggleNav ] = useState(false);
	const [ weekendOn3, setWeekendOn3 ] = useState(null);
	const [ weekendOn4, setWeekendOn4 ] = useState(null);
	const [ weekendOn5, setWeekendOn5 ] = useState(null);
	const [ weekendOn6, setWeekendOn6 ] = useState(null);
	const [ weekendOn7, setWeekendOn7 ] = useState(null);
	const [ weekendOn8, setWeekendOn8 ] = useState(null);
	const [ weekendOn9, setWeekendOn9 ] = useState(null);
	const [ weekendOn10, setWeekendOn10 ] = useState(null);
	const [ weekdayOn3, setWeekdayOn3 ] = useState(null);
	const [ weekdayOn4, setWeekdayOn4 ] = useState(null);
	const [ weekdayOn5, setWeekdayOn5 ] = useState(null);
	const [ weekdayOn6, setWeekdayOn6 ] = useState(null);
	const [ weekdayOn7, setWeekdayOn7 ] = useState(null);
	const [ weekdayOn8, setWeekdayOn8 ] = useState(null);
	const [ weekdayOn9, setWeekdayOn9 ] = useState(null);
	const [ weekdayOn10, setWeekdayOn10 ] = useState(null);
	const [ weekendOff3, setWeekendOff3 ] = useState(null);
	const [ weekendOff4, setWeekendOff4 ] = useState(null);
	const [ weekendOff5, setWeekendOff5 ] = useState(null);
	const [ weekendOff6, setWeekendOff6 ] = useState(null);
	const [ weekendOff7, setWeekendOff7 ] = useState(null);
	const [ weekendOff8, setWeekendOff8 ] = useState(null);
	const [ weekendOff9, setWeekendOff9 ] = useState(null);
	const [ weekendOff10, setWeekendOff10 ] = useState(null);
	const [ weekdayOff3, setWeekdayOff3 ] = useState(null);
	const [ weekdayOff4, setWeekdayOff4 ] = useState(null);
	const [ weekdayOff5, setWeekdayOff5 ] = useState(null);
	const [ weekdayOff6, setWeekdayOff6 ] = useState(null);
	const [ weekdayOff7, setWeekdayOff7 ] = useState(null);
	const [ weekdayOff8, setWeekdayOff8 ] = useState(null);
	const [ weekdayOff9, setWeekdayOff9 ] = useState(null);
	const [ weekdayOff10, setWeekdayOff10 ] = useState(null);
	const [ januaryOff, setJanuaryOff ] = useState(false);
	const [ februaryOff, setFebruaryOff ] = useState(false);
	const [ marchOff, setMarchOff ] = useState(false);
	const [ aprilOff, setAprilOff ] = useState(false);
	const [ mayOff, setMayOff ] = useState(false);
	const [ juneOff, setJuneOff ] = useState(false);
	const [ julyOff, setJulyOff ] = useState(false);
	const [ augustOff, setAugustOff ] = useState(false);
	const [ septemberOff, setSeptemberOff ] = useState(false);
	const [ octoberOff, setOctoberOff ] = useState(false);
	const [ novemberOff, setNovemberOff ] = useState(false);
	const [ decemberOff, setDecemberOff ] = useState(false);
	const [ sundayOff, setSundayOff ] = useState(false);
	const [ mondayOff, setMondayOff ] = useState(false);
	const [ tuesdayOff, setTuesdayOff ] = useState(false);
	const [ wednesdayOff, setWednesdayOff ] = useState(false);
	const [ thursdayOff, setThursdayOff ] = useState(false);
	const [ fridayOff, setFridayOff ] = useState(false);
	const [ saturdayOff, setSaturdayOff ] = useState(false);
	const [loaded, setLoaded ] = useState(false);
	const [tooltipShowing, setTooltipShowing ] = useState(false)
	const { setPricingComplete, pricingTouched, setPricingTouched, profileComplete, setPersonalityTouched} = useContext(PhotographerContext);
	const { setPhotographer, setActivePage } = useContext(UserContext);

	const checkPricingComplete = () => {
		if(weekendOn3 > 0 && weekendOn4 > 0 && weekendOn5 > 0 && weekendOn6 > 0 && weekendOn7 > 0 && weekendOn8 > 0 && weekendOn9 > 0 && weekendOn10 > 0 &&
			weekdayOn3 > 0 && weekdayOn4 > 0 && weekdayOn5 > 0 && weekdayOn6 > 0 && weekdayOn7 > 0 && weekdayOn8 > 0 && weekdayOn9 > 0 && weekdayOn10 > 0 &&
			weekendOff3 > 0 && weekendOff4 > 0 && weekendOff5 > 0 && weekendOff6 > 0 && weekendOff7 > 0 && weekendOff8 > 0 && weekendOff9 > 0 && weekendOff10 > 0 &&
			weekdayOff3 > 0 && weekdayOff4 > 0 && weekdayOff5 > 0 && weekdayOff6 > 0 && weekdayOff7 > 0 && weekdayOff8 > 0 && weekdayOff9 > 0 && weekdayOff10 > 0){
				setPricingComplete(true)
		} else{
			if(pricingTouched){
				setPricingComplete(false)
			}
		}
	}
	useEffect(() => {
		setPricingTouched(false)
		setPersonalityTouched(false)
		setActivePage('pricingDashboard')
		setPhotographer(id)
		axios.get("/api/photographerdata/" + id).then((result) => {
			setWeekendOn3(result.data.weekendon3);
			setWeekendOn4(result.data.weekendon4);
			setWeekendOn5(result.data.weekendon5);
			setWeekendOn6(result.data.weekendon6);
			setWeekendOn7(result.data.weekendon7);
			setWeekendOn8(result.data.weekendon8);
			setWeekendOn9(result.data.weekendon9);
			setWeekendOn10(result.data.weekendon10);
			setWeekendOff3(result.data.weekendoff3);
			setWeekendOff4(result.data.weekendoff4);
			setWeekendOff5(result.data.weekendoff5);
			setWeekendOff6(result.data.weekendoff6);
			setWeekendOff7(result.data.weekendoff7);
			setWeekendOff8(result.data.weekendoff8);
			setWeekendOff9(result.data.weekendoff9);
			setWeekendOff10(result.data.weekendoff10);
			setWeekdayOff3(result.data.weekdayoff3);
			setWeekdayOff4(result.data.weekdayoff4);
			setWeekdayOff5(result.data.weekdayoff5);
			setWeekdayOff6(result.data.weekdayoff6);
			setWeekdayOff7(result.data.weekdayoff7);
			setWeekdayOff8(result.data.weekdayoff8);
			setWeekdayOff9(result.data.weekdayoff9);
			setWeekdayOff10(result.data.weekdayoff10);
			setWeekdayOn3(result.data.weekdayon3);
			setWeekdayOn4(result.data.weekdayon4);
			setWeekdayOn5(result.data.weekdayon5);
			setWeekdayOn6(result.data.weekdayon6);
			setWeekdayOn7(result.data.weekdayon7);
			setWeekdayOn8(result.data.weekdayon8);
			setWeekdayOn9(result.data.weekdayon9);
			setWeekdayOn10(result.data.weekdayon10);
			setSundayOff(result.data.sunday_off);
			setMondayOff(result.data.monday_off);
			setTuesdayOff(result.data.tuesday_off);
			setWednesdayOff(result.data.wednesday_off);
			setThursdayOff(result.data.thursday_off);
			setFridayOff(result.data.friday_off);
			setSaturdayOff(result.data.saturday_off);
			setJanuaryOff(result.data.january_off);
			setFebruaryOff(result.data.february_off);
			setMarchOff(result.data.march_off);
			setAprilOff(result.data.april_off);
			setMayOff(result.data.may_off);
			setJuneOff(result.data.june_off);
			setJulyOff(result.data.july_off);
			setAugustOff(result.data.august_off);
			setSeptemberOff(result.data.september_off);
			setOctoberOff(result.data.october_off);
			setNovemberOff(result.data.november_off);
			setDecemberOff(result.data.december_off);
		})
		.then(() => setLoaded(true))
	}, []);

	const updatePricing = () => {
		const data = {
			id,
			weekendOn3,
			weekendOn4,
			weekendOn5,
			weekendOn6,
			weekendOn7,
			weekendOn8,
			weekendOn9,
			weekendOn10,
			weekendOff3,
			weekendOff4,
			weekendOff5,
			weekendOff6,
			weekendOff7,
			weekendOff8,
			weekendOff9,
			weekendOff10,
			weekdayOn3,
			weekdayOn4,
			weekdayOn5,
			weekdayOn6,
			weekdayOn7,
			weekdayOn8,
			weekdayOn9,
			weekdayOn10,
			weekdayOff3,
			weekdayOff4,
			weekdayOff5,
			weekdayOff6,
			weekdayOff7,
			weekdayOff8,
			weekdayOff9,
			weekdayOff10
		};
		axios.post("/api/photographerpricing", data)
		.then(() => checkPricingComplete())
	};

	const updateOffSeason = () => {
		if(loaded){
			const data = {
				id,
				sundayOff,
				mondayOff,
				tuesdayOff,
				wednesdayOff,
				thursdayOff,
				fridayOff,
				saturdayOff,
				januaryOff,
				februaryOff,
				marchOff,
				aprilOff,
				mayOff,
				juneOff,
				julyOff,
				augustOff,
				septemberOff,
				octoberOff,
				novemberOff,
				decemberOff
			};
			axios.post("/api/photographeroffseason", data);
		}
	};
	useEffect(
		() => {
			updateOffSeason();
		},
		[
			januaryOff,
			februaryOff,
			marchOff,
			aprilOff,
			mayOff,
			juneOff,
			julyOff,
			augustOff,
			septemberOff,
			octoberOff,
			novemberOff,
			decemberOff,
			sundayOff,
			mondayOff,
			tuesdayOff,
			wednesdayOff,
			thursdayOff,
			fridayOff,
			saturdayOff
		]
	);

	return (
		<div className="dashboard-container">
			<DashboardNav toggleNav={toggleNav} />
			<div
				className="dashboard-sub-container "
				onClick={() => {
					setToggleNav(!toggleNav);
				}}>
					
					{profileComplete && <span className="profile-active">Active</span>}
					{!profileComplete && <span className="profile-inactive">Inactive</span>}
				<Category>
					<div className="dashboard-text-2" style={{ marginBottom: "5px", position: 'relative' }}>
						Your rates  
						<img onClick={() => setTooltipShowing(true)} className="question-mark" src={QuestionMark} />
						{tooltipShowing &&
						<div className="tip"><div onClick={() => setTooltipShowing(false)} className="close-tooltip-button">x</div>
							•This pricing table will NOT be seen by users.<br /> •Each box must have a real price in it.<br /> 
							•Prices should be for your most basic coverage that you offer<br /> •Include 2nd shooter and engagement session if they come standard.<br /> 
							•You can have the same price for mutiple fields.<br /> 
							•For example, if you have an 8 hour package but not 7, include the 8 hour price in both fields.
						</div>
						}
						<br />
					</div>
					<Table>
						<tr>
							<th>Hrs</th>
							<th>
								Weekend<br />On-Season
							</th>
							<th>
								Weekday<br />On-Season
							</th>
							<th>
								Weekend<br />Off-Season
							</th>
							<th>
								Weekday<br />Off-Season
							</th>
						</tr>
						<tr>
							<td>3</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOn3(e.target.value)} else{setWeekendOn3(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOn3}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOn3(e.target.value)} else{setWeekdayOn3(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOn3}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOff3(e.target.value)} else{setWeekendOff3(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOff3}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOff3(e.target.value)} else{setWeekdayOff3(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOff3}
									type="number"
								/>
							</td>
						</tr>
						<tr>
							<td>4</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOn4(e.target.value)} else{setWeekendOn4(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOn4}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOn4(e.target.value)} else{setWeekdayOn4(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOn4}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOff4(e.target.value)} else{setWeekendOff4(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOff4}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOff4(e.target.value)} else{setWeekdayOff4(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOff4}
									type="number"
								/>
							</td>
						</tr>
						<tr>
							<td>5</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOn5(e.target.value)} else{setWeekendOn5(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOn5}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOn5(e.target.value)} else{setWeekdayOn5(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOn5}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOff5(e.target.value)} else{setWeekendOff5(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOff5}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOff5(e.target.value)} else{setWeekdayOff5(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOff5}
									type="number"
								/>
							</td>
						</tr>
						<tr>
							<td>6</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOn6(e.target.value)} else{setWeekendOn6(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOn6}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOn6(e.target.value)} else{setWeekdayOn6(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOn6}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOff6(e.target.value)} else{setWeekendOff6(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOff6}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOff6(e.target.value)} else{setWeekdayOff6(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOff6}
									type="number"
								/>
							</td>
						</tr>
						<tr>
							<td>7</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOn7(e.target.value)} else{setWeekendOn7(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOn7}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOn7(e.target.value)} else{setWeekdayOn7(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOn7}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOff7(e.target.value)} else{setWeekendOff7(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOff7}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOff7(e.target.value)} else{setWeekdayOff7(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOff7}
									type="number"
								/>
							</td>
						</tr>
						<tr>
							<td>8</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOn8(e.target.value)} else{setWeekendOn8(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOn8}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOn8(e.target.value)} else{setWeekdayOn8(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOn8}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOff8(e.target.value)} else{setWeekendOff8(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOff8}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOff8(e.target.value)} else{setWeekdayOff8(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOff8}
									type="number"
								/>
							</td>
						</tr>
						<tr>
							<td>9</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOn9(e.target.value)} else{setWeekendOn9(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOn9}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOn9(e.target.value)} else{setWeekdayOn9(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOn9}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOff9(e.target.value)} else{setWeekendOff9(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOff9}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOff9(e.target.value)} else{setWeekdayOff9(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOff9}
									type="number"
								/>
							</td>
						</tr>
						<tr>
							<td>10</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOn10(e.target.value)} else{setWeekendOn10(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOn10}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOn10(e.target.value)} else{setWeekdayOn10(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOn10}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekendOff10(e.target.value)} else{setWeekendOff10(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekendOff10}
									type="number"
								/>
							</td>
							<td>
								<input
									ng-pattern="/^[0-9]{1,8}$|^$/"
									size="5"
									className="dashboard-pricing-input"
									onChange={(e) => {if(e.target.value != ""){setWeekdayOff10(e.target.value)} else{setWeekdayOff10(null)}; setPricingTouched(true);}}
									onBlur={updatePricing}
									value={weekdayOff10}
									type="number"
								/>
							</td>
						</tr>
					</Table>
				</Category>
				<Category>
					<div className="dashboard-text-2" style={{ marginBottom: "5px" }}>
						Select the months you consider to be "off-season"<br />
					</div>
					<ToggleButton month text="Jan" value={januaryOff} setValue={setJanuaryOff} />					
					<ToggleButton month text="Feb" value={februaryOff} setValue={setFebruaryOff} />
					<ToggleButton month text="Mar" value={marchOff} setValue={setMarchOff} />					
					<ToggleButton month text="Apr" value={aprilOff} setValue={setAprilOff} />
					<ToggleButton month text="May" value={mayOff} setValue={setMayOff} />
					<ToggleButton month text="June" value={juneOff} setValue={setJuneOff} />
					<ToggleButton month text="July" value={julyOff} setValue={setJulyOff} />
					<ToggleButton month text="Aug" value={augustOff} setValue={setAugustOff} />
					<ToggleButton month text="Sept" value={septemberOff} setValue={setSeptemberOff} />
					<ToggleButton month text="Oct" value={octoberOff} setValue={setOctoberOff} />
					<ToggleButton month text="Nov" value={novemberOff} setValue={setNovemberOff} />
					<ToggleButton month text="Dec" value={decemberOff} setValue={setDecemberOff} />
				</Category>
				<Category>
					<div className="dashboard-text-2" style={{ marginBottom: "5px" }}>
						Select the days you consider to be "weekdays"<br />
					</div>
					<ToggleButton text="Su" value={sundayOff} setValue={setSundayOff} />	
					<ToggleButton text="M" value={mondayOff} setValue={setMondayOff} />	
					<ToggleButton text="Tu" value={tuesdayOff} setValue={setTuesdayOff} />	
					<ToggleButton text="W" value={wednesdayOff} setValue={setWednesdayOff} />	
					<ToggleButton text="Th" value={thursdayOff} setValue={setThursdayOff} />	
					<ToggleButton text="F" value={fridayOff} setValue={setFridayOff} />	
					<ToggleButton text="Sa" value={saturdayOff} setValue={setSaturdayOff} />	
				</Category>
			</div>
		</div>
	);
};

export default DashboardPricing;
