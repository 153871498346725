import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../UserContext";
import DashboardNav from "./dashboard-nav.component";
import axios from "axios";

const DashboardMatches = () => {
	let { id } = useParams();
	const [ results, setResults ] = useState([]);
	const [ toggleNav, setToggleNav ] = useState(false);
	const { setPhotographer, setActivePage } = useContext(UserContext);

	useEffect(() => {
		setActivePage('matchesDashboard')
		setPhotographer(id);
		axios.get("/api/photographerresults/" + id).then((result) => {
			setResults(result.data.sort((a, b) => a.created_at > b.created_at ? -1 : 1))
		})
	}, []);

	const setStatus = (result, status) => {
		axios.post('/api/updatephotographerresult', {status, result_status: result})
		var arr = [...results]
		results.find(r => r.id == result).status = status
		setResults(arr)
	}

	const determineAge = (date, bookStatus) => {
		if(!bookStatus){
			const matchMonth = parseInt(date.substring(0,2)) - 1
			const matchYear = parseInt(date.substring(6,10))
			const matchMonths = matchYear*12 + matchMonth
			const d = new Date(Date.now())
			const currentMonth = d.getMonth()
			const currentYear = d.getFullYear()
			const currentMonths = currentYear*12 + currentMonth
			if(currentMonths - matchMonths >= 2){
				return "warning"
			} else{
				return ""
			}
		} else{
			return ""
		}
	}

	return (
		<div className="dashboard-container">
			<DashboardNav toggleNav={toggleNav} />
			<div
				className="dashboard-sub-container "
				onClick={() => {
					setToggleNav(!toggleNav);
				}}>
					*Matches fewer than 30 days old cannot yet select "No"<br />
					*Inaccurate reporting of bookings can result in removal from the platform.
					<br /><br />
                    <div className="matches-table">
                        <div className="matches-row header-row"><div className="date-cell">Match Date</div><div className="name-cell">Name</div><div className="date-cell">Wedding Date</div><div className="date-cell">Venue</div><div className="email-cell">Email</div><div className="booked-cell">Booked?</div></div>
					{results.map(r => 
                    <div className={`matches-row ${determineAge(r.created_date, r.status)}`}>
                        <div className="date-cell">{r.created_date}</div><div className="name-cell">{r.first_name} {r.can_contact && r.last_name}{!r.can_contact && r.last_name.substring(0,3) + "..."}</div><div className="date-cell">{r.month}-{r.day}-{r.year}</div><div className='venue-cell'>{r.venue_name}</div><div className="email-cell">{r.can_contact && r.email}</div>
                        <div className="booked-cell">
							<div onClick={() => setStatus(r.id, r.status == "Booked" ? null : "Booked")} className={`booked-button${r.status == 'Booked' ? " booked-button-active" : ""}`}>Yes</div>
							{parseInt((new Date(r.created_date.substring(6, 10), parseInt(r.created_date.substring(0,2)) - 1, parseInt(r.created_date.substring(3,6))) - Date.now())/1000/60/60/24) < -30 &&< div onClick={() => setStatus(r.id, r.status == "Not Booked" ? null : "Not Booked")} className={`booked-button${r.status == 'Not Booked' ? " booked-button-active" : ""}`}>No</div>}
							{parseInt((new Date(r.created_date.substring(6, 10), parseInt(r.created_date.substring(0,2)) - 1, parseInt(r.created_date.substring(3,6))) - Date.now())/1000/60/60/24) < -30 && <div onClick={() => setStatus(r.id, r.status == "Still Talking" ? null : "Still Talking")} className={`booked-button${r.status == 'Still Talking' ? " booked-button-active" : ""}`}>Still Talking</div>}</div>
                    </div>)}
                    </div>
				{/* </InfoContainer> */}
			</div>
		</div>
	);
};

export default DashboardMatches;