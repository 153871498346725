import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import NavbarNew from '.././navbarnew.component';
import Timeline from '.././timeline.component';
import Lock from "../../../styles/lock";


const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });


  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useNavigate();


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const authResponse = await axios.post('/api/user-login', {
        email: formData.email,
        password: formData.password,
      });

      const { token } = authResponse.data;

      localStorage.setItem('auth_token', token.access_token);
      localStorage.setItem('refresh_token', token.refresh_token);

      history('/home');

    } catch (error) {
      if (error.response) {
        setError(error.response.data.message || 'An error occurred during login');
      } else if (error.request) {
        setError('No response received from the server. Please try again.');
      } else {
        setError('An error occurred during login. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="w-full min-h-screen flex flex-col items-center pb-32 bg-[#F5F7FC] font-poppins">
      <div className="w-full">
        <NavbarNew />
      </div>

      <div className="w-[75%] max-w-[1000px] flex flex-col items-center px-3 pt-14 pb-0 mt-5 rounded-3xl bg-white mobile:w-[90%]">
        {/* Status Bar */}

        {/* Login Form */}
        <div className="w-full max-w-2xl mx-auto py-8 mobile:text-sm">
          <div className="px-16 mobile:px-3 mobile:mt-3">
            <h2 className="mb-3 text-2xl font-medium text-center mobile:text-xl">Log In For Couples</h2>
            <p className="text-center text-gray-600 mb-8">
              Welcome back! Please enter your details to access your account.
            </p>

            {error && <p className="text-red-500 text-center mb-4">{error}</p>}

            <form onSubmit={handleSubmit} className="space-y-4">
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full pl-3 pr-2 py-[14px] border rounded-md"
                required
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
                className="w-full pl-3 pr-2 py-[14px] border rounded-md"
                required
              />

              <div className="flex items-center justify-between ml-[3px]">
                <div className="flex items-center space-x-2">
                  <label className="checkbox-container" style={{marginRight: '0px', marginTop: '0px'}}>
                    <input
                      type="checkbox"
                      name="rememberMe"
                      checked={formData.rememberMe}
                      onChange={handleInputChange}
                    />
                    <span className="checkmark email-checkmark" />
                  </label>
                  <span className="text-sm text-gray-700 mobile:text-xs">Remember me</span>
                </div>
                <a href="/resetpassword" className="text-sm text-greenblue hover:underline mobile:text-xs">Forgot password?</a>
              </div>

              <div className="flex justify-center">
                <button
                  type="submit"
                  className="px-5 py-3 mt-[20px] rounded-full bg-greenblue text-white"
                  disabled={isLoading}
                >
                  {isLoading ? 'Logging In...' : 'Log In'}
                </button>
              </div>
            </form>

            <p className="text-center text-sm mt-4 mobile:text-xs">
              Don't have an account?{' '}
              <a href="/" className="text-greenblue hover:underline cursor-pointer">
                Get started now
              </a>
            </p>


            <p className="text-center text-sm mt-3 mobile:text-xs">
              Are you a vendor?{' '}
              <a href="/vendorlogin" className="text-greenblue hover:underline cursor-pointer">
                Login here
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;