import React, {useEffect, useState} from 'react';
import axios from 'axios';

const SignupRow = ({ signup }) => {
    const [ status, setStatus ] = useState(signup.status)

    
    const approveVenue = (id) => {
        axios.post('/api/approvesignup', {id})
        .then(r => setStatus('approved'))
    }

    return(
        <tr key={signup.id}>
            <td>{signup.business_name}</td>
            <td>{signup.first_name}</td>
            <td>{signup.last_name}</td>
            <td>{signup.city}</td>
            <td>{signup.state}</td>
            <td>{signup.zip_code}</td>
            <td>{signup.planned_account_use.map((p, index) => <span key={index}>{`${p}${index === signup.planned_account_use.length - 1 ? '' : ', '}`}</span>)}</td>
            <td>{signup.ceremony_exact_locations.map((c, index) => <span key={index}>{`${c}${index === signup.ceremony_exact_locations.length - 1 ? '' : ', '}`}</span>)}</td>
            <td>{signup.reception_exact_locations.map((c, index) => <span key={index}>{`${c}${index === signup.reception_exact_locations.length - 1 ? '' : ', '}`}</span>)}</td>
            <td><a rel="noopener noreferrer" target='_blank' href={signup.preferred_photographers_url}>{signup.preferred_photographers_url}</a></td>
            <td>
                {status !== 'approved' &&<button style={{border: '1px solid black', borderRadius: '5px'}} onClick={() => approveVenue(signup.id)}>Approve</button>}
                {status === 'approved' && <div>Approved</div>}
            
            </td>
        </tr>
    )
}
const SignupList = () => {   
    const [ signups, setSignups ] = useState([])
    useEffect(() => {
        axios.get('/api/getsignups')
        .then(r => setSignups(r.data))
    }, [])

    return(
        <table>
            <tr>
                <th>Business Name</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>City</th>
                <th>State</th>
                <th>Zip</th>
                <th>Planned usage</th>
                <th>Ceremony Locations</th>
                <th>Reception Locations</th>
                <th>Preferred Photographrs Url</th>
                <th>Status</th>
            </tr>
           {signups.map(s =>
                <SignupRow signup={s} />
           )}
        </table>
    )
}

export default SignupList;