import React, { useState, useContext, useEffect, useRef } from "react";
import { AnswersContext } from "../../AnswersContext";
import Slider from '@mui/material/Slider';
import { containerVarients } from "../../animations/animations";
import NavbarNew from "./navbarnew.component";
import Timeline from "./timeline.component";
import FooterNew from "./footernew.component";
import styled from "styled-components";
import { Mixpanel } from '../../Mixpanel';
import DownArrow from '../../styles/down-arrow.svg';
import ScrollJump from "../scroll-jump.component";

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	width: 375px;
	max-width: 100%;
`;

const Section = styled.section`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 40px;
`;

const BarOutline = styled.div`
	width: 100%;
	height: 10px;
	background: #E8EAED;
	border-radius: 10px;
	margin-bottom: 10px;
`;

const hoursOptions = [3, 4, 5, 6, 7, 8, 9, 10]

const FooterRender = ({disabled, setFinished}) => {
	if(!disabled){
		return <FooterNew to="/budget" from="/date" button={true} buttonText="Next: Budget" otherText="Basic Information 3/4" clickEvent={() => setFinished(true)} />;
	} else{
		return <FooterNew inactive={true} to="" from="/date" button={true} buttonText="Next: Budget" otherText="Basic Information 3/4" />;
	}
};
const ImportantNew = () => {

	const {
		quizState,
		toggleBoolean,
		setValue,
	} = useContext(AnswersContext);
	const [ showHours, setShowHours ] = useState(false)
	useEffect(() => {
		Mixpanel.track('View Priorities Page');
	}, [])

	//convert percentage data into 1-5 to store in state
	const convertToScale = (i) => {
		if (i === 7) return 1;
		if (i === 28) return 2;
		if (i === 50) return 3;
		if (i === 72) return 4;
		if (i === 93) return 5;
	};
	const convertToPercentage = (i) => {
		if (i === 1) return 7;
		if (i === 2) return 28;
		if (i === 3) return 50;
		if (i === 4) return 72;
		if (i === 5) return 93;
	};
	const [ finished, setFinished ] = useState(false);

	const marks = [
		{
			value: 7
		},
		{
			value: 28
		},
		{
			value: 50
		},
		{
			value: 72
		},
		{
			value: 93
		}
	];

	const options = {
		root: {
			color: "transparent",
			height: 10,
			padding: 0,
			width: "100%",
			margin: "auto"
		},
		thumb: {
			height: 24,
			width: 24,
			backgroundColor: "white",
			transform: "translate(-20%, -20%)",
			"&:focus, &:hover, &$active": {
				boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
				// Reset on touch devices, it doesn't add specificity
				"@media (hover: none)": {
					boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)"
				}
			},
            border: '3px solid #FFFFFF',
            boxSizing: 'border-box',
            boxShadow: '0px 3px 12px rgba(61, 136, 147, 0.3)'
		},
		track: {
			height: 10,
			backgroundColor: "#64A0A9",
            bottom: '8px'
		},
		rail: {
			height: 10,
			opacity: 1,
			backgroundColor: "transparent"
		},
		mark: {
			backgroundColor: "white",
            width: '2px',
            height: '10px',
            transform: 'translateY(-80%)',
            backgroundColor: 'white'
		},
		markActive: {
			opacity: 1,
			backgroundColor: "white"
		},
		active: {}
	};

	const CustomSlider = styled(Slider)(({ theme }) => ({
		color: 'transparent',
		height: 10,
		padding: 0,
		width: '100%',
		margin: 'auto',
		'& .MuiSlider-thumb': {
			height: 24,
			width: 24,
			backgroundColor: 'white',
			transform: 'translate(-50%, -50%)',
			'&:focus, &:hover, &.Mui-active': {
				boxShadow: '0 3px 1px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.3), 0 0 0 1px rgba(0,0,0,0.02)',
				'@media (hover: none)': {
					boxShadow: '0 3px 1px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.13), 0 0 0 1px rgba(0,0,0,0.02)',
				},
			},
			border: '3px solid #FFFFFF',
			boxSizing: 'border-box',
			boxShadow: '0px 3px 12px rgba(61, 136, 147, 0.3)',
		},
		'& .MuiSlider-track': {
			height: 10,
			backgroundColor: '#64A0A9',
			marginTop: -10,
		},
		'& .MuiSlider-rail': {
			height: 10,
			opacity: 1,
			backgroundColor: 'transparent',
		},
		'& .MuiSlider-mark': {
			backgroundColor: 'white',
			width: '2px',
			height: '10px',
			transform: 'translateY(-100%)',
			marginTop: -5,
		},
		'& .MuiSlider-markActive': {
			opacity: 1,
			backgroundColor: 'white',
		},
	}));

	//fix value
	const onChangeGettingReady = (value, type) => {
		let fixedValue = value;

		if (value >= 0 && value <= 13) {
			fixedValue = 7;
		}
		if (value >= 14 && value <= 37) {
			fixedValue = 28;
		}
		if (value >= 38 && value <= 62) {
			fixedValue = 50;
		}
		if (value >= 63 && value <= 87) {
			fixedValue = 72;
		}
		if (value >= 88 && value <= 100) {
			fixedValue = 93;
		}
		setValue(type, convertToScale(fixedValue))
	};

	return (
		<div className="question-page">

			<NavbarNew />
			<Timeline phase="howimportant" />
			<div
				className="question-container "
				variants={containerVarients}
				initial="hidden"
			>

				<div>
					<div className="header">Priorities</div>
					<div className="subheader">When comparing photographer’s photos, we’ll show you more or less from each part of the day based on your priorities.</div>
				</div>

				<div>
					<div className="question" style={{marginBottom: '0px'}}>
					Select the parts of the day you want photography coverage for, and then adjust their importance:
					</div>
					<div className="hide-on-mobile"><br /></div><br />
					<div>
						<Section id="getting-ready">

						<div className="slider-wrapper">
							<TitleContainer>
								<label class="how-important-checkbox-container">
									<input
										checked={quizState.gettingReadyChecked}
										name='gettingReadyChecked'
										type="checkbox"
										onClick={toggleBoolean}
									/>
									<span class="how-important-checkmark" />
								</label>
								<h2 className="slider-title">Getting Ready</h2>
							</TitleContainer>
								<span className="slider-label">Not</span>
								<BarOutline className={quizState.gettingReadyChecked ? "" : "disabled-outline"}>
									 <CustomSlider
											disabled={!quizState.gettingReadyChecked}
											defaultValue={convertToPercentage(quizState.gettingReadyImportance)}
											marks={marks}
											valueLabelDisplay="off"
											onChangeCommitted={(event, value) => {
												onChangeGettingReady(value, 'gettingReadyImportance');
											}}
										/>
								</BarOutline>
								<span className="slider-label">Very</span>
								<div className="mobile-slider-labels"><div>Not</div><div>Very</div></div>
							</div>
						</Section>

						<Section id="ceremony">
						<div className="slider-wrapper">
							<TitleContainer>
								<label class="how-important-checkbox-container">
									<input
										checked={quizState.ceremonyChecked}
										type="checkbox"
										name='ceremonyChecked'
										onClick={toggleBoolean}
									/>
									<span class="how-important-checkmark" />
								</label>
								<h2 className="slider-title">Ceremony</h2>
							</TitleContainer>

							<span className="slider-label">Not</span>
							<BarOutline className={quizState.ceremonyChecked ? "" : "disabled-outline"}>
								<CustomSlider
									disabled={!quizState.ceremonyChecked}
									defaultValue={convertToPercentage(quizState.ceremonyImportance)}
									marks={marks}
									valueLabelDisplay="off"
									onChangeCommitted={(event, value) => {
										onChangeGettingReady(value, 'ceremonyImportance');
									}}
								/>
							</BarOutline>
								<span className="slider-label">Very</span>
								<div className="mobile-slider-labels"><div>Not</div><div>Very</div></div>
								</div>
						</Section>

						<Section id="couples-portraits">
						<div className="slider-wrapper">
							<TitleContainer>
								<label class="how-important-checkbox-container">
									<input
										checked={quizState.portraitsChecked}
										type="checkbox"
										name='portraitsChecked'
										onClick={toggleBoolean}
									/>
									<span class="how-important-checkmark" />
								</label>

								<h2 className="slider-title">Couple's Portraits</h2>
							</TitleContainer>
								<span className="slider-label">Not</span>
							<BarOutline className={quizState.portraitsChecked ? "" : "disabled-outline"}>
								<CustomSlider
									disabled={!quizState.portraitsChecked}
									defaultValue={convertToPercentage(quizState.portraitsImportance)}
									marks={marks}
									valueLabelDisplay="off"
									onChangeCommitted={(event, value) => {
										onChangeGettingReady(value, 'portraitsImportance');
									}}
								/>
							</BarOutline>
								<span className="slider-label">Very</span>
								<div className="mobile-slider-labels"><div>Not</div><div>Very</div></div>
								</div>
						</Section>

						<Section id="reception">
							<div className="slider-wrapper">
							<TitleContainer>
							<label class="how-important-checkbox-container">
									<input
										checked={quizState.receptionChecked}
										type="checkbox"
										name='receptionChecked'
										onClick={toggleBoolean}
									/>
									<span class="how-important-checkmark" />
								</label>
								<h2 className="slider-title">Reception</h2>
							</TitleContainer>
								<span className="slider-label">Not</span>
							<BarOutline className={quizState.receptionChecked ? "" : "disabled-outline"}>
								<CustomSlider
									disabled={!quizState.receptionChecked}
									defaultValue={convertToPercentage(quizState.receptionImportance)}
									marks={marks}
									valueLabelDisplay="off"
									onChangeCommitted={(event, value) => {
										onChangeGettingReady(value, 'receptionImportance');
									}}
								/>
							</BarOutline>
								<span className="slider-label">Very</span>
								<div className="mobile-slider-labels"><div>Not</div><div>Very</div></div>
								</div>
						</Section>
					</div>
					<ScrollJump />
					<div className="question" style={{marginBottom: '0px'}}>
					How many hours of wedding day coverage will you need?
					</div>
					<br />
					<div style={{display: 'flex', alignItems: 'center'}}>
					<div onClick={() => setShowHours(!showHours)} style={{paddingTop: '12px', marginRight: '20px'}} className="location-dropdown date-dropdown">
							<div style={{display: 'flex', justifyContent: 'space-between'}} >
								{(showHours || !quizState.hoursSelected) &&
								 	<span className="dropdown-placeholder">Hours</span>
								}
								{quizState.hoursSelected && !showHours &&
									<span className="dropdown-chosen date-chosen">{quizState.hoursSelected}</span>
								}
								<img style={{width: '16px'}} src={DownArrow} /> </div>
							{showHours &&
								<div className="location-options date-options">
									<div className="location-option" style={{paddingTop: '10px'}} onClick={() => {setValue('hoursSelected', null); setShowHours(!showHours)}}>----</div>
									{hoursOptions.map(h =>
										<div className="location-option" onClick={() => {setValue('hoursSelected', h); setShowHours(!showHours)}}>{h}</div>
									)}
								</div>
							}
						</div>
						<div className="subheader" style={{marginBottom: '0px'}}>
							Leave blank if unsure, we can estimate based on your answers
						</div>
						</div>
				</div>
			</div>
			<FooterRender disabled={!quizState.ceremonyChecked &&! quizState.portraitsChecked && !quizState.receptionChecked && !quizState.gettingReadyChecked} setFinished={setFinished} />
		</div>
	);
};

export default ImportantNew;
